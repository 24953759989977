import gql from "graphql-tag";
import * as Urql from "urql";
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  date: any;
  float8: any;
  geography: any;
  geometry: any;
  jsonb: any;
  name: any;
  time: any;
  timestamptz: any;
  uuid: any;
};

/** Boolean expression to compare columns of type "Boolean". All fields are combined with logical 'AND'. */
export type Boolean_Comparison_Exp = {
  _eq?: InputMaybe<Scalars["Boolean"]>;
  _gt?: InputMaybe<Scalars["Boolean"]>;
  _gte?: InputMaybe<Scalars["Boolean"]>;
  _in?: InputMaybe<Array<Scalars["Boolean"]>>;
  _is_null?: InputMaybe<Scalars["Boolean"]>;
  _lt?: InputMaybe<Scalars["Boolean"]>;
  _lte?: InputMaybe<Scalars["Boolean"]>;
  _neq?: InputMaybe<Scalars["Boolean"]>;
  _nin?: InputMaybe<Array<Scalars["Boolean"]>>;
};

/** Boolean expression to compare columns of type "Int". All fields are combined with logical 'AND'. */
export type Int_Comparison_Exp = {
  _eq?: InputMaybe<Scalars["Int"]>;
  _gt?: InputMaybe<Scalars["Int"]>;
  _gte?: InputMaybe<Scalars["Int"]>;
  _in?: InputMaybe<Array<Scalars["Int"]>>;
  _is_null?: InputMaybe<Scalars["Boolean"]>;
  _lt?: InputMaybe<Scalars["Int"]>;
  _lte?: InputMaybe<Scalars["Int"]>;
  _neq?: InputMaybe<Scalars["Int"]>;
  _nin?: InputMaybe<Array<Scalars["Int"]>>;
};

/** Boolean expression to compare columns of type "String". All fields are combined with logical 'AND'. */
export type String_Comparison_Exp = {
  _eq?: InputMaybe<Scalars["String"]>;
  _gt?: InputMaybe<Scalars["String"]>;
  _gte?: InputMaybe<Scalars["String"]>;
  /** does the column match the given case-insensitive pattern */
  _ilike?: InputMaybe<Scalars["String"]>;
  _in?: InputMaybe<Array<Scalars["String"]>>;
  /** does the column match the given POSIX regular expression, case insensitive */
  _iregex?: InputMaybe<Scalars["String"]>;
  _is_null?: InputMaybe<Scalars["Boolean"]>;
  /** does the column match the given pattern */
  _like?: InputMaybe<Scalars["String"]>;
  _lt?: InputMaybe<Scalars["String"]>;
  _lte?: InputMaybe<Scalars["String"]>;
  _neq?: InputMaybe<Scalars["String"]>;
  /** does the column NOT match the given case-insensitive pattern */
  _nilike?: InputMaybe<Scalars["String"]>;
  _nin?: InputMaybe<Array<Scalars["String"]>>;
  /** does the column NOT match the given POSIX regular expression, case insensitive */
  _niregex?: InputMaybe<Scalars["String"]>;
  /** does the column NOT match the given pattern */
  _nlike?: InputMaybe<Scalars["String"]>;
  /** does the column NOT match the given POSIX regular expression, case sensitive */
  _nregex?: InputMaybe<Scalars["String"]>;
  /** does the column NOT match the given SQL regular expression */
  _nsimilar?: InputMaybe<Scalars["String"]>;
  /** does the column match the given POSIX regular expression, case sensitive */
  _regex?: InputMaybe<Scalars["String"]>;
  /** does the column match the given SQL regular expression */
  _similar?: InputMaybe<Scalars["String"]>;
};

/** columns and relationships of "advertisement" */
export type Advertisement = {
  __typename?: "advertisement";
  accepted?: Maybe<Scalars["Boolean"]>;
  app_state: Scalars["String"];
  /** An object relationship */
  collaborator?: Maybe<Collaborator>;
  collaborator_id?: Maybe<Scalars["uuid"]>;
  created_at: Scalars["timestamptz"];
  firebase_id?: Maybe<Scalars["String"]>;
  /** An object relationship */
  geopoint?: Maybe<Geopoint>;
  geopoint_id?: Maybe<Scalars["uuid"]>;
  id: Scalars["uuid"];
  updated_at: Scalars["timestamptz"];
  /** An object relationship */
  workspace?: Maybe<Workspace>;
  workspace_id?: Maybe<Scalars["uuid"]>;
};

/** aggregated selection of "advertisement" */
export type Advertisement_Aggregate = {
  __typename?: "advertisement_aggregate";
  aggregate?: Maybe<Advertisement_Aggregate_Fields>;
  nodes: Array<Advertisement>;
};

export type Advertisement_Aggregate_Bool_Exp = {
  bool_and?: InputMaybe<Advertisement_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: InputMaybe<Advertisement_Aggregate_Bool_Exp_Bool_Or>;
  count?: InputMaybe<Advertisement_Aggregate_Bool_Exp_Count>;
};

export type Advertisement_Aggregate_Bool_Exp_Bool_And = {
  arguments: Advertisement_Select_Column_Advertisement_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: InputMaybe<Scalars["Boolean"]>;
  filter?: InputMaybe<Advertisement_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Advertisement_Aggregate_Bool_Exp_Bool_Or = {
  arguments: Advertisement_Select_Column_Advertisement_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: InputMaybe<Scalars["Boolean"]>;
  filter?: InputMaybe<Advertisement_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Advertisement_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Advertisement_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]>;
  filter?: InputMaybe<Advertisement_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "advertisement" */
export type Advertisement_Aggregate_Fields = {
  __typename?: "advertisement_aggregate_fields";
  count: Scalars["Int"];
  max?: Maybe<Advertisement_Max_Fields>;
  min?: Maybe<Advertisement_Min_Fields>;
};

/** aggregate fields of "advertisement" */
export type Advertisement_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Advertisement_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "advertisement" */
export type Advertisement_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Advertisement_Max_Order_By>;
  min?: InputMaybe<Advertisement_Min_Order_By>;
};

/** input type for inserting array relation for remote table "advertisement" */
export type Advertisement_Arr_Rel_Insert_Input = {
  data: Array<Advertisement_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Advertisement_On_Conflict>;
};

/** Boolean expression to filter rows from the table "advertisement". All fields are combined with a logical 'AND'. */
export type Advertisement_Bool_Exp = {
  _and?: InputMaybe<Array<Advertisement_Bool_Exp>>;
  _not?: InputMaybe<Advertisement_Bool_Exp>;
  _or?: InputMaybe<Array<Advertisement_Bool_Exp>>;
  accepted?: InputMaybe<Boolean_Comparison_Exp>;
  app_state?: InputMaybe<String_Comparison_Exp>;
  collaborator?: InputMaybe<Collaborator_Bool_Exp>;
  collaborator_id?: InputMaybe<Uuid_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  firebase_id?: InputMaybe<String_Comparison_Exp>;
  geopoint?: InputMaybe<Geopoint_Bool_Exp>;
  geopoint_id?: InputMaybe<Uuid_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  workspace?: InputMaybe<Workspace_Bool_Exp>;
  workspace_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "advertisement" */
export enum Advertisement_Constraint {
  /** unique or primary key constraint on columns "id" */
  AdvertisementPkey = "advertisement_pkey",
}

/** input type for inserting data into table "advertisement" */
export type Advertisement_Insert_Input = {
  accepted?: InputMaybe<Scalars["Boolean"]>;
  app_state?: InputMaybe<Scalars["String"]>;
  collaborator?: InputMaybe<Collaborator_Obj_Rel_Insert_Input>;
  collaborator_id?: InputMaybe<Scalars["uuid"]>;
  created_at?: InputMaybe<Scalars["timestamptz"]>;
  firebase_id?: InputMaybe<Scalars["String"]>;
  geopoint?: InputMaybe<Geopoint_Obj_Rel_Insert_Input>;
  geopoint_id?: InputMaybe<Scalars["uuid"]>;
  id?: InputMaybe<Scalars["uuid"]>;
  updated_at?: InputMaybe<Scalars["timestamptz"]>;
  workspace?: InputMaybe<Workspace_Obj_Rel_Insert_Input>;
  workspace_id?: InputMaybe<Scalars["uuid"]>;
};

/** aggregate max on columns */
export type Advertisement_Max_Fields = {
  __typename?: "advertisement_max_fields";
  app_state?: Maybe<Scalars["String"]>;
  collaborator_id?: Maybe<Scalars["uuid"]>;
  created_at?: Maybe<Scalars["timestamptz"]>;
  firebase_id?: Maybe<Scalars["String"]>;
  geopoint_id?: Maybe<Scalars["uuid"]>;
  id?: Maybe<Scalars["uuid"]>;
  updated_at?: Maybe<Scalars["timestamptz"]>;
  workspace_id?: Maybe<Scalars["uuid"]>;
};

/** order by max() on columns of table "advertisement" */
export type Advertisement_Max_Order_By = {
  app_state?: InputMaybe<Order_By>;
  collaborator_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  firebase_id?: InputMaybe<Order_By>;
  geopoint_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  workspace_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Advertisement_Min_Fields = {
  __typename?: "advertisement_min_fields";
  app_state?: Maybe<Scalars["String"]>;
  collaborator_id?: Maybe<Scalars["uuid"]>;
  created_at?: Maybe<Scalars["timestamptz"]>;
  firebase_id?: Maybe<Scalars["String"]>;
  geopoint_id?: Maybe<Scalars["uuid"]>;
  id?: Maybe<Scalars["uuid"]>;
  updated_at?: Maybe<Scalars["timestamptz"]>;
  workspace_id?: Maybe<Scalars["uuid"]>;
};

/** order by min() on columns of table "advertisement" */
export type Advertisement_Min_Order_By = {
  app_state?: InputMaybe<Order_By>;
  collaborator_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  firebase_id?: InputMaybe<Order_By>;
  geopoint_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  workspace_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "advertisement" */
export type Advertisement_Mutation_Response = {
  __typename?: "advertisement_mutation_response";
  /** number of rows affected by the mutation */
  affected_rows: Scalars["Int"];
  /** data from the rows affected by the mutation */
  returning: Array<Advertisement>;
};

/** on_conflict condition type for table "advertisement" */
export type Advertisement_On_Conflict = {
  constraint: Advertisement_Constraint;
  update_columns?: Array<Advertisement_Update_Column>;
  where?: InputMaybe<Advertisement_Bool_Exp>;
};

/** Ordering options when selecting data from "advertisement". */
export type Advertisement_Order_By = {
  accepted?: InputMaybe<Order_By>;
  app_state?: InputMaybe<Order_By>;
  collaborator?: InputMaybe<Collaborator_Order_By>;
  collaborator_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  firebase_id?: InputMaybe<Order_By>;
  geopoint?: InputMaybe<Geopoint_Order_By>;
  geopoint_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  workspace?: InputMaybe<Workspace_Order_By>;
  workspace_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: advertisement */
export type Advertisement_Pk_Columns_Input = {
  id: Scalars["uuid"];
};

/** select columns of table "advertisement" */
export enum Advertisement_Select_Column {
  /** column name */
  Accepted = "accepted",
  /** column name */
  AppState = "app_state",
  /** column name */
  CollaboratorId = "collaborator_id",
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  FirebaseId = "firebase_id",
  /** column name */
  GeopointId = "geopoint_id",
  /** column name */
  Id = "id",
  /** column name */
  UpdatedAt = "updated_at",
  /** column name */
  WorkspaceId = "workspace_id",
}

/** select "advertisement_aggregate_bool_exp_bool_and_arguments_columns" columns of table "advertisement" */
export enum Advertisement_Select_Column_Advertisement_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  Accepted = "accepted",
}

/** select "advertisement_aggregate_bool_exp_bool_or_arguments_columns" columns of table "advertisement" */
export enum Advertisement_Select_Column_Advertisement_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  Accepted = "accepted",
}

/** input type for updating data in table "advertisement" */
export type Advertisement_Set_Input = {
  accepted?: InputMaybe<Scalars["Boolean"]>;
  app_state?: InputMaybe<Scalars["String"]>;
  collaborator_id?: InputMaybe<Scalars["uuid"]>;
  created_at?: InputMaybe<Scalars["timestamptz"]>;
  firebase_id?: InputMaybe<Scalars["String"]>;
  geopoint_id?: InputMaybe<Scalars["uuid"]>;
  id?: InputMaybe<Scalars["uuid"]>;
  updated_at?: InputMaybe<Scalars["timestamptz"]>;
  workspace_id?: InputMaybe<Scalars["uuid"]>;
};

/** Streaming cursor of the table "advertisement" */
export type Advertisement_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Advertisement_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Advertisement_Stream_Cursor_Value_Input = {
  accepted?: InputMaybe<Scalars["Boolean"]>;
  app_state?: InputMaybe<Scalars["String"]>;
  collaborator_id?: InputMaybe<Scalars["uuid"]>;
  created_at?: InputMaybe<Scalars["timestamptz"]>;
  firebase_id?: InputMaybe<Scalars["String"]>;
  geopoint_id?: InputMaybe<Scalars["uuid"]>;
  id?: InputMaybe<Scalars["uuid"]>;
  updated_at?: InputMaybe<Scalars["timestamptz"]>;
  workspace_id?: InputMaybe<Scalars["uuid"]>;
};

/** update columns of table "advertisement" */
export enum Advertisement_Update_Column {
  /** column name */
  Accepted = "accepted",
  /** column name */
  AppState = "app_state",
  /** column name */
  CollaboratorId = "collaborator_id",
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  FirebaseId = "firebase_id",
  /** column name */
  GeopointId = "geopoint_id",
  /** column name */
  Id = "id",
  /** column name */
  UpdatedAt = "updated_at",
  /** column name */
  WorkspaceId = "workspace_id",
}

export type Advertisement_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Advertisement_Set_Input>;
  /** filter the rows which have to be updated */
  where: Advertisement_Bool_Exp;
};

/** columns and relationships of "collaborator" */
export type Collaborator = {
  __typename?: "collaborator";
  /** An array relationship */
  advertisements: Array<Advertisement>;
  /** An aggregate relationship */
  advertisements_aggregate: Advertisement_Aggregate;
  created_at: Scalars["timestamptz"];
  dni?: Maybe<Scalars["String"]>;
  email: Scalars["String"];
  id: Scalars["uuid"];
  /** An array relationship */
  inspections: Array<Inspection>;
  /** An aggregate relationship */
  inspections_aggregate: Inspection_Aggregate;
  name?: Maybe<Scalars["String"]>;
  /** An array relationship */
  observations: Array<Observation>;
  /** An aggregate relationship */
  observations_aggregate: Observation_Aggregate;
  updated_at: Scalars["timestamptz"];
  /** An array relationship */
  work_sessions: Array<Work_Sessions>;
  /** An aggregate relationship */
  work_sessions_aggregate: Work_Sessions_Aggregate;
  /** An array relationship */
  workspaces: Array<Workspace>;
  /** An aggregate relationship */
  workspaces_aggregate: Workspace_Aggregate;
};

/** columns and relationships of "collaborator" */
export type CollaboratorAdvertisementsArgs = {
  distinct_on?: InputMaybe<Array<Advertisement_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Advertisement_Order_By>>;
  where?: InputMaybe<Advertisement_Bool_Exp>;
};

/** columns and relationships of "collaborator" */
export type CollaboratorAdvertisements_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Advertisement_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Advertisement_Order_By>>;
  where?: InputMaybe<Advertisement_Bool_Exp>;
};

/** columns and relationships of "collaborator" */
export type CollaboratorInspectionsArgs = {
  distinct_on?: InputMaybe<Array<Inspection_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Inspection_Order_By>>;
  where?: InputMaybe<Inspection_Bool_Exp>;
};

/** columns and relationships of "collaborator" */
export type CollaboratorInspections_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Inspection_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Inspection_Order_By>>;
  where?: InputMaybe<Inspection_Bool_Exp>;
};

/** columns and relationships of "collaborator" */
export type CollaboratorObservationsArgs = {
  distinct_on?: InputMaybe<Array<Observation_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Observation_Order_By>>;
  where?: InputMaybe<Observation_Bool_Exp>;
};

/** columns and relationships of "collaborator" */
export type CollaboratorObservations_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Observation_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Observation_Order_By>>;
  where?: InputMaybe<Observation_Bool_Exp>;
};

/** columns and relationships of "collaborator" */
export type CollaboratorWork_SessionsArgs = {
  distinct_on?: InputMaybe<Array<Work_Sessions_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Work_Sessions_Order_By>>;
  where?: InputMaybe<Work_Sessions_Bool_Exp>;
};

/** columns and relationships of "collaborator" */
export type CollaboratorWork_Sessions_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Work_Sessions_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Work_Sessions_Order_By>>;
  where?: InputMaybe<Work_Sessions_Bool_Exp>;
};

/** columns and relationships of "collaborator" */
export type CollaboratorWorkspacesArgs = {
  distinct_on?: InputMaybe<Array<Workspace_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Workspace_Order_By>>;
  where?: InputMaybe<Workspace_Bool_Exp>;
};

/** columns and relationships of "collaborator" */
export type CollaboratorWorkspaces_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Workspace_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Workspace_Order_By>>;
  where?: InputMaybe<Workspace_Bool_Exp>;
};

/** aggregated selection of "collaborator" */
export type Collaborator_Aggregate = {
  __typename?: "collaborator_aggregate";
  aggregate?: Maybe<Collaborator_Aggregate_Fields>;
  nodes: Array<Collaborator>;
};

/** aggregate fields of "collaborator" */
export type Collaborator_Aggregate_Fields = {
  __typename?: "collaborator_aggregate_fields";
  count: Scalars["Int"];
  max?: Maybe<Collaborator_Max_Fields>;
  min?: Maybe<Collaborator_Min_Fields>;
};

/** aggregate fields of "collaborator" */
export type Collaborator_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Collaborator_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]>;
};

/** Boolean expression to filter rows from the table "collaborator". All fields are combined with a logical 'AND'. */
export type Collaborator_Bool_Exp = {
  _and?: InputMaybe<Array<Collaborator_Bool_Exp>>;
  _not?: InputMaybe<Collaborator_Bool_Exp>;
  _or?: InputMaybe<Array<Collaborator_Bool_Exp>>;
  advertisements?: InputMaybe<Advertisement_Bool_Exp>;
  advertisements_aggregate?: InputMaybe<Advertisement_Aggregate_Bool_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  dni?: InputMaybe<String_Comparison_Exp>;
  email?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  inspections?: InputMaybe<Inspection_Bool_Exp>;
  inspections_aggregate?: InputMaybe<Inspection_Aggregate_Bool_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  observations?: InputMaybe<Observation_Bool_Exp>;
  observations_aggregate?: InputMaybe<Observation_Aggregate_Bool_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  work_sessions?: InputMaybe<Work_Sessions_Bool_Exp>;
  work_sessions_aggregate?: InputMaybe<Work_Sessions_Aggregate_Bool_Exp>;
  workspaces?: InputMaybe<Workspace_Bool_Exp>;
  workspaces_aggregate?: InputMaybe<Workspace_Aggregate_Bool_Exp>;
};

/** unique or primary key constraints on table "collaborator" */
export enum Collaborator_Constraint {
  /** unique or primary key constraint on columns "id" */
  CollaboratorPkey = "collaborator_pkey",
}

/** input type for inserting data into table "collaborator" */
export type Collaborator_Insert_Input = {
  advertisements?: InputMaybe<Advertisement_Arr_Rel_Insert_Input>;
  created_at?: InputMaybe<Scalars["timestamptz"]>;
  dni?: InputMaybe<Scalars["String"]>;
  email?: InputMaybe<Scalars["String"]>;
  id?: InputMaybe<Scalars["uuid"]>;
  inspections?: InputMaybe<Inspection_Arr_Rel_Insert_Input>;
  name?: InputMaybe<Scalars["String"]>;
  observations?: InputMaybe<Observation_Arr_Rel_Insert_Input>;
  updated_at?: InputMaybe<Scalars["timestamptz"]>;
  work_sessions?: InputMaybe<Work_Sessions_Arr_Rel_Insert_Input>;
  workspaces?: InputMaybe<Workspace_Arr_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Collaborator_Max_Fields = {
  __typename?: "collaborator_max_fields";
  created_at?: Maybe<Scalars["timestamptz"]>;
  dni?: Maybe<Scalars["String"]>;
  email?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["uuid"]>;
  name?: Maybe<Scalars["String"]>;
  updated_at?: Maybe<Scalars["timestamptz"]>;
};

/** aggregate min on columns */
export type Collaborator_Min_Fields = {
  __typename?: "collaborator_min_fields";
  created_at?: Maybe<Scalars["timestamptz"]>;
  dni?: Maybe<Scalars["String"]>;
  email?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["uuid"]>;
  name?: Maybe<Scalars["String"]>;
  updated_at?: Maybe<Scalars["timestamptz"]>;
};

/** response of any mutation on the table "collaborator" */
export type Collaborator_Mutation_Response = {
  __typename?: "collaborator_mutation_response";
  /** number of rows affected by the mutation */
  affected_rows: Scalars["Int"];
  /** data from the rows affected by the mutation */
  returning: Array<Collaborator>;
};

/** input type for inserting object relation for remote table "collaborator" */
export type Collaborator_Obj_Rel_Insert_Input = {
  data: Collaborator_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Collaborator_On_Conflict>;
};

/** on_conflict condition type for table "collaborator" */
export type Collaborator_On_Conflict = {
  constraint: Collaborator_Constraint;
  update_columns?: Array<Collaborator_Update_Column>;
  where?: InputMaybe<Collaborator_Bool_Exp>;
};

/** Ordering options when selecting data from "collaborator". */
export type Collaborator_Order_By = {
  advertisements_aggregate?: InputMaybe<Advertisement_Aggregate_Order_By>;
  created_at?: InputMaybe<Order_By>;
  dni?: InputMaybe<Order_By>;
  email?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  inspections_aggregate?: InputMaybe<Inspection_Aggregate_Order_By>;
  name?: InputMaybe<Order_By>;
  observations_aggregate?: InputMaybe<Observation_Aggregate_Order_By>;
  updated_at?: InputMaybe<Order_By>;
  work_sessions_aggregate?: InputMaybe<Work_Sessions_Aggregate_Order_By>;
  workspaces_aggregate?: InputMaybe<Workspace_Aggregate_Order_By>;
};

/** primary key columns input for table: collaborator */
export type Collaborator_Pk_Columns_Input = {
  id: Scalars["uuid"];
};

/** select columns of table "collaborator" */
export enum Collaborator_Select_Column {
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  Dni = "dni",
  /** column name */
  Email = "email",
  /** column name */
  Id = "id",
  /** column name */
  Name = "name",
  /** column name */
  UpdatedAt = "updated_at",
}

/** input type for updating data in table "collaborator" */
export type Collaborator_Set_Input = {
  created_at?: InputMaybe<Scalars["timestamptz"]>;
  dni?: InputMaybe<Scalars["String"]>;
  email?: InputMaybe<Scalars["String"]>;
  id?: InputMaybe<Scalars["uuid"]>;
  name?: InputMaybe<Scalars["String"]>;
  updated_at?: InputMaybe<Scalars["timestamptz"]>;
};

/** Streaming cursor of the table "collaborator" */
export type Collaborator_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Collaborator_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Collaborator_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars["timestamptz"]>;
  dni?: InputMaybe<Scalars["String"]>;
  email?: InputMaybe<Scalars["String"]>;
  id?: InputMaybe<Scalars["uuid"]>;
  name?: InputMaybe<Scalars["String"]>;
  updated_at?: InputMaybe<Scalars["timestamptz"]>;
};

/** update columns of table "collaborator" */
export enum Collaborator_Update_Column {
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  Dni = "dni",
  /** column name */
  Email = "email",
  /** column name */
  Id = "id",
  /** column name */
  Name = "name",
  /** column name */
  UpdatedAt = "updated_at",
}

export type Collaborator_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Collaborator_Set_Input>;
  /** filter the rows which have to be updated */
  where: Collaborator_Bool_Exp;
};

/** columns and relationships of "complaint" */
export type Complaint = {
  __typename?: "complaint";
  address?: Maybe<Scalars["String"]>;
  app_state: Scalars["String"];
  code_number: Scalars["Int"];
  collaborator_id: Scalars["uuid"];
  complainer_name?: Maybe<Scalars["String"]>;
  complainer_phone?: Maybe<Scalars["String"]>;
  /** An object relationship */
  complaintKindByComplaintKind: Complaint_Kind;
  complaint_kind: Scalars["String"];
  /** An object relationship */
  complaint_source: Complaint_Source;
  created_at: Scalars["timestamptz"];
  experiment_id?: Maybe<Scalars["uuid"]>;
  firebase_code?: Maybe<Scalars["Int"]>;
  firebase_id?: Maybe<Scalars["String"]>;
  geogroup_id?: Maybe<Scalars["uuid"]>;
  geopoint_id?: Maybe<Scalars["uuid"]>;
  geopoint_unicode?: Maybe<Scalars["String"]>;
  id: Scalars["uuid"];
  insect_image?: Maybe<Scalars["String"]>;
  insect_name?: Maybe<Scalars["String"]>;
  is_positive?: Maybe<Scalars["Boolean"]>;
  location_id?: Maybe<Scalars["uuid"]>;
  /** An object relationship */
  place?: Maybe<Place>;
  reference?: Maybe<Scalars["String"]>;
  reported_at?: Maybe<Scalars["timestamptz"]>;
  source: Scalars["String"];
  updated_at: Scalars["timestamptz"];
  /** An object relationship */
  workspace?: Maybe<Workspace>;
  workspace_id?: Maybe<Scalars["uuid"]>;
  workspace_metadata_id?: Maybe<Scalars["uuid"]>;
  /** An object relationship */
  workspace_metadatum?: Maybe<Workspace_Metadata>;
};

/** aggregated selection of "complaint" */
export type Complaint_Aggregate = {
  __typename?: "complaint_aggregate";
  aggregate?: Maybe<Complaint_Aggregate_Fields>;
  nodes: Array<Complaint>;
};

export type Complaint_Aggregate_Bool_Exp = {
  bool_and?: InputMaybe<Complaint_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: InputMaybe<Complaint_Aggregate_Bool_Exp_Bool_Or>;
  count?: InputMaybe<Complaint_Aggregate_Bool_Exp_Count>;
};

export type Complaint_Aggregate_Bool_Exp_Bool_And = {
  arguments: Complaint_Select_Column_Complaint_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: InputMaybe<Scalars["Boolean"]>;
  filter?: InputMaybe<Complaint_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Complaint_Aggregate_Bool_Exp_Bool_Or = {
  arguments: Complaint_Select_Column_Complaint_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: InputMaybe<Scalars["Boolean"]>;
  filter?: InputMaybe<Complaint_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Complaint_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Complaint_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]>;
  filter?: InputMaybe<Complaint_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "complaint" */
export type Complaint_Aggregate_Fields = {
  __typename?: "complaint_aggregate_fields";
  avg?: Maybe<Complaint_Avg_Fields>;
  count: Scalars["Int"];
  max?: Maybe<Complaint_Max_Fields>;
  min?: Maybe<Complaint_Min_Fields>;
  stddev?: Maybe<Complaint_Stddev_Fields>;
  stddev_pop?: Maybe<Complaint_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Complaint_Stddev_Samp_Fields>;
  sum?: Maybe<Complaint_Sum_Fields>;
  var_pop?: Maybe<Complaint_Var_Pop_Fields>;
  var_samp?: Maybe<Complaint_Var_Samp_Fields>;
  variance?: Maybe<Complaint_Variance_Fields>;
};

/** aggregate fields of "complaint" */
export type Complaint_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Complaint_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "complaint" */
export type Complaint_Aggregate_Order_By = {
  avg?: InputMaybe<Complaint_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Complaint_Max_Order_By>;
  min?: InputMaybe<Complaint_Min_Order_By>;
  stddev?: InputMaybe<Complaint_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Complaint_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Complaint_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Complaint_Sum_Order_By>;
  var_pop?: InputMaybe<Complaint_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Complaint_Var_Samp_Order_By>;
  variance?: InputMaybe<Complaint_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "complaint" */
export type Complaint_Arr_Rel_Insert_Input = {
  data: Array<Complaint_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Complaint_On_Conflict>;
};

/** aggregate avg on columns */
export type Complaint_Avg_Fields = {
  __typename?: "complaint_avg_fields";
  code_number?: Maybe<Scalars["Float"]>;
  firebase_code?: Maybe<Scalars["Float"]>;
};

/** order by avg() on columns of table "complaint" */
export type Complaint_Avg_Order_By = {
  code_number?: InputMaybe<Order_By>;
  firebase_code?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "complaint". All fields are combined with a logical 'AND'. */
export type Complaint_Bool_Exp = {
  _and?: InputMaybe<Array<Complaint_Bool_Exp>>;
  _not?: InputMaybe<Complaint_Bool_Exp>;
  _or?: InputMaybe<Array<Complaint_Bool_Exp>>;
  address?: InputMaybe<String_Comparison_Exp>;
  app_state?: InputMaybe<String_Comparison_Exp>;
  code_number?: InputMaybe<Int_Comparison_Exp>;
  collaborator_id?: InputMaybe<Uuid_Comparison_Exp>;
  complainer_name?: InputMaybe<String_Comparison_Exp>;
  complainer_phone?: InputMaybe<String_Comparison_Exp>;
  complaintKindByComplaintKind?: InputMaybe<Complaint_Kind_Bool_Exp>;
  complaint_kind?: InputMaybe<String_Comparison_Exp>;
  complaint_source?: InputMaybe<Complaint_Source_Bool_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  experiment_id?: InputMaybe<Uuid_Comparison_Exp>;
  firebase_code?: InputMaybe<Int_Comparison_Exp>;
  firebase_id?: InputMaybe<String_Comparison_Exp>;
  geogroup_id?: InputMaybe<Uuid_Comparison_Exp>;
  geopoint_id?: InputMaybe<Uuid_Comparison_Exp>;
  geopoint_unicode?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  insect_image?: InputMaybe<String_Comparison_Exp>;
  insect_name?: InputMaybe<String_Comparison_Exp>;
  is_positive?: InputMaybe<Boolean_Comparison_Exp>;
  location_id?: InputMaybe<Uuid_Comparison_Exp>;
  place?: InputMaybe<Place_Bool_Exp>;
  reference?: InputMaybe<String_Comparison_Exp>;
  reported_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  source?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  workspace?: InputMaybe<Workspace_Bool_Exp>;
  workspace_id?: InputMaybe<Uuid_Comparison_Exp>;
  workspace_metadata_id?: InputMaybe<Uuid_Comparison_Exp>;
  workspace_metadatum?: InputMaybe<Workspace_Metadata_Bool_Exp>;
};

/** unique or primary key constraints on table "complaint" */
export enum Complaint_Constraint {
  /** unique or primary key constraint on columns "id" */
  ComplaintPkey = "complaint_pkey",
}

/** input type for incrementing numeric columns in table "complaint" */
export type Complaint_Inc_Input = {
  code_number?: InputMaybe<Scalars["Int"]>;
  firebase_code?: InputMaybe<Scalars["Int"]>;
};

/** input type for inserting data into table "complaint" */
export type Complaint_Insert_Input = {
  address?: InputMaybe<Scalars["String"]>;
  app_state?: InputMaybe<Scalars["String"]>;
  code_number?: InputMaybe<Scalars["Int"]>;
  collaborator_id?: InputMaybe<Scalars["uuid"]>;
  complainer_name?: InputMaybe<Scalars["String"]>;
  complainer_phone?: InputMaybe<Scalars["String"]>;
  complaintKindByComplaintKind?: InputMaybe<Complaint_Kind_Obj_Rel_Insert_Input>;
  complaint_kind?: InputMaybe<Scalars["String"]>;
  complaint_source?: InputMaybe<Complaint_Source_Obj_Rel_Insert_Input>;
  created_at?: InputMaybe<Scalars["timestamptz"]>;
  experiment_id?: InputMaybe<Scalars["uuid"]>;
  firebase_code?: InputMaybe<Scalars["Int"]>;
  firebase_id?: InputMaybe<Scalars["String"]>;
  geogroup_id?: InputMaybe<Scalars["uuid"]>;
  geopoint_id?: InputMaybe<Scalars["uuid"]>;
  geopoint_unicode?: InputMaybe<Scalars["String"]>;
  id?: InputMaybe<Scalars["uuid"]>;
  insect_image?: InputMaybe<Scalars["String"]>;
  insect_name?: InputMaybe<Scalars["String"]>;
  is_positive?: InputMaybe<Scalars["Boolean"]>;
  location_id?: InputMaybe<Scalars["uuid"]>;
  place?: InputMaybe<Place_Obj_Rel_Insert_Input>;
  reference?: InputMaybe<Scalars["String"]>;
  reported_at?: InputMaybe<Scalars["timestamptz"]>;
  source?: InputMaybe<Scalars["String"]>;
  updated_at?: InputMaybe<Scalars["timestamptz"]>;
  workspace?: InputMaybe<Workspace_Obj_Rel_Insert_Input>;
  workspace_id?: InputMaybe<Scalars["uuid"]>;
  workspace_metadata_id?: InputMaybe<Scalars["uuid"]>;
  workspace_metadatum?: InputMaybe<Workspace_Metadata_Obj_Rel_Insert_Input>;
};

/** columns and relationships of "complaint_kind" */
export type Complaint_Kind = {
  __typename?: "complaint_kind";
  comment?: Maybe<Scalars["String"]>;
  /** An array relationship */
  complaints: Array<Complaint>;
  /** An aggregate relationship */
  complaints_aggregate: Complaint_Aggregate;
  value: Scalars["String"];
};

/** columns and relationships of "complaint_kind" */
export type Complaint_KindComplaintsArgs = {
  distinct_on?: InputMaybe<Array<Complaint_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Complaint_Order_By>>;
  where?: InputMaybe<Complaint_Bool_Exp>;
};

/** columns and relationships of "complaint_kind" */
export type Complaint_KindComplaints_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Complaint_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Complaint_Order_By>>;
  where?: InputMaybe<Complaint_Bool_Exp>;
};

/** aggregated selection of "complaint_kind" */
export type Complaint_Kind_Aggregate = {
  __typename?: "complaint_kind_aggregate";
  aggregate?: Maybe<Complaint_Kind_Aggregate_Fields>;
  nodes: Array<Complaint_Kind>;
};

/** aggregate fields of "complaint_kind" */
export type Complaint_Kind_Aggregate_Fields = {
  __typename?: "complaint_kind_aggregate_fields";
  count: Scalars["Int"];
  max?: Maybe<Complaint_Kind_Max_Fields>;
  min?: Maybe<Complaint_Kind_Min_Fields>;
};

/** aggregate fields of "complaint_kind" */
export type Complaint_Kind_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Complaint_Kind_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]>;
};

/** Boolean expression to filter rows from the table "complaint_kind". All fields are combined with a logical 'AND'. */
export type Complaint_Kind_Bool_Exp = {
  _and?: InputMaybe<Array<Complaint_Kind_Bool_Exp>>;
  _not?: InputMaybe<Complaint_Kind_Bool_Exp>;
  _or?: InputMaybe<Array<Complaint_Kind_Bool_Exp>>;
  comment?: InputMaybe<String_Comparison_Exp>;
  complaints?: InputMaybe<Complaint_Bool_Exp>;
  complaints_aggregate?: InputMaybe<Complaint_Aggregate_Bool_Exp>;
  value?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "complaint_kind" */
export enum Complaint_Kind_Constraint {
  /** unique or primary key constraint on columns "value" */
  ComplaintKindPkey = "complaint_kind_pkey",
}

/** input type for inserting data into table "complaint_kind" */
export type Complaint_Kind_Insert_Input = {
  comment?: InputMaybe<Scalars["String"]>;
  complaints?: InputMaybe<Complaint_Arr_Rel_Insert_Input>;
  value?: InputMaybe<Scalars["String"]>;
};

/** aggregate max on columns */
export type Complaint_Kind_Max_Fields = {
  __typename?: "complaint_kind_max_fields";
  comment?: Maybe<Scalars["String"]>;
  value?: Maybe<Scalars["String"]>;
};

/** aggregate min on columns */
export type Complaint_Kind_Min_Fields = {
  __typename?: "complaint_kind_min_fields";
  comment?: Maybe<Scalars["String"]>;
  value?: Maybe<Scalars["String"]>;
};

/** response of any mutation on the table "complaint_kind" */
export type Complaint_Kind_Mutation_Response = {
  __typename?: "complaint_kind_mutation_response";
  /** number of rows affected by the mutation */
  affected_rows: Scalars["Int"];
  /** data from the rows affected by the mutation */
  returning: Array<Complaint_Kind>;
};

/** input type for inserting object relation for remote table "complaint_kind" */
export type Complaint_Kind_Obj_Rel_Insert_Input = {
  data: Complaint_Kind_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Complaint_Kind_On_Conflict>;
};

/** on_conflict condition type for table "complaint_kind" */
export type Complaint_Kind_On_Conflict = {
  constraint: Complaint_Kind_Constraint;
  update_columns?: Array<Complaint_Kind_Update_Column>;
  where?: InputMaybe<Complaint_Kind_Bool_Exp>;
};

/** Ordering options when selecting data from "complaint_kind". */
export type Complaint_Kind_Order_By = {
  comment?: InputMaybe<Order_By>;
  complaints_aggregate?: InputMaybe<Complaint_Aggregate_Order_By>;
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: complaint_kind */
export type Complaint_Kind_Pk_Columns_Input = {
  value: Scalars["String"];
};

/** select columns of table "complaint_kind" */
export enum Complaint_Kind_Select_Column {
  /** column name */
  Comment = "comment",
  /** column name */
  Value = "value",
}

/** input type for updating data in table "complaint_kind" */
export type Complaint_Kind_Set_Input = {
  comment?: InputMaybe<Scalars["String"]>;
  value?: InputMaybe<Scalars["String"]>;
};

/** Streaming cursor of the table "complaint_kind" */
export type Complaint_Kind_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Complaint_Kind_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Complaint_Kind_Stream_Cursor_Value_Input = {
  comment?: InputMaybe<Scalars["String"]>;
  value?: InputMaybe<Scalars["String"]>;
};

/** update columns of table "complaint_kind" */
export enum Complaint_Kind_Update_Column {
  /** column name */
  Comment = "comment",
  /** column name */
  Value = "value",
}

export type Complaint_Kind_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Complaint_Kind_Set_Input>;
  /** filter the rows which have to be updated */
  where: Complaint_Kind_Bool_Exp;
};

/** aggregate max on columns */
export type Complaint_Max_Fields = {
  __typename?: "complaint_max_fields";
  address?: Maybe<Scalars["String"]>;
  app_state?: Maybe<Scalars["String"]>;
  code_number?: Maybe<Scalars["Int"]>;
  collaborator_id?: Maybe<Scalars["uuid"]>;
  complainer_name?: Maybe<Scalars["String"]>;
  complainer_phone?: Maybe<Scalars["String"]>;
  complaint_kind?: Maybe<Scalars["String"]>;
  created_at?: Maybe<Scalars["timestamptz"]>;
  experiment_id?: Maybe<Scalars["uuid"]>;
  firebase_code?: Maybe<Scalars["Int"]>;
  firebase_id?: Maybe<Scalars["String"]>;
  geogroup_id?: Maybe<Scalars["uuid"]>;
  geopoint_id?: Maybe<Scalars["uuid"]>;
  geopoint_unicode?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["uuid"]>;
  insect_image?: Maybe<Scalars["String"]>;
  insect_name?: Maybe<Scalars["String"]>;
  location_id?: Maybe<Scalars["uuid"]>;
  reference?: Maybe<Scalars["String"]>;
  reported_at?: Maybe<Scalars["timestamptz"]>;
  source?: Maybe<Scalars["String"]>;
  updated_at?: Maybe<Scalars["timestamptz"]>;
  workspace_id?: Maybe<Scalars["uuid"]>;
  workspace_metadata_id?: Maybe<Scalars["uuid"]>;
};

/** order by max() on columns of table "complaint" */
export type Complaint_Max_Order_By = {
  address?: InputMaybe<Order_By>;
  app_state?: InputMaybe<Order_By>;
  code_number?: InputMaybe<Order_By>;
  collaborator_id?: InputMaybe<Order_By>;
  complainer_name?: InputMaybe<Order_By>;
  complainer_phone?: InputMaybe<Order_By>;
  complaint_kind?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  experiment_id?: InputMaybe<Order_By>;
  firebase_code?: InputMaybe<Order_By>;
  firebase_id?: InputMaybe<Order_By>;
  geogroup_id?: InputMaybe<Order_By>;
  geopoint_id?: InputMaybe<Order_By>;
  geopoint_unicode?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  insect_image?: InputMaybe<Order_By>;
  insect_name?: InputMaybe<Order_By>;
  location_id?: InputMaybe<Order_By>;
  reference?: InputMaybe<Order_By>;
  reported_at?: InputMaybe<Order_By>;
  source?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  workspace_id?: InputMaybe<Order_By>;
  workspace_metadata_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Complaint_Min_Fields = {
  __typename?: "complaint_min_fields";
  address?: Maybe<Scalars["String"]>;
  app_state?: Maybe<Scalars["String"]>;
  code_number?: Maybe<Scalars["Int"]>;
  collaborator_id?: Maybe<Scalars["uuid"]>;
  complainer_name?: Maybe<Scalars["String"]>;
  complainer_phone?: Maybe<Scalars["String"]>;
  complaint_kind?: Maybe<Scalars["String"]>;
  created_at?: Maybe<Scalars["timestamptz"]>;
  experiment_id?: Maybe<Scalars["uuid"]>;
  firebase_code?: Maybe<Scalars["Int"]>;
  firebase_id?: Maybe<Scalars["String"]>;
  geogroup_id?: Maybe<Scalars["uuid"]>;
  geopoint_id?: Maybe<Scalars["uuid"]>;
  geopoint_unicode?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["uuid"]>;
  insect_image?: Maybe<Scalars["String"]>;
  insect_name?: Maybe<Scalars["String"]>;
  location_id?: Maybe<Scalars["uuid"]>;
  reference?: Maybe<Scalars["String"]>;
  reported_at?: Maybe<Scalars["timestamptz"]>;
  source?: Maybe<Scalars["String"]>;
  updated_at?: Maybe<Scalars["timestamptz"]>;
  workspace_id?: Maybe<Scalars["uuid"]>;
  workspace_metadata_id?: Maybe<Scalars["uuid"]>;
};

/** order by min() on columns of table "complaint" */
export type Complaint_Min_Order_By = {
  address?: InputMaybe<Order_By>;
  app_state?: InputMaybe<Order_By>;
  code_number?: InputMaybe<Order_By>;
  collaborator_id?: InputMaybe<Order_By>;
  complainer_name?: InputMaybe<Order_By>;
  complainer_phone?: InputMaybe<Order_By>;
  complaint_kind?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  experiment_id?: InputMaybe<Order_By>;
  firebase_code?: InputMaybe<Order_By>;
  firebase_id?: InputMaybe<Order_By>;
  geogroup_id?: InputMaybe<Order_By>;
  geopoint_id?: InputMaybe<Order_By>;
  geopoint_unicode?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  insect_image?: InputMaybe<Order_By>;
  insect_name?: InputMaybe<Order_By>;
  location_id?: InputMaybe<Order_By>;
  reference?: InputMaybe<Order_By>;
  reported_at?: InputMaybe<Order_By>;
  source?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  workspace_id?: InputMaybe<Order_By>;
  workspace_metadata_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "complaint" */
export type Complaint_Mutation_Response = {
  __typename?: "complaint_mutation_response";
  /** number of rows affected by the mutation */
  affected_rows: Scalars["Int"];
  /** data from the rows affected by the mutation */
  returning: Array<Complaint>;
};

/** on_conflict condition type for table "complaint" */
export type Complaint_On_Conflict = {
  constraint: Complaint_Constraint;
  update_columns?: Array<Complaint_Update_Column>;
  where?: InputMaybe<Complaint_Bool_Exp>;
};

/** Ordering options when selecting data from "complaint". */
export type Complaint_Order_By = {
  address?: InputMaybe<Order_By>;
  app_state?: InputMaybe<Order_By>;
  code_number?: InputMaybe<Order_By>;
  collaborator_id?: InputMaybe<Order_By>;
  complainer_name?: InputMaybe<Order_By>;
  complainer_phone?: InputMaybe<Order_By>;
  complaintKindByComplaintKind?: InputMaybe<Complaint_Kind_Order_By>;
  complaint_kind?: InputMaybe<Order_By>;
  complaint_source?: InputMaybe<Complaint_Source_Order_By>;
  created_at?: InputMaybe<Order_By>;
  experiment_id?: InputMaybe<Order_By>;
  firebase_code?: InputMaybe<Order_By>;
  firebase_id?: InputMaybe<Order_By>;
  geogroup_id?: InputMaybe<Order_By>;
  geopoint_id?: InputMaybe<Order_By>;
  geopoint_unicode?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  insect_image?: InputMaybe<Order_By>;
  insect_name?: InputMaybe<Order_By>;
  is_positive?: InputMaybe<Order_By>;
  location_id?: InputMaybe<Order_By>;
  place?: InputMaybe<Place_Order_By>;
  reference?: InputMaybe<Order_By>;
  reported_at?: InputMaybe<Order_By>;
  source?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  workspace?: InputMaybe<Workspace_Order_By>;
  workspace_id?: InputMaybe<Order_By>;
  workspace_metadata_id?: InputMaybe<Order_By>;
  workspace_metadatum?: InputMaybe<Workspace_Metadata_Order_By>;
};

/** primary key columns input for table: complaint */
export type Complaint_Pk_Columns_Input = {
  id: Scalars["uuid"];
};

/** select columns of table "complaint" */
export enum Complaint_Select_Column {
  /** column name */
  Address = "address",
  /** column name */
  AppState = "app_state",
  /** column name */
  CodeNumber = "code_number",
  /** column name */
  CollaboratorId = "collaborator_id",
  /** column name */
  ComplainerName = "complainer_name",
  /** column name */
  ComplainerPhone = "complainer_phone",
  /** column name */
  ComplaintKind = "complaint_kind",
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  ExperimentId = "experiment_id",
  /** column name */
  FirebaseCode = "firebase_code",
  /** column name */
  FirebaseId = "firebase_id",
  /** column name */
  GeogroupId = "geogroup_id",
  /** column name */
  GeopointId = "geopoint_id",
  /** column name */
  GeopointUnicode = "geopoint_unicode",
  /** column name */
  Id = "id",
  /** column name */
  InsectImage = "insect_image",
  /** column name */
  InsectName = "insect_name",
  /** column name */
  IsPositive = "is_positive",
  /** column name */
  LocationId = "location_id",
  /** column name */
  Reference = "reference",
  /** column name */
  ReportedAt = "reported_at",
  /** column name */
  Source = "source",
  /** column name */
  UpdatedAt = "updated_at",
  /** column name */
  WorkspaceId = "workspace_id",
  /** column name */
  WorkspaceMetadataId = "workspace_metadata_id",
}

/** select "complaint_aggregate_bool_exp_bool_and_arguments_columns" columns of table "complaint" */
export enum Complaint_Select_Column_Complaint_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  IsPositive = "is_positive",
}

/** select "complaint_aggregate_bool_exp_bool_or_arguments_columns" columns of table "complaint" */
export enum Complaint_Select_Column_Complaint_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  IsPositive = "is_positive",
}

/** input type for updating data in table "complaint" */
export type Complaint_Set_Input = {
  address?: InputMaybe<Scalars["String"]>;
  app_state?: InputMaybe<Scalars["String"]>;
  code_number?: InputMaybe<Scalars["Int"]>;
  collaborator_id?: InputMaybe<Scalars["uuid"]>;
  complainer_name?: InputMaybe<Scalars["String"]>;
  complainer_phone?: InputMaybe<Scalars["String"]>;
  complaint_kind?: InputMaybe<Scalars["String"]>;
  created_at?: InputMaybe<Scalars["timestamptz"]>;
  experiment_id?: InputMaybe<Scalars["uuid"]>;
  firebase_code?: InputMaybe<Scalars["Int"]>;
  firebase_id?: InputMaybe<Scalars["String"]>;
  geogroup_id?: InputMaybe<Scalars["uuid"]>;
  geopoint_id?: InputMaybe<Scalars["uuid"]>;
  geopoint_unicode?: InputMaybe<Scalars["String"]>;
  id?: InputMaybe<Scalars["uuid"]>;
  insect_image?: InputMaybe<Scalars["String"]>;
  insect_name?: InputMaybe<Scalars["String"]>;
  is_positive?: InputMaybe<Scalars["Boolean"]>;
  location_id?: InputMaybe<Scalars["uuid"]>;
  reference?: InputMaybe<Scalars["String"]>;
  reported_at?: InputMaybe<Scalars["timestamptz"]>;
  source?: InputMaybe<Scalars["String"]>;
  updated_at?: InputMaybe<Scalars["timestamptz"]>;
  workspace_id?: InputMaybe<Scalars["uuid"]>;
  workspace_metadata_id?: InputMaybe<Scalars["uuid"]>;
};

/** columns and relationships of "complaint_source" */
export type Complaint_Source = {
  __typename?: "complaint_source";
  comment?: Maybe<Scalars["String"]>;
  /** An array relationship */
  complaints: Array<Complaint>;
  /** An aggregate relationship */
  complaints_aggregate: Complaint_Aggregate;
  value: Scalars["String"];
};

/** columns and relationships of "complaint_source" */
export type Complaint_SourceComplaintsArgs = {
  distinct_on?: InputMaybe<Array<Complaint_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Complaint_Order_By>>;
  where?: InputMaybe<Complaint_Bool_Exp>;
};

/** columns and relationships of "complaint_source" */
export type Complaint_SourceComplaints_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Complaint_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Complaint_Order_By>>;
  where?: InputMaybe<Complaint_Bool_Exp>;
};

/** aggregated selection of "complaint_source" */
export type Complaint_Source_Aggregate = {
  __typename?: "complaint_source_aggregate";
  aggregate?: Maybe<Complaint_Source_Aggregate_Fields>;
  nodes: Array<Complaint_Source>;
};

/** aggregate fields of "complaint_source" */
export type Complaint_Source_Aggregate_Fields = {
  __typename?: "complaint_source_aggregate_fields";
  count: Scalars["Int"];
  max?: Maybe<Complaint_Source_Max_Fields>;
  min?: Maybe<Complaint_Source_Min_Fields>;
};

/** aggregate fields of "complaint_source" */
export type Complaint_Source_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Complaint_Source_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]>;
};

/** Boolean expression to filter rows from the table "complaint_source". All fields are combined with a logical 'AND'. */
export type Complaint_Source_Bool_Exp = {
  _and?: InputMaybe<Array<Complaint_Source_Bool_Exp>>;
  _not?: InputMaybe<Complaint_Source_Bool_Exp>;
  _or?: InputMaybe<Array<Complaint_Source_Bool_Exp>>;
  comment?: InputMaybe<String_Comparison_Exp>;
  complaints?: InputMaybe<Complaint_Bool_Exp>;
  complaints_aggregate?: InputMaybe<Complaint_Aggregate_Bool_Exp>;
  value?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "complaint_source" */
export enum Complaint_Source_Constraint {
  /** unique or primary key constraint on columns "value" */
  ComplaintMediaPkey = "complaint_media_pkey",
}

/** input type for inserting data into table "complaint_source" */
export type Complaint_Source_Insert_Input = {
  comment?: InputMaybe<Scalars["String"]>;
  complaints?: InputMaybe<Complaint_Arr_Rel_Insert_Input>;
  value?: InputMaybe<Scalars["String"]>;
};

/** aggregate max on columns */
export type Complaint_Source_Max_Fields = {
  __typename?: "complaint_source_max_fields";
  comment?: Maybe<Scalars["String"]>;
  value?: Maybe<Scalars["String"]>;
};

/** aggregate min on columns */
export type Complaint_Source_Min_Fields = {
  __typename?: "complaint_source_min_fields";
  comment?: Maybe<Scalars["String"]>;
  value?: Maybe<Scalars["String"]>;
};

/** response of any mutation on the table "complaint_source" */
export type Complaint_Source_Mutation_Response = {
  __typename?: "complaint_source_mutation_response";
  /** number of rows affected by the mutation */
  affected_rows: Scalars["Int"];
  /** data from the rows affected by the mutation */
  returning: Array<Complaint_Source>;
};

/** input type for inserting object relation for remote table "complaint_source" */
export type Complaint_Source_Obj_Rel_Insert_Input = {
  data: Complaint_Source_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Complaint_Source_On_Conflict>;
};

/** on_conflict condition type for table "complaint_source" */
export type Complaint_Source_On_Conflict = {
  constraint: Complaint_Source_Constraint;
  update_columns?: Array<Complaint_Source_Update_Column>;
  where?: InputMaybe<Complaint_Source_Bool_Exp>;
};

/** Ordering options when selecting data from "complaint_source". */
export type Complaint_Source_Order_By = {
  comment?: InputMaybe<Order_By>;
  complaints_aggregate?: InputMaybe<Complaint_Aggregate_Order_By>;
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: complaint_source */
export type Complaint_Source_Pk_Columns_Input = {
  value: Scalars["String"];
};

/** select columns of table "complaint_source" */
export enum Complaint_Source_Select_Column {
  /** column name */
  Comment = "comment",
  /** column name */
  Value = "value",
}

/** input type for updating data in table "complaint_source" */
export type Complaint_Source_Set_Input = {
  comment?: InputMaybe<Scalars["String"]>;
  value?: InputMaybe<Scalars["String"]>;
};

/** Streaming cursor of the table "complaint_source" */
export type Complaint_Source_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Complaint_Source_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Complaint_Source_Stream_Cursor_Value_Input = {
  comment?: InputMaybe<Scalars["String"]>;
  value?: InputMaybe<Scalars["String"]>;
};

/** update columns of table "complaint_source" */
export enum Complaint_Source_Update_Column {
  /** column name */
  Comment = "comment",
  /** column name */
  Value = "value",
}

export type Complaint_Source_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Complaint_Source_Set_Input>;
  /** filter the rows which have to be updated */
  where: Complaint_Source_Bool_Exp;
};

/** aggregate stddev on columns */
export type Complaint_Stddev_Fields = {
  __typename?: "complaint_stddev_fields";
  code_number?: Maybe<Scalars["Float"]>;
  firebase_code?: Maybe<Scalars["Float"]>;
};

/** order by stddev() on columns of table "complaint" */
export type Complaint_Stddev_Order_By = {
  code_number?: InputMaybe<Order_By>;
  firebase_code?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Complaint_Stddev_Pop_Fields = {
  __typename?: "complaint_stddev_pop_fields";
  code_number?: Maybe<Scalars["Float"]>;
  firebase_code?: Maybe<Scalars["Float"]>;
};

/** order by stddev_pop() on columns of table "complaint" */
export type Complaint_Stddev_Pop_Order_By = {
  code_number?: InputMaybe<Order_By>;
  firebase_code?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Complaint_Stddev_Samp_Fields = {
  __typename?: "complaint_stddev_samp_fields";
  code_number?: Maybe<Scalars["Float"]>;
  firebase_code?: Maybe<Scalars["Float"]>;
};

/** order by stddev_samp() on columns of table "complaint" */
export type Complaint_Stddev_Samp_Order_By = {
  code_number?: InputMaybe<Order_By>;
  firebase_code?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "complaint" */
export type Complaint_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Complaint_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Complaint_Stream_Cursor_Value_Input = {
  address?: InputMaybe<Scalars["String"]>;
  app_state?: InputMaybe<Scalars["String"]>;
  code_number?: InputMaybe<Scalars["Int"]>;
  collaborator_id?: InputMaybe<Scalars["uuid"]>;
  complainer_name?: InputMaybe<Scalars["String"]>;
  complainer_phone?: InputMaybe<Scalars["String"]>;
  complaint_kind?: InputMaybe<Scalars["String"]>;
  created_at?: InputMaybe<Scalars["timestamptz"]>;
  experiment_id?: InputMaybe<Scalars["uuid"]>;
  firebase_code?: InputMaybe<Scalars["Int"]>;
  firebase_id?: InputMaybe<Scalars["String"]>;
  geogroup_id?: InputMaybe<Scalars["uuid"]>;
  geopoint_id?: InputMaybe<Scalars["uuid"]>;
  geopoint_unicode?: InputMaybe<Scalars["String"]>;
  id?: InputMaybe<Scalars["uuid"]>;
  insect_image?: InputMaybe<Scalars["String"]>;
  insect_name?: InputMaybe<Scalars["String"]>;
  is_positive?: InputMaybe<Scalars["Boolean"]>;
  location_id?: InputMaybe<Scalars["uuid"]>;
  reference?: InputMaybe<Scalars["String"]>;
  reported_at?: InputMaybe<Scalars["timestamptz"]>;
  source?: InputMaybe<Scalars["String"]>;
  updated_at?: InputMaybe<Scalars["timestamptz"]>;
  workspace_id?: InputMaybe<Scalars["uuid"]>;
  workspace_metadata_id?: InputMaybe<Scalars["uuid"]>;
};

/** aggregate sum on columns */
export type Complaint_Sum_Fields = {
  __typename?: "complaint_sum_fields";
  code_number?: Maybe<Scalars["Int"]>;
  firebase_code?: Maybe<Scalars["Int"]>;
};

/** order by sum() on columns of table "complaint" */
export type Complaint_Sum_Order_By = {
  code_number?: InputMaybe<Order_By>;
  firebase_code?: InputMaybe<Order_By>;
};

/** update columns of table "complaint" */
export enum Complaint_Update_Column {
  /** column name */
  Address = "address",
  /** column name */
  AppState = "app_state",
  /** column name */
  CodeNumber = "code_number",
  /** column name */
  CollaboratorId = "collaborator_id",
  /** column name */
  ComplainerName = "complainer_name",
  /** column name */
  ComplainerPhone = "complainer_phone",
  /** column name */
  ComplaintKind = "complaint_kind",
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  ExperimentId = "experiment_id",
  /** column name */
  FirebaseCode = "firebase_code",
  /** column name */
  FirebaseId = "firebase_id",
  /** column name */
  GeogroupId = "geogroup_id",
  /** column name */
  GeopointId = "geopoint_id",
  /** column name */
  GeopointUnicode = "geopoint_unicode",
  /** column name */
  Id = "id",
  /** column name */
  InsectImage = "insect_image",
  /** column name */
  InsectName = "insect_name",
  /** column name */
  IsPositive = "is_positive",
  /** column name */
  LocationId = "location_id",
  /** column name */
  Reference = "reference",
  /** column name */
  ReportedAt = "reported_at",
  /** column name */
  Source = "source",
  /** column name */
  UpdatedAt = "updated_at",
  /** column name */
  WorkspaceId = "workspace_id",
  /** column name */
  WorkspaceMetadataId = "workspace_metadata_id",
}

export type Complaint_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Complaint_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Complaint_Set_Input>;
  /** filter the rows which have to be updated */
  where: Complaint_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Complaint_Var_Pop_Fields = {
  __typename?: "complaint_var_pop_fields";
  code_number?: Maybe<Scalars["Float"]>;
  firebase_code?: Maybe<Scalars["Float"]>;
};

/** order by var_pop() on columns of table "complaint" */
export type Complaint_Var_Pop_Order_By = {
  code_number?: InputMaybe<Order_By>;
  firebase_code?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Complaint_Var_Samp_Fields = {
  __typename?: "complaint_var_samp_fields";
  code_number?: Maybe<Scalars["Float"]>;
  firebase_code?: Maybe<Scalars["Float"]>;
};

/** order by var_samp() on columns of table "complaint" */
export type Complaint_Var_Samp_Order_By = {
  code_number?: InputMaybe<Order_By>;
  firebase_code?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Complaint_Variance_Fields = {
  __typename?: "complaint_variance_fields";
  code_number?: Maybe<Scalars["Float"]>;
  firebase_code?: Maybe<Scalars["Float"]>;
};

/** order by variance() on columns of table "complaint" */
export type Complaint_Variance_Order_By = {
  code_number?: InputMaybe<Order_By>;
  firebase_code?: InputMaybe<Order_By>;
};

/** ordering argument of a cursor */
export enum Cursor_Ordering {
  /** ascending ordering of the cursor */
  Asc = "ASC",
  /** descending ordering of the cursor */
  Desc = "DESC",
}

/** Boolean expression to compare columns of type "date". All fields are combined with logical 'AND'. */
export type Date_Comparison_Exp = {
  _eq?: InputMaybe<Scalars["date"]>;
  _gt?: InputMaybe<Scalars["date"]>;
  _gte?: InputMaybe<Scalars["date"]>;
  _in?: InputMaybe<Array<Scalars["date"]>>;
  _is_null?: InputMaybe<Scalars["Boolean"]>;
  _lt?: InputMaybe<Scalars["date"]>;
  _lte?: InputMaybe<Scalars["date"]>;
  _neq?: InputMaybe<Scalars["date"]>;
  _nin?: InputMaybe<Array<Scalars["date"]>>;
};

/** columns and relationships of "distances" */
export type Distances = {
  __typename?: "distances";
  created_at: Scalars["timestamptz"];
  from_id: Scalars["uuid"];
  /** An object relationship */
  geopoint: Geopoint;
  /** An object relationship */
  geopointByToId: Geopoint;
  id: Scalars["uuid"];
  kind: Scalars["String"];
  raw?: Maybe<Scalars["jsonb"]>;
  to_id: Scalars["uuid"];
  unit: Scalars["String"];
  updated_at: Scalars["timestamptz"];
  value: Scalars["float8"];
};

/** columns and relationships of "distances" */
export type DistancesRawArgs = {
  path?: InputMaybe<Scalars["String"]>;
};

/** aggregated selection of "distances" */
export type Distances_Aggregate = {
  __typename?: "distances_aggregate";
  aggregate?: Maybe<Distances_Aggregate_Fields>;
  nodes: Array<Distances>;
};

export type Distances_Aggregate_Bool_Exp = {
  avg?: InputMaybe<Distances_Aggregate_Bool_Exp_Avg>;
  corr?: InputMaybe<Distances_Aggregate_Bool_Exp_Corr>;
  count?: InputMaybe<Distances_Aggregate_Bool_Exp_Count>;
  covar_samp?: InputMaybe<Distances_Aggregate_Bool_Exp_Covar_Samp>;
  max?: InputMaybe<Distances_Aggregate_Bool_Exp_Max>;
  min?: InputMaybe<Distances_Aggregate_Bool_Exp_Min>;
  stddev_samp?: InputMaybe<Distances_Aggregate_Bool_Exp_Stddev_Samp>;
  sum?: InputMaybe<Distances_Aggregate_Bool_Exp_Sum>;
  var_samp?: InputMaybe<Distances_Aggregate_Bool_Exp_Var_Samp>;
};

export type Distances_Aggregate_Bool_Exp_Avg = {
  arguments: Distances_Select_Column_Distances_Aggregate_Bool_Exp_Avg_Arguments_Columns;
  distinct?: InputMaybe<Scalars["Boolean"]>;
  filter?: InputMaybe<Distances_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Distances_Aggregate_Bool_Exp_Corr = {
  arguments: Distances_Aggregate_Bool_Exp_Corr_Arguments;
  distinct?: InputMaybe<Scalars["Boolean"]>;
  filter?: InputMaybe<Distances_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Distances_Aggregate_Bool_Exp_Corr_Arguments = {
  X: Distances_Select_Column_Distances_Aggregate_Bool_Exp_Corr_Arguments_Columns;
  Y: Distances_Select_Column_Distances_Aggregate_Bool_Exp_Corr_Arguments_Columns;
};

export type Distances_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Distances_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]>;
  filter?: InputMaybe<Distances_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

export type Distances_Aggregate_Bool_Exp_Covar_Samp = {
  arguments: Distances_Aggregate_Bool_Exp_Covar_Samp_Arguments;
  distinct?: InputMaybe<Scalars["Boolean"]>;
  filter?: InputMaybe<Distances_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Distances_Aggregate_Bool_Exp_Covar_Samp_Arguments = {
  X: Distances_Select_Column_Distances_Aggregate_Bool_Exp_Covar_Samp_Arguments_Columns;
  Y: Distances_Select_Column_Distances_Aggregate_Bool_Exp_Covar_Samp_Arguments_Columns;
};

export type Distances_Aggregate_Bool_Exp_Max = {
  arguments: Distances_Select_Column_Distances_Aggregate_Bool_Exp_Max_Arguments_Columns;
  distinct?: InputMaybe<Scalars["Boolean"]>;
  filter?: InputMaybe<Distances_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Distances_Aggregate_Bool_Exp_Min = {
  arguments: Distances_Select_Column_Distances_Aggregate_Bool_Exp_Min_Arguments_Columns;
  distinct?: InputMaybe<Scalars["Boolean"]>;
  filter?: InputMaybe<Distances_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Distances_Aggregate_Bool_Exp_Stddev_Samp = {
  arguments: Distances_Select_Column_Distances_Aggregate_Bool_Exp_Stddev_Samp_Arguments_Columns;
  distinct?: InputMaybe<Scalars["Boolean"]>;
  filter?: InputMaybe<Distances_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Distances_Aggregate_Bool_Exp_Sum = {
  arguments: Distances_Select_Column_Distances_Aggregate_Bool_Exp_Sum_Arguments_Columns;
  distinct?: InputMaybe<Scalars["Boolean"]>;
  filter?: InputMaybe<Distances_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Distances_Aggregate_Bool_Exp_Var_Samp = {
  arguments: Distances_Select_Column_Distances_Aggregate_Bool_Exp_Var_Samp_Arguments_Columns;
  distinct?: InputMaybe<Scalars["Boolean"]>;
  filter?: InputMaybe<Distances_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

/** aggregate fields of "distances" */
export type Distances_Aggregate_Fields = {
  __typename?: "distances_aggregate_fields";
  avg?: Maybe<Distances_Avg_Fields>;
  count: Scalars["Int"];
  max?: Maybe<Distances_Max_Fields>;
  min?: Maybe<Distances_Min_Fields>;
  stddev?: Maybe<Distances_Stddev_Fields>;
  stddev_pop?: Maybe<Distances_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Distances_Stddev_Samp_Fields>;
  sum?: Maybe<Distances_Sum_Fields>;
  var_pop?: Maybe<Distances_Var_Pop_Fields>;
  var_samp?: Maybe<Distances_Var_Samp_Fields>;
  variance?: Maybe<Distances_Variance_Fields>;
};

/** aggregate fields of "distances" */
export type Distances_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Distances_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "distances" */
export type Distances_Aggregate_Order_By = {
  avg?: InputMaybe<Distances_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Distances_Max_Order_By>;
  min?: InputMaybe<Distances_Min_Order_By>;
  stddev?: InputMaybe<Distances_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Distances_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Distances_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Distances_Sum_Order_By>;
  var_pop?: InputMaybe<Distances_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Distances_Var_Samp_Order_By>;
  variance?: InputMaybe<Distances_Variance_Order_By>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Distances_Append_Input = {
  raw?: InputMaybe<Scalars["jsonb"]>;
};

/** input type for inserting array relation for remote table "distances" */
export type Distances_Arr_Rel_Insert_Input = {
  data: Array<Distances_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Distances_On_Conflict>;
};

/** aggregate avg on columns */
export type Distances_Avg_Fields = {
  __typename?: "distances_avg_fields";
  value?: Maybe<Scalars["Float"]>;
};

/** order by avg() on columns of table "distances" */
export type Distances_Avg_Order_By = {
  value?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "distances". All fields are combined with a logical 'AND'. */
export type Distances_Bool_Exp = {
  _and?: InputMaybe<Array<Distances_Bool_Exp>>;
  _not?: InputMaybe<Distances_Bool_Exp>;
  _or?: InputMaybe<Array<Distances_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  from_id?: InputMaybe<Uuid_Comparison_Exp>;
  geopoint?: InputMaybe<Geopoint_Bool_Exp>;
  geopointByToId?: InputMaybe<Geopoint_Bool_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  kind?: InputMaybe<String_Comparison_Exp>;
  raw?: InputMaybe<Jsonb_Comparison_Exp>;
  to_id?: InputMaybe<Uuid_Comparison_Exp>;
  unit?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  value?: InputMaybe<Float8_Comparison_Exp>;
};

/** unique or primary key constraints on table "distances" */
export enum Distances_Constraint {
  /** unique or primary key constraint on columns "id" */
  DistancesPkey = "distances_pkey",
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Distances_Delete_At_Path_Input = {
  raw?: InputMaybe<Array<Scalars["String"]>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Distances_Delete_Elem_Input = {
  raw?: InputMaybe<Scalars["Int"]>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Distances_Delete_Key_Input = {
  raw?: InputMaybe<Scalars["String"]>;
};

/** input type for incrementing numeric columns in table "distances" */
export type Distances_Inc_Input = {
  value?: InputMaybe<Scalars["float8"]>;
};

/** input type for inserting data into table "distances" */
export type Distances_Insert_Input = {
  created_at?: InputMaybe<Scalars["timestamptz"]>;
  from_id?: InputMaybe<Scalars["uuid"]>;
  geopoint?: InputMaybe<Geopoint_Obj_Rel_Insert_Input>;
  geopointByToId?: InputMaybe<Geopoint_Obj_Rel_Insert_Input>;
  id?: InputMaybe<Scalars["uuid"]>;
  kind?: InputMaybe<Scalars["String"]>;
  raw?: InputMaybe<Scalars["jsonb"]>;
  to_id?: InputMaybe<Scalars["uuid"]>;
  unit?: InputMaybe<Scalars["String"]>;
  updated_at?: InputMaybe<Scalars["timestamptz"]>;
  value?: InputMaybe<Scalars["float8"]>;
};

/** aggregate max on columns */
export type Distances_Max_Fields = {
  __typename?: "distances_max_fields";
  created_at?: Maybe<Scalars["timestamptz"]>;
  from_id?: Maybe<Scalars["uuid"]>;
  id?: Maybe<Scalars["uuid"]>;
  kind?: Maybe<Scalars["String"]>;
  to_id?: Maybe<Scalars["uuid"]>;
  unit?: Maybe<Scalars["String"]>;
  updated_at?: Maybe<Scalars["timestamptz"]>;
  value?: Maybe<Scalars["float8"]>;
};

/** order by max() on columns of table "distances" */
export type Distances_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  from_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  kind?: InputMaybe<Order_By>;
  to_id?: InputMaybe<Order_By>;
  unit?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Distances_Min_Fields = {
  __typename?: "distances_min_fields";
  created_at?: Maybe<Scalars["timestamptz"]>;
  from_id?: Maybe<Scalars["uuid"]>;
  id?: Maybe<Scalars["uuid"]>;
  kind?: Maybe<Scalars["String"]>;
  to_id?: Maybe<Scalars["uuid"]>;
  unit?: Maybe<Scalars["String"]>;
  updated_at?: Maybe<Scalars["timestamptz"]>;
  value?: Maybe<Scalars["float8"]>;
};

/** order by min() on columns of table "distances" */
export type Distances_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  from_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  kind?: InputMaybe<Order_By>;
  to_id?: InputMaybe<Order_By>;
  unit?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "distances" */
export type Distances_Mutation_Response = {
  __typename?: "distances_mutation_response";
  /** number of rows affected by the mutation */
  affected_rows: Scalars["Int"];
  /** data from the rows affected by the mutation */
  returning: Array<Distances>;
};

/** on_conflict condition type for table "distances" */
export type Distances_On_Conflict = {
  constraint: Distances_Constraint;
  update_columns?: Array<Distances_Update_Column>;
  where?: InputMaybe<Distances_Bool_Exp>;
};

/** Ordering options when selecting data from "distances". */
export type Distances_Order_By = {
  created_at?: InputMaybe<Order_By>;
  from_id?: InputMaybe<Order_By>;
  geopoint?: InputMaybe<Geopoint_Order_By>;
  geopointByToId?: InputMaybe<Geopoint_Order_By>;
  id?: InputMaybe<Order_By>;
  kind?: InputMaybe<Order_By>;
  raw?: InputMaybe<Order_By>;
  to_id?: InputMaybe<Order_By>;
  unit?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: distances */
export type Distances_Pk_Columns_Input = {
  id: Scalars["uuid"];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Distances_Prepend_Input = {
  raw?: InputMaybe<Scalars["jsonb"]>;
};

/** select columns of table "distances" */
export enum Distances_Select_Column {
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  FromId = "from_id",
  /** column name */
  Id = "id",
  /** column name */
  Kind = "kind",
  /** column name */
  Raw = "raw",
  /** column name */
  ToId = "to_id",
  /** column name */
  Unit = "unit",
  /** column name */
  UpdatedAt = "updated_at",
  /** column name */
  Value = "value",
}

/** select "distances_aggregate_bool_exp_avg_arguments_columns" columns of table "distances" */
export enum Distances_Select_Column_Distances_Aggregate_Bool_Exp_Avg_Arguments_Columns {
  /** column name */
  Value = "value",
}

/** select "distances_aggregate_bool_exp_corr_arguments_columns" columns of table "distances" */
export enum Distances_Select_Column_Distances_Aggregate_Bool_Exp_Corr_Arguments_Columns {
  /** column name */
  Value = "value",
}

/** select "distances_aggregate_bool_exp_covar_samp_arguments_columns" columns of table "distances" */
export enum Distances_Select_Column_Distances_Aggregate_Bool_Exp_Covar_Samp_Arguments_Columns {
  /** column name */
  Value = "value",
}

/** select "distances_aggregate_bool_exp_max_arguments_columns" columns of table "distances" */
export enum Distances_Select_Column_Distances_Aggregate_Bool_Exp_Max_Arguments_Columns {
  /** column name */
  Value = "value",
}

/** select "distances_aggregate_bool_exp_min_arguments_columns" columns of table "distances" */
export enum Distances_Select_Column_Distances_Aggregate_Bool_Exp_Min_Arguments_Columns {
  /** column name */
  Value = "value",
}

/** select "distances_aggregate_bool_exp_stddev_samp_arguments_columns" columns of table "distances" */
export enum Distances_Select_Column_Distances_Aggregate_Bool_Exp_Stddev_Samp_Arguments_Columns {
  /** column name */
  Value = "value",
}

/** select "distances_aggregate_bool_exp_sum_arguments_columns" columns of table "distances" */
export enum Distances_Select_Column_Distances_Aggregate_Bool_Exp_Sum_Arguments_Columns {
  /** column name */
  Value = "value",
}

/** select "distances_aggregate_bool_exp_var_samp_arguments_columns" columns of table "distances" */
export enum Distances_Select_Column_Distances_Aggregate_Bool_Exp_Var_Samp_Arguments_Columns {
  /** column name */
  Value = "value",
}

/** input type for updating data in table "distances" */
export type Distances_Set_Input = {
  created_at?: InputMaybe<Scalars["timestamptz"]>;
  from_id?: InputMaybe<Scalars["uuid"]>;
  id?: InputMaybe<Scalars["uuid"]>;
  kind?: InputMaybe<Scalars["String"]>;
  raw?: InputMaybe<Scalars["jsonb"]>;
  to_id?: InputMaybe<Scalars["uuid"]>;
  unit?: InputMaybe<Scalars["String"]>;
  updated_at?: InputMaybe<Scalars["timestamptz"]>;
  value?: InputMaybe<Scalars["float8"]>;
};

/** aggregate stddev on columns */
export type Distances_Stddev_Fields = {
  __typename?: "distances_stddev_fields";
  value?: Maybe<Scalars["Float"]>;
};

/** order by stddev() on columns of table "distances" */
export type Distances_Stddev_Order_By = {
  value?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Distances_Stddev_Pop_Fields = {
  __typename?: "distances_stddev_pop_fields";
  value?: Maybe<Scalars["Float"]>;
};

/** order by stddev_pop() on columns of table "distances" */
export type Distances_Stddev_Pop_Order_By = {
  value?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Distances_Stddev_Samp_Fields = {
  __typename?: "distances_stddev_samp_fields";
  value?: Maybe<Scalars["Float"]>;
};

/** order by stddev_samp() on columns of table "distances" */
export type Distances_Stddev_Samp_Order_By = {
  value?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "distances" */
export type Distances_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Distances_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Distances_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars["timestamptz"]>;
  from_id?: InputMaybe<Scalars["uuid"]>;
  id?: InputMaybe<Scalars["uuid"]>;
  kind?: InputMaybe<Scalars["String"]>;
  raw?: InputMaybe<Scalars["jsonb"]>;
  to_id?: InputMaybe<Scalars["uuid"]>;
  unit?: InputMaybe<Scalars["String"]>;
  updated_at?: InputMaybe<Scalars["timestamptz"]>;
  value?: InputMaybe<Scalars["float8"]>;
};

/** aggregate sum on columns */
export type Distances_Sum_Fields = {
  __typename?: "distances_sum_fields";
  value?: Maybe<Scalars["float8"]>;
};

/** order by sum() on columns of table "distances" */
export type Distances_Sum_Order_By = {
  value?: InputMaybe<Order_By>;
};

/** update columns of table "distances" */
export enum Distances_Update_Column {
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  FromId = "from_id",
  /** column name */
  Id = "id",
  /** column name */
  Kind = "kind",
  /** column name */
  Raw = "raw",
  /** column name */
  ToId = "to_id",
  /** column name */
  Unit = "unit",
  /** column name */
  UpdatedAt = "updated_at",
  /** column name */
  Value = "value",
}

export type Distances_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Distances_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Distances_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Distances_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Distances_Delete_Key_Input>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Distances_Inc_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Distances_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Distances_Set_Input>;
  /** filter the rows which have to be updated */
  where: Distances_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Distances_Var_Pop_Fields = {
  __typename?: "distances_var_pop_fields";
  value?: Maybe<Scalars["Float"]>;
};

/** order by var_pop() on columns of table "distances" */
export type Distances_Var_Pop_Order_By = {
  value?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Distances_Var_Samp_Fields = {
  __typename?: "distances_var_samp_fields";
  value?: Maybe<Scalars["Float"]>;
};

/** order by var_samp() on columns of table "distances" */
export type Distances_Var_Samp_Order_By = {
  value?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Distances_Variance_Fields = {
  __typename?: "distances_variance_fields";
  value?: Maybe<Scalars["Float"]>;
};

/** order by variance() on columns of table "distances" */
export type Distances_Variance_Order_By = {
  value?: InputMaybe<Order_By>;
};

/** columns and relationships of "estate_kind" */
export type Estate_Kind = {
  __typename?: "estate_kind";
  comment?: Maybe<Scalars["String"]>;
  value: Scalars["String"];
};

/** aggregated selection of "estate_kind" */
export type Estate_Kind_Aggregate = {
  __typename?: "estate_kind_aggregate";
  aggregate?: Maybe<Estate_Kind_Aggregate_Fields>;
  nodes: Array<Estate_Kind>;
};

/** aggregate fields of "estate_kind" */
export type Estate_Kind_Aggregate_Fields = {
  __typename?: "estate_kind_aggregate_fields";
  count: Scalars["Int"];
  max?: Maybe<Estate_Kind_Max_Fields>;
  min?: Maybe<Estate_Kind_Min_Fields>;
};

/** aggregate fields of "estate_kind" */
export type Estate_Kind_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Estate_Kind_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]>;
};

/** Boolean expression to filter rows from the table "estate_kind". All fields are combined with a logical 'AND'. */
export type Estate_Kind_Bool_Exp = {
  _and?: InputMaybe<Array<Estate_Kind_Bool_Exp>>;
  _not?: InputMaybe<Estate_Kind_Bool_Exp>;
  _or?: InputMaybe<Array<Estate_Kind_Bool_Exp>>;
  comment?: InputMaybe<String_Comparison_Exp>;
  value?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "estate_kind" */
export enum Estate_Kind_Constraint {
  /** unique or primary key constraint on columns "value" */
  EstateKindPkey = "estate_kind_pkey",
}

/** input type for inserting data into table "estate_kind" */
export type Estate_Kind_Insert_Input = {
  comment?: InputMaybe<Scalars["String"]>;
  value?: InputMaybe<Scalars["String"]>;
};

/** aggregate max on columns */
export type Estate_Kind_Max_Fields = {
  __typename?: "estate_kind_max_fields";
  comment?: Maybe<Scalars["String"]>;
  value?: Maybe<Scalars["String"]>;
};

/** aggregate min on columns */
export type Estate_Kind_Min_Fields = {
  __typename?: "estate_kind_min_fields";
  comment?: Maybe<Scalars["String"]>;
  value?: Maybe<Scalars["String"]>;
};

/** response of any mutation on the table "estate_kind" */
export type Estate_Kind_Mutation_Response = {
  __typename?: "estate_kind_mutation_response";
  /** number of rows affected by the mutation */
  affected_rows: Scalars["Int"];
  /** data from the rows affected by the mutation */
  returning: Array<Estate_Kind>;
};

/** on_conflict condition type for table "estate_kind" */
export type Estate_Kind_On_Conflict = {
  constraint: Estate_Kind_Constraint;
  update_columns?: Array<Estate_Kind_Update_Column>;
  where?: InputMaybe<Estate_Kind_Bool_Exp>;
};

/** Ordering options when selecting data from "estate_kind". */
export type Estate_Kind_Order_By = {
  comment?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: estate_kind */
export type Estate_Kind_Pk_Columns_Input = {
  value: Scalars["String"];
};

/** select columns of table "estate_kind" */
export enum Estate_Kind_Select_Column {
  /** column name */
  Comment = "comment",
  /** column name */
  Value = "value",
}

/** input type for updating data in table "estate_kind" */
export type Estate_Kind_Set_Input = {
  comment?: InputMaybe<Scalars["String"]>;
  value?: InputMaybe<Scalars["String"]>;
};

/** Streaming cursor of the table "estate_kind" */
export type Estate_Kind_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Estate_Kind_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Estate_Kind_Stream_Cursor_Value_Input = {
  comment?: InputMaybe<Scalars["String"]>;
  value?: InputMaybe<Scalars["String"]>;
};

/** update columns of table "estate_kind" */
export enum Estate_Kind_Update_Column {
  /** column name */
  Comment = "comment",
  /** column name */
  Value = "value",
}

export type Estate_Kind_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Estate_Kind_Set_Input>;
  /** filter the rows which have to be updated */
  where: Estate_Kind_Bool_Exp;
};

/** columns and relationships of "experiment" */
export type Experiment = {
  __typename?: "experiment";
  created_at: Scalars["timestamptz"];
  data_fit_size?: Maybe<Scalars["Int"]>;
  data_pred_size?: Maybe<Scalars["Int"]>;
  /** An object relationship */
  experiment?: Maybe<Experiment>;
  experiment_duration?: Maybe<Scalars["float8"]>;
  experiment_start_date?: Maybe<Scalars["time"]>;
  experiment_threads?: Maybe<Scalars["Int"]>;
  experiment_worker_cpus?: Maybe<Scalars["Int"]>;
  experiment_worker_id?: Maybe<Scalars["String"]>;
  experiment_worker_memory?: Maybe<Scalars["float8"]>;
  /** An array relationship */
  experiments: Array<Experiment>;
  /** An aggregate relationship */
  experiments_aggregate: Experiment_Aggregate;
  first_cut?: Maybe<Scalars["date"]>;
  fixed_intercept_kld?: Maybe<Scalars["float8"]>;
  fixed_intercept_mean?: Maybe<Scalars["float8"]>;
  fixed_intercept_sd?: Maybe<Scalars["float8"]>;
  fixed_not_sprayed_kld?: Maybe<Scalars["float8"]>;
  fixed_not_sprayed_mean?: Maybe<Scalars["float8"]>;
  fixed_not_sprayed_sd?: Maybe<Scalars["float8"]>;
  fourth_cut?: Maybe<Scalars["date"]>;
  /** An object relationship */
  geogroup: Geogroup;
  geogroup_id: Scalars["uuid"];
  grouprho_mean?: Maybe<Scalars["float8"]>;
  grouprho_mode?: Maybe<Scalars["float8"]>;
  grouprho_sd?: Maybe<Scalars["float8"]>;
  hyperpar_theta_one_mode?: Maybe<Scalars["float8"]>;
  hyperpar_theta_two_mode?: Maybe<Scalars["float8"]>;
  id: Scalars["uuid"];
  infestation_probability_mean?: Maybe<Scalars["float8"]>;
  infestation_probability_sd?: Maybe<Scalars["float8"]>;
  intercept_precision?: Maybe<Scalars["float8"]>;
  intercept_prior?: Maybe<Scalars["float8"]>;
  kappa_mean?: Maybe<Scalars["float8"]>;
  kappa_sd?: Maybe<Scalars["float8"]>;
  kind?: Maybe<Scalars["String"]>;
  parent_id?: Maybe<Scalars["uuid"]>;
  second_cut?: Maybe<Scalars["date"]>;
  tau_mean?: Maybe<Scalars["float8"]>;
  tau_sd?: Maybe<Scalars["float8"]>;
  theta_one_mean?: Maybe<Scalars["float8"]>;
  theta_one_precision?: Maybe<Scalars["float8"]>;
  theta_one_prior?: Maybe<Scalars["float8"]>;
  theta_one_sd?: Maybe<Scalars["float8"]>;
  theta_two_mean?: Maybe<Scalars["float8"]>;
  theta_two_precision?: Maybe<Scalars["float8"]>;
  theta_two_prior?: Maybe<Scalars["float8"]>;
  theta_two_sd?: Maybe<Scalars["float8"]>;
  third_cut?: Maybe<Scalars["date"]>;
  total_house_in_area?: Maybe<Scalars["Int"]>;
  total_inspected_houses?: Maybe<Scalars["Int"]>;
  total_positive_houses_in_area?: Maybe<Scalars["Int"]>;
  total_sprayed_houses?: Maybe<Scalars["Int"]>;
  updated_at: Scalars["timestamptz"];
  /** An array relationship */
  workspace_metadata: Array<Workspace_Metadata>;
  /** An aggregate relationship */
  workspace_metadata_aggregate: Workspace_Metadata_Aggregate;
};

/** columns and relationships of "experiment" */
export type ExperimentExperimentsArgs = {
  distinct_on?: InputMaybe<Array<Experiment_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Experiment_Order_By>>;
  where?: InputMaybe<Experiment_Bool_Exp>;
};

/** columns and relationships of "experiment" */
export type ExperimentExperiments_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Experiment_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Experiment_Order_By>>;
  where?: InputMaybe<Experiment_Bool_Exp>;
};

/** columns and relationships of "experiment" */
export type ExperimentWorkspace_MetadataArgs = {
  distinct_on?: InputMaybe<Array<Workspace_Metadata_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Workspace_Metadata_Order_By>>;
  where?: InputMaybe<Workspace_Metadata_Bool_Exp>;
};

/** columns and relationships of "experiment" */
export type ExperimentWorkspace_Metadata_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Workspace_Metadata_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Workspace_Metadata_Order_By>>;
  where?: InputMaybe<Workspace_Metadata_Bool_Exp>;
};

/** aggregated selection of "experiment" */
export type Experiment_Aggregate = {
  __typename?: "experiment_aggregate";
  aggregate?: Maybe<Experiment_Aggregate_Fields>;
  nodes: Array<Experiment>;
};

export type Experiment_Aggregate_Bool_Exp = {
  avg?: InputMaybe<Experiment_Aggregate_Bool_Exp_Avg>;
  corr?: InputMaybe<Experiment_Aggregate_Bool_Exp_Corr>;
  count?: InputMaybe<Experiment_Aggregate_Bool_Exp_Count>;
  covar_samp?: InputMaybe<Experiment_Aggregate_Bool_Exp_Covar_Samp>;
  max?: InputMaybe<Experiment_Aggregate_Bool_Exp_Max>;
  min?: InputMaybe<Experiment_Aggregate_Bool_Exp_Min>;
  stddev_samp?: InputMaybe<Experiment_Aggregate_Bool_Exp_Stddev_Samp>;
  sum?: InputMaybe<Experiment_Aggregate_Bool_Exp_Sum>;
  var_samp?: InputMaybe<Experiment_Aggregate_Bool_Exp_Var_Samp>;
};

export type Experiment_Aggregate_Bool_Exp_Avg = {
  arguments: Experiment_Select_Column_Experiment_Aggregate_Bool_Exp_Avg_Arguments_Columns;
  distinct?: InputMaybe<Scalars["Boolean"]>;
  filter?: InputMaybe<Experiment_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Experiment_Aggregate_Bool_Exp_Corr = {
  arguments: Experiment_Aggregate_Bool_Exp_Corr_Arguments;
  distinct?: InputMaybe<Scalars["Boolean"]>;
  filter?: InputMaybe<Experiment_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Experiment_Aggregate_Bool_Exp_Corr_Arguments = {
  X: Experiment_Select_Column_Experiment_Aggregate_Bool_Exp_Corr_Arguments_Columns;
  Y: Experiment_Select_Column_Experiment_Aggregate_Bool_Exp_Corr_Arguments_Columns;
};

export type Experiment_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Experiment_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]>;
  filter?: InputMaybe<Experiment_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

export type Experiment_Aggregate_Bool_Exp_Covar_Samp = {
  arguments: Experiment_Aggregate_Bool_Exp_Covar_Samp_Arguments;
  distinct?: InputMaybe<Scalars["Boolean"]>;
  filter?: InputMaybe<Experiment_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Experiment_Aggregate_Bool_Exp_Covar_Samp_Arguments = {
  X: Experiment_Select_Column_Experiment_Aggregate_Bool_Exp_Covar_Samp_Arguments_Columns;
  Y: Experiment_Select_Column_Experiment_Aggregate_Bool_Exp_Covar_Samp_Arguments_Columns;
};

export type Experiment_Aggregate_Bool_Exp_Max = {
  arguments: Experiment_Select_Column_Experiment_Aggregate_Bool_Exp_Max_Arguments_Columns;
  distinct?: InputMaybe<Scalars["Boolean"]>;
  filter?: InputMaybe<Experiment_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Experiment_Aggregate_Bool_Exp_Min = {
  arguments: Experiment_Select_Column_Experiment_Aggregate_Bool_Exp_Min_Arguments_Columns;
  distinct?: InputMaybe<Scalars["Boolean"]>;
  filter?: InputMaybe<Experiment_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Experiment_Aggregate_Bool_Exp_Stddev_Samp = {
  arguments: Experiment_Select_Column_Experiment_Aggregate_Bool_Exp_Stddev_Samp_Arguments_Columns;
  distinct?: InputMaybe<Scalars["Boolean"]>;
  filter?: InputMaybe<Experiment_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Experiment_Aggregate_Bool_Exp_Sum = {
  arguments: Experiment_Select_Column_Experiment_Aggregate_Bool_Exp_Sum_Arguments_Columns;
  distinct?: InputMaybe<Scalars["Boolean"]>;
  filter?: InputMaybe<Experiment_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Experiment_Aggregate_Bool_Exp_Var_Samp = {
  arguments: Experiment_Select_Column_Experiment_Aggregate_Bool_Exp_Var_Samp_Arguments_Columns;
  distinct?: InputMaybe<Scalars["Boolean"]>;
  filter?: InputMaybe<Experiment_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

/** aggregate fields of "experiment" */
export type Experiment_Aggregate_Fields = {
  __typename?: "experiment_aggregate_fields";
  avg?: Maybe<Experiment_Avg_Fields>;
  count: Scalars["Int"];
  max?: Maybe<Experiment_Max_Fields>;
  min?: Maybe<Experiment_Min_Fields>;
  stddev?: Maybe<Experiment_Stddev_Fields>;
  stddev_pop?: Maybe<Experiment_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Experiment_Stddev_Samp_Fields>;
  sum?: Maybe<Experiment_Sum_Fields>;
  var_pop?: Maybe<Experiment_Var_Pop_Fields>;
  var_samp?: Maybe<Experiment_Var_Samp_Fields>;
  variance?: Maybe<Experiment_Variance_Fields>;
};

/** aggregate fields of "experiment" */
export type Experiment_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Experiment_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "experiment" */
export type Experiment_Aggregate_Order_By = {
  avg?: InputMaybe<Experiment_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Experiment_Max_Order_By>;
  min?: InputMaybe<Experiment_Min_Order_By>;
  stddev?: InputMaybe<Experiment_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Experiment_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Experiment_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Experiment_Sum_Order_By>;
  var_pop?: InputMaybe<Experiment_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Experiment_Var_Samp_Order_By>;
  variance?: InputMaybe<Experiment_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "experiment" */
export type Experiment_Arr_Rel_Insert_Input = {
  data: Array<Experiment_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Experiment_On_Conflict>;
};

/** aggregate avg on columns */
export type Experiment_Avg_Fields = {
  __typename?: "experiment_avg_fields";
  data_fit_size?: Maybe<Scalars["Float"]>;
  data_pred_size?: Maybe<Scalars["Float"]>;
  experiment_duration?: Maybe<Scalars["Float"]>;
  experiment_threads?: Maybe<Scalars["Float"]>;
  experiment_worker_cpus?: Maybe<Scalars["Float"]>;
  experiment_worker_memory?: Maybe<Scalars["Float"]>;
  fixed_intercept_kld?: Maybe<Scalars["Float"]>;
  fixed_intercept_mean?: Maybe<Scalars["Float"]>;
  fixed_intercept_sd?: Maybe<Scalars["Float"]>;
  fixed_not_sprayed_kld?: Maybe<Scalars["Float"]>;
  fixed_not_sprayed_mean?: Maybe<Scalars["Float"]>;
  fixed_not_sprayed_sd?: Maybe<Scalars["Float"]>;
  grouprho_mean?: Maybe<Scalars["Float"]>;
  grouprho_mode?: Maybe<Scalars["Float"]>;
  grouprho_sd?: Maybe<Scalars["Float"]>;
  hyperpar_theta_one_mode?: Maybe<Scalars["Float"]>;
  hyperpar_theta_two_mode?: Maybe<Scalars["Float"]>;
  infestation_probability_mean?: Maybe<Scalars["Float"]>;
  infestation_probability_sd?: Maybe<Scalars["Float"]>;
  intercept_precision?: Maybe<Scalars["Float"]>;
  intercept_prior?: Maybe<Scalars["Float"]>;
  kappa_mean?: Maybe<Scalars["Float"]>;
  kappa_sd?: Maybe<Scalars["Float"]>;
  tau_mean?: Maybe<Scalars["Float"]>;
  tau_sd?: Maybe<Scalars["Float"]>;
  theta_one_mean?: Maybe<Scalars["Float"]>;
  theta_one_precision?: Maybe<Scalars["Float"]>;
  theta_one_prior?: Maybe<Scalars["Float"]>;
  theta_one_sd?: Maybe<Scalars["Float"]>;
  theta_two_mean?: Maybe<Scalars["Float"]>;
  theta_two_precision?: Maybe<Scalars["Float"]>;
  theta_two_prior?: Maybe<Scalars["Float"]>;
  theta_two_sd?: Maybe<Scalars["Float"]>;
  total_house_in_area?: Maybe<Scalars["Float"]>;
  total_inspected_houses?: Maybe<Scalars["Float"]>;
  total_positive_houses_in_area?: Maybe<Scalars["Float"]>;
  total_sprayed_houses?: Maybe<Scalars["Float"]>;
};

/** order by avg() on columns of table "experiment" */
export type Experiment_Avg_Order_By = {
  data_fit_size?: InputMaybe<Order_By>;
  data_pred_size?: InputMaybe<Order_By>;
  experiment_duration?: InputMaybe<Order_By>;
  experiment_threads?: InputMaybe<Order_By>;
  experiment_worker_cpus?: InputMaybe<Order_By>;
  experiment_worker_memory?: InputMaybe<Order_By>;
  fixed_intercept_kld?: InputMaybe<Order_By>;
  fixed_intercept_mean?: InputMaybe<Order_By>;
  fixed_intercept_sd?: InputMaybe<Order_By>;
  fixed_not_sprayed_kld?: InputMaybe<Order_By>;
  fixed_not_sprayed_mean?: InputMaybe<Order_By>;
  fixed_not_sprayed_sd?: InputMaybe<Order_By>;
  grouprho_mean?: InputMaybe<Order_By>;
  grouprho_mode?: InputMaybe<Order_By>;
  grouprho_sd?: InputMaybe<Order_By>;
  hyperpar_theta_one_mode?: InputMaybe<Order_By>;
  hyperpar_theta_two_mode?: InputMaybe<Order_By>;
  infestation_probability_mean?: InputMaybe<Order_By>;
  infestation_probability_sd?: InputMaybe<Order_By>;
  intercept_precision?: InputMaybe<Order_By>;
  intercept_prior?: InputMaybe<Order_By>;
  kappa_mean?: InputMaybe<Order_By>;
  kappa_sd?: InputMaybe<Order_By>;
  tau_mean?: InputMaybe<Order_By>;
  tau_sd?: InputMaybe<Order_By>;
  theta_one_mean?: InputMaybe<Order_By>;
  theta_one_precision?: InputMaybe<Order_By>;
  theta_one_prior?: InputMaybe<Order_By>;
  theta_one_sd?: InputMaybe<Order_By>;
  theta_two_mean?: InputMaybe<Order_By>;
  theta_two_precision?: InputMaybe<Order_By>;
  theta_two_prior?: InputMaybe<Order_By>;
  theta_two_sd?: InputMaybe<Order_By>;
  total_house_in_area?: InputMaybe<Order_By>;
  total_inspected_houses?: InputMaybe<Order_By>;
  total_positive_houses_in_area?: InputMaybe<Order_By>;
  total_sprayed_houses?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "experiment". All fields are combined with a logical 'AND'. */
export type Experiment_Bool_Exp = {
  _and?: InputMaybe<Array<Experiment_Bool_Exp>>;
  _not?: InputMaybe<Experiment_Bool_Exp>;
  _or?: InputMaybe<Array<Experiment_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  data_fit_size?: InputMaybe<Int_Comparison_Exp>;
  data_pred_size?: InputMaybe<Int_Comparison_Exp>;
  experiment?: InputMaybe<Experiment_Bool_Exp>;
  experiment_duration?: InputMaybe<Float8_Comparison_Exp>;
  experiment_start_date?: InputMaybe<Time_Comparison_Exp>;
  experiment_threads?: InputMaybe<Int_Comparison_Exp>;
  experiment_worker_cpus?: InputMaybe<Int_Comparison_Exp>;
  experiment_worker_id?: InputMaybe<String_Comparison_Exp>;
  experiment_worker_memory?: InputMaybe<Float8_Comparison_Exp>;
  experiments?: InputMaybe<Experiment_Bool_Exp>;
  experiments_aggregate?: InputMaybe<Experiment_Aggregate_Bool_Exp>;
  first_cut?: InputMaybe<Date_Comparison_Exp>;
  fixed_intercept_kld?: InputMaybe<Float8_Comparison_Exp>;
  fixed_intercept_mean?: InputMaybe<Float8_Comparison_Exp>;
  fixed_intercept_sd?: InputMaybe<Float8_Comparison_Exp>;
  fixed_not_sprayed_kld?: InputMaybe<Float8_Comparison_Exp>;
  fixed_not_sprayed_mean?: InputMaybe<Float8_Comparison_Exp>;
  fixed_not_sprayed_sd?: InputMaybe<Float8_Comparison_Exp>;
  fourth_cut?: InputMaybe<Date_Comparison_Exp>;
  geogroup?: InputMaybe<Geogroup_Bool_Exp>;
  geogroup_id?: InputMaybe<Uuid_Comparison_Exp>;
  grouprho_mean?: InputMaybe<Float8_Comparison_Exp>;
  grouprho_mode?: InputMaybe<Float8_Comparison_Exp>;
  grouprho_sd?: InputMaybe<Float8_Comparison_Exp>;
  hyperpar_theta_one_mode?: InputMaybe<Float8_Comparison_Exp>;
  hyperpar_theta_two_mode?: InputMaybe<Float8_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  infestation_probability_mean?: InputMaybe<Float8_Comparison_Exp>;
  infestation_probability_sd?: InputMaybe<Float8_Comparison_Exp>;
  intercept_precision?: InputMaybe<Float8_Comparison_Exp>;
  intercept_prior?: InputMaybe<Float8_Comparison_Exp>;
  kappa_mean?: InputMaybe<Float8_Comparison_Exp>;
  kappa_sd?: InputMaybe<Float8_Comparison_Exp>;
  kind?: InputMaybe<String_Comparison_Exp>;
  parent_id?: InputMaybe<Uuid_Comparison_Exp>;
  second_cut?: InputMaybe<Date_Comparison_Exp>;
  tau_mean?: InputMaybe<Float8_Comparison_Exp>;
  tau_sd?: InputMaybe<Float8_Comparison_Exp>;
  theta_one_mean?: InputMaybe<Float8_Comparison_Exp>;
  theta_one_precision?: InputMaybe<Float8_Comparison_Exp>;
  theta_one_prior?: InputMaybe<Float8_Comparison_Exp>;
  theta_one_sd?: InputMaybe<Float8_Comparison_Exp>;
  theta_two_mean?: InputMaybe<Float8_Comparison_Exp>;
  theta_two_precision?: InputMaybe<Float8_Comparison_Exp>;
  theta_two_prior?: InputMaybe<Float8_Comparison_Exp>;
  theta_two_sd?: InputMaybe<Float8_Comparison_Exp>;
  third_cut?: InputMaybe<Date_Comparison_Exp>;
  total_house_in_area?: InputMaybe<Int_Comparison_Exp>;
  total_inspected_houses?: InputMaybe<Int_Comparison_Exp>;
  total_positive_houses_in_area?: InputMaybe<Int_Comparison_Exp>;
  total_sprayed_houses?: InputMaybe<Int_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  workspace_metadata?: InputMaybe<Workspace_Metadata_Bool_Exp>;
  workspace_metadata_aggregate?: InputMaybe<Workspace_Metadata_Aggregate_Bool_Exp>;
};

/** unique or primary key constraints on table "experiment" */
export enum Experiment_Constraint {
  /** unique or primary key constraint on columns "id" */
  ExperimentPkey = "experiment_pkey",
}

/** input type for incrementing numeric columns in table "experiment" */
export type Experiment_Inc_Input = {
  data_fit_size?: InputMaybe<Scalars["Int"]>;
  data_pred_size?: InputMaybe<Scalars["Int"]>;
  experiment_duration?: InputMaybe<Scalars["float8"]>;
  experiment_threads?: InputMaybe<Scalars["Int"]>;
  experiment_worker_cpus?: InputMaybe<Scalars["Int"]>;
  experiment_worker_memory?: InputMaybe<Scalars["float8"]>;
  fixed_intercept_kld?: InputMaybe<Scalars["float8"]>;
  fixed_intercept_mean?: InputMaybe<Scalars["float8"]>;
  fixed_intercept_sd?: InputMaybe<Scalars["float8"]>;
  fixed_not_sprayed_kld?: InputMaybe<Scalars["float8"]>;
  fixed_not_sprayed_mean?: InputMaybe<Scalars["float8"]>;
  fixed_not_sprayed_sd?: InputMaybe<Scalars["float8"]>;
  grouprho_mean?: InputMaybe<Scalars["float8"]>;
  grouprho_mode?: InputMaybe<Scalars["float8"]>;
  grouprho_sd?: InputMaybe<Scalars["float8"]>;
  hyperpar_theta_one_mode?: InputMaybe<Scalars["float8"]>;
  hyperpar_theta_two_mode?: InputMaybe<Scalars["float8"]>;
  infestation_probability_mean?: InputMaybe<Scalars["float8"]>;
  infestation_probability_sd?: InputMaybe<Scalars["float8"]>;
  intercept_precision?: InputMaybe<Scalars["float8"]>;
  intercept_prior?: InputMaybe<Scalars["float8"]>;
  kappa_mean?: InputMaybe<Scalars["float8"]>;
  kappa_sd?: InputMaybe<Scalars["float8"]>;
  tau_mean?: InputMaybe<Scalars["float8"]>;
  tau_sd?: InputMaybe<Scalars["float8"]>;
  theta_one_mean?: InputMaybe<Scalars["float8"]>;
  theta_one_precision?: InputMaybe<Scalars["float8"]>;
  theta_one_prior?: InputMaybe<Scalars["float8"]>;
  theta_one_sd?: InputMaybe<Scalars["float8"]>;
  theta_two_mean?: InputMaybe<Scalars["float8"]>;
  theta_two_precision?: InputMaybe<Scalars["float8"]>;
  theta_two_prior?: InputMaybe<Scalars["float8"]>;
  theta_two_sd?: InputMaybe<Scalars["float8"]>;
  total_house_in_area?: InputMaybe<Scalars["Int"]>;
  total_inspected_houses?: InputMaybe<Scalars["Int"]>;
  total_positive_houses_in_area?: InputMaybe<Scalars["Int"]>;
  total_sprayed_houses?: InputMaybe<Scalars["Int"]>;
};

/** input type for inserting data into table "experiment" */
export type Experiment_Insert_Input = {
  created_at?: InputMaybe<Scalars["timestamptz"]>;
  data_fit_size?: InputMaybe<Scalars["Int"]>;
  data_pred_size?: InputMaybe<Scalars["Int"]>;
  experiment?: InputMaybe<Experiment_Obj_Rel_Insert_Input>;
  experiment_duration?: InputMaybe<Scalars["float8"]>;
  experiment_start_date?: InputMaybe<Scalars["time"]>;
  experiment_threads?: InputMaybe<Scalars["Int"]>;
  experiment_worker_cpus?: InputMaybe<Scalars["Int"]>;
  experiment_worker_id?: InputMaybe<Scalars["String"]>;
  experiment_worker_memory?: InputMaybe<Scalars["float8"]>;
  experiments?: InputMaybe<Experiment_Arr_Rel_Insert_Input>;
  first_cut?: InputMaybe<Scalars["date"]>;
  fixed_intercept_kld?: InputMaybe<Scalars["float8"]>;
  fixed_intercept_mean?: InputMaybe<Scalars["float8"]>;
  fixed_intercept_sd?: InputMaybe<Scalars["float8"]>;
  fixed_not_sprayed_kld?: InputMaybe<Scalars["float8"]>;
  fixed_not_sprayed_mean?: InputMaybe<Scalars["float8"]>;
  fixed_not_sprayed_sd?: InputMaybe<Scalars["float8"]>;
  fourth_cut?: InputMaybe<Scalars["date"]>;
  geogroup?: InputMaybe<Geogroup_Obj_Rel_Insert_Input>;
  geogroup_id?: InputMaybe<Scalars["uuid"]>;
  grouprho_mean?: InputMaybe<Scalars["float8"]>;
  grouprho_mode?: InputMaybe<Scalars["float8"]>;
  grouprho_sd?: InputMaybe<Scalars["float8"]>;
  hyperpar_theta_one_mode?: InputMaybe<Scalars["float8"]>;
  hyperpar_theta_two_mode?: InputMaybe<Scalars["float8"]>;
  id?: InputMaybe<Scalars["uuid"]>;
  infestation_probability_mean?: InputMaybe<Scalars["float8"]>;
  infestation_probability_sd?: InputMaybe<Scalars["float8"]>;
  intercept_precision?: InputMaybe<Scalars["float8"]>;
  intercept_prior?: InputMaybe<Scalars["float8"]>;
  kappa_mean?: InputMaybe<Scalars["float8"]>;
  kappa_sd?: InputMaybe<Scalars["float8"]>;
  kind?: InputMaybe<Scalars["String"]>;
  parent_id?: InputMaybe<Scalars["uuid"]>;
  second_cut?: InputMaybe<Scalars["date"]>;
  tau_mean?: InputMaybe<Scalars["float8"]>;
  tau_sd?: InputMaybe<Scalars["float8"]>;
  theta_one_mean?: InputMaybe<Scalars["float8"]>;
  theta_one_precision?: InputMaybe<Scalars["float8"]>;
  theta_one_prior?: InputMaybe<Scalars["float8"]>;
  theta_one_sd?: InputMaybe<Scalars["float8"]>;
  theta_two_mean?: InputMaybe<Scalars["float8"]>;
  theta_two_precision?: InputMaybe<Scalars["float8"]>;
  theta_two_prior?: InputMaybe<Scalars["float8"]>;
  theta_two_sd?: InputMaybe<Scalars["float8"]>;
  third_cut?: InputMaybe<Scalars["date"]>;
  total_house_in_area?: InputMaybe<Scalars["Int"]>;
  total_inspected_houses?: InputMaybe<Scalars["Int"]>;
  total_positive_houses_in_area?: InputMaybe<Scalars["Int"]>;
  total_sprayed_houses?: InputMaybe<Scalars["Int"]>;
  updated_at?: InputMaybe<Scalars["timestamptz"]>;
  workspace_metadata?: InputMaybe<Workspace_Metadata_Arr_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Experiment_Max_Fields = {
  __typename?: "experiment_max_fields";
  created_at?: Maybe<Scalars["timestamptz"]>;
  data_fit_size?: Maybe<Scalars["Int"]>;
  data_pred_size?: Maybe<Scalars["Int"]>;
  experiment_duration?: Maybe<Scalars["float8"]>;
  experiment_threads?: Maybe<Scalars["Int"]>;
  experiment_worker_cpus?: Maybe<Scalars["Int"]>;
  experiment_worker_id?: Maybe<Scalars["String"]>;
  experiment_worker_memory?: Maybe<Scalars["float8"]>;
  first_cut?: Maybe<Scalars["date"]>;
  fixed_intercept_kld?: Maybe<Scalars["float8"]>;
  fixed_intercept_mean?: Maybe<Scalars["float8"]>;
  fixed_intercept_sd?: Maybe<Scalars["float8"]>;
  fixed_not_sprayed_kld?: Maybe<Scalars["float8"]>;
  fixed_not_sprayed_mean?: Maybe<Scalars["float8"]>;
  fixed_not_sprayed_sd?: Maybe<Scalars["float8"]>;
  fourth_cut?: Maybe<Scalars["date"]>;
  geogroup_id?: Maybe<Scalars["uuid"]>;
  grouprho_mean?: Maybe<Scalars["float8"]>;
  grouprho_mode?: Maybe<Scalars["float8"]>;
  grouprho_sd?: Maybe<Scalars["float8"]>;
  hyperpar_theta_one_mode?: Maybe<Scalars["float8"]>;
  hyperpar_theta_two_mode?: Maybe<Scalars["float8"]>;
  id?: Maybe<Scalars["uuid"]>;
  infestation_probability_mean?: Maybe<Scalars["float8"]>;
  infestation_probability_sd?: Maybe<Scalars["float8"]>;
  intercept_precision?: Maybe<Scalars["float8"]>;
  intercept_prior?: Maybe<Scalars["float8"]>;
  kappa_mean?: Maybe<Scalars["float8"]>;
  kappa_sd?: Maybe<Scalars["float8"]>;
  kind?: Maybe<Scalars["String"]>;
  parent_id?: Maybe<Scalars["uuid"]>;
  second_cut?: Maybe<Scalars["date"]>;
  tau_mean?: Maybe<Scalars["float8"]>;
  tau_sd?: Maybe<Scalars["float8"]>;
  theta_one_mean?: Maybe<Scalars["float8"]>;
  theta_one_precision?: Maybe<Scalars["float8"]>;
  theta_one_prior?: Maybe<Scalars["float8"]>;
  theta_one_sd?: Maybe<Scalars["float8"]>;
  theta_two_mean?: Maybe<Scalars["float8"]>;
  theta_two_precision?: Maybe<Scalars["float8"]>;
  theta_two_prior?: Maybe<Scalars["float8"]>;
  theta_two_sd?: Maybe<Scalars["float8"]>;
  third_cut?: Maybe<Scalars["date"]>;
  total_house_in_area?: Maybe<Scalars["Int"]>;
  total_inspected_houses?: Maybe<Scalars["Int"]>;
  total_positive_houses_in_area?: Maybe<Scalars["Int"]>;
  total_sprayed_houses?: Maybe<Scalars["Int"]>;
  updated_at?: Maybe<Scalars["timestamptz"]>;
};

/** order by max() on columns of table "experiment" */
export type Experiment_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  data_fit_size?: InputMaybe<Order_By>;
  data_pred_size?: InputMaybe<Order_By>;
  experiment_duration?: InputMaybe<Order_By>;
  experiment_threads?: InputMaybe<Order_By>;
  experiment_worker_cpus?: InputMaybe<Order_By>;
  experiment_worker_id?: InputMaybe<Order_By>;
  experiment_worker_memory?: InputMaybe<Order_By>;
  first_cut?: InputMaybe<Order_By>;
  fixed_intercept_kld?: InputMaybe<Order_By>;
  fixed_intercept_mean?: InputMaybe<Order_By>;
  fixed_intercept_sd?: InputMaybe<Order_By>;
  fixed_not_sprayed_kld?: InputMaybe<Order_By>;
  fixed_not_sprayed_mean?: InputMaybe<Order_By>;
  fixed_not_sprayed_sd?: InputMaybe<Order_By>;
  fourth_cut?: InputMaybe<Order_By>;
  geogroup_id?: InputMaybe<Order_By>;
  grouprho_mean?: InputMaybe<Order_By>;
  grouprho_mode?: InputMaybe<Order_By>;
  grouprho_sd?: InputMaybe<Order_By>;
  hyperpar_theta_one_mode?: InputMaybe<Order_By>;
  hyperpar_theta_two_mode?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  infestation_probability_mean?: InputMaybe<Order_By>;
  infestation_probability_sd?: InputMaybe<Order_By>;
  intercept_precision?: InputMaybe<Order_By>;
  intercept_prior?: InputMaybe<Order_By>;
  kappa_mean?: InputMaybe<Order_By>;
  kappa_sd?: InputMaybe<Order_By>;
  kind?: InputMaybe<Order_By>;
  parent_id?: InputMaybe<Order_By>;
  second_cut?: InputMaybe<Order_By>;
  tau_mean?: InputMaybe<Order_By>;
  tau_sd?: InputMaybe<Order_By>;
  theta_one_mean?: InputMaybe<Order_By>;
  theta_one_precision?: InputMaybe<Order_By>;
  theta_one_prior?: InputMaybe<Order_By>;
  theta_one_sd?: InputMaybe<Order_By>;
  theta_two_mean?: InputMaybe<Order_By>;
  theta_two_precision?: InputMaybe<Order_By>;
  theta_two_prior?: InputMaybe<Order_By>;
  theta_two_sd?: InputMaybe<Order_By>;
  third_cut?: InputMaybe<Order_By>;
  total_house_in_area?: InputMaybe<Order_By>;
  total_inspected_houses?: InputMaybe<Order_By>;
  total_positive_houses_in_area?: InputMaybe<Order_By>;
  total_sprayed_houses?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Experiment_Min_Fields = {
  __typename?: "experiment_min_fields";
  created_at?: Maybe<Scalars["timestamptz"]>;
  data_fit_size?: Maybe<Scalars["Int"]>;
  data_pred_size?: Maybe<Scalars["Int"]>;
  experiment_duration?: Maybe<Scalars["float8"]>;
  experiment_threads?: Maybe<Scalars["Int"]>;
  experiment_worker_cpus?: Maybe<Scalars["Int"]>;
  experiment_worker_id?: Maybe<Scalars["String"]>;
  experiment_worker_memory?: Maybe<Scalars["float8"]>;
  first_cut?: Maybe<Scalars["date"]>;
  fixed_intercept_kld?: Maybe<Scalars["float8"]>;
  fixed_intercept_mean?: Maybe<Scalars["float8"]>;
  fixed_intercept_sd?: Maybe<Scalars["float8"]>;
  fixed_not_sprayed_kld?: Maybe<Scalars["float8"]>;
  fixed_not_sprayed_mean?: Maybe<Scalars["float8"]>;
  fixed_not_sprayed_sd?: Maybe<Scalars["float8"]>;
  fourth_cut?: Maybe<Scalars["date"]>;
  geogroup_id?: Maybe<Scalars["uuid"]>;
  grouprho_mean?: Maybe<Scalars["float8"]>;
  grouprho_mode?: Maybe<Scalars["float8"]>;
  grouprho_sd?: Maybe<Scalars["float8"]>;
  hyperpar_theta_one_mode?: Maybe<Scalars["float8"]>;
  hyperpar_theta_two_mode?: Maybe<Scalars["float8"]>;
  id?: Maybe<Scalars["uuid"]>;
  infestation_probability_mean?: Maybe<Scalars["float8"]>;
  infestation_probability_sd?: Maybe<Scalars["float8"]>;
  intercept_precision?: Maybe<Scalars["float8"]>;
  intercept_prior?: Maybe<Scalars["float8"]>;
  kappa_mean?: Maybe<Scalars["float8"]>;
  kappa_sd?: Maybe<Scalars["float8"]>;
  kind?: Maybe<Scalars["String"]>;
  parent_id?: Maybe<Scalars["uuid"]>;
  second_cut?: Maybe<Scalars["date"]>;
  tau_mean?: Maybe<Scalars["float8"]>;
  tau_sd?: Maybe<Scalars["float8"]>;
  theta_one_mean?: Maybe<Scalars["float8"]>;
  theta_one_precision?: Maybe<Scalars["float8"]>;
  theta_one_prior?: Maybe<Scalars["float8"]>;
  theta_one_sd?: Maybe<Scalars["float8"]>;
  theta_two_mean?: Maybe<Scalars["float8"]>;
  theta_two_precision?: Maybe<Scalars["float8"]>;
  theta_two_prior?: Maybe<Scalars["float8"]>;
  theta_two_sd?: Maybe<Scalars["float8"]>;
  third_cut?: Maybe<Scalars["date"]>;
  total_house_in_area?: Maybe<Scalars["Int"]>;
  total_inspected_houses?: Maybe<Scalars["Int"]>;
  total_positive_houses_in_area?: Maybe<Scalars["Int"]>;
  total_sprayed_houses?: Maybe<Scalars["Int"]>;
  updated_at?: Maybe<Scalars["timestamptz"]>;
};

/** order by min() on columns of table "experiment" */
export type Experiment_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  data_fit_size?: InputMaybe<Order_By>;
  data_pred_size?: InputMaybe<Order_By>;
  experiment_duration?: InputMaybe<Order_By>;
  experiment_threads?: InputMaybe<Order_By>;
  experiment_worker_cpus?: InputMaybe<Order_By>;
  experiment_worker_id?: InputMaybe<Order_By>;
  experiment_worker_memory?: InputMaybe<Order_By>;
  first_cut?: InputMaybe<Order_By>;
  fixed_intercept_kld?: InputMaybe<Order_By>;
  fixed_intercept_mean?: InputMaybe<Order_By>;
  fixed_intercept_sd?: InputMaybe<Order_By>;
  fixed_not_sprayed_kld?: InputMaybe<Order_By>;
  fixed_not_sprayed_mean?: InputMaybe<Order_By>;
  fixed_not_sprayed_sd?: InputMaybe<Order_By>;
  fourth_cut?: InputMaybe<Order_By>;
  geogroup_id?: InputMaybe<Order_By>;
  grouprho_mean?: InputMaybe<Order_By>;
  grouprho_mode?: InputMaybe<Order_By>;
  grouprho_sd?: InputMaybe<Order_By>;
  hyperpar_theta_one_mode?: InputMaybe<Order_By>;
  hyperpar_theta_two_mode?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  infestation_probability_mean?: InputMaybe<Order_By>;
  infestation_probability_sd?: InputMaybe<Order_By>;
  intercept_precision?: InputMaybe<Order_By>;
  intercept_prior?: InputMaybe<Order_By>;
  kappa_mean?: InputMaybe<Order_By>;
  kappa_sd?: InputMaybe<Order_By>;
  kind?: InputMaybe<Order_By>;
  parent_id?: InputMaybe<Order_By>;
  second_cut?: InputMaybe<Order_By>;
  tau_mean?: InputMaybe<Order_By>;
  tau_sd?: InputMaybe<Order_By>;
  theta_one_mean?: InputMaybe<Order_By>;
  theta_one_precision?: InputMaybe<Order_By>;
  theta_one_prior?: InputMaybe<Order_By>;
  theta_one_sd?: InputMaybe<Order_By>;
  theta_two_mean?: InputMaybe<Order_By>;
  theta_two_precision?: InputMaybe<Order_By>;
  theta_two_prior?: InputMaybe<Order_By>;
  theta_two_sd?: InputMaybe<Order_By>;
  third_cut?: InputMaybe<Order_By>;
  total_house_in_area?: InputMaybe<Order_By>;
  total_inspected_houses?: InputMaybe<Order_By>;
  total_positive_houses_in_area?: InputMaybe<Order_By>;
  total_sprayed_houses?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "experiment" */
export type Experiment_Mutation_Response = {
  __typename?: "experiment_mutation_response";
  /** number of rows affected by the mutation */
  affected_rows: Scalars["Int"];
  /** data from the rows affected by the mutation */
  returning: Array<Experiment>;
};

/** input type for inserting object relation for remote table "experiment" */
export type Experiment_Obj_Rel_Insert_Input = {
  data: Experiment_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Experiment_On_Conflict>;
};

/** on_conflict condition type for table "experiment" */
export type Experiment_On_Conflict = {
  constraint: Experiment_Constraint;
  update_columns?: Array<Experiment_Update_Column>;
  where?: InputMaybe<Experiment_Bool_Exp>;
};

/** Ordering options when selecting data from "experiment". */
export type Experiment_Order_By = {
  created_at?: InputMaybe<Order_By>;
  data_fit_size?: InputMaybe<Order_By>;
  data_pred_size?: InputMaybe<Order_By>;
  experiment?: InputMaybe<Experiment_Order_By>;
  experiment_duration?: InputMaybe<Order_By>;
  experiment_start_date?: InputMaybe<Order_By>;
  experiment_threads?: InputMaybe<Order_By>;
  experiment_worker_cpus?: InputMaybe<Order_By>;
  experiment_worker_id?: InputMaybe<Order_By>;
  experiment_worker_memory?: InputMaybe<Order_By>;
  experiments_aggregate?: InputMaybe<Experiment_Aggregate_Order_By>;
  first_cut?: InputMaybe<Order_By>;
  fixed_intercept_kld?: InputMaybe<Order_By>;
  fixed_intercept_mean?: InputMaybe<Order_By>;
  fixed_intercept_sd?: InputMaybe<Order_By>;
  fixed_not_sprayed_kld?: InputMaybe<Order_By>;
  fixed_not_sprayed_mean?: InputMaybe<Order_By>;
  fixed_not_sprayed_sd?: InputMaybe<Order_By>;
  fourth_cut?: InputMaybe<Order_By>;
  geogroup?: InputMaybe<Geogroup_Order_By>;
  geogroup_id?: InputMaybe<Order_By>;
  grouprho_mean?: InputMaybe<Order_By>;
  grouprho_mode?: InputMaybe<Order_By>;
  grouprho_sd?: InputMaybe<Order_By>;
  hyperpar_theta_one_mode?: InputMaybe<Order_By>;
  hyperpar_theta_two_mode?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  infestation_probability_mean?: InputMaybe<Order_By>;
  infestation_probability_sd?: InputMaybe<Order_By>;
  intercept_precision?: InputMaybe<Order_By>;
  intercept_prior?: InputMaybe<Order_By>;
  kappa_mean?: InputMaybe<Order_By>;
  kappa_sd?: InputMaybe<Order_By>;
  kind?: InputMaybe<Order_By>;
  parent_id?: InputMaybe<Order_By>;
  second_cut?: InputMaybe<Order_By>;
  tau_mean?: InputMaybe<Order_By>;
  tau_sd?: InputMaybe<Order_By>;
  theta_one_mean?: InputMaybe<Order_By>;
  theta_one_precision?: InputMaybe<Order_By>;
  theta_one_prior?: InputMaybe<Order_By>;
  theta_one_sd?: InputMaybe<Order_By>;
  theta_two_mean?: InputMaybe<Order_By>;
  theta_two_precision?: InputMaybe<Order_By>;
  theta_two_prior?: InputMaybe<Order_By>;
  theta_two_sd?: InputMaybe<Order_By>;
  third_cut?: InputMaybe<Order_By>;
  total_house_in_area?: InputMaybe<Order_By>;
  total_inspected_houses?: InputMaybe<Order_By>;
  total_positive_houses_in_area?: InputMaybe<Order_By>;
  total_sprayed_houses?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  workspace_metadata_aggregate?: InputMaybe<Workspace_Metadata_Aggregate_Order_By>;
};

/** primary key columns input for table: experiment */
export type Experiment_Pk_Columns_Input = {
  id: Scalars["uuid"];
};

/** select columns of table "experiment" */
export enum Experiment_Select_Column {
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  DataFitSize = "data_fit_size",
  /** column name */
  DataPredSize = "data_pred_size",
  /** column name */
  ExperimentDuration = "experiment_duration",
  /** column name */
  ExperimentStartDate = "experiment_start_date",
  /** column name */
  ExperimentThreads = "experiment_threads",
  /** column name */
  ExperimentWorkerCpus = "experiment_worker_cpus",
  /** column name */
  ExperimentWorkerId = "experiment_worker_id",
  /** column name */
  ExperimentWorkerMemory = "experiment_worker_memory",
  /** column name */
  FirstCut = "first_cut",
  /** column name */
  FixedInterceptKld = "fixed_intercept_kld",
  /** column name */
  FixedInterceptMean = "fixed_intercept_mean",
  /** column name */
  FixedInterceptSd = "fixed_intercept_sd",
  /** column name */
  FixedNotSprayedKld = "fixed_not_sprayed_kld",
  /** column name */
  FixedNotSprayedMean = "fixed_not_sprayed_mean",
  /** column name */
  FixedNotSprayedSd = "fixed_not_sprayed_sd",
  /** column name */
  FourthCut = "fourth_cut",
  /** column name */
  GeogroupId = "geogroup_id",
  /** column name */
  GrouprhoMean = "grouprho_mean",
  /** column name */
  GrouprhoMode = "grouprho_mode",
  /** column name */
  GrouprhoSd = "grouprho_sd",
  /** column name */
  HyperparThetaOneMode = "hyperpar_theta_one_mode",
  /** column name */
  HyperparThetaTwoMode = "hyperpar_theta_two_mode",
  /** column name */
  Id = "id",
  /** column name */
  InfestationProbabilityMean = "infestation_probability_mean",
  /** column name */
  InfestationProbabilitySd = "infestation_probability_sd",
  /** column name */
  InterceptPrecision = "intercept_precision",
  /** column name */
  InterceptPrior = "intercept_prior",
  /** column name */
  KappaMean = "kappa_mean",
  /** column name */
  KappaSd = "kappa_sd",
  /** column name */
  Kind = "kind",
  /** column name */
  ParentId = "parent_id",
  /** column name */
  SecondCut = "second_cut",
  /** column name */
  TauMean = "tau_mean",
  /** column name */
  TauSd = "tau_sd",
  /** column name */
  ThetaOneMean = "theta_one_mean",
  /** column name */
  ThetaOnePrecision = "theta_one_precision",
  /** column name */
  ThetaOnePrior = "theta_one_prior",
  /** column name */
  ThetaOneSd = "theta_one_sd",
  /** column name */
  ThetaTwoMean = "theta_two_mean",
  /** column name */
  ThetaTwoPrecision = "theta_two_precision",
  /** column name */
  ThetaTwoPrior = "theta_two_prior",
  /** column name */
  ThetaTwoSd = "theta_two_sd",
  /** column name */
  ThirdCut = "third_cut",
  /** column name */
  TotalHouseInArea = "total_house_in_area",
  /** column name */
  TotalInspectedHouses = "total_inspected_houses",
  /** column name */
  TotalPositiveHousesInArea = "total_positive_houses_in_area",
  /** column name */
  TotalSprayedHouses = "total_sprayed_houses",
  /** column name */
  UpdatedAt = "updated_at",
}

/** select "experiment_aggregate_bool_exp_avg_arguments_columns" columns of table "experiment" */
export enum Experiment_Select_Column_Experiment_Aggregate_Bool_Exp_Avg_Arguments_Columns {
  /** column name */
  ExperimentDuration = "experiment_duration",
  /** column name */
  ExperimentWorkerMemory = "experiment_worker_memory",
  /** column name */
  FixedInterceptKld = "fixed_intercept_kld",
  /** column name */
  FixedInterceptMean = "fixed_intercept_mean",
  /** column name */
  FixedInterceptSd = "fixed_intercept_sd",
  /** column name */
  FixedNotSprayedKld = "fixed_not_sprayed_kld",
  /** column name */
  FixedNotSprayedMean = "fixed_not_sprayed_mean",
  /** column name */
  FixedNotSprayedSd = "fixed_not_sprayed_sd",
  /** column name */
  GrouprhoMean = "grouprho_mean",
  /** column name */
  GrouprhoMode = "grouprho_mode",
  /** column name */
  GrouprhoSd = "grouprho_sd",
  /** column name */
  HyperparThetaOneMode = "hyperpar_theta_one_mode",
  /** column name */
  HyperparThetaTwoMode = "hyperpar_theta_two_mode",
  /** column name */
  InfestationProbabilityMean = "infestation_probability_mean",
  /** column name */
  InfestationProbabilitySd = "infestation_probability_sd",
  /** column name */
  InterceptPrecision = "intercept_precision",
  /** column name */
  InterceptPrior = "intercept_prior",
  /** column name */
  KappaMean = "kappa_mean",
  /** column name */
  KappaSd = "kappa_sd",
  /** column name */
  TauMean = "tau_mean",
  /** column name */
  TauSd = "tau_sd",
  /** column name */
  ThetaOneMean = "theta_one_mean",
  /** column name */
  ThetaOnePrecision = "theta_one_precision",
  /** column name */
  ThetaOnePrior = "theta_one_prior",
  /** column name */
  ThetaOneSd = "theta_one_sd",
  /** column name */
  ThetaTwoMean = "theta_two_mean",
  /** column name */
  ThetaTwoPrecision = "theta_two_precision",
  /** column name */
  ThetaTwoPrior = "theta_two_prior",
  /** column name */
  ThetaTwoSd = "theta_two_sd",
}

/** select "experiment_aggregate_bool_exp_corr_arguments_columns" columns of table "experiment" */
export enum Experiment_Select_Column_Experiment_Aggregate_Bool_Exp_Corr_Arguments_Columns {
  /** column name */
  ExperimentDuration = "experiment_duration",
  /** column name */
  ExperimentWorkerMemory = "experiment_worker_memory",
  /** column name */
  FixedInterceptKld = "fixed_intercept_kld",
  /** column name */
  FixedInterceptMean = "fixed_intercept_mean",
  /** column name */
  FixedInterceptSd = "fixed_intercept_sd",
  /** column name */
  FixedNotSprayedKld = "fixed_not_sprayed_kld",
  /** column name */
  FixedNotSprayedMean = "fixed_not_sprayed_mean",
  /** column name */
  FixedNotSprayedSd = "fixed_not_sprayed_sd",
  /** column name */
  GrouprhoMean = "grouprho_mean",
  /** column name */
  GrouprhoMode = "grouprho_mode",
  /** column name */
  GrouprhoSd = "grouprho_sd",
  /** column name */
  HyperparThetaOneMode = "hyperpar_theta_one_mode",
  /** column name */
  HyperparThetaTwoMode = "hyperpar_theta_two_mode",
  /** column name */
  InfestationProbabilityMean = "infestation_probability_mean",
  /** column name */
  InfestationProbabilitySd = "infestation_probability_sd",
  /** column name */
  InterceptPrecision = "intercept_precision",
  /** column name */
  InterceptPrior = "intercept_prior",
  /** column name */
  KappaMean = "kappa_mean",
  /** column name */
  KappaSd = "kappa_sd",
  /** column name */
  TauMean = "tau_mean",
  /** column name */
  TauSd = "tau_sd",
  /** column name */
  ThetaOneMean = "theta_one_mean",
  /** column name */
  ThetaOnePrecision = "theta_one_precision",
  /** column name */
  ThetaOnePrior = "theta_one_prior",
  /** column name */
  ThetaOneSd = "theta_one_sd",
  /** column name */
  ThetaTwoMean = "theta_two_mean",
  /** column name */
  ThetaTwoPrecision = "theta_two_precision",
  /** column name */
  ThetaTwoPrior = "theta_two_prior",
  /** column name */
  ThetaTwoSd = "theta_two_sd",
}

/** select "experiment_aggregate_bool_exp_covar_samp_arguments_columns" columns of table "experiment" */
export enum Experiment_Select_Column_Experiment_Aggregate_Bool_Exp_Covar_Samp_Arguments_Columns {
  /** column name */
  ExperimentDuration = "experiment_duration",
  /** column name */
  ExperimentWorkerMemory = "experiment_worker_memory",
  /** column name */
  FixedInterceptKld = "fixed_intercept_kld",
  /** column name */
  FixedInterceptMean = "fixed_intercept_mean",
  /** column name */
  FixedInterceptSd = "fixed_intercept_sd",
  /** column name */
  FixedNotSprayedKld = "fixed_not_sprayed_kld",
  /** column name */
  FixedNotSprayedMean = "fixed_not_sprayed_mean",
  /** column name */
  FixedNotSprayedSd = "fixed_not_sprayed_sd",
  /** column name */
  GrouprhoMean = "grouprho_mean",
  /** column name */
  GrouprhoMode = "grouprho_mode",
  /** column name */
  GrouprhoSd = "grouprho_sd",
  /** column name */
  HyperparThetaOneMode = "hyperpar_theta_one_mode",
  /** column name */
  HyperparThetaTwoMode = "hyperpar_theta_two_mode",
  /** column name */
  InfestationProbabilityMean = "infestation_probability_mean",
  /** column name */
  InfestationProbabilitySd = "infestation_probability_sd",
  /** column name */
  InterceptPrecision = "intercept_precision",
  /** column name */
  InterceptPrior = "intercept_prior",
  /** column name */
  KappaMean = "kappa_mean",
  /** column name */
  KappaSd = "kappa_sd",
  /** column name */
  TauMean = "tau_mean",
  /** column name */
  TauSd = "tau_sd",
  /** column name */
  ThetaOneMean = "theta_one_mean",
  /** column name */
  ThetaOnePrecision = "theta_one_precision",
  /** column name */
  ThetaOnePrior = "theta_one_prior",
  /** column name */
  ThetaOneSd = "theta_one_sd",
  /** column name */
  ThetaTwoMean = "theta_two_mean",
  /** column name */
  ThetaTwoPrecision = "theta_two_precision",
  /** column name */
  ThetaTwoPrior = "theta_two_prior",
  /** column name */
  ThetaTwoSd = "theta_two_sd",
}

/** select "experiment_aggregate_bool_exp_max_arguments_columns" columns of table "experiment" */
export enum Experiment_Select_Column_Experiment_Aggregate_Bool_Exp_Max_Arguments_Columns {
  /** column name */
  ExperimentDuration = "experiment_duration",
  /** column name */
  ExperimentWorkerMemory = "experiment_worker_memory",
  /** column name */
  FixedInterceptKld = "fixed_intercept_kld",
  /** column name */
  FixedInterceptMean = "fixed_intercept_mean",
  /** column name */
  FixedInterceptSd = "fixed_intercept_sd",
  /** column name */
  FixedNotSprayedKld = "fixed_not_sprayed_kld",
  /** column name */
  FixedNotSprayedMean = "fixed_not_sprayed_mean",
  /** column name */
  FixedNotSprayedSd = "fixed_not_sprayed_sd",
  /** column name */
  GrouprhoMean = "grouprho_mean",
  /** column name */
  GrouprhoMode = "grouprho_mode",
  /** column name */
  GrouprhoSd = "grouprho_sd",
  /** column name */
  HyperparThetaOneMode = "hyperpar_theta_one_mode",
  /** column name */
  HyperparThetaTwoMode = "hyperpar_theta_two_mode",
  /** column name */
  InfestationProbabilityMean = "infestation_probability_mean",
  /** column name */
  InfestationProbabilitySd = "infestation_probability_sd",
  /** column name */
  InterceptPrecision = "intercept_precision",
  /** column name */
  InterceptPrior = "intercept_prior",
  /** column name */
  KappaMean = "kappa_mean",
  /** column name */
  KappaSd = "kappa_sd",
  /** column name */
  TauMean = "tau_mean",
  /** column name */
  TauSd = "tau_sd",
  /** column name */
  ThetaOneMean = "theta_one_mean",
  /** column name */
  ThetaOnePrecision = "theta_one_precision",
  /** column name */
  ThetaOnePrior = "theta_one_prior",
  /** column name */
  ThetaOneSd = "theta_one_sd",
  /** column name */
  ThetaTwoMean = "theta_two_mean",
  /** column name */
  ThetaTwoPrecision = "theta_two_precision",
  /** column name */
  ThetaTwoPrior = "theta_two_prior",
  /** column name */
  ThetaTwoSd = "theta_two_sd",
}

/** select "experiment_aggregate_bool_exp_min_arguments_columns" columns of table "experiment" */
export enum Experiment_Select_Column_Experiment_Aggregate_Bool_Exp_Min_Arguments_Columns {
  /** column name */
  ExperimentDuration = "experiment_duration",
  /** column name */
  ExperimentWorkerMemory = "experiment_worker_memory",
  /** column name */
  FixedInterceptKld = "fixed_intercept_kld",
  /** column name */
  FixedInterceptMean = "fixed_intercept_mean",
  /** column name */
  FixedInterceptSd = "fixed_intercept_sd",
  /** column name */
  FixedNotSprayedKld = "fixed_not_sprayed_kld",
  /** column name */
  FixedNotSprayedMean = "fixed_not_sprayed_mean",
  /** column name */
  FixedNotSprayedSd = "fixed_not_sprayed_sd",
  /** column name */
  GrouprhoMean = "grouprho_mean",
  /** column name */
  GrouprhoMode = "grouprho_mode",
  /** column name */
  GrouprhoSd = "grouprho_sd",
  /** column name */
  HyperparThetaOneMode = "hyperpar_theta_one_mode",
  /** column name */
  HyperparThetaTwoMode = "hyperpar_theta_two_mode",
  /** column name */
  InfestationProbabilityMean = "infestation_probability_mean",
  /** column name */
  InfestationProbabilitySd = "infestation_probability_sd",
  /** column name */
  InterceptPrecision = "intercept_precision",
  /** column name */
  InterceptPrior = "intercept_prior",
  /** column name */
  KappaMean = "kappa_mean",
  /** column name */
  KappaSd = "kappa_sd",
  /** column name */
  TauMean = "tau_mean",
  /** column name */
  TauSd = "tau_sd",
  /** column name */
  ThetaOneMean = "theta_one_mean",
  /** column name */
  ThetaOnePrecision = "theta_one_precision",
  /** column name */
  ThetaOnePrior = "theta_one_prior",
  /** column name */
  ThetaOneSd = "theta_one_sd",
  /** column name */
  ThetaTwoMean = "theta_two_mean",
  /** column name */
  ThetaTwoPrecision = "theta_two_precision",
  /** column name */
  ThetaTwoPrior = "theta_two_prior",
  /** column name */
  ThetaTwoSd = "theta_two_sd",
}

/** select "experiment_aggregate_bool_exp_stddev_samp_arguments_columns" columns of table "experiment" */
export enum Experiment_Select_Column_Experiment_Aggregate_Bool_Exp_Stddev_Samp_Arguments_Columns {
  /** column name */
  ExperimentDuration = "experiment_duration",
  /** column name */
  ExperimentWorkerMemory = "experiment_worker_memory",
  /** column name */
  FixedInterceptKld = "fixed_intercept_kld",
  /** column name */
  FixedInterceptMean = "fixed_intercept_mean",
  /** column name */
  FixedInterceptSd = "fixed_intercept_sd",
  /** column name */
  FixedNotSprayedKld = "fixed_not_sprayed_kld",
  /** column name */
  FixedNotSprayedMean = "fixed_not_sprayed_mean",
  /** column name */
  FixedNotSprayedSd = "fixed_not_sprayed_sd",
  /** column name */
  GrouprhoMean = "grouprho_mean",
  /** column name */
  GrouprhoMode = "grouprho_mode",
  /** column name */
  GrouprhoSd = "grouprho_sd",
  /** column name */
  HyperparThetaOneMode = "hyperpar_theta_one_mode",
  /** column name */
  HyperparThetaTwoMode = "hyperpar_theta_two_mode",
  /** column name */
  InfestationProbabilityMean = "infestation_probability_mean",
  /** column name */
  InfestationProbabilitySd = "infestation_probability_sd",
  /** column name */
  InterceptPrecision = "intercept_precision",
  /** column name */
  InterceptPrior = "intercept_prior",
  /** column name */
  KappaMean = "kappa_mean",
  /** column name */
  KappaSd = "kappa_sd",
  /** column name */
  TauMean = "tau_mean",
  /** column name */
  TauSd = "tau_sd",
  /** column name */
  ThetaOneMean = "theta_one_mean",
  /** column name */
  ThetaOnePrecision = "theta_one_precision",
  /** column name */
  ThetaOnePrior = "theta_one_prior",
  /** column name */
  ThetaOneSd = "theta_one_sd",
  /** column name */
  ThetaTwoMean = "theta_two_mean",
  /** column name */
  ThetaTwoPrecision = "theta_two_precision",
  /** column name */
  ThetaTwoPrior = "theta_two_prior",
  /** column name */
  ThetaTwoSd = "theta_two_sd",
}

/** select "experiment_aggregate_bool_exp_sum_arguments_columns" columns of table "experiment" */
export enum Experiment_Select_Column_Experiment_Aggregate_Bool_Exp_Sum_Arguments_Columns {
  /** column name */
  ExperimentDuration = "experiment_duration",
  /** column name */
  ExperimentWorkerMemory = "experiment_worker_memory",
  /** column name */
  FixedInterceptKld = "fixed_intercept_kld",
  /** column name */
  FixedInterceptMean = "fixed_intercept_mean",
  /** column name */
  FixedInterceptSd = "fixed_intercept_sd",
  /** column name */
  FixedNotSprayedKld = "fixed_not_sprayed_kld",
  /** column name */
  FixedNotSprayedMean = "fixed_not_sprayed_mean",
  /** column name */
  FixedNotSprayedSd = "fixed_not_sprayed_sd",
  /** column name */
  GrouprhoMean = "grouprho_mean",
  /** column name */
  GrouprhoMode = "grouprho_mode",
  /** column name */
  GrouprhoSd = "grouprho_sd",
  /** column name */
  HyperparThetaOneMode = "hyperpar_theta_one_mode",
  /** column name */
  HyperparThetaTwoMode = "hyperpar_theta_two_mode",
  /** column name */
  InfestationProbabilityMean = "infestation_probability_mean",
  /** column name */
  InfestationProbabilitySd = "infestation_probability_sd",
  /** column name */
  InterceptPrecision = "intercept_precision",
  /** column name */
  InterceptPrior = "intercept_prior",
  /** column name */
  KappaMean = "kappa_mean",
  /** column name */
  KappaSd = "kappa_sd",
  /** column name */
  TauMean = "tau_mean",
  /** column name */
  TauSd = "tau_sd",
  /** column name */
  ThetaOneMean = "theta_one_mean",
  /** column name */
  ThetaOnePrecision = "theta_one_precision",
  /** column name */
  ThetaOnePrior = "theta_one_prior",
  /** column name */
  ThetaOneSd = "theta_one_sd",
  /** column name */
  ThetaTwoMean = "theta_two_mean",
  /** column name */
  ThetaTwoPrecision = "theta_two_precision",
  /** column name */
  ThetaTwoPrior = "theta_two_prior",
  /** column name */
  ThetaTwoSd = "theta_two_sd",
}

/** select "experiment_aggregate_bool_exp_var_samp_arguments_columns" columns of table "experiment" */
export enum Experiment_Select_Column_Experiment_Aggregate_Bool_Exp_Var_Samp_Arguments_Columns {
  /** column name */
  ExperimentDuration = "experiment_duration",
  /** column name */
  ExperimentWorkerMemory = "experiment_worker_memory",
  /** column name */
  FixedInterceptKld = "fixed_intercept_kld",
  /** column name */
  FixedInterceptMean = "fixed_intercept_mean",
  /** column name */
  FixedInterceptSd = "fixed_intercept_sd",
  /** column name */
  FixedNotSprayedKld = "fixed_not_sprayed_kld",
  /** column name */
  FixedNotSprayedMean = "fixed_not_sprayed_mean",
  /** column name */
  FixedNotSprayedSd = "fixed_not_sprayed_sd",
  /** column name */
  GrouprhoMean = "grouprho_mean",
  /** column name */
  GrouprhoMode = "grouprho_mode",
  /** column name */
  GrouprhoSd = "grouprho_sd",
  /** column name */
  HyperparThetaOneMode = "hyperpar_theta_one_mode",
  /** column name */
  HyperparThetaTwoMode = "hyperpar_theta_two_mode",
  /** column name */
  InfestationProbabilityMean = "infestation_probability_mean",
  /** column name */
  InfestationProbabilitySd = "infestation_probability_sd",
  /** column name */
  InterceptPrecision = "intercept_precision",
  /** column name */
  InterceptPrior = "intercept_prior",
  /** column name */
  KappaMean = "kappa_mean",
  /** column name */
  KappaSd = "kappa_sd",
  /** column name */
  TauMean = "tau_mean",
  /** column name */
  TauSd = "tau_sd",
  /** column name */
  ThetaOneMean = "theta_one_mean",
  /** column name */
  ThetaOnePrecision = "theta_one_precision",
  /** column name */
  ThetaOnePrior = "theta_one_prior",
  /** column name */
  ThetaOneSd = "theta_one_sd",
  /** column name */
  ThetaTwoMean = "theta_two_mean",
  /** column name */
  ThetaTwoPrecision = "theta_two_precision",
  /** column name */
  ThetaTwoPrior = "theta_two_prior",
  /** column name */
  ThetaTwoSd = "theta_two_sd",
}

/** input type for updating data in table "experiment" */
export type Experiment_Set_Input = {
  created_at?: InputMaybe<Scalars["timestamptz"]>;
  data_fit_size?: InputMaybe<Scalars["Int"]>;
  data_pred_size?: InputMaybe<Scalars["Int"]>;
  experiment_duration?: InputMaybe<Scalars["float8"]>;
  experiment_start_date?: InputMaybe<Scalars["time"]>;
  experiment_threads?: InputMaybe<Scalars["Int"]>;
  experiment_worker_cpus?: InputMaybe<Scalars["Int"]>;
  experiment_worker_id?: InputMaybe<Scalars["String"]>;
  experiment_worker_memory?: InputMaybe<Scalars["float8"]>;
  first_cut?: InputMaybe<Scalars["date"]>;
  fixed_intercept_kld?: InputMaybe<Scalars["float8"]>;
  fixed_intercept_mean?: InputMaybe<Scalars["float8"]>;
  fixed_intercept_sd?: InputMaybe<Scalars["float8"]>;
  fixed_not_sprayed_kld?: InputMaybe<Scalars["float8"]>;
  fixed_not_sprayed_mean?: InputMaybe<Scalars["float8"]>;
  fixed_not_sprayed_sd?: InputMaybe<Scalars["float8"]>;
  fourth_cut?: InputMaybe<Scalars["date"]>;
  geogroup_id?: InputMaybe<Scalars["uuid"]>;
  grouprho_mean?: InputMaybe<Scalars["float8"]>;
  grouprho_mode?: InputMaybe<Scalars["float8"]>;
  grouprho_sd?: InputMaybe<Scalars["float8"]>;
  hyperpar_theta_one_mode?: InputMaybe<Scalars["float8"]>;
  hyperpar_theta_two_mode?: InputMaybe<Scalars["float8"]>;
  id?: InputMaybe<Scalars["uuid"]>;
  infestation_probability_mean?: InputMaybe<Scalars["float8"]>;
  infestation_probability_sd?: InputMaybe<Scalars["float8"]>;
  intercept_precision?: InputMaybe<Scalars["float8"]>;
  intercept_prior?: InputMaybe<Scalars["float8"]>;
  kappa_mean?: InputMaybe<Scalars["float8"]>;
  kappa_sd?: InputMaybe<Scalars["float8"]>;
  kind?: InputMaybe<Scalars["String"]>;
  parent_id?: InputMaybe<Scalars["uuid"]>;
  second_cut?: InputMaybe<Scalars["date"]>;
  tau_mean?: InputMaybe<Scalars["float8"]>;
  tau_sd?: InputMaybe<Scalars["float8"]>;
  theta_one_mean?: InputMaybe<Scalars["float8"]>;
  theta_one_precision?: InputMaybe<Scalars["float8"]>;
  theta_one_prior?: InputMaybe<Scalars["float8"]>;
  theta_one_sd?: InputMaybe<Scalars["float8"]>;
  theta_two_mean?: InputMaybe<Scalars["float8"]>;
  theta_two_precision?: InputMaybe<Scalars["float8"]>;
  theta_two_prior?: InputMaybe<Scalars["float8"]>;
  theta_two_sd?: InputMaybe<Scalars["float8"]>;
  third_cut?: InputMaybe<Scalars["date"]>;
  total_house_in_area?: InputMaybe<Scalars["Int"]>;
  total_inspected_houses?: InputMaybe<Scalars["Int"]>;
  total_positive_houses_in_area?: InputMaybe<Scalars["Int"]>;
  total_sprayed_houses?: InputMaybe<Scalars["Int"]>;
  updated_at?: InputMaybe<Scalars["timestamptz"]>;
};

/** aggregate stddev on columns */
export type Experiment_Stddev_Fields = {
  __typename?: "experiment_stddev_fields";
  data_fit_size?: Maybe<Scalars["Float"]>;
  data_pred_size?: Maybe<Scalars["Float"]>;
  experiment_duration?: Maybe<Scalars["Float"]>;
  experiment_threads?: Maybe<Scalars["Float"]>;
  experiment_worker_cpus?: Maybe<Scalars["Float"]>;
  experiment_worker_memory?: Maybe<Scalars["Float"]>;
  fixed_intercept_kld?: Maybe<Scalars["Float"]>;
  fixed_intercept_mean?: Maybe<Scalars["Float"]>;
  fixed_intercept_sd?: Maybe<Scalars["Float"]>;
  fixed_not_sprayed_kld?: Maybe<Scalars["Float"]>;
  fixed_not_sprayed_mean?: Maybe<Scalars["Float"]>;
  fixed_not_sprayed_sd?: Maybe<Scalars["Float"]>;
  grouprho_mean?: Maybe<Scalars["Float"]>;
  grouprho_mode?: Maybe<Scalars["Float"]>;
  grouprho_sd?: Maybe<Scalars["Float"]>;
  hyperpar_theta_one_mode?: Maybe<Scalars["Float"]>;
  hyperpar_theta_two_mode?: Maybe<Scalars["Float"]>;
  infestation_probability_mean?: Maybe<Scalars["Float"]>;
  infestation_probability_sd?: Maybe<Scalars["Float"]>;
  intercept_precision?: Maybe<Scalars["Float"]>;
  intercept_prior?: Maybe<Scalars["Float"]>;
  kappa_mean?: Maybe<Scalars["Float"]>;
  kappa_sd?: Maybe<Scalars["Float"]>;
  tau_mean?: Maybe<Scalars["Float"]>;
  tau_sd?: Maybe<Scalars["Float"]>;
  theta_one_mean?: Maybe<Scalars["Float"]>;
  theta_one_precision?: Maybe<Scalars["Float"]>;
  theta_one_prior?: Maybe<Scalars["Float"]>;
  theta_one_sd?: Maybe<Scalars["Float"]>;
  theta_two_mean?: Maybe<Scalars["Float"]>;
  theta_two_precision?: Maybe<Scalars["Float"]>;
  theta_two_prior?: Maybe<Scalars["Float"]>;
  theta_two_sd?: Maybe<Scalars["Float"]>;
  total_house_in_area?: Maybe<Scalars["Float"]>;
  total_inspected_houses?: Maybe<Scalars["Float"]>;
  total_positive_houses_in_area?: Maybe<Scalars["Float"]>;
  total_sprayed_houses?: Maybe<Scalars["Float"]>;
};

/** order by stddev() on columns of table "experiment" */
export type Experiment_Stddev_Order_By = {
  data_fit_size?: InputMaybe<Order_By>;
  data_pred_size?: InputMaybe<Order_By>;
  experiment_duration?: InputMaybe<Order_By>;
  experiment_threads?: InputMaybe<Order_By>;
  experiment_worker_cpus?: InputMaybe<Order_By>;
  experiment_worker_memory?: InputMaybe<Order_By>;
  fixed_intercept_kld?: InputMaybe<Order_By>;
  fixed_intercept_mean?: InputMaybe<Order_By>;
  fixed_intercept_sd?: InputMaybe<Order_By>;
  fixed_not_sprayed_kld?: InputMaybe<Order_By>;
  fixed_not_sprayed_mean?: InputMaybe<Order_By>;
  fixed_not_sprayed_sd?: InputMaybe<Order_By>;
  grouprho_mean?: InputMaybe<Order_By>;
  grouprho_mode?: InputMaybe<Order_By>;
  grouprho_sd?: InputMaybe<Order_By>;
  hyperpar_theta_one_mode?: InputMaybe<Order_By>;
  hyperpar_theta_two_mode?: InputMaybe<Order_By>;
  infestation_probability_mean?: InputMaybe<Order_By>;
  infestation_probability_sd?: InputMaybe<Order_By>;
  intercept_precision?: InputMaybe<Order_By>;
  intercept_prior?: InputMaybe<Order_By>;
  kappa_mean?: InputMaybe<Order_By>;
  kappa_sd?: InputMaybe<Order_By>;
  tau_mean?: InputMaybe<Order_By>;
  tau_sd?: InputMaybe<Order_By>;
  theta_one_mean?: InputMaybe<Order_By>;
  theta_one_precision?: InputMaybe<Order_By>;
  theta_one_prior?: InputMaybe<Order_By>;
  theta_one_sd?: InputMaybe<Order_By>;
  theta_two_mean?: InputMaybe<Order_By>;
  theta_two_precision?: InputMaybe<Order_By>;
  theta_two_prior?: InputMaybe<Order_By>;
  theta_two_sd?: InputMaybe<Order_By>;
  total_house_in_area?: InputMaybe<Order_By>;
  total_inspected_houses?: InputMaybe<Order_By>;
  total_positive_houses_in_area?: InputMaybe<Order_By>;
  total_sprayed_houses?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Experiment_Stddev_Pop_Fields = {
  __typename?: "experiment_stddev_pop_fields";
  data_fit_size?: Maybe<Scalars["Float"]>;
  data_pred_size?: Maybe<Scalars["Float"]>;
  experiment_duration?: Maybe<Scalars["Float"]>;
  experiment_threads?: Maybe<Scalars["Float"]>;
  experiment_worker_cpus?: Maybe<Scalars["Float"]>;
  experiment_worker_memory?: Maybe<Scalars["Float"]>;
  fixed_intercept_kld?: Maybe<Scalars["Float"]>;
  fixed_intercept_mean?: Maybe<Scalars["Float"]>;
  fixed_intercept_sd?: Maybe<Scalars["Float"]>;
  fixed_not_sprayed_kld?: Maybe<Scalars["Float"]>;
  fixed_not_sprayed_mean?: Maybe<Scalars["Float"]>;
  fixed_not_sprayed_sd?: Maybe<Scalars["Float"]>;
  grouprho_mean?: Maybe<Scalars["Float"]>;
  grouprho_mode?: Maybe<Scalars["Float"]>;
  grouprho_sd?: Maybe<Scalars["Float"]>;
  hyperpar_theta_one_mode?: Maybe<Scalars["Float"]>;
  hyperpar_theta_two_mode?: Maybe<Scalars["Float"]>;
  infestation_probability_mean?: Maybe<Scalars["Float"]>;
  infestation_probability_sd?: Maybe<Scalars["Float"]>;
  intercept_precision?: Maybe<Scalars["Float"]>;
  intercept_prior?: Maybe<Scalars["Float"]>;
  kappa_mean?: Maybe<Scalars["Float"]>;
  kappa_sd?: Maybe<Scalars["Float"]>;
  tau_mean?: Maybe<Scalars["Float"]>;
  tau_sd?: Maybe<Scalars["Float"]>;
  theta_one_mean?: Maybe<Scalars["Float"]>;
  theta_one_precision?: Maybe<Scalars["Float"]>;
  theta_one_prior?: Maybe<Scalars["Float"]>;
  theta_one_sd?: Maybe<Scalars["Float"]>;
  theta_two_mean?: Maybe<Scalars["Float"]>;
  theta_two_precision?: Maybe<Scalars["Float"]>;
  theta_two_prior?: Maybe<Scalars["Float"]>;
  theta_two_sd?: Maybe<Scalars["Float"]>;
  total_house_in_area?: Maybe<Scalars["Float"]>;
  total_inspected_houses?: Maybe<Scalars["Float"]>;
  total_positive_houses_in_area?: Maybe<Scalars["Float"]>;
  total_sprayed_houses?: Maybe<Scalars["Float"]>;
};

/** order by stddev_pop() on columns of table "experiment" */
export type Experiment_Stddev_Pop_Order_By = {
  data_fit_size?: InputMaybe<Order_By>;
  data_pred_size?: InputMaybe<Order_By>;
  experiment_duration?: InputMaybe<Order_By>;
  experiment_threads?: InputMaybe<Order_By>;
  experiment_worker_cpus?: InputMaybe<Order_By>;
  experiment_worker_memory?: InputMaybe<Order_By>;
  fixed_intercept_kld?: InputMaybe<Order_By>;
  fixed_intercept_mean?: InputMaybe<Order_By>;
  fixed_intercept_sd?: InputMaybe<Order_By>;
  fixed_not_sprayed_kld?: InputMaybe<Order_By>;
  fixed_not_sprayed_mean?: InputMaybe<Order_By>;
  fixed_not_sprayed_sd?: InputMaybe<Order_By>;
  grouprho_mean?: InputMaybe<Order_By>;
  grouprho_mode?: InputMaybe<Order_By>;
  grouprho_sd?: InputMaybe<Order_By>;
  hyperpar_theta_one_mode?: InputMaybe<Order_By>;
  hyperpar_theta_two_mode?: InputMaybe<Order_By>;
  infestation_probability_mean?: InputMaybe<Order_By>;
  infestation_probability_sd?: InputMaybe<Order_By>;
  intercept_precision?: InputMaybe<Order_By>;
  intercept_prior?: InputMaybe<Order_By>;
  kappa_mean?: InputMaybe<Order_By>;
  kappa_sd?: InputMaybe<Order_By>;
  tau_mean?: InputMaybe<Order_By>;
  tau_sd?: InputMaybe<Order_By>;
  theta_one_mean?: InputMaybe<Order_By>;
  theta_one_precision?: InputMaybe<Order_By>;
  theta_one_prior?: InputMaybe<Order_By>;
  theta_one_sd?: InputMaybe<Order_By>;
  theta_two_mean?: InputMaybe<Order_By>;
  theta_two_precision?: InputMaybe<Order_By>;
  theta_two_prior?: InputMaybe<Order_By>;
  theta_two_sd?: InputMaybe<Order_By>;
  total_house_in_area?: InputMaybe<Order_By>;
  total_inspected_houses?: InputMaybe<Order_By>;
  total_positive_houses_in_area?: InputMaybe<Order_By>;
  total_sprayed_houses?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Experiment_Stddev_Samp_Fields = {
  __typename?: "experiment_stddev_samp_fields";
  data_fit_size?: Maybe<Scalars["Float"]>;
  data_pred_size?: Maybe<Scalars["Float"]>;
  experiment_duration?: Maybe<Scalars["Float"]>;
  experiment_threads?: Maybe<Scalars["Float"]>;
  experiment_worker_cpus?: Maybe<Scalars["Float"]>;
  experiment_worker_memory?: Maybe<Scalars["Float"]>;
  fixed_intercept_kld?: Maybe<Scalars["Float"]>;
  fixed_intercept_mean?: Maybe<Scalars["Float"]>;
  fixed_intercept_sd?: Maybe<Scalars["Float"]>;
  fixed_not_sprayed_kld?: Maybe<Scalars["Float"]>;
  fixed_not_sprayed_mean?: Maybe<Scalars["Float"]>;
  fixed_not_sprayed_sd?: Maybe<Scalars["Float"]>;
  grouprho_mean?: Maybe<Scalars["Float"]>;
  grouprho_mode?: Maybe<Scalars["Float"]>;
  grouprho_sd?: Maybe<Scalars["Float"]>;
  hyperpar_theta_one_mode?: Maybe<Scalars["Float"]>;
  hyperpar_theta_two_mode?: Maybe<Scalars["Float"]>;
  infestation_probability_mean?: Maybe<Scalars["Float"]>;
  infestation_probability_sd?: Maybe<Scalars["Float"]>;
  intercept_precision?: Maybe<Scalars["Float"]>;
  intercept_prior?: Maybe<Scalars["Float"]>;
  kappa_mean?: Maybe<Scalars["Float"]>;
  kappa_sd?: Maybe<Scalars["Float"]>;
  tau_mean?: Maybe<Scalars["Float"]>;
  tau_sd?: Maybe<Scalars["Float"]>;
  theta_one_mean?: Maybe<Scalars["Float"]>;
  theta_one_precision?: Maybe<Scalars["Float"]>;
  theta_one_prior?: Maybe<Scalars["Float"]>;
  theta_one_sd?: Maybe<Scalars["Float"]>;
  theta_two_mean?: Maybe<Scalars["Float"]>;
  theta_two_precision?: Maybe<Scalars["Float"]>;
  theta_two_prior?: Maybe<Scalars["Float"]>;
  theta_two_sd?: Maybe<Scalars["Float"]>;
  total_house_in_area?: Maybe<Scalars["Float"]>;
  total_inspected_houses?: Maybe<Scalars["Float"]>;
  total_positive_houses_in_area?: Maybe<Scalars["Float"]>;
  total_sprayed_houses?: Maybe<Scalars["Float"]>;
};

/** order by stddev_samp() on columns of table "experiment" */
export type Experiment_Stddev_Samp_Order_By = {
  data_fit_size?: InputMaybe<Order_By>;
  data_pred_size?: InputMaybe<Order_By>;
  experiment_duration?: InputMaybe<Order_By>;
  experiment_threads?: InputMaybe<Order_By>;
  experiment_worker_cpus?: InputMaybe<Order_By>;
  experiment_worker_memory?: InputMaybe<Order_By>;
  fixed_intercept_kld?: InputMaybe<Order_By>;
  fixed_intercept_mean?: InputMaybe<Order_By>;
  fixed_intercept_sd?: InputMaybe<Order_By>;
  fixed_not_sprayed_kld?: InputMaybe<Order_By>;
  fixed_not_sprayed_mean?: InputMaybe<Order_By>;
  fixed_not_sprayed_sd?: InputMaybe<Order_By>;
  grouprho_mean?: InputMaybe<Order_By>;
  grouprho_mode?: InputMaybe<Order_By>;
  grouprho_sd?: InputMaybe<Order_By>;
  hyperpar_theta_one_mode?: InputMaybe<Order_By>;
  hyperpar_theta_two_mode?: InputMaybe<Order_By>;
  infestation_probability_mean?: InputMaybe<Order_By>;
  infestation_probability_sd?: InputMaybe<Order_By>;
  intercept_precision?: InputMaybe<Order_By>;
  intercept_prior?: InputMaybe<Order_By>;
  kappa_mean?: InputMaybe<Order_By>;
  kappa_sd?: InputMaybe<Order_By>;
  tau_mean?: InputMaybe<Order_By>;
  tau_sd?: InputMaybe<Order_By>;
  theta_one_mean?: InputMaybe<Order_By>;
  theta_one_precision?: InputMaybe<Order_By>;
  theta_one_prior?: InputMaybe<Order_By>;
  theta_one_sd?: InputMaybe<Order_By>;
  theta_two_mean?: InputMaybe<Order_By>;
  theta_two_precision?: InputMaybe<Order_By>;
  theta_two_prior?: InputMaybe<Order_By>;
  theta_two_sd?: InputMaybe<Order_By>;
  total_house_in_area?: InputMaybe<Order_By>;
  total_inspected_houses?: InputMaybe<Order_By>;
  total_positive_houses_in_area?: InputMaybe<Order_By>;
  total_sprayed_houses?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "experiment" */
export type Experiment_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Experiment_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Experiment_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars["timestamptz"]>;
  data_fit_size?: InputMaybe<Scalars["Int"]>;
  data_pred_size?: InputMaybe<Scalars["Int"]>;
  experiment_duration?: InputMaybe<Scalars["float8"]>;
  experiment_start_date?: InputMaybe<Scalars["time"]>;
  experiment_threads?: InputMaybe<Scalars["Int"]>;
  experiment_worker_cpus?: InputMaybe<Scalars["Int"]>;
  experiment_worker_id?: InputMaybe<Scalars["String"]>;
  experiment_worker_memory?: InputMaybe<Scalars["float8"]>;
  first_cut?: InputMaybe<Scalars["date"]>;
  fixed_intercept_kld?: InputMaybe<Scalars["float8"]>;
  fixed_intercept_mean?: InputMaybe<Scalars["float8"]>;
  fixed_intercept_sd?: InputMaybe<Scalars["float8"]>;
  fixed_not_sprayed_kld?: InputMaybe<Scalars["float8"]>;
  fixed_not_sprayed_mean?: InputMaybe<Scalars["float8"]>;
  fixed_not_sprayed_sd?: InputMaybe<Scalars["float8"]>;
  fourth_cut?: InputMaybe<Scalars["date"]>;
  geogroup_id?: InputMaybe<Scalars["uuid"]>;
  grouprho_mean?: InputMaybe<Scalars["float8"]>;
  grouprho_mode?: InputMaybe<Scalars["float8"]>;
  grouprho_sd?: InputMaybe<Scalars["float8"]>;
  hyperpar_theta_one_mode?: InputMaybe<Scalars["float8"]>;
  hyperpar_theta_two_mode?: InputMaybe<Scalars["float8"]>;
  id?: InputMaybe<Scalars["uuid"]>;
  infestation_probability_mean?: InputMaybe<Scalars["float8"]>;
  infestation_probability_sd?: InputMaybe<Scalars["float8"]>;
  intercept_precision?: InputMaybe<Scalars["float8"]>;
  intercept_prior?: InputMaybe<Scalars["float8"]>;
  kappa_mean?: InputMaybe<Scalars["float8"]>;
  kappa_sd?: InputMaybe<Scalars["float8"]>;
  kind?: InputMaybe<Scalars["String"]>;
  parent_id?: InputMaybe<Scalars["uuid"]>;
  second_cut?: InputMaybe<Scalars["date"]>;
  tau_mean?: InputMaybe<Scalars["float8"]>;
  tau_sd?: InputMaybe<Scalars["float8"]>;
  theta_one_mean?: InputMaybe<Scalars["float8"]>;
  theta_one_precision?: InputMaybe<Scalars["float8"]>;
  theta_one_prior?: InputMaybe<Scalars["float8"]>;
  theta_one_sd?: InputMaybe<Scalars["float8"]>;
  theta_two_mean?: InputMaybe<Scalars["float8"]>;
  theta_two_precision?: InputMaybe<Scalars["float8"]>;
  theta_two_prior?: InputMaybe<Scalars["float8"]>;
  theta_two_sd?: InputMaybe<Scalars["float8"]>;
  third_cut?: InputMaybe<Scalars["date"]>;
  total_house_in_area?: InputMaybe<Scalars["Int"]>;
  total_inspected_houses?: InputMaybe<Scalars["Int"]>;
  total_positive_houses_in_area?: InputMaybe<Scalars["Int"]>;
  total_sprayed_houses?: InputMaybe<Scalars["Int"]>;
  updated_at?: InputMaybe<Scalars["timestamptz"]>;
};

/** aggregate sum on columns */
export type Experiment_Sum_Fields = {
  __typename?: "experiment_sum_fields";
  data_fit_size?: Maybe<Scalars["Int"]>;
  data_pred_size?: Maybe<Scalars["Int"]>;
  experiment_duration?: Maybe<Scalars["float8"]>;
  experiment_threads?: Maybe<Scalars["Int"]>;
  experiment_worker_cpus?: Maybe<Scalars["Int"]>;
  experiment_worker_memory?: Maybe<Scalars["float8"]>;
  fixed_intercept_kld?: Maybe<Scalars["float8"]>;
  fixed_intercept_mean?: Maybe<Scalars["float8"]>;
  fixed_intercept_sd?: Maybe<Scalars["float8"]>;
  fixed_not_sprayed_kld?: Maybe<Scalars["float8"]>;
  fixed_not_sprayed_mean?: Maybe<Scalars["float8"]>;
  fixed_not_sprayed_sd?: Maybe<Scalars["float8"]>;
  grouprho_mean?: Maybe<Scalars["float8"]>;
  grouprho_mode?: Maybe<Scalars["float8"]>;
  grouprho_sd?: Maybe<Scalars["float8"]>;
  hyperpar_theta_one_mode?: Maybe<Scalars["float8"]>;
  hyperpar_theta_two_mode?: Maybe<Scalars["float8"]>;
  infestation_probability_mean?: Maybe<Scalars["float8"]>;
  infestation_probability_sd?: Maybe<Scalars["float8"]>;
  intercept_precision?: Maybe<Scalars["float8"]>;
  intercept_prior?: Maybe<Scalars["float8"]>;
  kappa_mean?: Maybe<Scalars["float8"]>;
  kappa_sd?: Maybe<Scalars["float8"]>;
  tau_mean?: Maybe<Scalars["float8"]>;
  tau_sd?: Maybe<Scalars["float8"]>;
  theta_one_mean?: Maybe<Scalars["float8"]>;
  theta_one_precision?: Maybe<Scalars["float8"]>;
  theta_one_prior?: Maybe<Scalars["float8"]>;
  theta_one_sd?: Maybe<Scalars["float8"]>;
  theta_two_mean?: Maybe<Scalars["float8"]>;
  theta_two_precision?: Maybe<Scalars["float8"]>;
  theta_two_prior?: Maybe<Scalars["float8"]>;
  theta_two_sd?: Maybe<Scalars["float8"]>;
  total_house_in_area?: Maybe<Scalars["Int"]>;
  total_inspected_houses?: Maybe<Scalars["Int"]>;
  total_positive_houses_in_area?: Maybe<Scalars["Int"]>;
  total_sprayed_houses?: Maybe<Scalars["Int"]>;
};

/** order by sum() on columns of table "experiment" */
export type Experiment_Sum_Order_By = {
  data_fit_size?: InputMaybe<Order_By>;
  data_pred_size?: InputMaybe<Order_By>;
  experiment_duration?: InputMaybe<Order_By>;
  experiment_threads?: InputMaybe<Order_By>;
  experiment_worker_cpus?: InputMaybe<Order_By>;
  experiment_worker_memory?: InputMaybe<Order_By>;
  fixed_intercept_kld?: InputMaybe<Order_By>;
  fixed_intercept_mean?: InputMaybe<Order_By>;
  fixed_intercept_sd?: InputMaybe<Order_By>;
  fixed_not_sprayed_kld?: InputMaybe<Order_By>;
  fixed_not_sprayed_mean?: InputMaybe<Order_By>;
  fixed_not_sprayed_sd?: InputMaybe<Order_By>;
  grouprho_mean?: InputMaybe<Order_By>;
  grouprho_mode?: InputMaybe<Order_By>;
  grouprho_sd?: InputMaybe<Order_By>;
  hyperpar_theta_one_mode?: InputMaybe<Order_By>;
  hyperpar_theta_two_mode?: InputMaybe<Order_By>;
  infestation_probability_mean?: InputMaybe<Order_By>;
  infestation_probability_sd?: InputMaybe<Order_By>;
  intercept_precision?: InputMaybe<Order_By>;
  intercept_prior?: InputMaybe<Order_By>;
  kappa_mean?: InputMaybe<Order_By>;
  kappa_sd?: InputMaybe<Order_By>;
  tau_mean?: InputMaybe<Order_By>;
  tau_sd?: InputMaybe<Order_By>;
  theta_one_mean?: InputMaybe<Order_By>;
  theta_one_precision?: InputMaybe<Order_By>;
  theta_one_prior?: InputMaybe<Order_By>;
  theta_one_sd?: InputMaybe<Order_By>;
  theta_two_mean?: InputMaybe<Order_By>;
  theta_two_precision?: InputMaybe<Order_By>;
  theta_two_prior?: InputMaybe<Order_By>;
  theta_two_sd?: InputMaybe<Order_By>;
  total_house_in_area?: InputMaybe<Order_By>;
  total_inspected_houses?: InputMaybe<Order_By>;
  total_positive_houses_in_area?: InputMaybe<Order_By>;
  total_sprayed_houses?: InputMaybe<Order_By>;
};

/** update columns of table "experiment" */
export enum Experiment_Update_Column {
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  DataFitSize = "data_fit_size",
  /** column name */
  DataPredSize = "data_pred_size",
  /** column name */
  ExperimentDuration = "experiment_duration",
  /** column name */
  ExperimentStartDate = "experiment_start_date",
  /** column name */
  ExperimentThreads = "experiment_threads",
  /** column name */
  ExperimentWorkerCpus = "experiment_worker_cpus",
  /** column name */
  ExperimentWorkerId = "experiment_worker_id",
  /** column name */
  ExperimentWorkerMemory = "experiment_worker_memory",
  /** column name */
  FirstCut = "first_cut",
  /** column name */
  FixedInterceptKld = "fixed_intercept_kld",
  /** column name */
  FixedInterceptMean = "fixed_intercept_mean",
  /** column name */
  FixedInterceptSd = "fixed_intercept_sd",
  /** column name */
  FixedNotSprayedKld = "fixed_not_sprayed_kld",
  /** column name */
  FixedNotSprayedMean = "fixed_not_sprayed_mean",
  /** column name */
  FixedNotSprayedSd = "fixed_not_sprayed_sd",
  /** column name */
  FourthCut = "fourth_cut",
  /** column name */
  GeogroupId = "geogroup_id",
  /** column name */
  GrouprhoMean = "grouprho_mean",
  /** column name */
  GrouprhoMode = "grouprho_mode",
  /** column name */
  GrouprhoSd = "grouprho_sd",
  /** column name */
  HyperparThetaOneMode = "hyperpar_theta_one_mode",
  /** column name */
  HyperparThetaTwoMode = "hyperpar_theta_two_mode",
  /** column name */
  Id = "id",
  /** column name */
  InfestationProbabilityMean = "infestation_probability_mean",
  /** column name */
  InfestationProbabilitySd = "infestation_probability_sd",
  /** column name */
  InterceptPrecision = "intercept_precision",
  /** column name */
  InterceptPrior = "intercept_prior",
  /** column name */
  KappaMean = "kappa_mean",
  /** column name */
  KappaSd = "kappa_sd",
  /** column name */
  Kind = "kind",
  /** column name */
  ParentId = "parent_id",
  /** column name */
  SecondCut = "second_cut",
  /** column name */
  TauMean = "tau_mean",
  /** column name */
  TauSd = "tau_sd",
  /** column name */
  ThetaOneMean = "theta_one_mean",
  /** column name */
  ThetaOnePrecision = "theta_one_precision",
  /** column name */
  ThetaOnePrior = "theta_one_prior",
  /** column name */
  ThetaOneSd = "theta_one_sd",
  /** column name */
  ThetaTwoMean = "theta_two_mean",
  /** column name */
  ThetaTwoPrecision = "theta_two_precision",
  /** column name */
  ThetaTwoPrior = "theta_two_prior",
  /** column name */
  ThetaTwoSd = "theta_two_sd",
  /** column name */
  ThirdCut = "third_cut",
  /** column name */
  TotalHouseInArea = "total_house_in_area",
  /** column name */
  TotalInspectedHouses = "total_inspected_houses",
  /** column name */
  TotalPositiveHousesInArea = "total_positive_houses_in_area",
  /** column name */
  TotalSprayedHouses = "total_sprayed_houses",
  /** column name */
  UpdatedAt = "updated_at",
}

export type Experiment_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Experiment_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Experiment_Set_Input>;
  /** filter the rows which have to be updated */
  where: Experiment_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Experiment_Var_Pop_Fields = {
  __typename?: "experiment_var_pop_fields";
  data_fit_size?: Maybe<Scalars["Float"]>;
  data_pred_size?: Maybe<Scalars["Float"]>;
  experiment_duration?: Maybe<Scalars["Float"]>;
  experiment_threads?: Maybe<Scalars["Float"]>;
  experiment_worker_cpus?: Maybe<Scalars["Float"]>;
  experiment_worker_memory?: Maybe<Scalars["Float"]>;
  fixed_intercept_kld?: Maybe<Scalars["Float"]>;
  fixed_intercept_mean?: Maybe<Scalars["Float"]>;
  fixed_intercept_sd?: Maybe<Scalars["Float"]>;
  fixed_not_sprayed_kld?: Maybe<Scalars["Float"]>;
  fixed_not_sprayed_mean?: Maybe<Scalars["Float"]>;
  fixed_not_sprayed_sd?: Maybe<Scalars["Float"]>;
  grouprho_mean?: Maybe<Scalars["Float"]>;
  grouprho_mode?: Maybe<Scalars["Float"]>;
  grouprho_sd?: Maybe<Scalars["Float"]>;
  hyperpar_theta_one_mode?: Maybe<Scalars["Float"]>;
  hyperpar_theta_two_mode?: Maybe<Scalars["Float"]>;
  infestation_probability_mean?: Maybe<Scalars["Float"]>;
  infestation_probability_sd?: Maybe<Scalars["Float"]>;
  intercept_precision?: Maybe<Scalars["Float"]>;
  intercept_prior?: Maybe<Scalars["Float"]>;
  kappa_mean?: Maybe<Scalars["Float"]>;
  kappa_sd?: Maybe<Scalars["Float"]>;
  tau_mean?: Maybe<Scalars["Float"]>;
  tau_sd?: Maybe<Scalars["Float"]>;
  theta_one_mean?: Maybe<Scalars["Float"]>;
  theta_one_precision?: Maybe<Scalars["Float"]>;
  theta_one_prior?: Maybe<Scalars["Float"]>;
  theta_one_sd?: Maybe<Scalars["Float"]>;
  theta_two_mean?: Maybe<Scalars["Float"]>;
  theta_two_precision?: Maybe<Scalars["Float"]>;
  theta_two_prior?: Maybe<Scalars["Float"]>;
  theta_two_sd?: Maybe<Scalars["Float"]>;
  total_house_in_area?: Maybe<Scalars["Float"]>;
  total_inspected_houses?: Maybe<Scalars["Float"]>;
  total_positive_houses_in_area?: Maybe<Scalars["Float"]>;
  total_sprayed_houses?: Maybe<Scalars["Float"]>;
};

/** order by var_pop() on columns of table "experiment" */
export type Experiment_Var_Pop_Order_By = {
  data_fit_size?: InputMaybe<Order_By>;
  data_pred_size?: InputMaybe<Order_By>;
  experiment_duration?: InputMaybe<Order_By>;
  experiment_threads?: InputMaybe<Order_By>;
  experiment_worker_cpus?: InputMaybe<Order_By>;
  experiment_worker_memory?: InputMaybe<Order_By>;
  fixed_intercept_kld?: InputMaybe<Order_By>;
  fixed_intercept_mean?: InputMaybe<Order_By>;
  fixed_intercept_sd?: InputMaybe<Order_By>;
  fixed_not_sprayed_kld?: InputMaybe<Order_By>;
  fixed_not_sprayed_mean?: InputMaybe<Order_By>;
  fixed_not_sprayed_sd?: InputMaybe<Order_By>;
  grouprho_mean?: InputMaybe<Order_By>;
  grouprho_mode?: InputMaybe<Order_By>;
  grouprho_sd?: InputMaybe<Order_By>;
  hyperpar_theta_one_mode?: InputMaybe<Order_By>;
  hyperpar_theta_two_mode?: InputMaybe<Order_By>;
  infestation_probability_mean?: InputMaybe<Order_By>;
  infestation_probability_sd?: InputMaybe<Order_By>;
  intercept_precision?: InputMaybe<Order_By>;
  intercept_prior?: InputMaybe<Order_By>;
  kappa_mean?: InputMaybe<Order_By>;
  kappa_sd?: InputMaybe<Order_By>;
  tau_mean?: InputMaybe<Order_By>;
  tau_sd?: InputMaybe<Order_By>;
  theta_one_mean?: InputMaybe<Order_By>;
  theta_one_precision?: InputMaybe<Order_By>;
  theta_one_prior?: InputMaybe<Order_By>;
  theta_one_sd?: InputMaybe<Order_By>;
  theta_two_mean?: InputMaybe<Order_By>;
  theta_two_precision?: InputMaybe<Order_By>;
  theta_two_prior?: InputMaybe<Order_By>;
  theta_two_sd?: InputMaybe<Order_By>;
  total_house_in_area?: InputMaybe<Order_By>;
  total_inspected_houses?: InputMaybe<Order_By>;
  total_positive_houses_in_area?: InputMaybe<Order_By>;
  total_sprayed_houses?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Experiment_Var_Samp_Fields = {
  __typename?: "experiment_var_samp_fields";
  data_fit_size?: Maybe<Scalars["Float"]>;
  data_pred_size?: Maybe<Scalars["Float"]>;
  experiment_duration?: Maybe<Scalars["Float"]>;
  experiment_threads?: Maybe<Scalars["Float"]>;
  experiment_worker_cpus?: Maybe<Scalars["Float"]>;
  experiment_worker_memory?: Maybe<Scalars["Float"]>;
  fixed_intercept_kld?: Maybe<Scalars["Float"]>;
  fixed_intercept_mean?: Maybe<Scalars["Float"]>;
  fixed_intercept_sd?: Maybe<Scalars["Float"]>;
  fixed_not_sprayed_kld?: Maybe<Scalars["Float"]>;
  fixed_not_sprayed_mean?: Maybe<Scalars["Float"]>;
  fixed_not_sprayed_sd?: Maybe<Scalars["Float"]>;
  grouprho_mean?: Maybe<Scalars["Float"]>;
  grouprho_mode?: Maybe<Scalars["Float"]>;
  grouprho_sd?: Maybe<Scalars["Float"]>;
  hyperpar_theta_one_mode?: Maybe<Scalars["Float"]>;
  hyperpar_theta_two_mode?: Maybe<Scalars["Float"]>;
  infestation_probability_mean?: Maybe<Scalars["Float"]>;
  infestation_probability_sd?: Maybe<Scalars["Float"]>;
  intercept_precision?: Maybe<Scalars["Float"]>;
  intercept_prior?: Maybe<Scalars["Float"]>;
  kappa_mean?: Maybe<Scalars["Float"]>;
  kappa_sd?: Maybe<Scalars["Float"]>;
  tau_mean?: Maybe<Scalars["Float"]>;
  tau_sd?: Maybe<Scalars["Float"]>;
  theta_one_mean?: Maybe<Scalars["Float"]>;
  theta_one_precision?: Maybe<Scalars["Float"]>;
  theta_one_prior?: Maybe<Scalars["Float"]>;
  theta_one_sd?: Maybe<Scalars["Float"]>;
  theta_two_mean?: Maybe<Scalars["Float"]>;
  theta_two_precision?: Maybe<Scalars["Float"]>;
  theta_two_prior?: Maybe<Scalars["Float"]>;
  theta_two_sd?: Maybe<Scalars["Float"]>;
  total_house_in_area?: Maybe<Scalars["Float"]>;
  total_inspected_houses?: Maybe<Scalars["Float"]>;
  total_positive_houses_in_area?: Maybe<Scalars["Float"]>;
  total_sprayed_houses?: Maybe<Scalars["Float"]>;
};

/** order by var_samp() on columns of table "experiment" */
export type Experiment_Var_Samp_Order_By = {
  data_fit_size?: InputMaybe<Order_By>;
  data_pred_size?: InputMaybe<Order_By>;
  experiment_duration?: InputMaybe<Order_By>;
  experiment_threads?: InputMaybe<Order_By>;
  experiment_worker_cpus?: InputMaybe<Order_By>;
  experiment_worker_memory?: InputMaybe<Order_By>;
  fixed_intercept_kld?: InputMaybe<Order_By>;
  fixed_intercept_mean?: InputMaybe<Order_By>;
  fixed_intercept_sd?: InputMaybe<Order_By>;
  fixed_not_sprayed_kld?: InputMaybe<Order_By>;
  fixed_not_sprayed_mean?: InputMaybe<Order_By>;
  fixed_not_sprayed_sd?: InputMaybe<Order_By>;
  grouprho_mean?: InputMaybe<Order_By>;
  grouprho_mode?: InputMaybe<Order_By>;
  grouprho_sd?: InputMaybe<Order_By>;
  hyperpar_theta_one_mode?: InputMaybe<Order_By>;
  hyperpar_theta_two_mode?: InputMaybe<Order_By>;
  infestation_probability_mean?: InputMaybe<Order_By>;
  infestation_probability_sd?: InputMaybe<Order_By>;
  intercept_precision?: InputMaybe<Order_By>;
  intercept_prior?: InputMaybe<Order_By>;
  kappa_mean?: InputMaybe<Order_By>;
  kappa_sd?: InputMaybe<Order_By>;
  tau_mean?: InputMaybe<Order_By>;
  tau_sd?: InputMaybe<Order_By>;
  theta_one_mean?: InputMaybe<Order_By>;
  theta_one_precision?: InputMaybe<Order_By>;
  theta_one_prior?: InputMaybe<Order_By>;
  theta_one_sd?: InputMaybe<Order_By>;
  theta_two_mean?: InputMaybe<Order_By>;
  theta_two_precision?: InputMaybe<Order_By>;
  theta_two_prior?: InputMaybe<Order_By>;
  theta_two_sd?: InputMaybe<Order_By>;
  total_house_in_area?: InputMaybe<Order_By>;
  total_inspected_houses?: InputMaybe<Order_By>;
  total_positive_houses_in_area?: InputMaybe<Order_By>;
  total_sprayed_houses?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Experiment_Variance_Fields = {
  __typename?: "experiment_variance_fields";
  data_fit_size?: Maybe<Scalars["Float"]>;
  data_pred_size?: Maybe<Scalars["Float"]>;
  experiment_duration?: Maybe<Scalars["Float"]>;
  experiment_threads?: Maybe<Scalars["Float"]>;
  experiment_worker_cpus?: Maybe<Scalars["Float"]>;
  experiment_worker_memory?: Maybe<Scalars["Float"]>;
  fixed_intercept_kld?: Maybe<Scalars["Float"]>;
  fixed_intercept_mean?: Maybe<Scalars["Float"]>;
  fixed_intercept_sd?: Maybe<Scalars["Float"]>;
  fixed_not_sprayed_kld?: Maybe<Scalars["Float"]>;
  fixed_not_sprayed_mean?: Maybe<Scalars["Float"]>;
  fixed_not_sprayed_sd?: Maybe<Scalars["Float"]>;
  grouprho_mean?: Maybe<Scalars["Float"]>;
  grouprho_mode?: Maybe<Scalars["Float"]>;
  grouprho_sd?: Maybe<Scalars["Float"]>;
  hyperpar_theta_one_mode?: Maybe<Scalars["Float"]>;
  hyperpar_theta_two_mode?: Maybe<Scalars["Float"]>;
  infestation_probability_mean?: Maybe<Scalars["Float"]>;
  infestation_probability_sd?: Maybe<Scalars["Float"]>;
  intercept_precision?: Maybe<Scalars["Float"]>;
  intercept_prior?: Maybe<Scalars["Float"]>;
  kappa_mean?: Maybe<Scalars["Float"]>;
  kappa_sd?: Maybe<Scalars["Float"]>;
  tau_mean?: Maybe<Scalars["Float"]>;
  tau_sd?: Maybe<Scalars["Float"]>;
  theta_one_mean?: Maybe<Scalars["Float"]>;
  theta_one_precision?: Maybe<Scalars["Float"]>;
  theta_one_prior?: Maybe<Scalars["Float"]>;
  theta_one_sd?: Maybe<Scalars["Float"]>;
  theta_two_mean?: Maybe<Scalars["Float"]>;
  theta_two_precision?: Maybe<Scalars["Float"]>;
  theta_two_prior?: Maybe<Scalars["Float"]>;
  theta_two_sd?: Maybe<Scalars["Float"]>;
  total_house_in_area?: Maybe<Scalars["Float"]>;
  total_inspected_houses?: Maybe<Scalars["Float"]>;
  total_positive_houses_in_area?: Maybe<Scalars["Float"]>;
  total_sprayed_houses?: Maybe<Scalars["Float"]>;
};

/** order by variance() on columns of table "experiment" */
export type Experiment_Variance_Order_By = {
  data_fit_size?: InputMaybe<Order_By>;
  data_pred_size?: InputMaybe<Order_By>;
  experiment_duration?: InputMaybe<Order_By>;
  experiment_threads?: InputMaybe<Order_By>;
  experiment_worker_cpus?: InputMaybe<Order_By>;
  experiment_worker_memory?: InputMaybe<Order_By>;
  fixed_intercept_kld?: InputMaybe<Order_By>;
  fixed_intercept_mean?: InputMaybe<Order_By>;
  fixed_intercept_sd?: InputMaybe<Order_By>;
  fixed_not_sprayed_kld?: InputMaybe<Order_By>;
  fixed_not_sprayed_mean?: InputMaybe<Order_By>;
  fixed_not_sprayed_sd?: InputMaybe<Order_By>;
  grouprho_mean?: InputMaybe<Order_By>;
  grouprho_mode?: InputMaybe<Order_By>;
  grouprho_sd?: InputMaybe<Order_By>;
  hyperpar_theta_one_mode?: InputMaybe<Order_By>;
  hyperpar_theta_two_mode?: InputMaybe<Order_By>;
  infestation_probability_mean?: InputMaybe<Order_By>;
  infestation_probability_sd?: InputMaybe<Order_By>;
  intercept_precision?: InputMaybe<Order_By>;
  intercept_prior?: InputMaybe<Order_By>;
  kappa_mean?: InputMaybe<Order_By>;
  kappa_sd?: InputMaybe<Order_By>;
  tau_mean?: InputMaybe<Order_By>;
  tau_sd?: InputMaybe<Order_By>;
  theta_one_mean?: InputMaybe<Order_By>;
  theta_one_precision?: InputMaybe<Order_By>;
  theta_one_prior?: InputMaybe<Order_By>;
  theta_one_sd?: InputMaybe<Order_By>;
  theta_two_mean?: InputMaybe<Order_By>;
  theta_two_precision?: InputMaybe<Order_By>;
  theta_two_prior?: InputMaybe<Order_By>;
  theta_two_sd?: InputMaybe<Order_By>;
  total_house_in_area?: InputMaybe<Order_By>;
  total_inspected_houses?: InputMaybe<Order_By>;
  total_positive_houses_in_area?: InputMaybe<Order_By>;
  total_sprayed_houses?: InputMaybe<Order_By>;
};

/** Boolean expression to compare columns of type "float8". All fields are combined with logical 'AND'. */
export type Float8_Comparison_Exp = {
  _eq?: InputMaybe<Scalars["float8"]>;
  _gt?: InputMaybe<Scalars["float8"]>;
  _gte?: InputMaybe<Scalars["float8"]>;
  _in?: InputMaybe<Array<Scalars["float8"]>>;
  _is_null?: InputMaybe<Scalars["Boolean"]>;
  _lt?: InputMaybe<Scalars["float8"]>;
  _lte?: InputMaybe<Scalars["float8"]>;
  _neq?: InputMaybe<Scalars["float8"]>;
  _nin?: InputMaybe<Array<Scalars["float8"]>>;
};

export type Geography_Cast_Exp = {
  geometry?: InputMaybe<Geometry_Comparison_Exp>;
};

/** columns and relationships of "geography_columns" */
export type Geography_Columns = {
  __typename?: "geography_columns";
  coord_dimension?: Maybe<Scalars["Int"]>;
  f_geography_column?: Maybe<Scalars["name"]>;
  f_table_catalog?: Maybe<Scalars["name"]>;
  f_table_name?: Maybe<Scalars["name"]>;
  f_table_schema?: Maybe<Scalars["name"]>;
  srid?: Maybe<Scalars["Int"]>;
  type?: Maybe<Scalars["String"]>;
};

/** aggregated selection of "geography_columns" */
export type Geography_Columns_Aggregate = {
  __typename?: "geography_columns_aggregate";
  aggregate?: Maybe<Geography_Columns_Aggregate_Fields>;
  nodes: Array<Geography_Columns>;
};

/** aggregate fields of "geography_columns" */
export type Geography_Columns_Aggregate_Fields = {
  __typename?: "geography_columns_aggregate_fields";
  avg?: Maybe<Geography_Columns_Avg_Fields>;
  count: Scalars["Int"];
  max?: Maybe<Geography_Columns_Max_Fields>;
  min?: Maybe<Geography_Columns_Min_Fields>;
  stddev?: Maybe<Geography_Columns_Stddev_Fields>;
  stddev_pop?: Maybe<Geography_Columns_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Geography_Columns_Stddev_Samp_Fields>;
  sum?: Maybe<Geography_Columns_Sum_Fields>;
  var_pop?: Maybe<Geography_Columns_Var_Pop_Fields>;
  var_samp?: Maybe<Geography_Columns_Var_Samp_Fields>;
  variance?: Maybe<Geography_Columns_Variance_Fields>;
};

/** aggregate fields of "geography_columns" */
export type Geography_Columns_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Geography_Columns_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]>;
};

/** aggregate avg on columns */
export type Geography_Columns_Avg_Fields = {
  __typename?: "geography_columns_avg_fields";
  coord_dimension?: Maybe<Scalars["Float"]>;
  srid?: Maybe<Scalars["Float"]>;
};

/** Boolean expression to filter rows from the table "geography_columns". All fields are combined with a logical 'AND'. */
export type Geography_Columns_Bool_Exp = {
  _and?: InputMaybe<Array<Geography_Columns_Bool_Exp>>;
  _not?: InputMaybe<Geography_Columns_Bool_Exp>;
  _or?: InputMaybe<Array<Geography_Columns_Bool_Exp>>;
  coord_dimension?: InputMaybe<Int_Comparison_Exp>;
  f_geography_column?: InputMaybe<Name_Comparison_Exp>;
  f_table_catalog?: InputMaybe<Name_Comparison_Exp>;
  f_table_name?: InputMaybe<Name_Comparison_Exp>;
  f_table_schema?: InputMaybe<Name_Comparison_Exp>;
  srid?: InputMaybe<Int_Comparison_Exp>;
  type?: InputMaybe<String_Comparison_Exp>;
};

/** aggregate max on columns */
export type Geography_Columns_Max_Fields = {
  __typename?: "geography_columns_max_fields";
  coord_dimension?: Maybe<Scalars["Int"]>;
  srid?: Maybe<Scalars["Int"]>;
  type?: Maybe<Scalars["String"]>;
};

/** aggregate min on columns */
export type Geography_Columns_Min_Fields = {
  __typename?: "geography_columns_min_fields";
  coord_dimension?: Maybe<Scalars["Int"]>;
  srid?: Maybe<Scalars["Int"]>;
  type?: Maybe<Scalars["String"]>;
};

/** Ordering options when selecting data from "geography_columns". */
export type Geography_Columns_Order_By = {
  coord_dimension?: InputMaybe<Order_By>;
  f_geography_column?: InputMaybe<Order_By>;
  f_table_catalog?: InputMaybe<Order_By>;
  f_table_name?: InputMaybe<Order_By>;
  f_table_schema?: InputMaybe<Order_By>;
  srid?: InputMaybe<Order_By>;
  type?: InputMaybe<Order_By>;
};

/** select columns of table "geography_columns" */
export enum Geography_Columns_Select_Column {
  /** column name */
  CoordDimension = "coord_dimension",
  /** column name */
  FGeographyColumn = "f_geography_column",
  /** column name */
  FTableCatalog = "f_table_catalog",
  /** column name */
  FTableName = "f_table_name",
  /** column name */
  FTableSchema = "f_table_schema",
  /** column name */
  Srid = "srid",
  /** column name */
  Type = "type",
}

/** aggregate stddev on columns */
export type Geography_Columns_Stddev_Fields = {
  __typename?: "geography_columns_stddev_fields";
  coord_dimension?: Maybe<Scalars["Float"]>;
  srid?: Maybe<Scalars["Float"]>;
};

/** aggregate stddev_pop on columns */
export type Geography_Columns_Stddev_Pop_Fields = {
  __typename?: "geography_columns_stddev_pop_fields";
  coord_dimension?: Maybe<Scalars["Float"]>;
  srid?: Maybe<Scalars["Float"]>;
};

/** aggregate stddev_samp on columns */
export type Geography_Columns_Stddev_Samp_Fields = {
  __typename?: "geography_columns_stddev_samp_fields";
  coord_dimension?: Maybe<Scalars["Float"]>;
  srid?: Maybe<Scalars["Float"]>;
};

/** Streaming cursor of the table "geography_columns" */
export type Geography_Columns_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Geography_Columns_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Geography_Columns_Stream_Cursor_Value_Input = {
  coord_dimension?: InputMaybe<Scalars["Int"]>;
  f_geography_column?: InputMaybe<Scalars["name"]>;
  f_table_catalog?: InputMaybe<Scalars["name"]>;
  f_table_name?: InputMaybe<Scalars["name"]>;
  f_table_schema?: InputMaybe<Scalars["name"]>;
  srid?: InputMaybe<Scalars["Int"]>;
  type?: InputMaybe<Scalars["String"]>;
};

/** aggregate sum on columns */
export type Geography_Columns_Sum_Fields = {
  __typename?: "geography_columns_sum_fields";
  coord_dimension?: Maybe<Scalars["Int"]>;
  srid?: Maybe<Scalars["Int"]>;
};

/** aggregate var_pop on columns */
export type Geography_Columns_Var_Pop_Fields = {
  __typename?: "geography_columns_var_pop_fields";
  coord_dimension?: Maybe<Scalars["Float"]>;
  srid?: Maybe<Scalars["Float"]>;
};

/** aggregate var_samp on columns */
export type Geography_Columns_Var_Samp_Fields = {
  __typename?: "geography_columns_var_samp_fields";
  coord_dimension?: Maybe<Scalars["Float"]>;
  srid?: Maybe<Scalars["Float"]>;
};

/** aggregate variance on columns */
export type Geography_Columns_Variance_Fields = {
  __typename?: "geography_columns_variance_fields";
  coord_dimension?: Maybe<Scalars["Float"]>;
  srid?: Maybe<Scalars["Float"]>;
};

/** Boolean expression to compare columns of type "geography". All fields are combined with logical 'AND'. */
export type Geography_Comparison_Exp = {
  _cast?: InputMaybe<Geography_Cast_Exp>;
  _eq?: InputMaybe<Scalars["geography"]>;
  _gt?: InputMaybe<Scalars["geography"]>;
  _gte?: InputMaybe<Scalars["geography"]>;
  _in?: InputMaybe<Array<Scalars["geography"]>>;
  _is_null?: InputMaybe<Scalars["Boolean"]>;
  _lt?: InputMaybe<Scalars["geography"]>;
  _lte?: InputMaybe<Scalars["geography"]>;
  _neq?: InputMaybe<Scalars["geography"]>;
  _nin?: InputMaybe<Array<Scalars["geography"]>>;
  /** is the column within a given distance from the given geography value */
  _st_d_within?: InputMaybe<St_D_Within_Geography_Input>;
  /** does the column spatially intersect the given geography value */
  _st_intersects?: InputMaybe<Scalars["geography"]>;
};

/** columns and relationships of "geogroup" */
export type Geogroup = {
  __typename?: "geogroup";
  created_at?: Maybe<Scalars["timestamptz"]>;
  description?: Maybe<Scalars["jsonb"]>;
  /** An array relationship */
  experiments: Array<Experiment>;
  /** An aggregate relationship */
  experiments_aggregate: Experiment_Aggregate;
  external_code?: Maybe<Scalars["Int"]>;
  geometry?: Maybe<Scalars["geometry"]>;
  id: Scalars["uuid"];
  name: Scalars["String"];
  updated_at?: Maybe<Scalars["timestamptz"]>;
  /** An array relationship */
  work_sessions: Array<Work_Sessions>;
  /** An aggregate relationship */
  work_sessions_aggregate: Work_Sessions_Aggregate;
  /** An array relationship */
  workspace_metadata: Array<Workspace_Metadata>;
  /** An aggregate relationship */
  workspace_metadata_aggregate: Workspace_Metadata_Aggregate;
  /** An array relationship */
  workspaces: Array<Workspace>;
  /** An aggregate relationship */
  workspaces_aggregate: Workspace_Aggregate;
};

/** columns and relationships of "geogroup" */
export type GeogroupDescriptionArgs = {
  path?: InputMaybe<Scalars["String"]>;
};

/** columns and relationships of "geogroup" */
export type GeogroupExperimentsArgs = {
  distinct_on?: InputMaybe<Array<Experiment_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Experiment_Order_By>>;
  where?: InputMaybe<Experiment_Bool_Exp>;
};

/** columns and relationships of "geogroup" */
export type GeogroupExperiments_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Experiment_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Experiment_Order_By>>;
  where?: InputMaybe<Experiment_Bool_Exp>;
};

/** columns and relationships of "geogroup" */
export type GeogroupWork_SessionsArgs = {
  distinct_on?: InputMaybe<Array<Work_Sessions_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Work_Sessions_Order_By>>;
  where?: InputMaybe<Work_Sessions_Bool_Exp>;
};

/** columns and relationships of "geogroup" */
export type GeogroupWork_Sessions_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Work_Sessions_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Work_Sessions_Order_By>>;
  where?: InputMaybe<Work_Sessions_Bool_Exp>;
};

/** columns and relationships of "geogroup" */
export type GeogroupWorkspace_MetadataArgs = {
  distinct_on?: InputMaybe<Array<Workspace_Metadata_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Workspace_Metadata_Order_By>>;
  where?: InputMaybe<Workspace_Metadata_Bool_Exp>;
};

/** columns and relationships of "geogroup" */
export type GeogroupWorkspace_Metadata_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Workspace_Metadata_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Workspace_Metadata_Order_By>>;
  where?: InputMaybe<Workspace_Metadata_Bool_Exp>;
};

/** columns and relationships of "geogroup" */
export type GeogroupWorkspacesArgs = {
  distinct_on?: InputMaybe<Array<Workspace_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Workspace_Order_By>>;
  where?: InputMaybe<Workspace_Bool_Exp>;
};

/** columns and relationships of "geogroup" */
export type GeogroupWorkspaces_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Workspace_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Workspace_Order_By>>;
  where?: InputMaybe<Workspace_Bool_Exp>;
};

/** aggregated selection of "geogroup" */
export type Geogroup_Aggregate = {
  __typename?: "geogroup_aggregate";
  aggregate?: Maybe<Geogroup_Aggregate_Fields>;
  nodes: Array<Geogroup>;
};

/** aggregate fields of "geogroup" */
export type Geogroup_Aggregate_Fields = {
  __typename?: "geogroup_aggregate_fields";
  avg?: Maybe<Geogroup_Avg_Fields>;
  count: Scalars["Int"];
  max?: Maybe<Geogroup_Max_Fields>;
  min?: Maybe<Geogroup_Min_Fields>;
  stddev?: Maybe<Geogroup_Stddev_Fields>;
  stddev_pop?: Maybe<Geogroup_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Geogroup_Stddev_Samp_Fields>;
  sum?: Maybe<Geogroup_Sum_Fields>;
  var_pop?: Maybe<Geogroup_Var_Pop_Fields>;
  var_samp?: Maybe<Geogroup_Var_Samp_Fields>;
  variance?: Maybe<Geogroup_Variance_Fields>;
};

/** aggregate fields of "geogroup" */
export type Geogroup_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Geogroup_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Geogroup_Append_Input = {
  description?: InputMaybe<Scalars["jsonb"]>;
};

/** aggregate avg on columns */
export type Geogroup_Avg_Fields = {
  __typename?: "geogroup_avg_fields";
  external_code?: Maybe<Scalars["Float"]>;
};

/** Boolean expression to filter rows from the table "geogroup". All fields are combined with a logical 'AND'. */
export type Geogroup_Bool_Exp = {
  _and?: InputMaybe<Array<Geogroup_Bool_Exp>>;
  _not?: InputMaybe<Geogroup_Bool_Exp>;
  _or?: InputMaybe<Array<Geogroup_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  description?: InputMaybe<Jsonb_Comparison_Exp>;
  experiments?: InputMaybe<Experiment_Bool_Exp>;
  experiments_aggregate?: InputMaybe<Experiment_Aggregate_Bool_Exp>;
  external_code?: InputMaybe<Int_Comparison_Exp>;
  geometry?: InputMaybe<Geometry_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  work_sessions?: InputMaybe<Work_Sessions_Bool_Exp>;
  work_sessions_aggregate?: InputMaybe<Work_Sessions_Aggregate_Bool_Exp>;
  workspace_metadata?: InputMaybe<Workspace_Metadata_Bool_Exp>;
  workspace_metadata_aggregate?: InputMaybe<Workspace_Metadata_Aggregate_Bool_Exp>;
  workspaces?: InputMaybe<Workspace_Bool_Exp>;
  workspaces_aggregate?: InputMaybe<Workspace_Aggregate_Bool_Exp>;
};

/** unique or primary key constraints on table "geogroup" */
export enum Geogroup_Constraint {
  /** unique or primary key constraint on columns "name" */
  GeogroupNameKey = "geogroup_name_key",
  /** unique or primary key constraint on columns "id" */
  GeogroupPkId = "geogroup_pk_id",
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Geogroup_Delete_At_Path_Input = {
  description?: InputMaybe<Array<Scalars["String"]>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Geogroup_Delete_Elem_Input = {
  description?: InputMaybe<Scalars["Int"]>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Geogroup_Delete_Key_Input = {
  description?: InputMaybe<Scalars["String"]>;
};

/** input type for incrementing numeric columns in table "geogroup" */
export type Geogroup_Inc_Input = {
  external_code?: InputMaybe<Scalars["Int"]>;
};

/** input type for inserting data into table "geogroup" */
export type Geogroup_Insert_Input = {
  created_at?: InputMaybe<Scalars["timestamptz"]>;
  description?: InputMaybe<Scalars["jsonb"]>;
  experiments?: InputMaybe<Experiment_Arr_Rel_Insert_Input>;
  external_code?: InputMaybe<Scalars["Int"]>;
  geometry?: InputMaybe<Scalars["geometry"]>;
  id?: InputMaybe<Scalars["uuid"]>;
  name?: InputMaybe<Scalars["String"]>;
  updated_at?: InputMaybe<Scalars["timestamptz"]>;
  work_sessions?: InputMaybe<Work_Sessions_Arr_Rel_Insert_Input>;
  workspace_metadata?: InputMaybe<Workspace_Metadata_Arr_Rel_Insert_Input>;
  workspaces?: InputMaybe<Workspace_Arr_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Geogroup_Max_Fields = {
  __typename?: "geogroup_max_fields";
  created_at?: Maybe<Scalars["timestamptz"]>;
  external_code?: Maybe<Scalars["Int"]>;
  id?: Maybe<Scalars["uuid"]>;
  name?: Maybe<Scalars["String"]>;
  updated_at?: Maybe<Scalars["timestamptz"]>;
};

/** aggregate min on columns */
export type Geogroup_Min_Fields = {
  __typename?: "geogroup_min_fields";
  created_at?: Maybe<Scalars["timestamptz"]>;
  external_code?: Maybe<Scalars["Int"]>;
  id?: Maybe<Scalars["uuid"]>;
  name?: Maybe<Scalars["String"]>;
  updated_at?: Maybe<Scalars["timestamptz"]>;
};

/** response of any mutation on the table "geogroup" */
export type Geogroup_Mutation_Response = {
  __typename?: "geogroup_mutation_response";
  /** number of rows affected by the mutation */
  affected_rows: Scalars["Int"];
  /** data from the rows affected by the mutation */
  returning: Array<Geogroup>;
};

/** input type for inserting object relation for remote table "geogroup" */
export type Geogroup_Obj_Rel_Insert_Input = {
  data: Geogroup_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Geogroup_On_Conflict>;
};

/** on_conflict condition type for table "geogroup" */
export type Geogroup_On_Conflict = {
  constraint: Geogroup_Constraint;
  update_columns?: Array<Geogroup_Update_Column>;
  where?: InputMaybe<Geogroup_Bool_Exp>;
};

/** Ordering options when selecting data from "geogroup". */
export type Geogroup_Order_By = {
  created_at?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  experiments_aggregate?: InputMaybe<Experiment_Aggregate_Order_By>;
  external_code?: InputMaybe<Order_By>;
  geometry?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  work_sessions_aggregate?: InputMaybe<Work_Sessions_Aggregate_Order_By>;
  workspace_metadata_aggregate?: InputMaybe<Workspace_Metadata_Aggregate_Order_By>;
  workspaces_aggregate?: InputMaybe<Workspace_Aggregate_Order_By>;
};

/** primary key columns input for table: geogroup */
export type Geogroup_Pk_Columns_Input = {
  id: Scalars["uuid"];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Geogroup_Prepend_Input = {
  description?: InputMaybe<Scalars["jsonb"]>;
};

/** select columns of table "geogroup" */
export enum Geogroup_Select_Column {
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  Description = "description",
  /** column name */
  ExternalCode = "external_code",
  /** column name */
  Geometry = "geometry",
  /** column name */
  Id = "id",
  /** column name */
  Name = "name",
  /** column name */
  UpdatedAt = "updated_at",
}

/** input type for updating data in table "geogroup" */
export type Geogroup_Set_Input = {
  created_at?: InputMaybe<Scalars["timestamptz"]>;
  description?: InputMaybe<Scalars["jsonb"]>;
  external_code?: InputMaybe<Scalars["Int"]>;
  geometry?: InputMaybe<Scalars["geometry"]>;
  id?: InputMaybe<Scalars["uuid"]>;
  name?: InputMaybe<Scalars["String"]>;
  updated_at?: InputMaybe<Scalars["timestamptz"]>;
};

/** aggregate stddev on columns */
export type Geogroup_Stddev_Fields = {
  __typename?: "geogroup_stddev_fields";
  external_code?: Maybe<Scalars["Float"]>;
};

/** aggregate stddev_pop on columns */
export type Geogroup_Stddev_Pop_Fields = {
  __typename?: "geogroup_stddev_pop_fields";
  external_code?: Maybe<Scalars["Float"]>;
};

/** aggregate stddev_samp on columns */
export type Geogroup_Stddev_Samp_Fields = {
  __typename?: "geogroup_stddev_samp_fields";
  external_code?: Maybe<Scalars["Float"]>;
};

/** Streaming cursor of the table "geogroup" */
export type Geogroup_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Geogroup_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Geogroup_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars["timestamptz"]>;
  description?: InputMaybe<Scalars["jsonb"]>;
  external_code?: InputMaybe<Scalars["Int"]>;
  geometry?: InputMaybe<Scalars["geometry"]>;
  id?: InputMaybe<Scalars["uuid"]>;
  name?: InputMaybe<Scalars["String"]>;
  updated_at?: InputMaybe<Scalars["timestamptz"]>;
};

/** aggregate sum on columns */
export type Geogroup_Sum_Fields = {
  __typename?: "geogroup_sum_fields";
  external_code?: Maybe<Scalars["Int"]>;
};

/** update columns of table "geogroup" */
export enum Geogroup_Update_Column {
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  Description = "description",
  /** column name */
  ExternalCode = "external_code",
  /** column name */
  Geometry = "geometry",
  /** column name */
  Id = "id",
  /** column name */
  Name = "name",
  /** column name */
  UpdatedAt = "updated_at",
}

export type Geogroup_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Geogroup_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Geogroup_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Geogroup_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Geogroup_Delete_Key_Input>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Geogroup_Inc_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Geogroup_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Geogroup_Set_Input>;
  /** filter the rows which have to be updated */
  where: Geogroup_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Geogroup_Var_Pop_Fields = {
  __typename?: "geogroup_var_pop_fields";
  external_code?: Maybe<Scalars["Float"]>;
};

/** aggregate var_samp on columns */
export type Geogroup_Var_Samp_Fields = {
  __typename?: "geogroup_var_samp_fields";
  external_code?: Maybe<Scalars["Float"]>;
};

/** aggregate variance on columns */
export type Geogroup_Variance_Fields = {
  __typename?: "geogroup_variance_fields";
  external_code?: Maybe<Scalars["Float"]>;
};

export type Geometry_Cast_Exp = {
  geography?: InputMaybe<Geography_Comparison_Exp>;
};

/** columns and relationships of "geometry_columns" */
export type Geometry_Columns = {
  __typename?: "geometry_columns";
  coord_dimension?: Maybe<Scalars["Int"]>;
  f_geometry_column?: Maybe<Scalars["name"]>;
  f_table_catalog?: Maybe<Scalars["String"]>;
  f_table_name?: Maybe<Scalars["name"]>;
  f_table_schema?: Maybe<Scalars["name"]>;
  srid?: Maybe<Scalars["Int"]>;
  type?: Maybe<Scalars["String"]>;
};

/** aggregated selection of "geometry_columns" */
export type Geometry_Columns_Aggregate = {
  __typename?: "geometry_columns_aggregate";
  aggregate?: Maybe<Geometry_Columns_Aggregate_Fields>;
  nodes: Array<Geometry_Columns>;
};

/** aggregate fields of "geometry_columns" */
export type Geometry_Columns_Aggregate_Fields = {
  __typename?: "geometry_columns_aggregate_fields";
  avg?: Maybe<Geometry_Columns_Avg_Fields>;
  count: Scalars["Int"];
  max?: Maybe<Geometry_Columns_Max_Fields>;
  min?: Maybe<Geometry_Columns_Min_Fields>;
  stddev?: Maybe<Geometry_Columns_Stddev_Fields>;
  stddev_pop?: Maybe<Geometry_Columns_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Geometry_Columns_Stddev_Samp_Fields>;
  sum?: Maybe<Geometry_Columns_Sum_Fields>;
  var_pop?: Maybe<Geometry_Columns_Var_Pop_Fields>;
  var_samp?: Maybe<Geometry_Columns_Var_Samp_Fields>;
  variance?: Maybe<Geometry_Columns_Variance_Fields>;
};

/** aggregate fields of "geometry_columns" */
export type Geometry_Columns_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Geometry_Columns_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]>;
};

/** aggregate avg on columns */
export type Geometry_Columns_Avg_Fields = {
  __typename?: "geometry_columns_avg_fields";
  coord_dimension?: Maybe<Scalars["Float"]>;
  srid?: Maybe<Scalars["Float"]>;
};

/** Boolean expression to filter rows from the table "geometry_columns". All fields are combined with a logical 'AND'. */
export type Geometry_Columns_Bool_Exp = {
  _and?: InputMaybe<Array<Geometry_Columns_Bool_Exp>>;
  _not?: InputMaybe<Geometry_Columns_Bool_Exp>;
  _or?: InputMaybe<Array<Geometry_Columns_Bool_Exp>>;
  coord_dimension?: InputMaybe<Int_Comparison_Exp>;
  f_geometry_column?: InputMaybe<Name_Comparison_Exp>;
  f_table_catalog?: InputMaybe<String_Comparison_Exp>;
  f_table_name?: InputMaybe<Name_Comparison_Exp>;
  f_table_schema?: InputMaybe<Name_Comparison_Exp>;
  srid?: InputMaybe<Int_Comparison_Exp>;
  type?: InputMaybe<String_Comparison_Exp>;
};

/** input type for incrementing numeric columns in table "geometry_columns" */
export type Geometry_Columns_Inc_Input = {
  coord_dimension?: InputMaybe<Scalars["Int"]>;
  srid?: InputMaybe<Scalars["Int"]>;
};

/** input type for inserting data into table "geometry_columns" */
export type Geometry_Columns_Insert_Input = {
  coord_dimension?: InputMaybe<Scalars["Int"]>;
  f_geometry_column?: InputMaybe<Scalars["name"]>;
  f_table_catalog?: InputMaybe<Scalars["String"]>;
  f_table_name?: InputMaybe<Scalars["name"]>;
  f_table_schema?: InputMaybe<Scalars["name"]>;
  srid?: InputMaybe<Scalars["Int"]>;
  type?: InputMaybe<Scalars["String"]>;
};

/** aggregate max on columns */
export type Geometry_Columns_Max_Fields = {
  __typename?: "geometry_columns_max_fields";
  coord_dimension?: Maybe<Scalars["Int"]>;
  f_table_catalog?: Maybe<Scalars["String"]>;
  srid?: Maybe<Scalars["Int"]>;
  type?: Maybe<Scalars["String"]>;
};

/** aggregate min on columns */
export type Geometry_Columns_Min_Fields = {
  __typename?: "geometry_columns_min_fields";
  coord_dimension?: Maybe<Scalars["Int"]>;
  f_table_catalog?: Maybe<Scalars["String"]>;
  srid?: Maybe<Scalars["Int"]>;
  type?: Maybe<Scalars["String"]>;
};

/** response of any mutation on the table "geometry_columns" */
export type Geometry_Columns_Mutation_Response = {
  __typename?: "geometry_columns_mutation_response";
  /** number of rows affected by the mutation */
  affected_rows: Scalars["Int"];
  /** data from the rows affected by the mutation */
  returning: Array<Geometry_Columns>;
};

/** Ordering options when selecting data from "geometry_columns". */
export type Geometry_Columns_Order_By = {
  coord_dimension?: InputMaybe<Order_By>;
  f_geometry_column?: InputMaybe<Order_By>;
  f_table_catalog?: InputMaybe<Order_By>;
  f_table_name?: InputMaybe<Order_By>;
  f_table_schema?: InputMaybe<Order_By>;
  srid?: InputMaybe<Order_By>;
  type?: InputMaybe<Order_By>;
};

/** select columns of table "geometry_columns" */
export enum Geometry_Columns_Select_Column {
  /** column name */
  CoordDimension = "coord_dimension",
  /** column name */
  FGeometryColumn = "f_geometry_column",
  /** column name */
  FTableCatalog = "f_table_catalog",
  /** column name */
  FTableName = "f_table_name",
  /** column name */
  FTableSchema = "f_table_schema",
  /** column name */
  Srid = "srid",
  /** column name */
  Type = "type",
}

/** input type for updating data in table "geometry_columns" */
export type Geometry_Columns_Set_Input = {
  coord_dimension?: InputMaybe<Scalars["Int"]>;
  f_geometry_column?: InputMaybe<Scalars["name"]>;
  f_table_catalog?: InputMaybe<Scalars["String"]>;
  f_table_name?: InputMaybe<Scalars["name"]>;
  f_table_schema?: InputMaybe<Scalars["name"]>;
  srid?: InputMaybe<Scalars["Int"]>;
  type?: InputMaybe<Scalars["String"]>;
};

/** aggregate stddev on columns */
export type Geometry_Columns_Stddev_Fields = {
  __typename?: "geometry_columns_stddev_fields";
  coord_dimension?: Maybe<Scalars["Float"]>;
  srid?: Maybe<Scalars["Float"]>;
};

/** aggregate stddev_pop on columns */
export type Geometry_Columns_Stddev_Pop_Fields = {
  __typename?: "geometry_columns_stddev_pop_fields";
  coord_dimension?: Maybe<Scalars["Float"]>;
  srid?: Maybe<Scalars["Float"]>;
};

/** aggregate stddev_samp on columns */
export type Geometry_Columns_Stddev_Samp_Fields = {
  __typename?: "geometry_columns_stddev_samp_fields";
  coord_dimension?: Maybe<Scalars["Float"]>;
  srid?: Maybe<Scalars["Float"]>;
};

/** Streaming cursor of the table "geometry_columns" */
export type Geometry_Columns_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Geometry_Columns_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Geometry_Columns_Stream_Cursor_Value_Input = {
  coord_dimension?: InputMaybe<Scalars["Int"]>;
  f_geometry_column?: InputMaybe<Scalars["name"]>;
  f_table_catalog?: InputMaybe<Scalars["String"]>;
  f_table_name?: InputMaybe<Scalars["name"]>;
  f_table_schema?: InputMaybe<Scalars["name"]>;
  srid?: InputMaybe<Scalars["Int"]>;
  type?: InputMaybe<Scalars["String"]>;
};

/** aggregate sum on columns */
export type Geometry_Columns_Sum_Fields = {
  __typename?: "geometry_columns_sum_fields";
  coord_dimension?: Maybe<Scalars["Int"]>;
  srid?: Maybe<Scalars["Int"]>;
};

export type Geometry_Columns_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Geometry_Columns_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Geometry_Columns_Set_Input>;
  /** filter the rows which have to be updated */
  where: Geometry_Columns_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Geometry_Columns_Var_Pop_Fields = {
  __typename?: "geometry_columns_var_pop_fields";
  coord_dimension?: Maybe<Scalars["Float"]>;
  srid?: Maybe<Scalars["Float"]>;
};

/** aggregate var_samp on columns */
export type Geometry_Columns_Var_Samp_Fields = {
  __typename?: "geometry_columns_var_samp_fields";
  coord_dimension?: Maybe<Scalars["Float"]>;
  srid?: Maybe<Scalars["Float"]>;
};

/** aggregate variance on columns */
export type Geometry_Columns_Variance_Fields = {
  __typename?: "geometry_columns_variance_fields";
  coord_dimension?: Maybe<Scalars["Float"]>;
  srid?: Maybe<Scalars["Float"]>;
};

/** Boolean expression to compare columns of type "geometry". All fields are combined with logical 'AND'. */
export type Geometry_Comparison_Exp = {
  _cast?: InputMaybe<Geometry_Cast_Exp>;
  _eq?: InputMaybe<Scalars["geometry"]>;
  _gt?: InputMaybe<Scalars["geometry"]>;
  _gte?: InputMaybe<Scalars["geometry"]>;
  _in?: InputMaybe<Array<Scalars["geometry"]>>;
  _is_null?: InputMaybe<Scalars["Boolean"]>;
  _lt?: InputMaybe<Scalars["geometry"]>;
  _lte?: InputMaybe<Scalars["geometry"]>;
  _neq?: InputMaybe<Scalars["geometry"]>;
  _nin?: InputMaybe<Array<Scalars["geometry"]>>;
  /** is the column within a given 3D distance from the given geometry value */
  _st_3d_d_within?: InputMaybe<St_D_Within_Input>;
  /** does the column spatially intersect the given geometry value in 3D */
  _st_3d_intersects?: InputMaybe<Scalars["geometry"]>;
  /** does the column contain the given geometry value */
  _st_contains?: InputMaybe<Scalars["geometry"]>;
  /** does the column cross the given geometry value */
  _st_crosses?: InputMaybe<Scalars["geometry"]>;
  /** is the column within a given distance from the given geometry value */
  _st_d_within?: InputMaybe<St_D_Within_Input>;
  /** is the column equal to given geometry value (directionality is ignored) */
  _st_equals?: InputMaybe<Scalars["geometry"]>;
  /** does the column spatially intersect the given geometry value */
  _st_intersects?: InputMaybe<Scalars["geometry"]>;
  /** does the column 'spatially overlap' (intersect but not completely contain) the given geometry value */
  _st_overlaps?: InputMaybe<Scalars["geometry"]>;
  /** does the column have atleast one point in common with the given geometry value */
  _st_touches?: InputMaybe<Scalars["geometry"]>;
  /** is the column contained in the given geometry value */
  _st_within?: InputMaybe<Scalars["geometry"]>;
};

/** columns and relationships of "geopoint" */
export type Geopoint = {
  __typename?: "geopoint";
  /** An array relationship */
  advertisements: Array<Advertisement>;
  /** An aggregate relationship */
  advertisements_aggregate: Advertisement_Aggregate;
  block?: Maybe<Scalars["String"]>;
  created_at?: Maybe<Scalars["timestamptz"]>;
  /** An array relationship */
  distances: Array<Distances>;
  /** An array relationship */
  distancesByToId: Array<Distances>;
  /** An aggregate relationship */
  distancesByToId_aggregate: Distances_Aggregate;
  /** An aggregate relationship */
  distances_aggregate: Distances_Aggregate;
  geometry?: Maybe<Scalars["geometry"]>;
  id: Scalars["uuid"];
  lat: Scalars["float8"];
  lng: Scalars["float8"];
  /** An array relationship */
  observations: Array<Observation>;
  /** An aggregate relationship */
  observations_aggregate: Observation_Aggregate;
  unicode?: Maybe<Scalars["String"]>;
  updated_at?: Maybe<Scalars["timestamptz"]>;
  /** An array relationship */
  workspace_metadata: Array<Workspace_Metadata>;
  /** An aggregate relationship */
  workspace_metadata_aggregate: Workspace_Metadata_Aggregate;
};

/** columns and relationships of "geopoint" */
export type GeopointAdvertisementsArgs = {
  distinct_on?: InputMaybe<Array<Advertisement_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Advertisement_Order_By>>;
  where?: InputMaybe<Advertisement_Bool_Exp>;
};

/** columns and relationships of "geopoint" */
export type GeopointAdvertisements_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Advertisement_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Advertisement_Order_By>>;
  where?: InputMaybe<Advertisement_Bool_Exp>;
};

/** columns and relationships of "geopoint" */
export type GeopointDistancesArgs = {
  distinct_on?: InputMaybe<Array<Distances_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Distances_Order_By>>;
  where?: InputMaybe<Distances_Bool_Exp>;
};

/** columns and relationships of "geopoint" */
export type GeopointDistancesByToIdArgs = {
  distinct_on?: InputMaybe<Array<Distances_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Distances_Order_By>>;
  where?: InputMaybe<Distances_Bool_Exp>;
};

/** columns and relationships of "geopoint" */
export type GeopointDistancesByToId_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Distances_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Distances_Order_By>>;
  where?: InputMaybe<Distances_Bool_Exp>;
};

/** columns and relationships of "geopoint" */
export type GeopointDistances_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Distances_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Distances_Order_By>>;
  where?: InputMaybe<Distances_Bool_Exp>;
};

/** columns and relationships of "geopoint" */
export type GeopointObservationsArgs = {
  distinct_on?: InputMaybe<Array<Observation_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Observation_Order_By>>;
  where?: InputMaybe<Observation_Bool_Exp>;
};

/** columns and relationships of "geopoint" */
export type GeopointObservations_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Observation_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Observation_Order_By>>;
  where?: InputMaybe<Observation_Bool_Exp>;
};

/** columns and relationships of "geopoint" */
export type GeopointWorkspace_MetadataArgs = {
  distinct_on?: InputMaybe<Array<Workspace_Metadata_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Workspace_Metadata_Order_By>>;
  where?: InputMaybe<Workspace_Metadata_Bool_Exp>;
};

/** columns and relationships of "geopoint" */
export type GeopointWorkspace_Metadata_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Workspace_Metadata_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Workspace_Metadata_Order_By>>;
  where?: InputMaybe<Workspace_Metadata_Bool_Exp>;
};

/** aggregated selection of "geopoint" */
export type Geopoint_Aggregate = {
  __typename?: "geopoint_aggregate";
  aggregate?: Maybe<Geopoint_Aggregate_Fields>;
  nodes: Array<Geopoint>;
};

/** aggregate fields of "geopoint" */
export type Geopoint_Aggregate_Fields = {
  __typename?: "geopoint_aggregate_fields";
  avg?: Maybe<Geopoint_Avg_Fields>;
  count: Scalars["Int"];
  max?: Maybe<Geopoint_Max_Fields>;
  min?: Maybe<Geopoint_Min_Fields>;
  stddev?: Maybe<Geopoint_Stddev_Fields>;
  stddev_pop?: Maybe<Geopoint_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Geopoint_Stddev_Samp_Fields>;
  sum?: Maybe<Geopoint_Sum_Fields>;
  var_pop?: Maybe<Geopoint_Var_Pop_Fields>;
  var_samp?: Maybe<Geopoint_Var_Samp_Fields>;
  variance?: Maybe<Geopoint_Variance_Fields>;
};

/** aggregate fields of "geopoint" */
export type Geopoint_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Geopoint_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]>;
};

/** aggregate avg on columns */
export type Geopoint_Avg_Fields = {
  __typename?: "geopoint_avg_fields";
  lat?: Maybe<Scalars["Float"]>;
  lng?: Maybe<Scalars["Float"]>;
};

/** Boolean expression to filter rows from the table "geopoint". All fields are combined with a logical 'AND'. */
export type Geopoint_Bool_Exp = {
  _and?: InputMaybe<Array<Geopoint_Bool_Exp>>;
  _not?: InputMaybe<Geopoint_Bool_Exp>;
  _or?: InputMaybe<Array<Geopoint_Bool_Exp>>;
  advertisements?: InputMaybe<Advertisement_Bool_Exp>;
  advertisements_aggregate?: InputMaybe<Advertisement_Aggregate_Bool_Exp>;
  block?: InputMaybe<String_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  distances?: InputMaybe<Distances_Bool_Exp>;
  distancesByToId?: InputMaybe<Distances_Bool_Exp>;
  distancesByToId_aggregate?: InputMaybe<Distances_Aggregate_Bool_Exp>;
  distances_aggregate?: InputMaybe<Distances_Aggregate_Bool_Exp>;
  geometry?: InputMaybe<Geometry_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  lat?: InputMaybe<Float8_Comparison_Exp>;
  lng?: InputMaybe<Float8_Comparison_Exp>;
  observations?: InputMaybe<Observation_Bool_Exp>;
  observations_aggregate?: InputMaybe<Observation_Aggregate_Bool_Exp>;
  unicode?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  workspace_metadata?: InputMaybe<Workspace_Metadata_Bool_Exp>;
  workspace_metadata_aggregate?: InputMaybe<Workspace_Metadata_Aggregate_Bool_Exp>;
};

/** unique or primary key constraints on table "geopoint" */
export enum Geopoint_Constraint {
  /** unique or primary key constraint on columns "id" */
  GeopointPkey = "geopoint_pkey",
}

/** input type for incrementing numeric columns in table "geopoint" */
export type Geopoint_Inc_Input = {
  lat?: InputMaybe<Scalars["float8"]>;
  lng?: InputMaybe<Scalars["float8"]>;
};

/** input type for inserting data into table "geopoint" */
export type Geopoint_Insert_Input = {
  advertisements?: InputMaybe<Advertisement_Arr_Rel_Insert_Input>;
  block?: InputMaybe<Scalars["String"]>;
  created_at?: InputMaybe<Scalars["timestamptz"]>;
  distances?: InputMaybe<Distances_Arr_Rel_Insert_Input>;
  distancesByToId?: InputMaybe<Distances_Arr_Rel_Insert_Input>;
  geometry?: InputMaybe<Scalars["geometry"]>;
  id?: InputMaybe<Scalars["uuid"]>;
  lat?: InputMaybe<Scalars["float8"]>;
  lng?: InputMaybe<Scalars["float8"]>;
  observations?: InputMaybe<Observation_Arr_Rel_Insert_Input>;
  unicode?: InputMaybe<Scalars["String"]>;
  updated_at?: InputMaybe<Scalars["timestamptz"]>;
  workspace_metadata?: InputMaybe<Workspace_Metadata_Arr_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Geopoint_Max_Fields = {
  __typename?: "geopoint_max_fields";
  block?: Maybe<Scalars["String"]>;
  created_at?: Maybe<Scalars["timestamptz"]>;
  id?: Maybe<Scalars["uuid"]>;
  lat?: Maybe<Scalars["float8"]>;
  lng?: Maybe<Scalars["float8"]>;
  unicode?: Maybe<Scalars["String"]>;
  updated_at?: Maybe<Scalars["timestamptz"]>;
};

/** aggregate min on columns */
export type Geopoint_Min_Fields = {
  __typename?: "geopoint_min_fields";
  block?: Maybe<Scalars["String"]>;
  created_at?: Maybe<Scalars["timestamptz"]>;
  id?: Maybe<Scalars["uuid"]>;
  lat?: Maybe<Scalars["float8"]>;
  lng?: Maybe<Scalars["float8"]>;
  unicode?: Maybe<Scalars["String"]>;
  updated_at?: Maybe<Scalars["timestamptz"]>;
};

/** response of any mutation on the table "geopoint" */
export type Geopoint_Mutation_Response = {
  __typename?: "geopoint_mutation_response";
  /** number of rows affected by the mutation */
  affected_rows: Scalars["Int"];
  /** data from the rows affected by the mutation */
  returning: Array<Geopoint>;
};

/** input type for inserting object relation for remote table "geopoint" */
export type Geopoint_Obj_Rel_Insert_Input = {
  data: Geopoint_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Geopoint_On_Conflict>;
};

/** on_conflict condition type for table "geopoint" */
export type Geopoint_On_Conflict = {
  constraint: Geopoint_Constraint;
  update_columns?: Array<Geopoint_Update_Column>;
  where?: InputMaybe<Geopoint_Bool_Exp>;
};

/** Ordering options when selecting data from "geopoint". */
export type Geopoint_Order_By = {
  advertisements_aggregate?: InputMaybe<Advertisement_Aggregate_Order_By>;
  block?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  distancesByToId_aggregate?: InputMaybe<Distances_Aggregate_Order_By>;
  distances_aggregate?: InputMaybe<Distances_Aggregate_Order_By>;
  geometry?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  lat?: InputMaybe<Order_By>;
  lng?: InputMaybe<Order_By>;
  observations_aggregate?: InputMaybe<Observation_Aggregate_Order_By>;
  unicode?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  workspace_metadata_aggregate?: InputMaybe<Workspace_Metadata_Aggregate_Order_By>;
};

/** primary key columns input for table: geopoint */
export type Geopoint_Pk_Columns_Input = {
  id: Scalars["uuid"];
};

/** select columns of table "geopoint" */
export enum Geopoint_Select_Column {
  /** column name */
  Block = "block",
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  Geometry = "geometry",
  /** column name */
  Id = "id",
  /** column name */
  Lat = "lat",
  /** column name */
  Lng = "lng",
  /** column name */
  Unicode = "unicode",
  /** column name */
  UpdatedAt = "updated_at",
}

/** input type for updating data in table "geopoint" */
export type Geopoint_Set_Input = {
  block?: InputMaybe<Scalars["String"]>;
  created_at?: InputMaybe<Scalars["timestamptz"]>;
  geometry?: InputMaybe<Scalars["geometry"]>;
  id?: InputMaybe<Scalars["uuid"]>;
  lat?: InputMaybe<Scalars["float8"]>;
  lng?: InputMaybe<Scalars["float8"]>;
  unicode?: InputMaybe<Scalars["String"]>;
  updated_at?: InputMaybe<Scalars["timestamptz"]>;
};

/** aggregate stddev on columns */
export type Geopoint_Stddev_Fields = {
  __typename?: "geopoint_stddev_fields";
  lat?: Maybe<Scalars["Float"]>;
  lng?: Maybe<Scalars["Float"]>;
};

/** aggregate stddev_pop on columns */
export type Geopoint_Stddev_Pop_Fields = {
  __typename?: "geopoint_stddev_pop_fields";
  lat?: Maybe<Scalars["Float"]>;
  lng?: Maybe<Scalars["Float"]>;
};

/** aggregate stddev_samp on columns */
export type Geopoint_Stddev_Samp_Fields = {
  __typename?: "geopoint_stddev_samp_fields";
  lat?: Maybe<Scalars["Float"]>;
  lng?: Maybe<Scalars["Float"]>;
};

/** Streaming cursor of the table "geopoint" */
export type Geopoint_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Geopoint_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Geopoint_Stream_Cursor_Value_Input = {
  block?: InputMaybe<Scalars["String"]>;
  created_at?: InputMaybe<Scalars["timestamptz"]>;
  geometry?: InputMaybe<Scalars["geometry"]>;
  id?: InputMaybe<Scalars["uuid"]>;
  lat?: InputMaybe<Scalars["float8"]>;
  lng?: InputMaybe<Scalars["float8"]>;
  unicode?: InputMaybe<Scalars["String"]>;
  updated_at?: InputMaybe<Scalars["timestamptz"]>;
};

/** aggregate sum on columns */
export type Geopoint_Sum_Fields = {
  __typename?: "geopoint_sum_fields";
  lat?: Maybe<Scalars["float8"]>;
  lng?: Maybe<Scalars["float8"]>;
};

/** update columns of table "geopoint" */
export enum Geopoint_Update_Column {
  /** column name */
  Block = "block",
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  Geometry = "geometry",
  /** column name */
  Id = "id",
  /** column name */
  Lat = "lat",
  /** column name */
  Lng = "lng",
  /** column name */
  Unicode = "unicode",
  /** column name */
  UpdatedAt = "updated_at",
}

export type Geopoint_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Geopoint_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Geopoint_Set_Input>;
  /** filter the rows which have to be updated */
  where: Geopoint_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Geopoint_Var_Pop_Fields = {
  __typename?: "geopoint_var_pop_fields";
  lat?: Maybe<Scalars["Float"]>;
  lng?: Maybe<Scalars["Float"]>;
};

/** aggregate var_samp on columns */
export type Geopoint_Var_Samp_Fields = {
  __typename?: "geopoint_var_samp_fields";
  lat?: Maybe<Scalars["Float"]>;
  lng?: Maybe<Scalars["Float"]>;
};

/** aggregate variance on columns */
export type Geopoint_Variance_Fields = {
  __typename?: "geopoint_variance_fields";
  lat?: Maybe<Scalars["Float"]>;
  lng?: Maybe<Scalars["Float"]>;
};

/** columns and relationships of "inspection" */
export type Inspection = {
  __typename?: "inspection";
  ambientes?: Maybe<Scalars["jsonb"]>;
  app_state: Scalars["String"];
  /** An object relationship */
  collaborator: Collaborator;
  collaborator_id: Scalars["uuid"];
  conoce_chinches?: Maybe<Scalars["Boolean"]>;
  conoce_chiris?: Maybe<Scalars["Boolean"]>;
  created_at: Scalars["timestamptz"];
  estado_chinches?: Maybe<Scalars["Boolean"]>;
  estado_chiris?: Maybe<Scalars["Boolean"]>;
  estado_inspeccion?: Maybe<Scalars["String"]>;
  experiment_id?: Maybe<Scalars["uuid"]>;
  familiar_picado?: Maybe<Scalars["String"]>;
  fecha?: Maybe<Scalars["timestamptz"]>;
  firebase_id?: Maybe<Scalars["String"]>;
  fumigada?: Maybe<Scalars["String"]>;
  geogroup_id?: Maybe<Scalars["uuid"]>;
  geopoint_id: Scalars["uuid"];
  geopoint_unicode?: Maybe<Scalars["String"]>;
  id: Scalars["uuid"];
  insecto_peligroso_visualizado?: Maybe<Scalars["String"]>;
  insectos_visualizados?: Maybe<Scalars["jsonb"]>;
  local_publico?: Maybe<Scalars["String"]>;
  motivo?: Maybe<Scalars["String"]>;
  nombre?: Maybe<Scalars["String"]>;
  observaciones?: Maybe<Scalars["String"]>;
  otro_telefono?: Maybe<Scalars["String"]>;
  predio?: Maybe<Scalars["String"]>;
  producto_usado?: Maybe<Scalars["String"]>;
  telefono?: Maybe<Scalars["String"]>;
  updated_at: Scalars["timestamptz"];
  /** An object relationship */
  workspace?: Maybe<Workspace>;
  workspace_id?: Maybe<Scalars["uuid"]>;
  workspace_metadata_id?: Maybe<Scalars["uuid"]>;
  /** An object relationship */
  workspace_metadatum?: Maybe<Workspace_Metadata>;
};

/** columns and relationships of "inspection" */
export type InspectionAmbientesArgs = {
  path?: InputMaybe<Scalars["String"]>;
};

/** columns and relationships of "inspection" */
export type InspectionInsectos_VisualizadosArgs = {
  path?: InputMaybe<Scalars["String"]>;
};

/** aggregated selection of "inspection" */
export type Inspection_Aggregate = {
  __typename?: "inspection_aggregate";
  aggregate?: Maybe<Inspection_Aggregate_Fields>;
  nodes: Array<Inspection>;
};

export type Inspection_Aggregate_Bool_Exp = {
  bool_and?: InputMaybe<Inspection_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: InputMaybe<Inspection_Aggregate_Bool_Exp_Bool_Or>;
  count?: InputMaybe<Inspection_Aggregate_Bool_Exp_Count>;
};

export type Inspection_Aggregate_Bool_Exp_Bool_And = {
  arguments: Inspection_Select_Column_Inspection_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: InputMaybe<Scalars["Boolean"]>;
  filter?: InputMaybe<Inspection_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Inspection_Aggregate_Bool_Exp_Bool_Or = {
  arguments: Inspection_Select_Column_Inspection_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: InputMaybe<Scalars["Boolean"]>;
  filter?: InputMaybe<Inspection_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Inspection_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Inspection_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]>;
  filter?: InputMaybe<Inspection_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "inspection" */
export type Inspection_Aggregate_Fields = {
  __typename?: "inspection_aggregate_fields";
  count: Scalars["Int"];
  max?: Maybe<Inspection_Max_Fields>;
  min?: Maybe<Inspection_Min_Fields>;
};

/** aggregate fields of "inspection" */
export type Inspection_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Inspection_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "inspection" */
export type Inspection_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Inspection_Max_Order_By>;
  min?: InputMaybe<Inspection_Min_Order_By>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Inspection_Append_Input = {
  ambientes?: InputMaybe<Scalars["jsonb"]>;
  insectos_visualizados?: InputMaybe<Scalars["jsonb"]>;
};

/** input type for inserting array relation for remote table "inspection" */
export type Inspection_Arr_Rel_Insert_Input = {
  data: Array<Inspection_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Inspection_On_Conflict>;
};

/** Boolean expression to filter rows from the table "inspection". All fields are combined with a logical 'AND'. */
export type Inspection_Bool_Exp = {
  _and?: InputMaybe<Array<Inspection_Bool_Exp>>;
  _not?: InputMaybe<Inspection_Bool_Exp>;
  _or?: InputMaybe<Array<Inspection_Bool_Exp>>;
  ambientes?: InputMaybe<Jsonb_Comparison_Exp>;
  app_state?: InputMaybe<String_Comparison_Exp>;
  collaborator?: InputMaybe<Collaborator_Bool_Exp>;
  collaborator_id?: InputMaybe<Uuid_Comparison_Exp>;
  conoce_chinches?: InputMaybe<Boolean_Comparison_Exp>;
  conoce_chiris?: InputMaybe<Boolean_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  estado_chinches?: InputMaybe<Boolean_Comparison_Exp>;
  estado_chiris?: InputMaybe<Boolean_Comparison_Exp>;
  estado_inspeccion?: InputMaybe<String_Comparison_Exp>;
  experiment_id?: InputMaybe<Uuid_Comparison_Exp>;
  familiar_picado?: InputMaybe<String_Comparison_Exp>;
  fecha?: InputMaybe<Timestamptz_Comparison_Exp>;
  firebase_id?: InputMaybe<String_Comparison_Exp>;
  fumigada?: InputMaybe<String_Comparison_Exp>;
  geogroup_id?: InputMaybe<Uuid_Comparison_Exp>;
  geopoint_id?: InputMaybe<Uuid_Comparison_Exp>;
  geopoint_unicode?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  insecto_peligroso_visualizado?: InputMaybe<String_Comparison_Exp>;
  insectos_visualizados?: InputMaybe<Jsonb_Comparison_Exp>;
  local_publico?: InputMaybe<String_Comparison_Exp>;
  motivo?: InputMaybe<String_Comparison_Exp>;
  nombre?: InputMaybe<String_Comparison_Exp>;
  observaciones?: InputMaybe<String_Comparison_Exp>;
  otro_telefono?: InputMaybe<String_Comparison_Exp>;
  predio?: InputMaybe<String_Comparison_Exp>;
  producto_usado?: InputMaybe<String_Comparison_Exp>;
  telefono?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  workspace?: InputMaybe<Workspace_Bool_Exp>;
  workspace_id?: InputMaybe<Uuid_Comparison_Exp>;
  workspace_metadata_id?: InputMaybe<Uuid_Comparison_Exp>;
  workspace_metadatum?: InputMaybe<Workspace_Metadata_Bool_Exp>;
};

/** unique or primary key constraints on table "inspection" */
export enum Inspection_Constraint {
  /** unique or primary key constraint on columns "id" */
  InspectionPkey = "inspection_pkey",
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Inspection_Delete_At_Path_Input = {
  ambientes?: InputMaybe<Array<Scalars["String"]>>;
  insectos_visualizados?: InputMaybe<Array<Scalars["String"]>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Inspection_Delete_Elem_Input = {
  ambientes?: InputMaybe<Scalars["Int"]>;
  insectos_visualizados?: InputMaybe<Scalars["Int"]>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Inspection_Delete_Key_Input = {
  ambientes?: InputMaybe<Scalars["String"]>;
  insectos_visualizados?: InputMaybe<Scalars["String"]>;
};

/** input type for inserting data into table "inspection" */
export type Inspection_Insert_Input = {
  ambientes?: InputMaybe<Scalars["jsonb"]>;
  app_state?: InputMaybe<Scalars["String"]>;
  collaborator?: InputMaybe<Collaborator_Obj_Rel_Insert_Input>;
  collaborator_id?: InputMaybe<Scalars["uuid"]>;
  conoce_chinches?: InputMaybe<Scalars["Boolean"]>;
  conoce_chiris?: InputMaybe<Scalars["Boolean"]>;
  created_at?: InputMaybe<Scalars["timestamptz"]>;
  estado_chinches?: InputMaybe<Scalars["Boolean"]>;
  estado_chiris?: InputMaybe<Scalars["Boolean"]>;
  estado_inspeccion?: InputMaybe<Scalars["String"]>;
  experiment_id?: InputMaybe<Scalars["uuid"]>;
  familiar_picado?: InputMaybe<Scalars["String"]>;
  fecha?: InputMaybe<Scalars["timestamptz"]>;
  firebase_id?: InputMaybe<Scalars["String"]>;
  fumigada?: InputMaybe<Scalars["String"]>;
  geogroup_id?: InputMaybe<Scalars["uuid"]>;
  geopoint_id?: InputMaybe<Scalars["uuid"]>;
  geopoint_unicode?: InputMaybe<Scalars["String"]>;
  id?: InputMaybe<Scalars["uuid"]>;
  insecto_peligroso_visualizado?: InputMaybe<Scalars["String"]>;
  insectos_visualizados?: InputMaybe<Scalars["jsonb"]>;
  local_publico?: InputMaybe<Scalars["String"]>;
  motivo?: InputMaybe<Scalars["String"]>;
  nombre?: InputMaybe<Scalars["String"]>;
  observaciones?: InputMaybe<Scalars["String"]>;
  otro_telefono?: InputMaybe<Scalars["String"]>;
  predio?: InputMaybe<Scalars["String"]>;
  producto_usado?: InputMaybe<Scalars["String"]>;
  telefono?: InputMaybe<Scalars["String"]>;
  updated_at?: InputMaybe<Scalars["timestamptz"]>;
  workspace?: InputMaybe<Workspace_Obj_Rel_Insert_Input>;
  workspace_id?: InputMaybe<Scalars["uuid"]>;
  workspace_metadata_id?: InputMaybe<Scalars["uuid"]>;
  workspace_metadatum?: InputMaybe<Workspace_Metadata_Obj_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Inspection_Max_Fields = {
  __typename?: "inspection_max_fields";
  app_state?: Maybe<Scalars["String"]>;
  collaborator_id?: Maybe<Scalars["uuid"]>;
  created_at?: Maybe<Scalars["timestamptz"]>;
  estado_inspeccion?: Maybe<Scalars["String"]>;
  experiment_id?: Maybe<Scalars["uuid"]>;
  familiar_picado?: Maybe<Scalars["String"]>;
  fecha?: Maybe<Scalars["timestamptz"]>;
  firebase_id?: Maybe<Scalars["String"]>;
  fumigada?: Maybe<Scalars["String"]>;
  geogroup_id?: Maybe<Scalars["uuid"]>;
  geopoint_id?: Maybe<Scalars["uuid"]>;
  geopoint_unicode?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["uuid"]>;
  insecto_peligroso_visualizado?: Maybe<Scalars["String"]>;
  local_publico?: Maybe<Scalars["String"]>;
  motivo?: Maybe<Scalars["String"]>;
  nombre?: Maybe<Scalars["String"]>;
  observaciones?: Maybe<Scalars["String"]>;
  otro_telefono?: Maybe<Scalars["String"]>;
  predio?: Maybe<Scalars["String"]>;
  producto_usado?: Maybe<Scalars["String"]>;
  telefono?: Maybe<Scalars["String"]>;
  updated_at?: Maybe<Scalars["timestamptz"]>;
  workspace_id?: Maybe<Scalars["uuid"]>;
  workspace_metadata_id?: Maybe<Scalars["uuid"]>;
};

/** order by max() on columns of table "inspection" */
export type Inspection_Max_Order_By = {
  app_state?: InputMaybe<Order_By>;
  collaborator_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  estado_inspeccion?: InputMaybe<Order_By>;
  experiment_id?: InputMaybe<Order_By>;
  familiar_picado?: InputMaybe<Order_By>;
  fecha?: InputMaybe<Order_By>;
  firebase_id?: InputMaybe<Order_By>;
  fumigada?: InputMaybe<Order_By>;
  geogroup_id?: InputMaybe<Order_By>;
  geopoint_id?: InputMaybe<Order_By>;
  geopoint_unicode?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  insecto_peligroso_visualizado?: InputMaybe<Order_By>;
  local_publico?: InputMaybe<Order_By>;
  motivo?: InputMaybe<Order_By>;
  nombre?: InputMaybe<Order_By>;
  observaciones?: InputMaybe<Order_By>;
  otro_telefono?: InputMaybe<Order_By>;
  predio?: InputMaybe<Order_By>;
  producto_usado?: InputMaybe<Order_By>;
  telefono?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  workspace_id?: InputMaybe<Order_By>;
  workspace_metadata_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Inspection_Min_Fields = {
  __typename?: "inspection_min_fields";
  app_state?: Maybe<Scalars["String"]>;
  collaborator_id?: Maybe<Scalars["uuid"]>;
  created_at?: Maybe<Scalars["timestamptz"]>;
  estado_inspeccion?: Maybe<Scalars["String"]>;
  experiment_id?: Maybe<Scalars["uuid"]>;
  familiar_picado?: Maybe<Scalars["String"]>;
  fecha?: Maybe<Scalars["timestamptz"]>;
  firebase_id?: Maybe<Scalars["String"]>;
  fumigada?: Maybe<Scalars["String"]>;
  geogroup_id?: Maybe<Scalars["uuid"]>;
  geopoint_id?: Maybe<Scalars["uuid"]>;
  geopoint_unicode?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["uuid"]>;
  insecto_peligroso_visualizado?: Maybe<Scalars["String"]>;
  local_publico?: Maybe<Scalars["String"]>;
  motivo?: Maybe<Scalars["String"]>;
  nombre?: Maybe<Scalars["String"]>;
  observaciones?: Maybe<Scalars["String"]>;
  otro_telefono?: Maybe<Scalars["String"]>;
  predio?: Maybe<Scalars["String"]>;
  producto_usado?: Maybe<Scalars["String"]>;
  telefono?: Maybe<Scalars["String"]>;
  updated_at?: Maybe<Scalars["timestamptz"]>;
  workspace_id?: Maybe<Scalars["uuid"]>;
  workspace_metadata_id?: Maybe<Scalars["uuid"]>;
};

/** order by min() on columns of table "inspection" */
export type Inspection_Min_Order_By = {
  app_state?: InputMaybe<Order_By>;
  collaborator_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  estado_inspeccion?: InputMaybe<Order_By>;
  experiment_id?: InputMaybe<Order_By>;
  familiar_picado?: InputMaybe<Order_By>;
  fecha?: InputMaybe<Order_By>;
  firebase_id?: InputMaybe<Order_By>;
  fumigada?: InputMaybe<Order_By>;
  geogroup_id?: InputMaybe<Order_By>;
  geopoint_id?: InputMaybe<Order_By>;
  geopoint_unicode?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  insecto_peligroso_visualizado?: InputMaybe<Order_By>;
  local_publico?: InputMaybe<Order_By>;
  motivo?: InputMaybe<Order_By>;
  nombre?: InputMaybe<Order_By>;
  observaciones?: InputMaybe<Order_By>;
  otro_telefono?: InputMaybe<Order_By>;
  predio?: InputMaybe<Order_By>;
  producto_usado?: InputMaybe<Order_By>;
  telefono?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  workspace_id?: InputMaybe<Order_By>;
  workspace_metadata_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "inspection" */
export type Inspection_Mutation_Response = {
  __typename?: "inspection_mutation_response";
  /** number of rows affected by the mutation */
  affected_rows: Scalars["Int"];
  /** data from the rows affected by the mutation */
  returning: Array<Inspection>;
};

/** on_conflict condition type for table "inspection" */
export type Inspection_On_Conflict = {
  constraint: Inspection_Constraint;
  update_columns?: Array<Inspection_Update_Column>;
  where?: InputMaybe<Inspection_Bool_Exp>;
};

/** Ordering options when selecting data from "inspection". */
export type Inspection_Order_By = {
  ambientes?: InputMaybe<Order_By>;
  app_state?: InputMaybe<Order_By>;
  collaborator?: InputMaybe<Collaborator_Order_By>;
  collaborator_id?: InputMaybe<Order_By>;
  conoce_chinches?: InputMaybe<Order_By>;
  conoce_chiris?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  estado_chinches?: InputMaybe<Order_By>;
  estado_chiris?: InputMaybe<Order_By>;
  estado_inspeccion?: InputMaybe<Order_By>;
  experiment_id?: InputMaybe<Order_By>;
  familiar_picado?: InputMaybe<Order_By>;
  fecha?: InputMaybe<Order_By>;
  firebase_id?: InputMaybe<Order_By>;
  fumigada?: InputMaybe<Order_By>;
  geogroup_id?: InputMaybe<Order_By>;
  geopoint_id?: InputMaybe<Order_By>;
  geopoint_unicode?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  insecto_peligroso_visualizado?: InputMaybe<Order_By>;
  insectos_visualizados?: InputMaybe<Order_By>;
  local_publico?: InputMaybe<Order_By>;
  motivo?: InputMaybe<Order_By>;
  nombre?: InputMaybe<Order_By>;
  observaciones?: InputMaybe<Order_By>;
  otro_telefono?: InputMaybe<Order_By>;
  predio?: InputMaybe<Order_By>;
  producto_usado?: InputMaybe<Order_By>;
  telefono?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  workspace?: InputMaybe<Workspace_Order_By>;
  workspace_id?: InputMaybe<Order_By>;
  workspace_metadata_id?: InputMaybe<Order_By>;
  workspace_metadatum?: InputMaybe<Workspace_Metadata_Order_By>;
};

/** primary key columns input for table: inspection */
export type Inspection_Pk_Columns_Input = {
  id: Scalars["uuid"];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Inspection_Prepend_Input = {
  ambientes?: InputMaybe<Scalars["jsonb"]>;
  insectos_visualizados?: InputMaybe<Scalars["jsonb"]>;
};

/** select columns of table "inspection" */
export enum Inspection_Select_Column {
  /** column name */
  Ambientes = "ambientes",
  /** column name */
  AppState = "app_state",
  /** column name */
  CollaboratorId = "collaborator_id",
  /** column name */
  ConoceChinches = "conoce_chinches",
  /** column name */
  ConoceChiris = "conoce_chiris",
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  EstadoChinches = "estado_chinches",
  /** column name */
  EstadoChiris = "estado_chiris",
  /** column name */
  EstadoInspeccion = "estado_inspeccion",
  /** column name */
  ExperimentId = "experiment_id",
  /** column name */
  FamiliarPicado = "familiar_picado",
  /** column name */
  Fecha = "fecha",
  /** column name */
  FirebaseId = "firebase_id",
  /** column name */
  Fumigada = "fumigada",
  /** column name */
  GeogroupId = "geogroup_id",
  /** column name */
  GeopointId = "geopoint_id",
  /** column name */
  GeopointUnicode = "geopoint_unicode",
  /** column name */
  Id = "id",
  /** column name */
  InsectoPeligrosoVisualizado = "insecto_peligroso_visualizado",
  /** column name */
  InsectosVisualizados = "insectos_visualizados",
  /** column name */
  LocalPublico = "local_publico",
  /** column name */
  Motivo = "motivo",
  /** column name */
  Nombre = "nombre",
  /** column name */
  Observaciones = "observaciones",
  /** column name */
  OtroTelefono = "otro_telefono",
  /** column name */
  Predio = "predio",
  /** column name */
  ProductoUsado = "producto_usado",
  /** column name */
  Telefono = "telefono",
  /** column name */
  UpdatedAt = "updated_at",
  /** column name */
  WorkspaceId = "workspace_id",
  /** column name */
  WorkspaceMetadataId = "workspace_metadata_id",
}

/** select "inspection_aggregate_bool_exp_bool_and_arguments_columns" columns of table "inspection" */
export enum Inspection_Select_Column_Inspection_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  ConoceChinches = "conoce_chinches",
  /** column name */
  ConoceChiris = "conoce_chiris",
  /** column name */
  EstadoChinches = "estado_chinches",
  /** column name */
  EstadoChiris = "estado_chiris",
}

/** select "inspection_aggregate_bool_exp_bool_or_arguments_columns" columns of table "inspection" */
export enum Inspection_Select_Column_Inspection_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  ConoceChinches = "conoce_chinches",
  /** column name */
  ConoceChiris = "conoce_chiris",
  /** column name */
  EstadoChinches = "estado_chinches",
  /** column name */
  EstadoChiris = "estado_chiris",
}

/** input type for updating data in table "inspection" */
export type Inspection_Set_Input = {
  ambientes?: InputMaybe<Scalars["jsonb"]>;
  app_state?: InputMaybe<Scalars["String"]>;
  collaborator_id?: InputMaybe<Scalars["uuid"]>;
  conoce_chinches?: InputMaybe<Scalars["Boolean"]>;
  conoce_chiris?: InputMaybe<Scalars["Boolean"]>;
  created_at?: InputMaybe<Scalars["timestamptz"]>;
  estado_chinches?: InputMaybe<Scalars["Boolean"]>;
  estado_chiris?: InputMaybe<Scalars["Boolean"]>;
  estado_inspeccion?: InputMaybe<Scalars["String"]>;
  experiment_id?: InputMaybe<Scalars["uuid"]>;
  familiar_picado?: InputMaybe<Scalars["String"]>;
  fecha?: InputMaybe<Scalars["timestamptz"]>;
  firebase_id?: InputMaybe<Scalars["String"]>;
  fumigada?: InputMaybe<Scalars["String"]>;
  geogroup_id?: InputMaybe<Scalars["uuid"]>;
  geopoint_id?: InputMaybe<Scalars["uuid"]>;
  geopoint_unicode?: InputMaybe<Scalars["String"]>;
  id?: InputMaybe<Scalars["uuid"]>;
  insecto_peligroso_visualizado?: InputMaybe<Scalars["String"]>;
  insectos_visualizados?: InputMaybe<Scalars["jsonb"]>;
  local_publico?: InputMaybe<Scalars["String"]>;
  motivo?: InputMaybe<Scalars["String"]>;
  nombre?: InputMaybe<Scalars["String"]>;
  observaciones?: InputMaybe<Scalars["String"]>;
  otro_telefono?: InputMaybe<Scalars["String"]>;
  predio?: InputMaybe<Scalars["String"]>;
  producto_usado?: InputMaybe<Scalars["String"]>;
  telefono?: InputMaybe<Scalars["String"]>;
  updated_at?: InputMaybe<Scalars["timestamptz"]>;
  workspace_id?: InputMaybe<Scalars["uuid"]>;
  workspace_metadata_id?: InputMaybe<Scalars["uuid"]>;
};

/** Streaming cursor of the table "inspection" */
export type Inspection_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Inspection_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Inspection_Stream_Cursor_Value_Input = {
  ambientes?: InputMaybe<Scalars["jsonb"]>;
  app_state?: InputMaybe<Scalars["String"]>;
  collaborator_id?: InputMaybe<Scalars["uuid"]>;
  conoce_chinches?: InputMaybe<Scalars["Boolean"]>;
  conoce_chiris?: InputMaybe<Scalars["Boolean"]>;
  created_at?: InputMaybe<Scalars["timestamptz"]>;
  estado_chinches?: InputMaybe<Scalars["Boolean"]>;
  estado_chiris?: InputMaybe<Scalars["Boolean"]>;
  estado_inspeccion?: InputMaybe<Scalars["String"]>;
  experiment_id?: InputMaybe<Scalars["uuid"]>;
  familiar_picado?: InputMaybe<Scalars["String"]>;
  fecha?: InputMaybe<Scalars["timestamptz"]>;
  firebase_id?: InputMaybe<Scalars["String"]>;
  fumigada?: InputMaybe<Scalars["String"]>;
  geogroup_id?: InputMaybe<Scalars["uuid"]>;
  geopoint_id?: InputMaybe<Scalars["uuid"]>;
  geopoint_unicode?: InputMaybe<Scalars["String"]>;
  id?: InputMaybe<Scalars["uuid"]>;
  insecto_peligroso_visualizado?: InputMaybe<Scalars["String"]>;
  insectos_visualizados?: InputMaybe<Scalars["jsonb"]>;
  local_publico?: InputMaybe<Scalars["String"]>;
  motivo?: InputMaybe<Scalars["String"]>;
  nombre?: InputMaybe<Scalars["String"]>;
  observaciones?: InputMaybe<Scalars["String"]>;
  otro_telefono?: InputMaybe<Scalars["String"]>;
  predio?: InputMaybe<Scalars["String"]>;
  producto_usado?: InputMaybe<Scalars["String"]>;
  telefono?: InputMaybe<Scalars["String"]>;
  updated_at?: InputMaybe<Scalars["timestamptz"]>;
  workspace_id?: InputMaybe<Scalars["uuid"]>;
  workspace_metadata_id?: InputMaybe<Scalars["uuid"]>;
};

/** update columns of table "inspection" */
export enum Inspection_Update_Column {
  /** column name */
  Ambientes = "ambientes",
  /** column name */
  AppState = "app_state",
  /** column name */
  CollaboratorId = "collaborator_id",
  /** column name */
  ConoceChinches = "conoce_chinches",
  /** column name */
  ConoceChiris = "conoce_chiris",
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  EstadoChinches = "estado_chinches",
  /** column name */
  EstadoChiris = "estado_chiris",
  /** column name */
  EstadoInspeccion = "estado_inspeccion",
  /** column name */
  ExperimentId = "experiment_id",
  /** column name */
  FamiliarPicado = "familiar_picado",
  /** column name */
  Fecha = "fecha",
  /** column name */
  FirebaseId = "firebase_id",
  /** column name */
  Fumigada = "fumigada",
  /** column name */
  GeogroupId = "geogroup_id",
  /** column name */
  GeopointId = "geopoint_id",
  /** column name */
  GeopointUnicode = "geopoint_unicode",
  /** column name */
  Id = "id",
  /** column name */
  InsectoPeligrosoVisualizado = "insecto_peligroso_visualizado",
  /** column name */
  InsectosVisualizados = "insectos_visualizados",
  /** column name */
  LocalPublico = "local_publico",
  /** column name */
  Motivo = "motivo",
  /** column name */
  Nombre = "nombre",
  /** column name */
  Observaciones = "observaciones",
  /** column name */
  OtroTelefono = "otro_telefono",
  /** column name */
  Predio = "predio",
  /** column name */
  ProductoUsado = "producto_usado",
  /** column name */
  Telefono = "telefono",
  /** column name */
  UpdatedAt = "updated_at",
  /** column name */
  WorkspaceId = "workspace_id",
  /** column name */
  WorkspaceMetadataId = "workspace_metadata_id",
}

export type Inspection_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Inspection_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Inspection_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Inspection_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Inspection_Delete_Key_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Inspection_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Inspection_Set_Input>;
  /** filter the rows which have to be updated */
  where: Inspection_Bool_Exp;
};

export type Jsonb_Cast_Exp = {
  String?: InputMaybe<String_Comparison_Exp>;
};

/** Boolean expression to compare columns of type "jsonb". All fields are combined with logical 'AND'. */
export type Jsonb_Comparison_Exp = {
  _cast?: InputMaybe<Jsonb_Cast_Exp>;
  /** is the column contained in the given json value */
  _contained_in?: InputMaybe<Scalars["jsonb"]>;
  /** does the column contain the given json value at the top level */
  _contains?: InputMaybe<Scalars["jsonb"]>;
  _eq?: InputMaybe<Scalars["jsonb"]>;
  _gt?: InputMaybe<Scalars["jsonb"]>;
  _gte?: InputMaybe<Scalars["jsonb"]>;
  /** does the string exist as a top-level key in the column */
  _has_key?: InputMaybe<Scalars["String"]>;
  /** do all of these strings exist as top-level keys in the column */
  _has_keys_all?: InputMaybe<Array<Scalars["String"]>>;
  /** do any of these strings exist as top-level keys in the column */
  _has_keys_any?: InputMaybe<Array<Scalars["String"]>>;
  _in?: InputMaybe<Array<Scalars["jsonb"]>>;
  _is_null?: InputMaybe<Scalars["Boolean"]>;
  _lt?: InputMaybe<Scalars["jsonb"]>;
  _lte?: InputMaybe<Scalars["jsonb"]>;
  _neq?: InputMaybe<Scalars["jsonb"]>;
  _nin?: InputMaybe<Array<Scalars["jsonb"]>>;
};

/** mutation root */
export type Mutation_Root = {
  __typename?: "mutation_root";
  /** delete data from the table: "advertisement" */
  delete_advertisement?: Maybe<Advertisement_Mutation_Response>;
  /** delete single row from the table: "advertisement" */
  delete_advertisement_by_pk?: Maybe<Advertisement>;
  /** delete data from the table: "collaborator" */
  delete_collaborator?: Maybe<Collaborator_Mutation_Response>;
  /** delete single row from the table: "collaborator" */
  delete_collaborator_by_pk?: Maybe<Collaborator>;
  /** delete data from the table: "complaint" */
  delete_complaint?: Maybe<Complaint_Mutation_Response>;
  /** delete single row from the table: "complaint" */
  delete_complaint_by_pk?: Maybe<Complaint>;
  /** delete data from the table: "complaint_kind" */
  delete_complaint_kind?: Maybe<Complaint_Kind_Mutation_Response>;
  /** delete single row from the table: "complaint_kind" */
  delete_complaint_kind_by_pk?: Maybe<Complaint_Kind>;
  /** delete data from the table: "complaint_source" */
  delete_complaint_source?: Maybe<Complaint_Source_Mutation_Response>;
  /** delete single row from the table: "complaint_source" */
  delete_complaint_source_by_pk?: Maybe<Complaint_Source>;
  /** delete data from the table: "distances" */
  delete_distances?: Maybe<Distances_Mutation_Response>;
  /** delete single row from the table: "distances" */
  delete_distances_by_pk?: Maybe<Distances>;
  /** delete data from the table: "estate_kind" */
  delete_estate_kind?: Maybe<Estate_Kind_Mutation_Response>;
  /** delete single row from the table: "estate_kind" */
  delete_estate_kind_by_pk?: Maybe<Estate_Kind>;
  /** delete data from the table: "experiment" */
  delete_experiment?: Maybe<Experiment_Mutation_Response>;
  /** delete single row from the table: "experiment" */
  delete_experiment_by_pk?: Maybe<Experiment>;
  /** delete data from the table: "geogroup" */
  delete_geogroup?: Maybe<Geogroup_Mutation_Response>;
  /** delete single row from the table: "geogroup" */
  delete_geogroup_by_pk?: Maybe<Geogroup>;
  /** delete data from the table: "geometry_columns" */
  delete_geometry_columns?: Maybe<Geometry_Columns_Mutation_Response>;
  /** delete data from the table: "geopoint" */
  delete_geopoint?: Maybe<Geopoint_Mutation_Response>;
  /** delete single row from the table: "geopoint" */
  delete_geopoint_by_pk?: Maybe<Geopoint>;
  /** delete data from the table: "inspection" */
  delete_inspection?: Maybe<Inspection_Mutation_Response>;
  /** delete single row from the table: "inspection" */
  delete_inspection_by_pk?: Maybe<Inspection>;
  /** delete data from the table: "observation" */
  delete_observation?: Maybe<Observation_Mutation_Response>;
  /** delete single row from the table: "observation" */
  delete_observation_by_pk?: Maybe<Observation>;
  /** delete data from the table: "place" */
  delete_place?: Maybe<Place_Mutation_Response>;
  /** delete single row from the table: "place" */
  delete_place_by_pk?: Maybe<Place>;
  /** delete data from the table: "spatial_ref_sys" */
  delete_spatial_ref_sys?: Maybe<Spatial_Ref_Sys_Mutation_Response>;
  /** delete single row from the table: "spatial_ref_sys" */
  delete_spatial_ref_sys_by_pk?: Maybe<Spatial_Ref_Sys>;
  /** delete data from the table: "sprayed" */
  delete_sprayed?: Maybe<Sprayed_Mutation_Response>;
  /** delete single row from the table: "sprayed" */
  delete_sprayed_by_pk?: Maybe<Sprayed>;
  /** delete data from the table: "sprayed_residual_treatment_kind" */
  delete_sprayed_residual_treatment_kind?: Maybe<Sprayed_Residual_Treatment_Kind_Mutation_Response>;
  /** delete single row from the table: "sprayed_residual_treatment_kind" */
  delete_sprayed_residual_treatment_kind_by_pk?: Maybe<Sprayed_Residual_Treatment_Kind>;
  /** delete data from the table: "sprayer" */
  delete_sprayer?: Maybe<Sprayer_Mutation_Response>;
  /** delete single row from the table: "sprayer" */
  delete_sprayer_by_pk?: Maybe<Sprayer>;
  /** delete data from the table: "work_sessions" */
  delete_work_sessions?: Maybe<Work_Sessions_Mutation_Response>;
  /** delete single row from the table: "work_sessions" */
  delete_work_sessions_by_pk?: Maybe<Work_Sessions>;
  /** delete data from the table: "workspace" */
  delete_workspace?: Maybe<Workspace_Mutation_Response>;
  /** delete single row from the table: "workspace" */
  delete_workspace_by_pk?: Maybe<Workspace>;
  /** delete data from the table: "workspace_kind" */
  delete_workspace_kind?: Maybe<Workspace_Kind_Mutation_Response>;
  /** delete single row from the table: "workspace_kind" */
  delete_workspace_kind_by_pk?: Maybe<Workspace_Kind>;
  /** delete data from the table: "workspace_metadata" */
  delete_workspace_metadata?: Maybe<Workspace_Metadata_Mutation_Response>;
  /** delete single row from the table: "workspace_metadata" */
  delete_workspace_metadata_by_pk?: Maybe<Workspace_Metadata>;
  /** delete data from the table: "workspace_owners" */
  delete_workspace_owners?: Maybe<Workspace_Owners_Mutation_Response>;
  /** delete single row from the table: "workspace_owners" */
  delete_workspace_owners_by_pk?: Maybe<Workspace_Owners>;
  /** insert data into the table: "advertisement" */
  insert_advertisement?: Maybe<Advertisement_Mutation_Response>;
  /** insert a single row into the table: "advertisement" */
  insert_advertisement_one?: Maybe<Advertisement>;
  /** insert data into the table: "collaborator" */
  insert_collaborator?: Maybe<Collaborator_Mutation_Response>;
  /** insert a single row into the table: "collaborator" */
  insert_collaborator_one?: Maybe<Collaborator>;
  /** insert data into the table: "complaint" */
  insert_complaint?: Maybe<Complaint_Mutation_Response>;
  /** insert data into the table: "complaint_kind" */
  insert_complaint_kind?: Maybe<Complaint_Kind_Mutation_Response>;
  /** insert a single row into the table: "complaint_kind" */
  insert_complaint_kind_one?: Maybe<Complaint_Kind>;
  /** insert a single row into the table: "complaint" */
  insert_complaint_one?: Maybe<Complaint>;
  /** insert data into the table: "complaint_source" */
  insert_complaint_source?: Maybe<Complaint_Source_Mutation_Response>;
  /** insert a single row into the table: "complaint_source" */
  insert_complaint_source_one?: Maybe<Complaint_Source>;
  /** insert data into the table: "distances" */
  insert_distances?: Maybe<Distances_Mutation_Response>;
  /** insert a single row into the table: "distances" */
  insert_distances_one?: Maybe<Distances>;
  /** insert data into the table: "estate_kind" */
  insert_estate_kind?: Maybe<Estate_Kind_Mutation_Response>;
  /** insert a single row into the table: "estate_kind" */
  insert_estate_kind_one?: Maybe<Estate_Kind>;
  /** insert data into the table: "experiment" */
  insert_experiment?: Maybe<Experiment_Mutation_Response>;
  /** insert a single row into the table: "experiment" */
  insert_experiment_one?: Maybe<Experiment>;
  /** insert data into the table: "geogroup" */
  insert_geogroup?: Maybe<Geogroup_Mutation_Response>;
  /** insert a single row into the table: "geogroup" */
  insert_geogroup_one?: Maybe<Geogroup>;
  /** insert data into the table: "geometry_columns" */
  insert_geometry_columns?: Maybe<Geometry_Columns_Mutation_Response>;
  /** insert a single row into the table: "geometry_columns" */
  insert_geometry_columns_one?: Maybe<Geometry_Columns>;
  /** insert data into the table: "geopoint" */
  insert_geopoint?: Maybe<Geopoint_Mutation_Response>;
  /** insert a single row into the table: "geopoint" */
  insert_geopoint_one?: Maybe<Geopoint>;
  /** insert data into the table: "inspection" */
  insert_inspection?: Maybe<Inspection_Mutation_Response>;
  /** insert a single row into the table: "inspection" */
  insert_inspection_one?: Maybe<Inspection>;
  /** insert data into the table: "observation" */
  insert_observation?: Maybe<Observation_Mutation_Response>;
  /** insert a single row into the table: "observation" */
  insert_observation_one?: Maybe<Observation>;
  /** insert data into the table: "place" */
  insert_place?: Maybe<Place_Mutation_Response>;
  /** insert a single row into the table: "place" */
  insert_place_one?: Maybe<Place>;
  /** insert data into the table: "spatial_ref_sys" */
  insert_spatial_ref_sys?: Maybe<Spatial_Ref_Sys_Mutation_Response>;
  /** insert a single row into the table: "spatial_ref_sys" */
  insert_spatial_ref_sys_one?: Maybe<Spatial_Ref_Sys>;
  /** insert data into the table: "sprayed" */
  insert_sprayed?: Maybe<Sprayed_Mutation_Response>;
  /** insert a single row into the table: "sprayed" */
  insert_sprayed_one?: Maybe<Sprayed>;
  /** insert data into the table: "sprayed_residual_treatment_kind" */
  insert_sprayed_residual_treatment_kind?: Maybe<Sprayed_Residual_Treatment_Kind_Mutation_Response>;
  /** insert a single row into the table: "sprayed_residual_treatment_kind" */
  insert_sprayed_residual_treatment_kind_one?: Maybe<Sprayed_Residual_Treatment_Kind>;
  /** insert data into the table: "sprayer" */
  insert_sprayer?: Maybe<Sprayer_Mutation_Response>;
  /** insert a single row into the table: "sprayer" */
  insert_sprayer_one?: Maybe<Sprayer>;
  /** insert data into the table: "work_sessions" */
  insert_work_sessions?: Maybe<Work_Sessions_Mutation_Response>;
  /** insert a single row into the table: "work_sessions" */
  insert_work_sessions_one?: Maybe<Work_Sessions>;
  /** insert data into the table: "workspace" */
  insert_workspace?: Maybe<Workspace_Mutation_Response>;
  /** insert data into the table: "workspace_kind" */
  insert_workspace_kind?: Maybe<Workspace_Kind_Mutation_Response>;
  /** insert a single row into the table: "workspace_kind" */
  insert_workspace_kind_one?: Maybe<Workspace_Kind>;
  /** insert data into the table: "workspace_metadata" */
  insert_workspace_metadata?: Maybe<Workspace_Metadata_Mutation_Response>;
  /** insert a single row into the table: "workspace_metadata" */
  insert_workspace_metadata_one?: Maybe<Workspace_Metadata>;
  /** insert a single row into the table: "workspace" */
  insert_workspace_one?: Maybe<Workspace>;
  /** insert data into the table: "workspace_owners" */
  insert_workspace_owners?: Maybe<Workspace_Owners_Mutation_Response>;
  /** insert a single row into the table: "workspace_owners" */
  insert_workspace_owners_one?: Maybe<Workspace_Owners>;
  /** update data of the table: "advertisement" */
  update_advertisement?: Maybe<Advertisement_Mutation_Response>;
  /** update single row of the table: "advertisement" */
  update_advertisement_by_pk?: Maybe<Advertisement>;
  /** update multiples rows of table: "advertisement" */
  update_advertisement_many?: Maybe<Array<Maybe<Advertisement_Mutation_Response>>>;
  /** update data of the table: "collaborator" */
  update_collaborator?: Maybe<Collaborator_Mutation_Response>;
  /** update single row of the table: "collaborator" */
  update_collaborator_by_pk?: Maybe<Collaborator>;
  /** update multiples rows of table: "collaborator" */
  update_collaborator_many?: Maybe<Array<Maybe<Collaborator_Mutation_Response>>>;
  /** update data of the table: "complaint" */
  update_complaint?: Maybe<Complaint_Mutation_Response>;
  /** update single row of the table: "complaint" */
  update_complaint_by_pk?: Maybe<Complaint>;
  /** update data of the table: "complaint_kind" */
  update_complaint_kind?: Maybe<Complaint_Kind_Mutation_Response>;
  /** update single row of the table: "complaint_kind" */
  update_complaint_kind_by_pk?: Maybe<Complaint_Kind>;
  /** update multiples rows of table: "complaint_kind" */
  update_complaint_kind_many?: Maybe<Array<Maybe<Complaint_Kind_Mutation_Response>>>;
  /** update multiples rows of table: "complaint" */
  update_complaint_many?: Maybe<Array<Maybe<Complaint_Mutation_Response>>>;
  /** update data of the table: "complaint_source" */
  update_complaint_source?: Maybe<Complaint_Source_Mutation_Response>;
  /** update single row of the table: "complaint_source" */
  update_complaint_source_by_pk?: Maybe<Complaint_Source>;
  /** update multiples rows of table: "complaint_source" */
  update_complaint_source_many?: Maybe<Array<Maybe<Complaint_Source_Mutation_Response>>>;
  /** update data of the table: "distances" */
  update_distances?: Maybe<Distances_Mutation_Response>;
  /** update single row of the table: "distances" */
  update_distances_by_pk?: Maybe<Distances>;
  /** update multiples rows of table: "distances" */
  update_distances_many?: Maybe<Array<Maybe<Distances_Mutation_Response>>>;
  /** update data of the table: "estate_kind" */
  update_estate_kind?: Maybe<Estate_Kind_Mutation_Response>;
  /** update single row of the table: "estate_kind" */
  update_estate_kind_by_pk?: Maybe<Estate_Kind>;
  /** update multiples rows of table: "estate_kind" */
  update_estate_kind_many?: Maybe<Array<Maybe<Estate_Kind_Mutation_Response>>>;
  /** update data of the table: "experiment" */
  update_experiment?: Maybe<Experiment_Mutation_Response>;
  /** update single row of the table: "experiment" */
  update_experiment_by_pk?: Maybe<Experiment>;
  /** update multiples rows of table: "experiment" */
  update_experiment_many?: Maybe<Array<Maybe<Experiment_Mutation_Response>>>;
  /** update data of the table: "geogroup" */
  update_geogroup?: Maybe<Geogroup_Mutation_Response>;
  /** update single row of the table: "geogroup" */
  update_geogroup_by_pk?: Maybe<Geogroup>;
  /** update multiples rows of table: "geogroup" */
  update_geogroup_many?: Maybe<Array<Maybe<Geogroup_Mutation_Response>>>;
  /** update data of the table: "geometry_columns" */
  update_geometry_columns?: Maybe<Geometry_Columns_Mutation_Response>;
  /** update multiples rows of table: "geometry_columns" */
  update_geometry_columns_many?: Maybe<Array<Maybe<Geometry_Columns_Mutation_Response>>>;
  /** update data of the table: "geopoint" */
  update_geopoint?: Maybe<Geopoint_Mutation_Response>;
  /** update single row of the table: "geopoint" */
  update_geopoint_by_pk?: Maybe<Geopoint>;
  /** update multiples rows of table: "geopoint" */
  update_geopoint_many?: Maybe<Array<Maybe<Geopoint_Mutation_Response>>>;
  /** update data of the table: "inspection" */
  update_inspection?: Maybe<Inspection_Mutation_Response>;
  /** update single row of the table: "inspection" */
  update_inspection_by_pk?: Maybe<Inspection>;
  /** update multiples rows of table: "inspection" */
  update_inspection_many?: Maybe<Array<Maybe<Inspection_Mutation_Response>>>;
  /** update data of the table: "observation" */
  update_observation?: Maybe<Observation_Mutation_Response>;
  /** update single row of the table: "observation" */
  update_observation_by_pk?: Maybe<Observation>;
  /** update multiples rows of table: "observation" */
  update_observation_many?: Maybe<Array<Maybe<Observation_Mutation_Response>>>;
  /** update data of the table: "place" */
  update_place?: Maybe<Place_Mutation_Response>;
  /** update single row of the table: "place" */
  update_place_by_pk?: Maybe<Place>;
  /** update multiples rows of table: "place" */
  update_place_many?: Maybe<Array<Maybe<Place_Mutation_Response>>>;
  /** update data of the table: "spatial_ref_sys" */
  update_spatial_ref_sys?: Maybe<Spatial_Ref_Sys_Mutation_Response>;
  /** update single row of the table: "spatial_ref_sys" */
  update_spatial_ref_sys_by_pk?: Maybe<Spatial_Ref_Sys>;
  /** update multiples rows of table: "spatial_ref_sys" */
  update_spatial_ref_sys_many?: Maybe<Array<Maybe<Spatial_Ref_Sys_Mutation_Response>>>;
  /** update data of the table: "sprayed" */
  update_sprayed?: Maybe<Sprayed_Mutation_Response>;
  /** update single row of the table: "sprayed" */
  update_sprayed_by_pk?: Maybe<Sprayed>;
  /** update multiples rows of table: "sprayed" */
  update_sprayed_many?: Maybe<Array<Maybe<Sprayed_Mutation_Response>>>;
  /** update data of the table: "sprayed_residual_treatment_kind" */
  update_sprayed_residual_treatment_kind?: Maybe<Sprayed_Residual_Treatment_Kind_Mutation_Response>;
  /** update single row of the table: "sprayed_residual_treatment_kind" */
  update_sprayed_residual_treatment_kind_by_pk?: Maybe<Sprayed_Residual_Treatment_Kind>;
  /** update multiples rows of table: "sprayed_residual_treatment_kind" */
  update_sprayed_residual_treatment_kind_many?: Maybe<
    Array<Maybe<Sprayed_Residual_Treatment_Kind_Mutation_Response>>
  >;
  /** update data of the table: "sprayer" */
  update_sprayer?: Maybe<Sprayer_Mutation_Response>;
  /** update single row of the table: "sprayer" */
  update_sprayer_by_pk?: Maybe<Sprayer>;
  /** update multiples rows of table: "sprayer" */
  update_sprayer_many?: Maybe<Array<Maybe<Sprayer_Mutation_Response>>>;
  /** update data of the table: "work_sessions" */
  update_work_sessions?: Maybe<Work_Sessions_Mutation_Response>;
  /** update single row of the table: "work_sessions" */
  update_work_sessions_by_pk?: Maybe<Work_Sessions>;
  /** update multiples rows of table: "work_sessions" */
  update_work_sessions_many?: Maybe<Array<Maybe<Work_Sessions_Mutation_Response>>>;
  /** update data of the table: "workspace" */
  update_workspace?: Maybe<Workspace_Mutation_Response>;
  /** update single row of the table: "workspace" */
  update_workspace_by_pk?: Maybe<Workspace>;
  /** update data of the table: "workspace_kind" */
  update_workspace_kind?: Maybe<Workspace_Kind_Mutation_Response>;
  /** update single row of the table: "workspace_kind" */
  update_workspace_kind_by_pk?: Maybe<Workspace_Kind>;
  /** update multiples rows of table: "workspace_kind" */
  update_workspace_kind_many?: Maybe<Array<Maybe<Workspace_Kind_Mutation_Response>>>;
  /** update multiples rows of table: "workspace" */
  update_workspace_many?: Maybe<Array<Maybe<Workspace_Mutation_Response>>>;
  /** update data of the table: "workspace_metadata" */
  update_workspace_metadata?: Maybe<Workspace_Metadata_Mutation_Response>;
  /** update single row of the table: "workspace_metadata" */
  update_workspace_metadata_by_pk?: Maybe<Workspace_Metadata>;
  /** update multiples rows of table: "workspace_metadata" */
  update_workspace_metadata_many?: Maybe<Array<Maybe<Workspace_Metadata_Mutation_Response>>>;
  /** update data of the table: "workspace_owners" */
  update_workspace_owners?: Maybe<Workspace_Owners_Mutation_Response>;
  /** update single row of the table: "workspace_owners" */
  update_workspace_owners_by_pk?: Maybe<Workspace_Owners>;
  /** update multiples rows of table: "workspace_owners" */
  update_workspace_owners_many?: Maybe<Array<Maybe<Workspace_Owners_Mutation_Response>>>;
};

/** mutation root */
export type Mutation_RootDelete_AdvertisementArgs = {
  where: Advertisement_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Advertisement_By_PkArgs = {
  id: Scalars["uuid"];
};

/** mutation root */
export type Mutation_RootDelete_CollaboratorArgs = {
  where: Collaborator_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Collaborator_By_PkArgs = {
  id: Scalars["uuid"];
};

/** mutation root */
export type Mutation_RootDelete_ComplaintArgs = {
  where: Complaint_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Complaint_By_PkArgs = {
  id: Scalars["uuid"];
};

/** mutation root */
export type Mutation_RootDelete_Complaint_KindArgs = {
  where: Complaint_Kind_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Complaint_Kind_By_PkArgs = {
  value: Scalars["String"];
};

/** mutation root */
export type Mutation_RootDelete_Complaint_SourceArgs = {
  where: Complaint_Source_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Complaint_Source_By_PkArgs = {
  value: Scalars["String"];
};

/** mutation root */
export type Mutation_RootDelete_DistancesArgs = {
  where: Distances_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Distances_By_PkArgs = {
  id: Scalars["uuid"];
};

/** mutation root */
export type Mutation_RootDelete_Estate_KindArgs = {
  where: Estate_Kind_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Estate_Kind_By_PkArgs = {
  value: Scalars["String"];
};

/** mutation root */
export type Mutation_RootDelete_ExperimentArgs = {
  where: Experiment_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Experiment_By_PkArgs = {
  id: Scalars["uuid"];
};

/** mutation root */
export type Mutation_RootDelete_GeogroupArgs = {
  where: Geogroup_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Geogroup_By_PkArgs = {
  id: Scalars["uuid"];
};

/** mutation root */
export type Mutation_RootDelete_Geometry_ColumnsArgs = {
  where: Geometry_Columns_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_GeopointArgs = {
  where: Geopoint_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Geopoint_By_PkArgs = {
  id: Scalars["uuid"];
};

/** mutation root */
export type Mutation_RootDelete_InspectionArgs = {
  where: Inspection_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Inspection_By_PkArgs = {
  id: Scalars["uuid"];
};

/** mutation root */
export type Mutation_RootDelete_ObservationArgs = {
  where: Observation_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Observation_By_PkArgs = {
  id: Scalars["uuid"];
};

/** mutation root */
export type Mutation_RootDelete_PlaceArgs = {
  where: Place_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Place_By_PkArgs = {
  id: Scalars["uuid"];
};

/** mutation root */
export type Mutation_RootDelete_Spatial_Ref_SysArgs = {
  where: Spatial_Ref_Sys_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Spatial_Ref_Sys_By_PkArgs = {
  srid: Scalars["Int"];
};

/** mutation root */
export type Mutation_RootDelete_SprayedArgs = {
  where: Sprayed_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Sprayed_By_PkArgs = {
  id: Scalars["uuid"];
};

/** mutation root */
export type Mutation_RootDelete_Sprayed_Residual_Treatment_KindArgs = {
  where: Sprayed_Residual_Treatment_Kind_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Sprayed_Residual_Treatment_Kind_By_PkArgs = {
  value: Scalars["String"];
};

/** mutation root */
export type Mutation_RootDelete_SprayerArgs = {
  where: Sprayer_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Sprayer_By_PkArgs = {
  id: Scalars["uuid"];
};

/** mutation root */
export type Mutation_RootDelete_Work_SessionsArgs = {
  where: Work_Sessions_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Work_Sessions_By_PkArgs = {
  id: Scalars["uuid"];
};

/** mutation root */
export type Mutation_RootDelete_WorkspaceArgs = {
  where: Workspace_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Workspace_By_PkArgs = {
  id: Scalars["uuid"];
};

/** mutation root */
export type Mutation_RootDelete_Workspace_KindArgs = {
  where: Workspace_Kind_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Workspace_Kind_By_PkArgs = {
  value: Scalars["String"];
};

/** mutation root */
export type Mutation_RootDelete_Workspace_MetadataArgs = {
  where: Workspace_Metadata_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Workspace_Metadata_By_PkArgs = {
  id: Scalars["uuid"];
};

/** mutation root */
export type Mutation_RootDelete_Workspace_OwnersArgs = {
  where: Workspace_Owners_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Workspace_Owners_By_PkArgs = {
  owner_id: Scalars["uuid"];
  workspace_id: Scalars["uuid"];
};

/** mutation root */
export type Mutation_RootInsert_AdvertisementArgs = {
  objects: Array<Advertisement_Insert_Input>;
  on_conflict?: InputMaybe<Advertisement_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Advertisement_OneArgs = {
  object: Advertisement_Insert_Input;
  on_conflict?: InputMaybe<Advertisement_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_CollaboratorArgs = {
  objects: Array<Collaborator_Insert_Input>;
  on_conflict?: InputMaybe<Collaborator_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Collaborator_OneArgs = {
  object: Collaborator_Insert_Input;
  on_conflict?: InputMaybe<Collaborator_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_ComplaintArgs = {
  objects: Array<Complaint_Insert_Input>;
  on_conflict?: InputMaybe<Complaint_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Complaint_KindArgs = {
  objects: Array<Complaint_Kind_Insert_Input>;
  on_conflict?: InputMaybe<Complaint_Kind_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Complaint_Kind_OneArgs = {
  object: Complaint_Kind_Insert_Input;
  on_conflict?: InputMaybe<Complaint_Kind_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Complaint_OneArgs = {
  object: Complaint_Insert_Input;
  on_conflict?: InputMaybe<Complaint_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Complaint_SourceArgs = {
  objects: Array<Complaint_Source_Insert_Input>;
  on_conflict?: InputMaybe<Complaint_Source_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Complaint_Source_OneArgs = {
  object: Complaint_Source_Insert_Input;
  on_conflict?: InputMaybe<Complaint_Source_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_DistancesArgs = {
  objects: Array<Distances_Insert_Input>;
  on_conflict?: InputMaybe<Distances_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Distances_OneArgs = {
  object: Distances_Insert_Input;
  on_conflict?: InputMaybe<Distances_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Estate_KindArgs = {
  objects: Array<Estate_Kind_Insert_Input>;
  on_conflict?: InputMaybe<Estate_Kind_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Estate_Kind_OneArgs = {
  object: Estate_Kind_Insert_Input;
  on_conflict?: InputMaybe<Estate_Kind_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_ExperimentArgs = {
  objects: Array<Experiment_Insert_Input>;
  on_conflict?: InputMaybe<Experiment_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Experiment_OneArgs = {
  object: Experiment_Insert_Input;
  on_conflict?: InputMaybe<Experiment_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_GeogroupArgs = {
  objects: Array<Geogroup_Insert_Input>;
  on_conflict?: InputMaybe<Geogroup_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Geogroup_OneArgs = {
  object: Geogroup_Insert_Input;
  on_conflict?: InputMaybe<Geogroup_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Geometry_ColumnsArgs = {
  objects: Array<Geometry_Columns_Insert_Input>;
};

/** mutation root */
export type Mutation_RootInsert_Geometry_Columns_OneArgs = {
  object: Geometry_Columns_Insert_Input;
};

/** mutation root */
export type Mutation_RootInsert_GeopointArgs = {
  objects: Array<Geopoint_Insert_Input>;
  on_conflict?: InputMaybe<Geopoint_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Geopoint_OneArgs = {
  object: Geopoint_Insert_Input;
  on_conflict?: InputMaybe<Geopoint_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_InspectionArgs = {
  objects: Array<Inspection_Insert_Input>;
  on_conflict?: InputMaybe<Inspection_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Inspection_OneArgs = {
  object: Inspection_Insert_Input;
  on_conflict?: InputMaybe<Inspection_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_ObservationArgs = {
  objects: Array<Observation_Insert_Input>;
  on_conflict?: InputMaybe<Observation_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Observation_OneArgs = {
  object: Observation_Insert_Input;
  on_conflict?: InputMaybe<Observation_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_PlaceArgs = {
  objects: Array<Place_Insert_Input>;
  on_conflict?: InputMaybe<Place_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Place_OneArgs = {
  object: Place_Insert_Input;
  on_conflict?: InputMaybe<Place_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Spatial_Ref_SysArgs = {
  objects: Array<Spatial_Ref_Sys_Insert_Input>;
  on_conflict?: InputMaybe<Spatial_Ref_Sys_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Spatial_Ref_Sys_OneArgs = {
  object: Spatial_Ref_Sys_Insert_Input;
  on_conflict?: InputMaybe<Spatial_Ref_Sys_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_SprayedArgs = {
  objects: Array<Sprayed_Insert_Input>;
  on_conflict?: InputMaybe<Sprayed_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Sprayed_OneArgs = {
  object: Sprayed_Insert_Input;
  on_conflict?: InputMaybe<Sprayed_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Sprayed_Residual_Treatment_KindArgs = {
  objects: Array<Sprayed_Residual_Treatment_Kind_Insert_Input>;
  on_conflict?: InputMaybe<Sprayed_Residual_Treatment_Kind_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Sprayed_Residual_Treatment_Kind_OneArgs = {
  object: Sprayed_Residual_Treatment_Kind_Insert_Input;
  on_conflict?: InputMaybe<Sprayed_Residual_Treatment_Kind_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_SprayerArgs = {
  objects: Array<Sprayer_Insert_Input>;
  on_conflict?: InputMaybe<Sprayer_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Sprayer_OneArgs = {
  object: Sprayer_Insert_Input;
  on_conflict?: InputMaybe<Sprayer_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Work_SessionsArgs = {
  objects: Array<Work_Sessions_Insert_Input>;
  on_conflict?: InputMaybe<Work_Sessions_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Work_Sessions_OneArgs = {
  object: Work_Sessions_Insert_Input;
  on_conflict?: InputMaybe<Work_Sessions_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_WorkspaceArgs = {
  objects: Array<Workspace_Insert_Input>;
  on_conflict?: InputMaybe<Workspace_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Workspace_KindArgs = {
  objects: Array<Workspace_Kind_Insert_Input>;
  on_conflict?: InputMaybe<Workspace_Kind_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Workspace_Kind_OneArgs = {
  object: Workspace_Kind_Insert_Input;
  on_conflict?: InputMaybe<Workspace_Kind_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Workspace_MetadataArgs = {
  objects: Array<Workspace_Metadata_Insert_Input>;
  on_conflict?: InputMaybe<Workspace_Metadata_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Workspace_Metadata_OneArgs = {
  object: Workspace_Metadata_Insert_Input;
  on_conflict?: InputMaybe<Workspace_Metadata_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Workspace_OneArgs = {
  object: Workspace_Insert_Input;
  on_conflict?: InputMaybe<Workspace_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Workspace_OwnersArgs = {
  objects: Array<Workspace_Owners_Insert_Input>;
  on_conflict?: InputMaybe<Workspace_Owners_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Workspace_Owners_OneArgs = {
  object: Workspace_Owners_Insert_Input;
  on_conflict?: InputMaybe<Workspace_Owners_On_Conflict>;
};

/** mutation root */
export type Mutation_RootUpdate_AdvertisementArgs = {
  _set?: InputMaybe<Advertisement_Set_Input>;
  where: Advertisement_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Advertisement_By_PkArgs = {
  _set?: InputMaybe<Advertisement_Set_Input>;
  pk_columns: Advertisement_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Advertisement_ManyArgs = {
  updates: Array<Advertisement_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_CollaboratorArgs = {
  _set?: InputMaybe<Collaborator_Set_Input>;
  where: Collaborator_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Collaborator_By_PkArgs = {
  _set?: InputMaybe<Collaborator_Set_Input>;
  pk_columns: Collaborator_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Collaborator_ManyArgs = {
  updates: Array<Collaborator_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_ComplaintArgs = {
  _inc?: InputMaybe<Complaint_Inc_Input>;
  _set?: InputMaybe<Complaint_Set_Input>;
  where: Complaint_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Complaint_By_PkArgs = {
  _inc?: InputMaybe<Complaint_Inc_Input>;
  _set?: InputMaybe<Complaint_Set_Input>;
  pk_columns: Complaint_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Complaint_KindArgs = {
  _set?: InputMaybe<Complaint_Kind_Set_Input>;
  where: Complaint_Kind_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Complaint_Kind_By_PkArgs = {
  _set?: InputMaybe<Complaint_Kind_Set_Input>;
  pk_columns: Complaint_Kind_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Complaint_Kind_ManyArgs = {
  updates: Array<Complaint_Kind_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Complaint_ManyArgs = {
  updates: Array<Complaint_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Complaint_SourceArgs = {
  _set?: InputMaybe<Complaint_Source_Set_Input>;
  where: Complaint_Source_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Complaint_Source_By_PkArgs = {
  _set?: InputMaybe<Complaint_Source_Set_Input>;
  pk_columns: Complaint_Source_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Complaint_Source_ManyArgs = {
  updates: Array<Complaint_Source_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_DistancesArgs = {
  _append?: InputMaybe<Distances_Append_Input>;
  _delete_at_path?: InputMaybe<Distances_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Distances_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Distances_Delete_Key_Input>;
  _inc?: InputMaybe<Distances_Inc_Input>;
  _prepend?: InputMaybe<Distances_Prepend_Input>;
  _set?: InputMaybe<Distances_Set_Input>;
  where: Distances_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Distances_By_PkArgs = {
  _append?: InputMaybe<Distances_Append_Input>;
  _delete_at_path?: InputMaybe<Distances_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Distances_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Distances_Delete_Key_Input>;
  _inc?: InputMaybe<Distances_Inc_Input>;
  _prepend?: InputMaybe<Distances_Prepend_Input>;
  _set?: InputMaybe<Distances_Set_Input>;
  pk_columns: Distances_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Distances_ManyArgs = {
  updates: Array<Distances_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Estate_KindArgs = {
  _set?: InputMaybe<Estate_Kind_Set_Input>;
  where: Estate_Kind_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Estate_Kind_By_PkArgs = {
  _set?: InputMaybe<Estate_Kind_Set_Input>;
  pk_columns: Estate_Kind_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Estate_Kind_ManyArgs = {
  updates: Array<Estate_Kind_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_ExperimentArgs = {
  _inc?: InputMaybe<Experiment_Inc_Input>;
  _set?: InputMaybe<Experiment_Set_Input>;
  where: Experiment_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Experiment_By_PkArgs = {
  _inc?: InputMaybe<Experiment_Inc_Input>;
  _set?: InputMaybe<Experiment_Set_Input>;
  pk_columns: Experiment_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Experiment_ManyArgs = {
  updates: Array<Experiment_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_GeogroupArgs = {
  _append?: InputMaybe<Geogroup_Append_Input>;
  _delete_at_path?: InputMaybe<Geogroup_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Geogroup_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Geogroup_Delete_Key_Input>;
  _inc?: InputMaybe<Geogroup_Inc_Input>;
  _prepend?: InputMaybe<Geogroup_Prepend_Input>;
  _set?: InputMaybe<Geogroup_Set_Input>;
  where: Geogroup_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Geogroup_By_PkArgs = {
  _append?: InputMaybe<Geogroup_Append_Input>;
  _delete_at_path?: InputMaybe<Geogroup_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Geogroup_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Geogroup_Delete_Key_Input>;
  _inc?: InputMaybe<Geogroup_Inc_Input>;
  _prepend?: InputMaybe<Geogroup_Prepend_Input>;
  _set?: InputMaybe<Geogroup_Set_Input>;
  pk_columns: Geogroup_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Geogroup_ManyArgs = {
  updates: Array<Geogroup_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Geometry_ColumnsArgs = {
  _inc?: InputMaybe<Geometry_Columns_Inc_Input>;
  _set?: InputMaybe<Geometry_Columns_Set_Input>;
  where: Geometry_Columns_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Geometry_Columns_ManyArgs = {
  updates: Array<Geometry_Columns_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_GeopointArgs = {
  _inc?: InputMaybe<Geopoint_Inc_Input>;
  _set?: InputMaybe<Geopoint_Set_Input>;
  where: Geopoint_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Geopoint_By_PkArgs = {
  _inc?: InputMaybe<Geopoint_Inc_Input>;
  _set?: InputMaybe<Geopoint_Set_Input>;
  pk_columns: Geopoint_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Geopoint_ManyArgs = {
  updates: Array<Geopoint_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_InspectionArgs = {
  _append?: InputMaybe<Inspection_Append_Input>;
  _delete_at_path?: InputMaybe<Inspection_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Inspection_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Inspection_Delete_Key_Input>;
  _prepend?: InputMaybe<Inspection_Prepend_Input>;
  _set?: InputMaybe<Inspection_Set_Input>;
  where: Inspection_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Inspection_By_PkArgs = {
  _append?: InputMaybe<Inspection_Append_Input>;
  _delete_at_path?: InputMaybe<Inspection_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Inspection_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Inspection_Delete_Key_Input>;
  _prepend?: InputMaybe<Inspection_Prepend_Input>;
  _set?: InputMaybe<Inspection_Set_Input>;
  pk_columns: Inspection_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Inspection_ManyArgs = {
  updates: Array<Inspection_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_ObservationArgs = {
  _set?: InputMaybe<Observation_Set_Input>;
  where: Observation_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Observation_By_PkArgs = {
  _set?: InputMaybe<Observation_Set_Input>;
  pk_columns: Observation_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Observation_ManyArgs = {
  updates: Array<Observation_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_PlaceArgs = {
  _set?: InputMaybe<Place_Set_Input>;
  where: Place_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Place_By_PkArgs = {
  _set?: InputMaybe<Place_Set_Input>;
  pk_columns: Place_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Place_ManyArgs = {
  updates: Array<Place_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Spatial_Ref_SysArgs = {
  _inc?: InputMaybe<Spatial_Ref_Sys_Inc_Input>;
  _set?: InputMaybe<Spatial_Ref_Sys_Set_Input>;
  where: Spatial_Ref_Sys_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Spatial_Ref_Sys_By_PkArgs = {
  _inc?: InputMaybe<Spatial_Ref_Sys_Inc_Input>;
  _set?: InputMaybe<Spatial_Ref_Sys_Set_Input>;
  pk_columns: Spatial_Ref_Sys_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Spatial_Ref_Sys_ManyArgs = {
  updates: Array<Spatial_Ref_Sys_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_SprayedArgs = {
  _append?: InputMaybe<Sprayed_Append_Input>;
  _delete_at_path?: InputMaybe<Sprayed_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Sprayed_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Sprayed_Delete_Key_Input>;
  _inc?: InputMaybe<Sprayed_Inc_Input>;
  _prepend?: InputMaybe<Sprayed_Prepend_Input>;
  _set?: InputMaybe<Sprayed_Set_Input>;
  where: Sprayed_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Sprayed_By_PkArgs = {
  _append?: InputMaybe<Sprayed_Append_Input>;
  _delete_at_path?: InputMaybe<Sprayed_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Sprayed_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Sprayed_Delete_Key_Input>;
  _inc?: InputMaybe<Sprayed_Inc_Input>;
  _prepend?: InputMaybe<Sprayed_Prepend_Input>;
  _set?: InputMaybe<Sprayed_Set_Input>;
  pk_columns: Sprayed_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Sprayed_ManyArgs = {
  updates: Array<Sprayed_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Sprayed_Residual_Treatment_KindArgs = {
  _set?: InputMaybe<Sprayed_Residual_Treatment_Kind_Set_Input>;
  where: Sprayed_Residual_Treatment_Kind_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Sprayed_Residual_Treatment_Kind_By_PkArgs = {
  _set?: InputMaybe<Sprayed_Residual_Treatment_Kind_Set_Input>;
  pk_columns: Sprayed_Residual_Treatment_Kind_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Sprayed_Residual_Treatment_Kind_ManyArgs = {
  updates: Array<Sprayed_Residual_Treatment_Kind_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_SprayerArgs = {
  _set?: InputMaybe<Sprayer_Set_Input>;
  where: Sprayer_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Sprayer_By_PkArgs = {
  _set?: InputMaybe<Sprayer_Set_Input>;
  pk_columns: Sprayer_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Sprayer_ManyArgs = {
  updates: Array<Sprayer_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Work_SessionsArgs = {
  _inc?: InputMaybe<Work_Sessions_Inc_Input>;
  _set?: InputMaybe<Work_Sessions_Set_Input>;
  where: Work_Sessions_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Work_Sessions_By_PkArgs = {
  _inc?: InputMaybe<Work_Sessions_Inc_Input>;
  _set?: InputMaybe<Work_Sessions_Set_Input>;
  pk_columns: Work_Sessions_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Work_Sessions_ManyArgs = {
  updates: Array<Work_Sessions_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_WorkspaceArgs = {
  _set?: InputMaybe<Workspace_Set_Input>;
  where: Workspace_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Workspace_By_PkArgs = {
  _set?: InputMaybe<Workspace_Set_Input>;
  pk_columns: Workspace_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Workspace_KindArgs = {
  _set?: InputMaybe<Workspace_Kind_Set_Input>;
  where: Workspace_Kind_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Workspace_Kind_By_PkArgs = {
  _set?: InputMaybe<Workspace_Kind_Set_Input>;
  pk_columns: Workspace_Kind_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Workspace_Kind_ManyArgs = {
  updates: Array<Workspace_Kind_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Workspace_ManyArgs = {
  updates: Array<Workspace_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Workspace_MetadataArgs = {
  _append?: InputMaybe<Workspace_Metadata_Append_Input>;
  _delete_at_path?: InputMaybe<Workspace_Metadata_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Workspace_Metadata_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Workspace_Metadata_Delete_Key_Input>;
  _prepend?: InputMaybe<Workspace_Metadata_Prepend_Input>;
  _set?: InputMaybe<Workspace_Metadata_Set_Input>;
  where: Workspace_Metadata_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Workspace_Metadata_By_PkArgs = {
  _append?: InputMaybe<Workspace_Metadata_Append_Input>;
  _delete_at_path?: InputMaybe<Workspace_Metadata_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Workspace_Metadata_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Workspace_Metadata_Delete_Key_Input>;
  _prepend?: InputMaybe<Workspace_Metadata_Prepend_Input>;
  _set?: InputMaybe<Workspace_Metadata_Set_Input>;
  pk_columns: Workspace_Metadata_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Workspace_Metadata_ManyArgs = {
  updates: Array<Workspace_Metadata_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Workspace_OwnersArgs = {
  _set?: InputMaybe<Workspace_Owners_Set_Input>;
  where: Workspace_Owners_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Workspace_Owners_By_PkArgs = {
  _set?: InputMaybe<Workspace_Owners_Set_Input>;
  pk_columns: Workspace_Owners_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Workspace_Owners_ManyArgs = {
  updates: Array<Workspace_Owners_Updates>;
};

/** Boolean expression to compare columns of type "name". All fields are combined with logical 'AND'. */
export type Name_Comparison_Exp = {
  _eq?: InputMaybe<Scalars["name"]>;
  _gt?: InputMaybe<Scalars["name"]>;
  _gte?: InputMaybe<Scalars["name"]>;
  _in?: InputMaybe<Array<Scalars["name"]>>;
  _is_null?: InputMaybe<Scalars["Boolean"]>;
  _lt?: InputMaybe<Scalars["name"]>;
  _lte?: InputMaybe<Scalars["name"]>;
  _neq?: InputMaybe<Scalars["name"]>;
  _nin?: InputMaybe<Array<Scalars["name"]>>;
};

/** columns and relationships of "observation" */
export type Observation = {
  __typename?: "observation";
  app_state: Scalars["String"];
  /** An object relationship */
  collaborator?: Maybe<Collaborator>;
  collaborator_id?: Maybe<Scalars["uuid"]>;
  created_at: Scalars["timestamptz"];
  firebase_id?: Maybe<Scalars["String"]>;
  /** An object relationship */
  geopoint?: Maybe<Geopoint>;
  geopoint_id?: Maybe<Scalars["uuid"]>;
  id: Scalars["uuid"];
  observation?: Maybe<Scalars["String"]>;
  updated_at: Scalars["timestamptz"];
  /** An object relationship */
  workspace?: Maybe<Workspace>;
  workspace_id?: Maybe<Scalars["uuid"]>;
};

/** aggregated selection of "observation" */
export type Observation_Aggregate = {
  __typename?: "observation_aggregate";
  aggregate?: Maybe<Observation_Aggregate_Fields>;
  nodes: Array<Observation>;
};

export type Observation_Aggregate_Bool_Exp = {
  count?: InputMaybe<Observation_Aggregate_Bool_Exp_Count>;
};

export type Observation_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Observation_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]>;
  filter?: InputMaybe<Observation_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "observation" */
export type Observation_Aggregate_Fields = {
  __typename?: "observation_aggregate_fields";
  count: Scalars["Int"];
  max?: Maybe<Observation_Max_Fields>;
  min?: Maybe<Observation_Min_Fields>;
};

/** aggregate fields of "observation" */
export type Observation_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Observation_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "observation" */
export type Observation_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Observation_Max_Order_By>;
  min?: InputMaybe<Observation_Min_Order_By>;
};

/** input type for inserting array relation for remote table "observation" */
export type Observation_Arr_Rel_Insert_Input = {
  data: Array<Observation_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Observation_On_Conflict>;
};

/** Boolean expression to filter rows from the table "observation". All fields are combined with a logical 'AND'. */
export type Observation_Bool_Exp = {
  _and?: InputMaybe<Array<Observation_Bool_Exp>>;
  _not?: InputMaybe<Observation_Bool_Exp>;
  _or?: InputMaybe<Array<Observation_Bool_Exp>>;
  app_state?: InputMaybe<String_Comparison_Exp>;
  collaborator?: InputMaybe<Collaborator_Bool_Exp>;
  collaborator_id?: InputMaybe<Uuid_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  firebase_id?: InputMaybe<String_Comparison_Exp>;
  geopoint?: InputMaybe<Geopoint_Bool_Exp>;
  geopoint_id?: InputMaybe<Uuid_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  observation?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  workspace?: InputMaybe<Workspace_Bool_Exp>;
  workspace_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "observation" */
export enum Observation_Constraint {
  /** unique or primary key constraint on columns "id" */
  ObservationPkey = "observation_pkey",
}

/** input type for inserting data into table "observation" */
export type Observation_Insert_Input = {
  app_state?: InputMaybe<Scalars["String"]>;
  collaborator?: InputMaybe<Collaborator_Obj_Rel_Insert_Input>;
  collaborator_id?: InputMaybe<Scalars["uuid"]>;
  created_at?: InputMaybe<Scalars["timestamptz"]>;
  firebase_id?: InputMaybe<Scalars["String"]>;
  geopoint?: InputMaybe<Geopoint_Obj_Rel_Insert_Input>;
  geopoint_id?: InputMaybe<Scalars["uuid"]>;
  id?: InputMaybe<Scalars["uuid"]>;
  observation?: InputMaybe<Scalars["String"]>;
  updated_at?: InputMaybe<Scalars["timestamptz"]>;
  workspace?: InputMaybe<Workspace_Obj_Rel_Insert_Input>;
  workspace_id?: InputMaybe<Scalars["uuid"]>;
};

/** aggregate max on columns */
export type Observation_Max_Fields = {
  __typename?: "observation_max_fields";
  app_state?: Maybe<Scalars["String"]>;
  collaborator_id?: Maybe<Scalars["uuid"]>;
  created_at?: Maybe<Scalars["timestamptz"]>;
  firebase_id?: Maybe<Scalars["String"]>;
  geopoint_id?: Maybe<Scalars["uuid"]>;
  id?: Maybe<Scalars["uuid"]>;
  observation?: Maybe<Scalars["String"]>;
  updated_at?: Maybe<Scalars["timestamptz"]>;
  workspace_id?: Maybe<Scalars["uuid"]>;
};

/** order by max() on columns of table "observation" */
export type Observation_Max_Order_By = {
  app_state?: InputMaybe<Order_By>;
  collaborator_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  firebase_id?: InputMaybe<Order_By>;
  geopoint_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  observation?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  workspace_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Observation_Min_Fields = {
  __typename?: "observation_min_fields";
  app_state?: Maybe<Scalars["String"]>;
  collaborator_id?: Maybe<Scalars["uuid"]>;
  created_at?: Maybe<Scalars["timestamptz"]>;
  firebase_id?: Maybe<Scalars["String"]>;
  geopoint_id?: Maybe<Scalars["uuid"]>;
  id?: Maybe<Scalars["uuid"]>;
  observation?: Maybe<Scalars["String"]>;
  updated_at?: Maybe<Scalars["timestamptz"]>;
  workspace_id?: Maybe<Scalars["uuid"]>;
};

/** order by min() on columns of table "observation" */
export type Observation_Min_Order_By = {
  app_state?: InputMaybe<Order_By>;
  collaborator_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  firebase_id?: InputMaybe<Order_By>;
  geopoint_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  observation?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  workspace_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "observation" */
export type Observation_Mutation_Response = {
  __typename?: "observation_mutation_response";
  /** number of rows affected by the mutation */
  affected_rows: Scalars["Int"];
  /** data from the rows affected by the mutation */
  returning: Array<Observation>;
};

/** on_conflict condition type for table "observation" */
export type Observation_On_Conflict = {
  constraint: Observation_Constraint;
  update_columns?: Array<Observation_Update_Column>;
  where?: InputMaybe<Observation_Bool_Exp>;
};

/** Ordering options when selecting data from "observation". */
export type Observation_Order_By = {
  app_state?: InputMaybe<Order_By>;
  collaborator?: InputMaybe<Collaborator_Order_By>;
  collaborator_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  firebase_id?: InputMaybe<Order_By>;
  geopoint?: InputMaybe<Geopoint_Order_By>;
  geopoint_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  observation?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  workspace?: InputMaybe<Workspace_Order_By>;
  workspace_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: observation */
export type Observation_Pk_Columns_Input = {
  id: Scalars["uuid"];
};

/** select columns of table "observation" */
export enum Observation_Select_Column {
  /** column name */
  AppState = "app_state",
  /** column name */
  CollaboratorId = "collaborator_id",
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  FirebaseId = "firebase_id",
  /** column name */
  GeopointId = "geopoint_id",
  /** column name */
  Id = "id",
  /** column name */
  Observation = "observation",
  /** column name */
  UpdatedAt = "updated_at",
  /** column name */
  WorkspaceId = "workspace_id",
}

/** input type for updating data in table "observation" */
export type Observation_Set_Input = {
  app_state?: InputMaybe<Scalars["String"]>;
  collaborator_id?: InputMaybe<Scalars["uuid"]>;
  created_at?: InputMaybe<Scalars["timestamptz"]>;
  firebase_id?: InputMaybe<Scalars["String"]>;
  geopoint_id?: InputMaybe<Scalars["uuid"]>;
  id?: InputMaybe<Scalars["uuid"]>;
  observation?: InputMaybe<Scalars["String"]>;
  updated_at?: InputMaybe<Scalars["timestamptz"]>;
  workspace_id?: InputMaybe<Scalars["uuid"]>;
};

/** Streaming cursor of the table "observation" */
export type Observation_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Observation_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Observation_Stream_Cursor_Value_Input = {
  app_state?: InputMaybe<Scalars["String"]>;
  collaborator_id?: InputMaybe<Scalars["uuid"]>;
  created_at?: InputMaybe<Scalars["timestamptz"]>;
  firebase_id?: InputMaybe<Scalars["String"]>;
  geopoint_id?: InputMaybe<Scalars["uuid"]>;
  id?: InputMaybe<Scalars["uuid"]>;
  observation?: InputMaybe<Scalars["String"]>;
  updated_at?: InputMaybe<Scalars["timestamptz"]>;
  workspace_id?: InputMaybe<Scalars["uuid"]>;
};

/** update columns of table "observation" */
export enum Observation_Update_Column {
  /** column name */
  AppState = "app_state",
  /** column name */
  CollaboratorId = "collaborator_id",
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  FirebaseId = "firebase_id",
  /** column name */
  GeopointId = "geopoint_id",
  /** column name */
  Id = "id",
  /** column name */
  Observation = "observation",
  /** column name */
  UpdatedAt = "updated_at",
  /** column name */
  WorkspaceId = "workspace_id",
}

export type Observation_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Observation_Set_Input>;
  /** filter the rows which have to be updated */
  where: Observation_Bool_Exp;
};

/** column ordering options */
export enum Order_By {
  /** in ascending order, nulls last */
  Asc = "asc",
  /** in ascending order, nulls first */
  AscNullsFirst = "asc_nulls_first",
  /** in ascending order, nulls last */
  AscNullsLast = "asc_nulls_last",
  /** in descending order, nulls first */
  Desc = "desc",
  /** in descending order, nulls first */
  DescNullsFirst = "desc_nulls_first",
  /** in descending order, nulls last */
  DescNullsLast = "desc_nulls_last",
}

/** columns and relationships of "place" */
export type Place = {
  __typename?: "place";
  /** An array relationship */
  complaints: Array<Complaint>;
  /** An aggregate relationship */
  complaints_aggregate: Complaint_Aggregate;
  created_at: Scalars["timestamptz"];
  description?: Maybe<Scalars["String"]>;
  geometry?: Maybe<Scalars["geometry"]>;
  id: Scalars["uuid"];
  kind?: Maybe<Scalars["String"]>;
  name: Scalars["String"];
  parent_id?: Maybe<Scalars["uuid"]>;
  /** An object relationship */
  place?: Maybe<Place>;
  /** An array relationship */
  places: Array<Place>;
  /** An aggregate relationship */
  places_aggregate: Place_Aggregate;
  updated_at: Scalars["timestamptz"];
};

/** columns and relationships of "place" */
export type PlaceComplaintsArgs = {
  distinct_on?: InputMaybe<Array<Complaint_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Complaint_Order_By>>;
  where?: InputMaybe<Complaint_Bool_Exp>;
};

/** columns and relationships of "place" */
export type PlaceComplaints_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Complaint_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Complaint_Order_By>>;
  where?: InputMaybe<Complaint_Bool_Exp>;
};

/** columns and relationships of "place" */
export type PlacePlacesArgs = {
  distinct_on?: InputMaybe<Array<Place_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Place_Order_By>>;
  where?: InputMaybe<Place_Bool_Exp>;
};

/** columns and relationships of "place" */
export type PlacePlaces_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Place_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Place_Order_By>>;
  where?: InputMaybe<Place_Bool_Exp>;
};

/** aggregated selection of "place" */
export type Place_Aggregate = {
  __typename?: "place_aggregate";
  aggregate?: Maybe<Place_Aggregate_Fields>;
  nodes: Array<Place>;
};

export type Place_Aggregate_Bool_Exp = {
  count?: InputMaybe<Place_Aggregate_Bool_Exp_Count>;
};

export type Place_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Place_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]>;
  filter?: InputMaybe<Place_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "place" */
export type Place_Aggregate_Fields = {
  __typename?: "place_aggregate_fields";
  count: Scalars["Int"];
  max?: Maybe<Place_Max_Fields>;
  min?: Maybe<Place_Min_Fields>;
};

/** aggregate fields of "place" */
export type Place_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Place_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "place" */
export type Place_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Place_Max_Order_By>;
  min?: InputMaybe<Place_Min_Order_By>;
};

/** input type for inserting array relation for remote table "place" */
export type Place_Arr_Rel_Insert_Input = {
  data: Array<Place_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Place_On_Conflict>;
};

/** Boolean expression to filter rows from the table "place". All fields are combined with a logical 'AND'. */
export type Place_Bool_Exp = {
  _and?: InputMaybe<Array<Place_Bool_Exp>>;
  _not?: InputMaybe<Place_Bool_Exp>;
  _or?: InputMaybe<Array<Place_Bool_Exp>>;
  complaints?: InputMaybe<Complaint_Bool_Exp>;
  complaints_aggregate?: InputMaybe<Complaint_Aggregate_Bool_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  description?: InputMaybe<String_Comparison_Exp>;
  geometry?: InputMaybe<Geometry_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  kind?: InputMaybe<String_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  parent_id?: InputMaybe<Uuid_Comparison_Exp>;
  place?: InputMaybe<Place_Bool_Exp>;
  places?: InputMaybe<Place_Bool_Exp>;
  places_aggregate?: InputMaybe<Place_Aggregate_Bool_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "place" */
export enum Place_Constraint {
  /** unique or primary key constraint on columns "name", "kind", "parent_id" */
  PlaceKindNameParentIdKey = "place_kind_name_parent_id_key",
  /** unique or primary key constraint on columns "id" */
  PlacePkey = "place_pkey",
}

/** input type for inserting data into table "place" */
export type Place_Insert_Input = {
  complaints?: InputMaybe<Complaint_Arr_Rel_Insert_Input>;
  created_at?: InputMaybe<Scalars["timestamptz"]>;
  description?: InputMaybe<Scalars["String"]>;
  geometry?: InputMaybe<Scalars["geometry"]>;
  id?: InputMaybe<Scalars["uuid"]>;
  kind?: InputMaybe<Scalars["String"]>;
  name?: InputMaybe<Scalars["String"]>;
  parent_id?: InputMaybe<Scalars["uuid"]>;
  place?: InputMaybe<Place_Obj_Rel_Insert_Input>;
  places?: InputMaybe<Place_Arr_Rel_Insert_Input>;
  updated_at?: InputMaybe<Scalars["timestamptz"]>;
};

/** aggregate max on columns */
export type Place_Max_Fields = {
  __typename?: "place_max_fields";
  created_at?: Maybe<Scalars["timestamptz"]>;
  description?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["uuid"]>;
  kind?: Maybe<Scalars["String"]>;
  name?: Maybe<Scalars["String"]>;
  parent_id?: Maybe<Scalars["uuid"]>;
  updated_at?: Maybe<Scalars["timestamptz"]>;
};

/** order by max() on columns of table "place" */
export type Place_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  kind?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  parent_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Place_Min_Fields = {
  __typename?: "place_min_fields";
  created_at?: Maybe<Scalars["timestamptz"]>;
  description?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["uuid"]>;
  kind?: Maybe<Scalars["String"]>;
  name?: Maybe<Scalars["String"]>;
  parent_id?: Maybe<Scalars["uuid"]>;
  updated_at?: Maybe<Scalars["timestamptz"]>;
};

/** order by min() on columns of table "place" */
export type Place_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  kind?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  parent_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "place" */
export type Place_Mutation_Response = {
  __typename?: "place_mutation_response";
  /** number of rows affected by the mutation */
  affected_rows: Scalars["Int"];
  /** data from the rows affected by the mutation */
  returning: Array<Place>;
};

/** input type for inserting object relation for remote table "place" */
export type Place_Obj_Rel_Insert_Input = {
  data: Place_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Place_On_Conflict>;
};

/** on_conflict condition type for table "place" */
export type Place_On_Conflict = {
  constraint: Place_Constraint;
  update_columns?: Array<Place_Update_Column>;
  where?: InputMaybe<Place_Bool_Exp>;
};

/** Ordering options when selecting data from "place". */
export type Place_Order_By = {
  complaints_aggregate?: InputMaybe<Complaint_Aggregate_Order_By>;
  created_at?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  geometry?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  kind?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  parent_id?: InputMaybe<Order_By>;
  place?: InputMaybe<Place_Order_By>;
  places_aggregate?: InputMaybe<Place_Aggregate_Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: place */
export type Place_Pk_Columns_Input = {
  id: Scalars["uuid"];
};

/** select columns of table "place" */
export enum Place_Select_Column {
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  Description = "description",
  /** column name */
  Geometry = "geometry",
  /** column name */
  Id = "id",
  /** column name */
  Kind = "kind",
  /** column name */
  Name = "name",
  /** column name */
  ParentId = "parent_id",
  /** column name */
  UpdatedAt = "updated_at",
}

/** input type for updating data in table "place" */
export type Place_Set_Input = {
  created_at?: InputMaybe<Scalars["timestamptz"]>;
  description?: InputMaybe<Scalars["String"]>;
  geometry?: InputMaybe<Scalars["geometry"]>;
  id?: InputMaybe<Scalars["uuid"]>;
  kind?: InputMaybe<Scalars["String"]>;
  name?: InputMaybe<Scalars["String"]>;
  parent_id?: InputMaybe<Scalars["uuid"]>;
  updated_at?: InputMaybe<Scalars["timestamptz"]>;
};

/** Streaming cursor of the table "place" */
export type Place_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Place_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Place_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars["timestamptz"]>;
  description?: InputMaybe<Scalars["String"]>;
  geometry?: InputMaybe<Scalars["geometry"]>;
  id?: InputMaybe<Scalars["uuid"]>;
  kind?: InputMaybe<Scalars["String"]>;
  name?: InputMaybe<Scalars["String"]>;
  parent_id?: InputMaybe<Scalars["uuid"]>;
  updated_at?: InputMaybe<Scalars["timestamptz"]>;
};

/** update columns of table "place" */
export enum Place_Update_Column {
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  Description = "description",
  /** column name */
  Geometry = "geometry",
  /** column name */
  Id = "id",
  /** column name */
  Kind = "kind",
  /** column name */
  Name = "name",
  /** column name */
  ParentId = "parent_id",
  /** column name */
  UpdatedAt = "updated_at",
}

export type Place_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Place_Set_Input>;
  /** filter the rows which have to be updated */
  where: Place_Bool_Exp;
};

export type Query_Root = {
  __typename?: "query_root";
  /** fetch data from the table: "advertisement" */
  advertisement: Array<Advertisement>;
  /** fetch aggregated fields from the table: "advertisement" */
  advertisement_aggregate: Advertisement_Aggregate;
  /** fetch data from the table: "advertisement" using primary key columns */
  advertisement_by_pk?: Maybe<Advertisement>;
  /** fetch data from the table: "collaborator" */
  collaborator: Array<Collaborator>;
  /** fetch aggregated fields from the table: "collaborator" */
  collaborator_aggregate: Collaborator_Aggregate;
  /** fetch data from the table: "collaborator" using primary key columns */
  collaborator_by_pk?: Maybe<Collaborator>;
  /** fetch data from the table: "complaint" */
  complaint: Array<Complaint>;
  /** fetch aggregated fields from the table: "complaint" */
  complaint_aggregate: Complaint_Aggregate;
  /** fetch data from the table: "complaint" using primary key columns */
  complaint_by_pk?: Maybe<Complaint>;
  /** fetch data from the table: "complaint_kind" */
  complaint_kind: Array<Complaint_Kind>;
  /** fetch aggregated fields from the table: "complaint_kind" */
  complaint_kind_aggregate: Complaint_Kind_Aggregate;
  /** fetch data from the table: "complaint_kind" using primary key columns */
  complaint_kind_by_pk?: Maybe<Complaint_Kind>;
  /** fetch data from the table: "complaint_source" */
  complaint_source: Array<Complaint_Source>;
  /** fetch aggregated fields from the table: "complaint_source" */
  complaint_source_aggregate: Complaint_Source_Aggregate;
  /** fetch data from the table: "complaint_source" using primary key columns */
  complaint_source_by_pk?: Maybe<Complaint_Source>;
  /** An array relationship */
  distances: Array<Distances>;
  /** An aggregate relationship */
  distances_aggregate: Distances_Aggregate;
  /** fetch data from the table: "distances" using primary key columns */
  distances_by_pk?: Maybe<Distances>;
  /** fetch data from the table: "estate_kind" */
  estate_kind: Array<Estate_Kind>;
  /** fetch aggregated fields from the table: "estate_kind" */
  estate_kind_aggregate: Estate_Kind_Aggregate;
  /** fetch data from the table: "estate_kind" using primary key columns */
  estate_kind_by_pk?: Maybe<Estate_Kind>;
  /** fetch data from the table: "experiment" */
  experiment: Array<Experiment>;
  /** fetch aggregated fields from the table: "experiment" */
  experiment_aggregate: Experiment_Aggregate;
  /** fetch data from the table: "experiment" using primary key columns */
  experiment_by_pk?: Maybe<Experiment>;
  /** fetch data from the table: "geography_columns" */
  geography_columns: Array<Geography_Columns>;
  /** fetch aggregated fields from the table: "geography_columns" */
  geography_columns_aggregate: Geography_Columns_Aggregate;
  /** fetch data from the table: "geogroup" */
  geogroup: Array<Geogroup>;
  /** fetch aggregated fields from the table: "geogroup" */
  geogroup_aggregate: Geogroup_Aggregate;
  /** fetch data from the table: "geogroup" using primary key columns */
  geogroup_by_pk?: Maybe<Geogroup>;
  /** fetch data from the table: "geometry_columns" */
  geometry_columns: Array<Geometry_Columns>;
  /** fetch aggregated fields from the table: "geometry_columns" */
  geometry_columns_aggregate: Geometry_Columns_Aggregate;
  /** fetch data from the table: "geopoint" */
  geopoint: Array<Geopoint>;
  /** fetch aggregated fields from the table: "geopoint" */
  geopoint_aggregate: Geopoint_Aggregate;
  /** fetch data from the table: "geopoint" using primary key columns */
  geopoint_by_pk?: Maybe<Geopoint>;
  /** fetch data from the table: "inspection" */
  inspection: Array<Inspection>;
  /** fetch aggregated fields from the table: "inspection" */
  inspection_aggregate: Inspection_Aggregate;
  /** fetch data from the table: "inspection" using primary key columns */
  inspection_by_pk?: Maybe<Inspection>;
  /** fetch data from the table: "observation" */
  observation: Array<Observation>;
  /** fetch aggregated fields from the table: "observation" */
  observation_aggregate: Observation_Aggregate;
  /** fetch data from the table: "observation" using primary key columns */
  observation_by_pk?: Maybe<Observation>;
  /** fetch data from the table: "place" */
  place: Array<Place>;
  /** fetch aggregated fields from the table: "place" */
  place_aggregate: Place_Aggregate;
  /** fetch data from the table: "place" using primary key columns */
  place_by_pk?: Maybe<Place>;
  /** fetch data from the table: "spatial_ref_sys" */
  spatial_ref_sys: Array<Spatial_Ref_Sys>;
  /** fetch aggregated fields from the table: "spatial_ref_sys" */
  spatial_ref_sys_aggregate: Spatial_Ref_Sys_Aggregate;
  /** fetch data from the table: "spatial_ref_sys" using primary key columns */
  spatial_ref_sys_by_pk?: Maybe<Spatial_Ref_Sys>;
  /** fetch data from the table: "sprayed" */
  sprayed: Array<Sprayed>;
  /** fetch aggregated fields from the table: "sprayed" */
  sprayed_aggregate: Sprayed_Aggregate;
  /** fetch data from the table: "sprayed" using primary key columns */
  sprayed_by_pk?: Maybe<Sprayed>;
  /** fetch data from the table: "sprayed_residual_treatment_kind" */
  sprayed_residual_treatment_kind: Array<Sprayed_Residual_Treatment_Kind>;
  /** fetch aggregated fields from the table: "sprayed_residual_treatment_kind" */
  sprayed_residual_treatment_kind_aggregate: Sprayed_Residual_Treatment_Kind_Aggregate;
  /** fetch data from the table: "sprayed_residual_treatment_kind" using primary key columns */
  sprayed_residual_treatment_kind_by_pk?: Maybe<Sprayed_Residual_Treatment_Kind>;
  /** fetch data from the table: "sprayer" */
  sprayer: Array<Sprayer>;
  /** fetch aggregated fields from the table: "sprayer" */
  sprayer_aggregate: Sprayer_Aggregate;
  /** fetch data from the table: "sprayer" using primary key columns */
  sprayer_by_pk?: Maybe<Sprayer>;
  /** An array relationship */
  work_sessions: Array<Work_Sessions>;
  /** An aggregate relationship */
  work_sessions_aggregate: Work_Sessions_Aggregate;
  /** fetch data from the table: "work_sessions" using primary key columns */
  work_sessions_by_pk?: Maybe<Work_Sessions>;
  /** fetch data from the table: "workspace" */
  workspace: Array<Workspace>;
  /** fetch aggregated fields from the table: "workspace" */
  workspace_aggregate: Workspace_Aggregate;
  /** fetch data from the table: "workspace" using primary key columns */
  workspace_by_pk?: Maybe<Workspace>;
  /** fetch data from the table: "workspace_kind" */
  workspace_kind: Array<Workspace_Kind>;
  /** fetch aggregated fields from the table: "workspace_kind" */
  workspace_kind_aggregate: Workspace_Kind_Aggregate;
  /** fetch data from the table: "workspace_kind" using primary key columns */
  workspace_kind_by_pk?: Maybe<Workspace_Kind>;
  /** An array relationship */
  workspace_metadata: Array<Workspace_Metadata>;
  /** An aggregate relationship */
  workspace_metadata_aggregate: Workspace_Metadata_Aggregate;
  /** fetch data from the table: "workspace_metadata" using primary key columns */
  workspace_metadata_by_pk?: Maybe<Workspace_Metadata>;
  /** fetch data from the table: "workspace_owners" */
  workspace_owners: Array<Workspace_Owners>;
  /** fetch aggregated fields from the table: "workspace_owners" */
  workspace_owners_aggregate: Workspace_Owners_Aggregate;
  /** fetch data from the table: "workspace_owners" using primary key columns */
  workspace_owners_by_pk?: Maybe<Workspace_Owners>;
};

export type Query_RootAdvertisementArgs = {
  distinct_on?: InputMaybe<Array<Advertisement_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Advertisement_Order_By>>;
  where?: InputMaybe<Advertisement_Bool_Exp>;
};

export type Query_RootAdvertisement_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Advertisement_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Advertisement_Order_By>>;
  where?: InputMaybe<Advertisement_Bool_Exp>;
};

export type Query_RootAdvertisement_By_PkArgs = {
  id: Scalars["uuid"];
};

export type Query_RootCollaboratorArgs = {
  distinct_on?: InputMaybe<Array<Collaborator_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Collaborator_Order_By>>;
  where?: InputMaybe<Collaborator_Bool_Exp>;
};

export type Query_RootCollaborator_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Collaborator_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Collaborator_Order_By>>;
  where?: InputMaybe<Collaborator_Bool_Exp>;
};

export type Query_RootCollaborator_By_PkArgs = {
  id: Scalars["uuid"];
};

export type Query_RootComplaintArgs = {
  distinct_on?: InputMaybe<Array<Complaint_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Complaint_Order_By>>;
  where?: InputMaybe<Complaint_Bool_Exp>;
};

export type Query_RootComplaint_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Complaint_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Complaint_Order_By>>;
  where?: InputMaybe<Complaint_Bool_Exp>;
};

export type Query_RootComplaint_By_PkArgs = {
  id: Scalars["uuid"];
};

export type Query_RootComplaint_KindArgs = {
  distinct_on?: InputMaybe<Array<Complaint_Kind_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Complaint_Kind_Order_By>>;
  where?: InputMaybe<Complaint_Kind_Bool_Exp>;
};

export type Query_RootComplaint_Kind_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Complaint_Kind_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Complaint_Kind_Order_By>>;
  where?: InputMaybe<Complaint_Kind_Bool_Exp>;
};

export type Query_RootComplaint_Kind_By_PkArgs = {
  value: Scalars["String"];
};

export type Query_RootComplaint_SourceArgs = {
  distinct_on?: InputMaybe<Array<Complaint_Source_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Complaint_Source_Order_By>>;
  where?: InputMaybe<Complaint_Source_Bool_Exp>;
};

export type Query_RootComplaint_Source_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Complaint_Source_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Complaint_Source_Order_By>>;
  where?: InputMaybe<Complaint_Source_Bool_Exp>;
};

export type Query_RootComplaint_Source_By_PkArgs = {
  value: Scalars["String"];
};

export type Query_RootDistancesArgs = {
  distinct_on?: InputMaybe<Array<Distances_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Distances_Order_By>>;
  where?: InputMaybe<Distances_Bool_Exp>;
};

export type Query_RootDistances_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Distances_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Distances_Order_By>>;
  where?: InputMaybe<Distances_Bool_Exp>;
};

export type Query_RootDistances_By_PkArgs = {
  id: Scalars["uuid"];
};

export type Query_RootEstate_KindArgs = {
  distinct_on?: InputMaybe<Array<Estate_Kind_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Estate_Kind_Order_By>>;
  where?: InputMaybe<Estate_Kind_Bool_Exp>;
};

export type Query_RootEstate_Kind_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Estate_Kind_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Estate_Kind_Order_By>>;
  where?: InputMaybe<Estate_Kind_Bool_Exp>;
};

export type Query_RootEstate_Kind_By_PkArgs = {
  value: Scalars["String"];
};

export type Query_RootExperimentArgs = {
  distinct_on?: InputMaybe<Array<Experiment_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Experiment_Order_By>>;
  where?: InputMaybe<Experiment_Bool_Exp>;
};

export type Query_RootExperiment_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Experiment_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Experiment_Order_By>>;
  where?: InputMaybe<Experiment_Bool_Exp>;
};

export type Query_RootExperiment_By_PkArgs = {
  id: Scalars["uuid"];
};

export type Query_RootGeography_ColumnsArgs = {
  distinct_on?: InputMaybe<Array<Geography_Columns_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Geography_Columns_Order_By>>;
  where?: InputMaybe<Geography_Columns_Bool_Exp>;
};

export type Query_RootGeography_Columns_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Geography_Columns_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Geography_Columns_Order_By>>;
  where?: InputMaybe<Geography_Columns_Bool_Exp>;
};

export type Query_RootGeogroupArgs = {
  distinct_on?: InputMaybe<Array<Geogroup_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Geogroup_Order_By>>;
  where?: InputMaybe<Geogroup_Bool_Exp>;
};

export type Query_RootGeogroup_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Geogroup_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Geogroup_Order_By>>;
  where?: InputMaybe<Geogroup_Bool_Exp>;
};

export type Query_RootGeogroup_By_PkArgs = {
  id: Scalars["uuid"];
};

export type Query_RootGeometry_ColumnsArgs = {
  distinct_on?: InputMaybe<Array<Geometry_Columns_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Geometry_Columns_Order_By>>;
  where?: InputMaybe<Geometry_Columns_Bool_Exp>;
};

export type Query_RootGeometry_Columns_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Geometry_Columns_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Geometry_Columns_Order_By>>;
  where?: InputMaybe<Geometry_Columns_Bool_Exp>;
};

export type Query_RootGeopointArgs = {
  distinct_on?: InputMaybe<Array<Geopoint_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Geopoint_Order_By>>;
  where?: InputMaybe<Geopoint_Bool_Exp>;
};

export type Query_RootGeopoint_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Geopoint_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Geopoint_Order_By>>;
  where?: InputMaybe<Geopoint_Bool_Exp>;
};

export type Query_RootGeopoint_By_PkArgs = {
  id: Scalars["uuid"];
};

export type Query_RootInspectionArgs = {
  distinct_on?: InputMaybe<Array<Inspection_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Inspection_Order_By>>;
  where?: InputMaybe<Inspection_Bool_Exp>;
};

export type Query_RootInspection_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Inspection_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Inspection_Order_By>>;
  where?: InputMaybe<Inspection_Bool_Exp>;
};

export type Query_RootInspection_By_PkArgs = {
  id: Scalars["uuid"];
};

export type Query_RootObservationArgs = {
  distinct_on?: InputMaybe<Array<Observation_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Observation_Order_By>>;
  where?: InputMaybe<Observation_Bool_Exp>;
};

export type Query_RootObservation_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Observation_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Observation_Order_By>>;
  where?: InputMaybe<Observation_Bool_Exp>;
};

export type Query_RootObservation_By_PkArgs = {
  id: Scalars["uuid"];
};

export type Query_RootPlaceArgs = {
  distinct_on?: InputMaybe<Array<Place_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Place_Order_By>>;
  where?: InputMaybe<Place_Bool_Exp>;
};

export type Query_RootPlace_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Place_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Place_Order_By>>;
  where?: InputMaybe<Place_Bool_Exp>;
};

export type Query_RootPlace_By_PkArgs = {
  id: Scalars["uuid"];
};

export type Query_RootSpatial_Ref_SysArgs = {
  distinct_on?: InputMaybe<Array<Spatial_Ref_Sys_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Spatial_Ref_Sys_Order_By>>;
  where?: InputMaybe<Spatial_Ref_Sys_Bool_Exp>;
};

export type Query_RootSpatial_Ref_Sys_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Spatial_Ref_Sys_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Spatial_Ref_Sys_Order_By>>;
  where?: InputMaybe<Spatial_Ref_Sys_Bool_Exp>;
};

export type Query_RootSpatial_Ref_Sys_By_PkArgs = {
  srid: Scalars["Int"];
};

export type Query_RootSprayedArgs = {
  distinct_on?: InputMaybe<Array<Sprayed_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Sprayed_Order_By>>;
  where?: InputMaybe<Sprayed_Bool_Exp>;
};

export type Query_RootSprayed_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Sprayed_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Sprayed_Order_By>>;
  where?: InputMaybe<Sprayed_Bool_Exp>;
};

export type Query_RootSprayed_By_PkArgs = {
  id: Scalars["uuid"];
};

export type Query_RootSprayed_Residual_Treatment_KindArgs = {
  distinct_on?: InputMaybe<Array<Sprayed_Residual_Treatment_Kind_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Sprayed_Residual_Treatment_Kind_Order_By>>;
  where?: InputMaybe<Sprayed_Residual_Treatment_Kind_Bool_Exp>;
};

export type Query_RootSprayed_Residual_Treatment_Kind_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Sprayed_Residual_Treatment_Kind_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Sprayed_Residual_Treatment_Kind_Order_By>>;
  where?: InputMaybe<Sprayed_Residual_Treatment_Kind_Bool_Exp>;
};

export type Query_RootSprayed_Residual_Treatment_Kind_By_PkArgs = {
  value: Scalars["String"];
};

export type Query_RootSprayerArgs = {
  distinct_on?: InputMaybe<Array<Sprayer_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Sprayer_Order_By>>;
  where?: InputMaybe<Sprayer_Bool_Exp>;
};

export type Query_RootSprayer_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Sprayer_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Sprayer_Order_By>>;
  where?: InputMaybe<Sprayer_Bool_Exp>;
};

export type Query_RootSprayer_By_PkArgs = {
  id: Scalars["uuid"];
};

export type Query_RootWork_SessionsArgs = {
  distinct_on?: InputMaybe<Array<Work_Sessions_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Work_Sessions_Order_By>>;
  where?: InputMaybe<Work_Sessions_Bool_Exp>;
};

export type Query_RootWork_Sessions_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Work_Sessions_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Work_Sessions_Order_By>>;
  where?: InputMaybe<Work_Sessions_Bool_Exp>;
};

export type Query_RootWork_Sessions_By_PkArgs = {
  id: Scalars["uuid"];
};

export type Query_RootWorkspaceArgs = {
  distinct_on?: InputMaybe<Array<Workspace_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Workspace_Order_By>>;
  where?: InputMaybe<Workspace_Bool_Exp>;
};

export type Query_RootWorkspace_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Workspace_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Workspace_Order_By>>;
  where?: InputMaybe<Workspace_Bool_Exp>;
};

export type Query_RootWorkspace_By_PkArgs = {
  id: Scalars["uuid"];
};

export type Query_RootWorkspace_KindArgs = {
  distinct_on?: InputMaybe<Array<Workspace_Kind_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Workspace_Kind_Order_By>>;
  where?: InputMaybe<Workspace_Kind_Bool_Exp>;
};

export type Query_RootWorkspace_Kind_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Workspace_Kind_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Workspace_Kind_Order_By>>;
  where?: InputMaybe<Workspace_Kind_Bool_Exp>;
};

export type Query_RootWorkspace_Kind_By_PkArgs = {
  value: Scalars["String"];
};

export type Query_RootWorkspace_MetadataArgs = {
  distinct_on?: InputMaybe<Array<Workspace_Metadata_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Workspace_Metadata_Order_By>>;
  where?: InputMaybe<Workspace_Metadata_Bool_Exp>;
};

export type Query_RootWorkspace_Metadata_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Workspace_Metadata_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Workspace_Metadata_Order_By>>;
  where?: InputMaybe<Workspace_Metadata_Bool_Exp>;
};

export type Query_RootWorkspace_Metadata_By_PkArgs = {
  id: Scalars["uuid"];
};

export type Query_RootWorkspace_OwnersArgs = {
  distinct_on?: InputMaybe<Array<Workspace_Owners_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Workspace_Owners_Order_By>>;
  where?: InputMaybe<Workspace_Owners_Bool_Exp>;
};

export type Query_RootWorkspace_Owners_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Workspace_Owners_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Workspace_Owners_Order_By>>;
  where?: InputMaybe<Workspace_Owners_Bool_Exp>;
};

export type Query_RootWorkspace_Owners_By_PkArgs = {
  owner_id: Scalars["uuid"];
  workspace_id: Scalars["uuid"];
};

/** columns and relationships of "spatial_ref_sys" */
export type Spatial_Ref_Sys = {
  __typename?: "spatial_ref_sys";
  auth_name?: Maybe<Scalars["String"]>;
  auth_srid?: Maybe<Scalars["Int"]>;
  proj4text?: Maybe<Scalars["String"]>;
  srid: Scalars["Int"];
  srtext?: Maybe<Scalars["String"]>;
};

/** aggregated selection of "spatial_ref_sys" */
export type Spatial_Ref_Sys_Aggregate = {
  __typename?: "spatial_ref_sys_aggregate";
  aggregate?: Maybe<Spatial_Ref_Sys_Aggregate_Fields>;
  nodes: Array<Spatial_Ref_Sys>;
};

/** aggregate fields of "spatial_ref_sys" */
export type Spatial_Ref_Sys_Aggregate_Fields = {
  __typename?: "spatial_ref_sys_aggregate_fields";
  avg?: Maybe<Spatial_Ref_Sys_Avg_Fields>;
  count: Scalars["Int"];
  max?: Maybe<Spatial_Ref_Sys_Max_Fields>;
  min?: Maybe<Spatial_Ref_Sys_Min_Fields>;
  stddev?: Maybe<Spatial_Ref_Sys_Stddev_Fields>;
  stddev_pop?: Maybe<Spatial_Ref_Sys_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Spatial_Ref_Sys_Stddev_Samp_Fields>;
  sum?: Maybe<Spatial_Ref_Sys_Sum_Fields>;
  var_pop?: Maybe<Spatial_Ref_Sys_Var_Pop_Fields>;
  var_samp?: Maybe<Spatial_Ref_Sys_Var_Samp_Fields>;
  variance?: Maybe<Spatial_Ref_Sys_Variance_Fields>;
};

/** aggregate fields of "spatial_ref_sys" */
export type Spatial_Ref_Sys_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Spatial_Ref_Sys_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]>;
};

/** aggregate avg on columns */
export type Spatial_Ref_Sys_Avg_Fields = {
  __typename?: "spatial_ref_sys_avg_fields";
  auth_srid?: Maybe<Scalars["Float"]>;
  srid?: Maybe<Scalars["Float"]>;
};

/** Boolean expression to filter rows from the table "spatial_ref_sys". All fields are combined with a logical 'AND'. */
export type Spatial_Ref_Sys_Bool_Exp = {
  _and?: InputMaybe<Array<Spatial_Ref_Sys_Bool_Exp>>;
  _not?: InputMaybe<Spatial_Ref_Sys_Bool_Exp>;
  _or?: InputMaybe<Array<Spatial_Ref_Sys_Bool_Exp>>;
  auth_name?: InputMaybe<String_Comparison_Exp>;
  auth_srid?: InputMaybe<Int_Comparison_Exp>;
  proj4text?: InputMaybe<String_Comparison_Exp>;
  srid?: InputMaybe<Int_Comparison_Exp>;
  srtext?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "spatial_ref_sys" */
export enum Spatial_Ref_Sys_Constraint {
  /** unique or primary key constraint on columns "srid" */
  SpatialRefSysPkey = "spatial_ref_sys_pkey",
}

/** input type for incrementing numeric columns in table "spatial_ref_sys" */
export type Spatial_Ref_Sys_Inc_Input = {
  auth_srid?: InputMaybe<Scalars["Int"]>;
  srid?: InputMaybe<Scalars["Int"]>;
};

/** input type for inserting data into table "spatial_ref_sys" */
export type Spatial_Ref_Sys_Insert_Input = {
  auth_name?: InputMaybe<Scalars["String"]>;
  auth_srid?: InputMaybe<Scalars["Int"]>;
  proj4text?: InputMaybe<Scalars["String"]>;
  srid?: InputMaybe<Scalars["Int"]>;
  srtext?: InputMaybe<Scalars["String"]>;
};

/** aggregate max on columns */
export type Spatial_Ref_Sys_Max_Fields = {
  __typename?: "spatial_ref_sys_max_fields";
  auth_name?: Maybe<Scalars["String"]>;
  auth_srid?: Maybe<Scalars["Int"]>;
  proj4text?: Maybe<Scalars["String"]>;
  srid?: Maybe<Scalars["Int"]>;
  srtext?: Maybe<Scalars["String"]>;
};

/** aggregate min on columns */
export type Spatial_Ref_Sys_Min_Fields = {
  __typename?: "spatial_ref_sys_min_fields";
  auth_name?: Maybe<Scalars["String"]>;
  auth_srid?: Maybe<Scalars["Int"]>;
  proj4text?: Maybe<Scalars["String"]>;
  srid?: Maybe<Scalars["Int"]>;
  srtext?: Maybe<Scalars["String"]>;
};

/** response of any mutation on the table "spatial_ref_sys" */
export type Spatial_Ref_Sys_Mutation_Response = {
  __typename?: "spatial_ref_sys_mutation_response";
  /** number of rows affected by the mutation */
  affected_rows: Scalars["Int"];
  /** data from the rows affected by the mutation */
  returning: Array<Spatial_Ref_Sys>;
};

/** on_conflict condition type for table "spatial_ref_sys" */
export type Spatial_Ref_Sys_On_Conflict = {
  constraint: Spatial_Ref_Sys_Constraint;
  update_columns?: Array<Spatial_Ref_Sys_Update_Column>;
  where?: InputMaybe<Spatial_Ref_Sys_Bool_Exp>;
};

/** Ordering options when selecting data from "spatial_ref_sys". */
export type Spatial_Ref_Sys_Order_By = {
  auth_name?: InputMaybe<Order_By>;
  auth_srid?: InputMaybe<Order_By>;
  proj4text?: InputMaybe<Order_By>;
  srid?: InputMaybe<Order_By>;
  srtext?: InputMaybe<Order_By>;
};

/** primary key columns input for table: spatial_ref_sys */
export type Spatial_Ref_Sys_Pk_Columns_Input = {
  srid: Scalars["Int"];
};

/** select columns of table "spatial_ref_sys" */
export enum Spatial_Ref_Sys_Select_Column {
  /** column name */
  AuthName = "auth_name",
  /** column name */
  AuthSrid = "auth_srid",
  /** column name */
  Proj4text = "proj4text",
  /** column name */
  Srid = "srid",
  /** column name */
  Srtext = "srtext",
}

/** input type for updating data in table "spatial_ref_sys" */
export type Spatial_Ref_Sys_Set_Input = {
  auth_name?: InputMaybe<Scalars["String"]>;
  auth_srid?: InputMaybe<Scalars["Int"]>;
  proj4text?: InputMaybe<Scalars["String"]>;
  srid?: InputMaybe<Scalars["Int"]>;
  srtext?: InputMaybe<Scalars["String"]>;
};

/** aggregate stddev on columns */
export type Spatial_Ref_Sys_Stddev_Fields = {
  __typename?: "spatial_ref_sys_stddev_fields";
  auth_srid?: Maybe<Scalars["Float"]>;
  srid?: Maybe<Scalars["Float"]>;
};

/** aggregate stddev_pop on columns */
export type Spatial_Ref_Sys_Stddev_Pop_Fields = {
  __typename?: "spatial_ref_sys_stddev_pop_fields";
  auth_srid?: Maybe<Scalars["Float"]>;
  srid?: Maybe<Scalars["Float"]>;
};

/** aggregate stddev_samp on columns */
export type Spatial_Ref_Sys_Stddev_Samp_Fields = {
  __typename?: "spatial_ref_sys_stddev_samp_fields";
  auth_srid?: Maybe<Scalars["Float"]>;
  srid?: Maybe<Scalars["Float"]>;
};

/** Streaming cursor of the table "spatial_ref_sys" */
export type Spatial_Ref_Sys_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Spatial_Ref_Sys_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Spatial_Ref_Sys_Stream_Cursor_Value_Input = {
  auth_name?: InputMaybe<Scalars["String"]>;
  auth_srid?: InputMaybe<Scalars["Int"]>;
  proj4text?: InputMaybe<Scalars["String"]>;
  srid?: InputMaybe<Scalars["Int"]>;
  srtext?: InputMaybe<Scalars["String"]>;
};

/** aggregate sum on columns */
export type Spatial_Ref_Sys_Sum_Fields = {
  __typename?: "spatial_ref_sys_sum_fields";
  auth_srid?: Maybe<Scalars["Int"]>;
  srid?: Maybe<Scalars["Int"]>;
};

/** update columns of table "spatial_ref_sys" */
export enum Spatial_Ref_Sys_Update_Column {
  /** column name */
  AuthName = "auth_name",
  /** column name */
  AuthSrid = "auth_srid",
  /** column name */
  Proj4text = "proj4text",
  /** column name */
  Srid = "srid",
  /** column name */
  Srtext = "srtext",
}

export type Spatial_Ref_Sys_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Spatial_Ref_Sys_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Spatial_Ref_Sys_Set_Input>;
  /** filter the rows which have to be updated */
  where: Spatial_Ref_Sys_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Spatial_Ref_Sys_Var_Pop_Fields = {
  __typename?: "spatial_ref_sys_var_pop_fields";
  auth_srid?: Maybe<Scalars["Float"]>;
  srid?: Maybe<Scalars["Float"]>;
};

/** aggregate var_samp on columns */
export type Spatial_Ref_Sys_Var_Samp_Fields = {
  __typename?: "spatial_ref_sys_var_samp_fields";
  auth_srid?: Maybe<Scalars["Float"]>;
  srid?: Maybe<Scalars["Float"]>;
};

/** aggregate variance on columns */
export type Spatial_Ref_Sys_Variance_Fields = {
  __typename?: "spatial_ref_sys_variance_fields";
  auth_srid?: Maybe<Scalars["Float"]>;
  srid?: Maybe<Scalars["Float"]>;
};

/** columns and relationships of "sprayed" */
export type Sprayed = {
  __typename?: "sprayed";
  address_in_crack?: Maybe<Scalars["Int"]>;
  address_in_env?: Maybe<Scalars["Int"]>;
  address_in_env_close?: Maybe<Scalars["Int"]>;
  address_in_material?: Maybe<Scalars["String"]>;
  address_out_crack?: Maybe<Scalars["Int"]>;
  address_out_env?: Maybe<Scalars["Int"]>;
  address_out_env_close?: Maybe<Scalars["Int"]>;
  address_out_material?: Maybe<Scalars["String"]>;
  address_out_triatomines?: Maybe<Scalars["Int"]>;
  address_triatomines?: Maybe<Scalars["Int"]>;
  app_state: Scalars["String"];
  clean_area?: Maybe<Scalars["Int"]>;
  collaborator_id: Scalars["uuid"];
  complaint_id?: Maybe<Scalars["uuid"]>;
  comprehensive_treatment_metadata?: Maybe<Scalars["jsonb"]>;
  created_at: Scalars["timestamptz"];
  experiment_id?: Maybe<Scalars["uuid"]>;
  family_chief?: Maybe<Scalars["String"]>;
  family_size?: Maybe<Scalars["Int"]>;
  firebase_id?: Maybe<Scalars["String"]>;
  from_radio?: Maybe<Scalars["Boolean"]>;
  geogroup_id?: Maybe<Scalars["uuid"]>;
  geopoint_id: Scalars["uuid"];
  geopoint_unicode?: Maybe<Scalars["String"]>;
  house_bird?: Maybe<Scalars["Int"]>;
  house_cat?: Maybe<Scalars["Int"]>;
  house_cuy?: Maybe<Scalars["Int"]>;
  house_dog?: Maybe<Scalars["Int"]>;
  house_other_animal_1_name?: Maybe<Scalars["String"]>;
  house_other_animal_1_number?: Maybe<Scalars["Int"]>;
  house_other_animal_2_name?: Maybe<Scalars["String"]>;
  house_other_animal_2_number?: Maybe<Scalars["Int"]>;
  house_other_animal_3_name?: Maybe<Scalars["String"]>;
  house_other_animal_3_number?: Maybe<Scalars["Int"]>;
  house_other_animal_4_name?: Maybe<Scalars["String"]>;
  house_other_animal_4_number?: Maybe<Scalars["Int"]>;
  house_other_animal_5_name?: Maybe<Scalars["String"]>;
  house_other_animal_5_number?: Maybe<Scalars["Int"]>;
  house_other_animal_name?: Maybe<Scalars["String"]>;
  house_other_animal_number?: Maybe<Scalars["Int"]>;
  house_rabbit?: Maybe<Scalars["Int"]>;
  id: Scalars["uuid"];
  insecticide?: Maybe<Scalars["String"]>;
  origin_from_complaint?: Maybe<Scalars["String"]>;
  reported_at?: Maybe<Scalars["timestamptz"]>;
  residual_treatment_kind?: Maybe<Scalars["String"]>;
  roof_other_animal_1_name?: Maybe<Scalars["String"]>;
  roof_other_animal_1_number?: Maybe<Scalars["Int"]>;
  roof_other_animal_2_name?: Maybe<Scalars["String"]>;
  roof_other_animal_2_number?: Maybe<Scalars["Int"]>;
  roof_other_animal_3_name?: Maybe<Scalars["String"]>;
  roof_other_animal_3_number?: Maybe<Scalars["Int"]>;
  roof_other_animal_4_name?: Maybe<Scalars["String"]>;
  roof_other_animal_4_number?: Maybe<Scalars["Int"]>;
  roof_other_animal_5_name?: Maybe<Scalars["String"]>;
  roof_other_animal_5_number?: Maybe<Scalars["Int"]>;
  roof_other_animal_name?: Maybe<Scalars["String"]>;
  roof_other_animal_number?: Maybe<Scalars["Int"]>;
  roof_top_bird?: Maybe<Scalars["Int"]>;
  roof_top_cat?: Maybe<Scalars["Int"]>;
  roof_top_cuy?: Maybe<Scalars["Int"]>;
  roof_top_dog?: Maybe<Scalars["Int"]>;
  roof_top_rabbit?: Maybe<Scalars["Int"]>;
  spent_insecticides?: Maybe<Scalars["Int"]>;
  sprayed_intra_event?: Maybe<Scalars["jsonb"]>;
  sprayed_peri_event?: Maybe<Scalars["jsonb"]>;
  /** An object relationship */
  sprayed_residual_treatment_kind?: Maybe<Sprayed_Residual_Treatment_Kind>;
  sprayer_id?: Maybe<Scalars["uuid"]>;
  sprayer_name?: Maybe<Scalars["String"]>;
  unicode_from_radio?: Maybe<Scalars["String"]>;
  updated_at: Scalars["timestamptz"];
  /** An object relationship */
  workspace?: Maybe<Workspace>;
  workspace_id?: Maybe<Scalars["uuid"]>;
  workspace_metadata_id?: Maybe<Scalars["uuid"]>;
  /** An object relationship */
  workspace_metadatum?: Maybe<Workspace_Metadata>;
  yard_bird?: Maybe<Scalars["Int"]>;
  yard_cat?: Maybe<Scalars["Int"]>;
  yard_cuy?: Maybe<Scalars["Int"]>;
  yard_dog?: Maybe<Scalars["Int"]>;
  yard_other_animal_1_name?: Maybe<Scalars["String"]>;
  yard_other_animal_1_number?: Maybe<Scalars["Int"]>;
  yard_other_animal_2_name?: Maybe<Scalars["String"]>;
  yard_other_animal_2_number?: Maybe<Scalars["Int"]>;
  yard_other_animal_3_name?: Maybe<Scalars["String"]>;
  yard_other_animal_3_number?: Maybe<Scalars["Int"]>;
  yard_other_animal_4_name?: Maybe<Scalars["String"]>;
  yard_other_animal_4_number?: Maybe<Scalars["Int"]>;
  yard_other_animal_5_name?: Maybe<Scalars["String"]>;
  yard_other_animal_5_number?: Maybe<Scalars["Int"]>;
  yard_other_animal_name?: Maybe<Scalars["String"]>;
  yard_other_animal_number?: Maybe<Scalars["Int"]>;
  yard_rabbit?: Maybe<Scalars["Int"]>;
};

/** columns and relationships of "sprayed" */
export type SprayedComprehensive_Treatment_MetadataArgs = {
  path?: InputMaybe<Scalars["String"]>;
};

/** columns and relationships of "sprayed" */
export type SprayedSprayed_Intra_EventArgs = {
  path?: InputMaybe<Scalars["String"]>;
};

/** columns and relationships of "sprayed" */
export type SprayedSprayed_Peri_EventArgs = {
  path?: InputMaybe<Scalars["String"]>;
};

/** aggregated selection of "sprayed" */
export type Sprayed_Aggregate = {
  __typename?: "sprayed_aggregate";
  aggregate?: Maybe<Sprayed_Aggregate_Fields>;
  nodes: Array<Sprayed>;
};

export type Sprayed_Aggregate_Bool_Exp = {
  bool_and?: InputMaybe<Sprayed_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: InputMaybe<Sprayed_Aggregate_Bool_Exp_Bool_Or>;
  count?: InputMaybe<Sprayed_Aggregate_Bool_Exp_Count>;
};

export type Sprayed_Aggregate_Bool_Exp_Bool_And = {
  arguments: Sprayed_Select_Column_Sprayed_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: InputMaybe<Scalars["Boolean"]>;
  filter?: InputMaybe<Sprayed_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Sprayed_Aggregate_Bool_Exp_Bool_Or = {
  arguments: Sprayed_Select_Column_Sprayed_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: InputMaybe<Scalars["Boolean"]>;
  filter?: InputMaybe<Sprayed_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Sprayed_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Sprayed_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]>;
  filter?: InputMaybe<Sprayed_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "sprayed" */
export type Sprayed_Aggregate_Fields = {
  __typename?: "sprayed_aggregate_fields";
  avg?: Maybe<Sprayed_Avg_Fields>;
  count: Scalars["Int"];
  max?: Maybe<Sprayed_Max_Fields>;
  min?: Maybe<Sprayed_Min_Fields>;
  stddev?: Maybe<Sprayed_Stddev_Fields>;
  stddev_pop?: Maybe<Sprayed_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Sprayed_Stddev_Samp_Fields>;
  sum?: Maybe<Sprayed_Sum_Fields>;
  var_pop?: Maybe<Sprayed_Var_Pop_Fields>;
  var_samp?: Maybe<Sprayed_Var_Samp_Fields>;
  variance?: Maybe<Sprayed_Variance_Fields>;
};

/** aggregate fields of "sprayed" */
export type Sprayed_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Sprayed_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "sprayed" */
export type Sprayed_Aggregate_Order_By = {
  avg?: InputMaybe<Sprayed_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Sprayed_Max_Order_By>;
  min?: InputMaybe<Sprayed_Min_Order_By>;
  stddev?: InputMaybe<Sprayed_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Sprayed_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Sprayed_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Sprayed_Sum_Order_By>;
  var_pop?: InputMaybe<Sprayed_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Sprayed_Var_Samp_Order_By>;
  variance?: InputMaybe<Sprayed_Variance_Order_By>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Sprayed_Append_Input = {
  comprehensive_treatment_metadata?: InputMaybe<Scalars["jsonb"]>;
  sprayed_intra_event?: InputMaybe<Scalars["jsonb"]>;
  sprayed_peri_event?: InputMaybe<Scalars["jsonb"]>;
};

/** input type for inserting array relation for remote table "sprayed" */
export type Sprayed_Arr_Rel_Insert_Input = {
  data: Array<Sprayed_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Sprayed_On_Conflict>;
};

/** aggregate avg on columns */
export type Sprayed_Avg_Fields = {
  __typename?: "sprayed_avg_fields";
  address_in_crack?: Maybe<Scalars["Float"]>;
  address_in_env?: Maybe<Scalars["Float"]>;
  address_in_env_close?: Maybe<Scalars["Float"]>;
  address_out_crack?: Maybe<Scalars["Float"]>;
  address_out_env?: Maybe<Scalars["Float"]>;
  address_out_env_close?: Maybe<Scalars["Float"]>;
  address_out_triatomines?: Maybe<Scalars["Float"]>;
  address_triatomines?: Maybe<Scalars["Float"]>;
  clean_area?: Maybe<Scalars["Float"]>;
  family_size?: Maybe<Scalars["Float"]>;
  house_bird?: Maybe<Scalars["Float"]>;
  house_cat?: Maybe<Scalars["Float"]>;
  house_cuy?: Maybe<Scalars["Float"]>;
  house_dog?: Maybe<Scalars["Float"]>;
  house_other_animal_1_number?: Maybe<Scalars["Float"]>;
  house_other_animal_2_number?: Maybe<Scalars["Float"]>;
  house_other_animal_3_number?: Maybe<Scalars["Float"]>;
  house_other_animal_4_number?: Maybe<Scalars["Float"]>;
  house_other_animal_5_number?: Maybe<Scalars["Float"]>;
  house_other_animal_number?: Maybe<Scalars["Float"]>;
  house_rabbit?: Maybe<Scalars["Float"]>;
  roof_other_animal_1_number?: Maybe<Scalars["Float"]>;
  roof_other_animal_2_number?: Maybe<Scalars["Float"]>;
  roof_other_animal_3_number?: Maybe<Scalars["Float"]>;
  roof_other_animal_4_number?: Maybe<Scalars["Float"]>;
  roof_other_animal_5_number?: Maybe<Scalars["Float"]>;
  roof_other_animal_number?: Maybe<Scalars["Float"]>;
  roof_top_bird?: Maybe<Scalars["Float"]>;
  roof_top_cat?: Maybe<Scalars["Float"]>;
  roof_top_cuy?: Maybe<Scalars["Float"]>;
  roof_top_dog?: Maybe<Scalars["Float"]>;
  roof_top_rabbit?: Maybe<Scalars["Float"]>;
  spent_insecticides?: Maybe<Scalars["Float"]>;
  yard_bird?: Maybe<Scalars["Float"]>;
  yard_cat?: Maybe<Scalars["Float"]>;
  yard_cuy?: Maybe<Scalars["Float"]>;
  yard_dog?: Maybe<Scalars["Float"]>;
  yard_other_animal_1_number?: Maybe<Scalars["Float"]>;
  yard_other_animal_2_number?: Maybe<Scalars["Float"]>;
  yard_other_animal_3_number?: Maybe<Scalars["Float"]>;
  yard_other_animal_4_number?: Maybe<Scalars["Float"]>;
  yard_other_animal_5_number?: Maybe<Scalars["Float"]>;
  yard_other_animal_number?: Maybe<Scalars["Float"]>;
  yard_rabbit?: Maybe<Scalars["Float"]>;
};

/** order by avg() on columns of table "sprayed" */
export type Sprayed_Avg_Order_By = {
  address_in_crack?: InputMaybe<Order_By>;
  address_in_env?: InputMaybe<Order_By>;
  address_in_env_close?: InputMaybe<Order_By>;
  address_out_crack?: InputMaybe<Order_By>;
  address_out_env?: InputMaybe<Order_By>;
  address_out_env_close?: InputMaybe<Order_By>;
  address_out_triatomines?: InputMaybe<Order_By>;
  address_triatomines?: InputMaybe<Order_By>;
  clean_area?: InputMaybe<Order_By>;
  family_size?: InputMaybe<Order_By>;
  house_bird?: InputMaybe<Order_By>;
  house_cat?: InputMaybe<Order_By>;
  house_cuy?: InputMaybe<Order_By>;
  house_dog?: InputMaybe<Order_By>;
  house_other_animal_1_number?: InputMaybe<Order_By>;
  house_other_animal_2_number?: InputMaybe<Order_By>;
  house_other_animal_3_number?: InputMaybe<Order_By>;
  house_other_animal_4_number?: InputMaybe<Order_By>;
  house_other_animal_5_number?: InputMaybe<Order_By>;
  house_other_animal_number?: InputMaybe<Order_By>;
  house_rabbit?: InputMaybe<Order_By>;
  roof_other_animal_1_number?: InputMaybe<Order_By>;
  roof_other_animal_2_number?: InputMaybe<Order_By>;
  roof_other_animal_3_number?: InputMaybe<Order_By>;
  roof_other_animal_4_number?: InputMaybe<Order_By>;
  roof_other_animal_5_number?: InputMaybe<Order_By>;
  roof_other_animal_number?: InputMaybe<Order_By>;
  roof_top_bird?: InputMaybe<Order_By>;
  roof_top_cat?: InputMaybe<Order_By>;
  roof_top_cuy?: InputMaybe<Order_By>;
  roof_top_dog?: InputMaybe<Order_By>;
  roof_top_rabbit?: InputMaybe<Order_By>;
  spent_insecticides?: InputMaybe<Order_By>;
  yard_bird?: InputMaybe<Order_By>;
  yard_cat?: InputMaybe<Order_By>;
  yard_cuy?: InputMaybe<Order_By>;
  yard_dog?: InputMaybe<Order_By>;
  yard_other_animal_1_number?: InputMaybe<Order_By>;
  yard_other_animal_2_number?: InputMaybe<Order_By>;
  yard_other_animal_3_number?: InputMaybe<Order_By>;
  yard_other_animal_4_number?: InputMaybe<Order_By>;
  yard_other_animal_5_number?: InputMaybe<Order_By>;
  yard_other_animal_number?: InputMaybe<Order_By>;
  yard_rabbit?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "sprayed". All fields are combined with a logical 'AND'. */
export type Sprayed_Bool_Exp = {
  _and?: InputMaybe<Array<Sprayed_Bool_Exp>>;
  _not?: InputMaybe<Sprayed_Bool_Exp>;
  _or?: InputMaybe<Array<Sprayed_Bool_Exp>>;
  address_in_crack?: InputMaybe<Int_Comparison_Exp>;
  address_in_env?: InputMaybe<Int_Comparison_Exp>;
  address_in_env_close?: InputMaybe<Int_Comparison_Exp>;
  address_in_material?: InputMaybe<String_Comparison_Exp>;
  address_out_crack?: InputMaybe<Int_Comparison_Exp>;
  address_out_env?: InputMaybe<Int_Comparison_Exp>;
  address_out_env_close?: InputMaybe<Int_Comparison_Exp>;
  address_out_material?: InputMaybe<String_Comparison_Exp>;
  address_out_triatomines?: InputMaybe<Int_Comparison_Exp>;
  address_triatomines?: InputMaybe<Int_Comparison_Exp>;
  app_state?: InputMaybe<String_Comparison_Exp>;
  clean_area?: InputMaybe<Int_Comparison_Exp>;
  collaborator_id?: InputMaybe<Uuid_Comparison_Exp>;
  complaint_id?: InputMaybe<Uuid_Comparison_Exp>;
  comprehensive_treatment_metadata?: InputMaybe<Jsonb_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  experiment_id?: InputMaybe<Uuid_Comparison_Exp>;
  family_chief?: InputMaybe<String_Comparison_Exp>;
  family_size?: InputMaybe<Int_Comparison_Exp>;
  firebase_id?: InputMaybe<String_Comparison_Exp>;
  from_radio?: InputMaybe<Boolean_Comparison_Exp>;
  geogroup_id?: InputMaybe<Uuid_Comparison_Exp>;
  geopoint_id?: InputMaybe<Uuid_Comparison_Exp>;
  geopoint_unicode?: InputMaybe<String_Comparison_Exp>;
  house_bird?: InputMaybe<Int_Comparison_Exp>;
  house_cat?: InputMaybe<Int_Comparison_Exp>;
  house_cuy?: InputMaybe<Int_Comparison_Exp>;
  house_dog?: InputMaybe<Int_Comparison_Exp>;
  house_other_animal_1_name?: InputMaybe<String_Comparison_Exp>;
  house_other_animal_1_number?: InputMaybe<Int_Comparison_Exp>;
  house_other_animal_2_name?: InputMaybe<String_Comparison_Exp>;
  house_other_animal_2_number?: InputMaybe<Int_Comparison_Exp>;
  house_other_animal_3_name?: InputMaybe<String_Comparison_Exp>;
  house_other_animal_3_number?: InputMaybe<Int_Comparison_Exp>;
  house_other_animal_4_name?: InputMaybe<String_Comparison_Exp>;
  house_other_animal_4_number?: InputMaybe<Int_Comparison_Exp>;
  house_other_animal_5_name?: InputMaybe<String_Comparison_Exp>;
  house_other_animal_5_number?: InputMaybe<Int_Comparison_Exp>;
  house_other_animal_name?: InputMaybe<String_Comparison_Exp>;
  house_other_animal_number?: InputMaybe<Int_Comparison_Exp>;
  house_rabbit?: InputMaybe<Int_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  insecticide?: InputMaybe<String_Comparison_Exp>;
  origin_from_complaint?: InputMaybe<String_Comparison_Exp>;
  reported_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  residual_treatment_kind?: InputMaybe<String_Comparison_Exp>;
  roof_other_animal_1_name?: InputMaybe<String_Comparison_Exp>;
  roof_other_animal_1_number?: InputMaybe<Int_Comparison_Exp>;
  roof_other_animal_2_name?: InputMaybe<String_Comparison_Exp>;
  roof_other_animal_2_number?: InputMaybe<Int_Comparison_Exp>;
  roof_other_animal_3_name?: InputMaybe<String_Comparison_Exp>;
  roof_other_animal_3_number?: InputMaybe<Int_Comparison_Exp>;
  roof_other_animal_4_name?: InputMaybe<String_Comparison_Exp>;
  roof_other_animal_4_number?: InputMaybe<Int_Comparison_Exp>;
  roof_other_animal_5_name?: InputMaybe<String_Comparison_Exp>;
  roof_other_animal_5_number?: InputMaybe<Int_Comparison_Exp>;
  roof_other_animal_name?: InputMaybe<String_Comparison_Exp>;
  roof_other_animal_number?: InputMaybe<Int_Comparison_Exp>;
  roof_top_bird?: InputMaybe<Int_Comparison_Exp>;
  roof_top_cat?: InputMaybe<Int_Comparison_Exp>;
  roof_top_cuy?: InputMaybe<Int_Comparison_Exp>;
  roof_top_dog?: InputMaybe<Int_Comparison_Exp>;
  roof_top_rabbit?: InputMaybe<Int_Comparison_Exp>;
  spent_insecticides?: InputMaybe<Int_Comparison_Exp>;
  sprayed_intra_event?: InputMaybe<Jsonb_Comparison_Exp>;
  sprayed_peri_event?: InputMaybe<Jsonb_Comparison_Exp>;
  sprayed_residual_treatment_kind?: InputMaybe<Sprayed_Residual_Treatment_Kind_Bool_Exp>;
  sprayer_id?: InputMaybe<Uuid_Comparison_Exp>;
  sprayer_name?: InputMaybe<String_Comparison_Exp>;
  unicode_from_radio?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  workspace?: InputMaybe<Workspace_Bool_Exp>;
  workspace_id?: InputMaybe<Uuid_Comparison_Exp>;
  workspace_metadata_id?: InputMaybe<Uuid_Comparison_Exp>;
  workspace_metadatum?: InputMaybe<Workspace_Metadata_Bool_Exp>;
  yard_bird?: InputMaybe<Int_Comparison_Exp>;
  yard_cat?: InputMaybe<Int_Comparison_Exp>;
  yard_cuy?: InputMaybe<Int_Comparison_Exp>;
  yard_dog?: InputMaybe<Int_Comparison_Exp>;
  yard_other_animal_1_name?: InputMaybe<String_Comparison_Exp>;
  yard_other_animal_1_number?: InputMaybe<Int_Comparison_Exp>;
  yard_other_animal_2_name?: InputMaybe<String_Comparison_Exp>;
  yard_other_animal_2_number?: InputMaybe<Int_Comparison_Exp>;
  yard_other_animal_3_name?: InputMaybe<String_Comparison_Exp>;
  yard_other_animal_3_number?: InputMaybe<Int_Comparison_Exp>;
  yard_other_animal_4_name?: InputMaybe<String_Comparison_Exp>;
  yard_other_animal_4_number?: InputMaybe<Int_Comparison_Exp>;
  yard_other_animal_5_name?: InputMaybe<String_Comparison_Exp>;
  yard_other_animal_5_number?: InputMaybe<Int_Comparison_Exp>;
  yard_other_animal_name?: InputMaybe<String_Comparison_Exp>;
  yard_other_animal_number?: InputMaybe<Int_Comparison_Exp>;
  yard_rabbit?: InputMaybe<Int_Comparison_Exp>;
};

/** unique or primary key constraints on table "sprayed" */
export enum Sprayed_Constraint {
  /** unique or primary key constraint on columns "id" */
  SpreadPkey = "spread_pkey",
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Sprayed_Delete_At_Path_Input = {
  comprehensive_treatment_metadata?: InputMaybe<Array<Scalars["String"]>>;
  sprayed_intra_event?: InputMaybe<Array<Scalars["String"]>>;
  sprayed_peri_event?: InputMaybe<Array<Scalars["String"]>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Sprayed_Delete_Elem_Input = {
  comprehensive_treatment_metadata?: InputMaybe<Scalars["Int"]>;
  sprayed_intra_event?: InputMaybe<Scalars["Int"]>;
  sprayed_peri_event?: InputMaybe<Scalars["Int"]>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Sprayed_Delete_Key_Input = {
  comprehensive_treatment_metadata?: InputMaybe<Scalars["String"]>;
  sprayed_intra_event?: InputMaybe<Scalars["String"]>;
  sprayed_peri_event?: InputMaybe<Scalars["String"]>;
};

/** input type for incrementing numeric columns in table "sprayed" */
export type Sprayed_Inc_Input = {
  address_in_crack?: InputMaybe<Scalars["Int"]>;
  address_in_env?: InputMaybe<Scalars["Int"]>;
  address_in_env_close?: InputMaybe<Scalars["Int"]>;
  address_out_crack?: InputMaybe<Scalars["Int"]>;
  address_out_env?: InputMaybe<Scalars["Int"]>;
  address_out_env_close?: InputMaybe<Scalars["Int"]>;
  address_out_triatomines?: InputMaybe<Scalars["Int"]>;
  address_triatomines?: InputMaybe<Scalars["Int"]>;
  clean_area?: InputMaybe<Scalars["Int"]>;
  family_size?: InputMaybe<Scalars["Int"]>;
  house_bird?: InputMaybe<Scalars["Int"]>;
  house_cat?: InputMaybe<Scalars["Int"]>;
  house_cuy?: InputMaybe<Scalars["Int"]>;
  house_dog?: InputMaybe<Scalars["Int"]>;
  house_other_animal_1_number?: InputMaybe<Scalars["Int"]>;
  house_other_animal_2_number?: InputMaybe<Scalars["Int"]>;
  house_other_animal_3_number?: InputMaybe<Scalars["Int"]>;
  house_other_animal_4_number?: InputMaybe<Scalars["Int"]>;
  house_other_animal_5_number?: InputMaybe<Scalars["Int"]>;
  house_other_animal_number?: InputMaybe<Scalars["Int"]>;
  house_rabbit?: InputMaybe<Scalars["Int"]>;
  roof_other_animal_1_number?: InputMaybe<Scalars["Int"]>;
  roof_other_animal_2_number?: InputMaybe<Scalars["Int"]>;
  roof_other_animal_3_number?: InputMaybe<Scalars["Int"]>;
  roof_other_animal_4_number?: InputMaybe<Scalars["Int"]>;
  roof_other_animal_5_number?: InputMaybe<Scalars["Int"]>;
  roof_other_animal_number?: InputMaybe<Scalars["Int"]>;
  roof_top_bird?: InputMaybe<Scalars["Int"]>;
  roof_top_cat?: InputMaybe<Scalars["Int"]>;
  roof_top_cuy?: InputMaybe<Scalars["Int"]>;
  roof_top_dog?: InputMaybe<Scalars["Int"]>;
  roof_top_rabbit?: InputMaybe<Scalars["Int"]>;
  spent_insecticides?: InputMaybe<Scalars["Int"]>;
  yard_bird?: InputMaybe<Scalars["Int"]>;
  yard_cat?: InputMaybe<Scalars["Int"]>;
  yard_cuy?: InputMaybe<Scalars["Int"]>;
  yard_dog?: InputMaybe<Scalars["Int"]>;
  yard_other_animal_1_number?: InputMaybe<Scalars["Int"]>;
  yard_other_animal_2_number?: InputMaybe<Scalars["Int"]>;
  yard_other_animal_3_number?: InputMaybe<Scalars["Int"]>;
  yard_other_animal_4_number?: InputMaybe<Scalars["Int"]>;
  yard_other_animal_5_number?: InputMaybe<Scalars["Int"]>;
  yard_other_animal_number?: InputMaybe<Scalars["Int"]>;
  yard_rabbit?: InputMaybe<Scalars["Int"]>;
};

/** input type for inserting data into table "sprayed" */
export type Sprayed_Insert_Input = {
  address_in_crack?: InputMaybe<Scalars["Int"]>;
  address_in_env?: InputMaybe<Scalars["Int"]>;
  address_in_env_close?: InputMaybe<Scalars["Int"]>;
  address_in_material?: InputMaybe<Scalars["String"]>;
  address_out_crack?: InputMaybe<Scalars["Int"]>;
  address_out_env?: InputMaybe<Scalars["Int"]>;
  address_out_env_close?: InputMaybe<Scalars["Int"]>;
  address_out_material?: InputMaybe<Scalars["String"]>;
  address_out_triatomines?: InputMaybe<Scalars["Int"]>;
  address_triatomines?: InputMaybe<Scalars["Int"]>;
  app_state?: InputMaybe<Scalars["String"]>;
  clean_area?: InputMaybe<Scalars["Int"]>;
  collaborator_id?: InputMaybe<Scalars["uuid"]>;
  complaint_id?: InputMaybe<Scalars["uuid"]>;
  comprehensive_treatment_metadata?: InputMaybe<Scalars["jsonb"]>;
  created_at?: InputMaybe<Scalars["timestamptz"]>;
  experiment_id?: InputMaybe<Scalars["uuid"]>;
  family_chief?: InputMaybe<Scalars["String"]>;
  family_size?: InputMaybe<Scalars["Int"]>;
  firebase_id?: InputMaybe<Scalars["String"]>;
  from_radio?: InputMaybe<Scalars["Boolean"]>;
  geogroup_id?: InputMaybe<Scalars["uuid"]>;
  geopoint_id?: InputMaybe<Scalars["uuid"]>;
  geopoint_unicode?: InputMaybe<Scalars["String"]>;
  house_bird?: InputMaybe<Scalars["Int"]>;
  house_cat?: InputMaybe<Scalars["Int"]>;
  house_cuy?: InputMaybe<Scalars["Int"]>;
  house_dog?: InputMaybe<Scalars["Int"]>;
  house_other_animal_1_name?: InputMaybe<Scalars["String"]>;
  house_other_animal_1_number?: InputMaybe<Scalars["Int"]>;
  house_other_animal_2_name?: InputMaybe<Scalars["String"]>;
  house_other_animal_2_number?: InputMaybe<Scalars["Int"]>;
  house_other_animal_3_name?: InputMaybe<Scalars["String"]>;
  house_other_animal_3_number?: InputMaybe<Scalars["Int"]>;
  house_other_animal_4_name?: InputMaybe<Scalars["String"]>;
  house_other_animal_4_number?: InputMaybe<Scalars["Int"]>;
  house_other_animal_5_name?: InputMaybe<Scalars["String"]>;
  house_other_animal_5_number?: InputMaybe<Scalars["Int"]>;
  house_other_animal_name?: InputMaybe<Scalars["String"]>;
  house_other_animal_number?: InputMaybe<Scalars["Int"]>;
  house_rabbit?: InputMaybe<Scalars["Int"]>;
  id?: InputMaybe<Scalars["uuid"]>;
  insecticide?: InputMaybe<Scalars["String"]>;
  origin_from_complaint?: InputMaybe<Scalars["String"]>;
  reported_at?: InputMaybe<Scalars["timestamptz"]>;
  residual_treatment_kind?: InputMaybe<Scalars["String"]>;
  roof_other_animal_1_name?: InputMaybe<Scalars["String"]>;
  roof_other_animal_1_number?: InputMaybe<Scalars["Int"]>;
  roof_other_animal_2_name?: InputMaybe<Scalars["String"]>;
  roof_other_animal_2_number?: InputMaybe<Scalars["Int"]>;
  roof_other_animal_3_name?: InputMaybe<Scalars["String"]>;
  roof_other_animal_3_number?: InputMaybe<Scalars["Int"]>;
  roof_other_animal_4_name?: InputMaybe<Scalars["String"]>;
  roof_other_animal_4_number?: InputMaybe<Scalars["Int"]>;
  roof_other_animal_5_name?: InputMaybe<Scalars["String"]>;
  roof_other_animal_5_number?: InputMaybe<Scalars["Int"]>;
  roof_other_animal_name?: InputMaybe<Scalars["String"]>;
  roof_other_animal_number?: InputMaybe<Scalars["Int"]>;
  roof_top_bird?: InputMaybe<Scalars["Int"]>;
  roof_top_cat?: InputMaybe<Scalars["Int"]>;
  roof_top_cuy?: InputMaybe<Scalars["Int"]>;
  roof_top_dog?: InputMaybe<Scalars["Int"]>;
  roof_top_rabbit?: InputMaybe<Scalars["Int"]>;
  spent_insecticides?: InputMaybe<Scalars["Int"]>;
  sprayed_intra_event?: InputMaybe<Scalars["jsonb"]>;
  sprayed_peri_event?: InputMaybe<Scalars["jsonb"]>;
  sprayed_residual_treatment_kind?: InputMaybe<Sprayed_Residual_Treatment_Kind_Obj_Rel_Insert_Input>;
  sprayer_id?: InputMaybe<Scalars["uuid"]>;
  sprayer_name?: InputMaybe<Scalars["String"]>;
  unicode_from_radio?: InputMaybe<Scalars["String"]>;
  updated_at?: InputMaybe<Scalars["timestamptz"]>;
  workspace?: InputMaybe<Workspace_Obj_Rel_Insert_Input>;
  workspace_id?: InputMaybe<Scalars["uuid"]>;
  workspace_metadata_id?: InputMaybe<Scalars["uuid"]>;
  workspace_metadatum?: InputMaybe<Workspace_Metadata_Obj_Rel_Insert_Input>;
  yard_bird?: InputMaybe<Scalars["Int"]>;
  yard_cat?: InputMaybe<Scalars["Int"]>;
  yard_cuy?: InputMaybe<Scalars["Int"]>;
  yard_dog?: InputMaybe<Scalars["Int"]>;
  yard_other_animal_1_name?: InputMaybe<Scalars["String"]>;
  yard_other_animal_1_number?: InputMaybe<Scalars["Int"]>;
  yard_other_animal_2_name?: InputMaybe<Scalars["String"]>;
  yard_other_animal_2_number?: InputMaybe<Scalars["Int"]>;
  yard_other_animal_3_name?: InputMaybe<Scalars["String"]>;
  yard_other_animal_3_number?: InputMaybe<Scalars["Int"]>;
  yard_other_animal_4_name?: InputMaybe<Scalars["String"]>;
  yard_other_animal_4_number?: InputMaybe<Scalars["Int"]>;
  yard_other_animal_5_name?: InputMaybe<Scalars["String"]>;
  yard_other_animal_5_number?: InputMaybe<Scalars["Int"]>;
  yard_other_animal_name?: InputMaybe<Scalars["String"]>;
  yard_other_animal_number?: InputMaybe<Scalars["Int"]>;
  yard_rabbit?: InputMaybe<Scalars["Int"]>;
};

/** aggregate max on columns */
export type Sprayed_Max_Fields = {
  __typename?: "sprayed_max_fields";
  address_in_crack?: Maybe<Scalars["Int"]>;
  address_in_env?: Maybe<Scalars["Int"]>;
  address_in_env_close?: Maybe<Scalars["Int"]>;
  address_in_material?: Maybe<Scalars["String"]>;
  address_out_crack?: Maybe<Scalars["Int"]>;
  address_out_env?: Maybe<Scalars["Int"]>;
  address_out_env_close?: Maybe<Scalars["Int"]>;
  address_out_material?: Maybe<Scalars["String"]>;
  address_out_triatomines?: Maybe<Scalars["Int"]>;
  address_triatomines?: Maybe<Scalars["Int"]>;
  app_state?: Maybe<Scalars["String"]>;
  clean_area?: Maybe<Scalars["Int"]>;
  collaborator_id?: Maybe<Scalars["uuid"]>;
  complaint_id?: Maybe<Scalars["uuid"]>;
  created_at?: Maybe<Scalars["timestamptz"]>;
  experiment_id?: Maybe<Scalars["uuid"]>;
  family_chief?: Maybe<Scalars["String"]>;
  family_size?: Maybe<Scalars["Int"]>;
  firebase_id?: Maybe<Scalars["String"]>;
  geogroup_id?: Maybe<Scalars["uuid"]>;
  geopoint_id?: Maybe<Scalars["uuid"]>;
  geopoint_unicode?: Maybe<Scalars["String"]>;
  house_bird?: Maybe<Scalars["Int"]>;
  house_cat?: Maybe<Scalars["Int"]>;
  house_cuy?: Maybe<Scalars["Int"]>;
  house_dog?: Maybe<Scalars["Int"]>;
  house_other_animal_1_name?: Maybe<Scalars["String"]>;
  house_other_animal_1_number?: Maybe<Scalars["Int"]>;
  house_other_animal_2_name?: Maybe<Scalars["String"]>;
  house_other_animal_2_number?: Maybe<Scalars["Int"]>;
  house_other_animal_3_name?: Maybe<Scalars["String"]>;
  house_other_animal_3_number?: Maybe<Scalars["Int"]>;
  house_other_animal_4_name?: Maybe<Scalars["String"]>;
  house_other_animal_4_number?: Maybe<Scalars["Int"]>;
  house_other_animal_5_name?: Maybe<Scalars["String"]>;
  house_other_animal_5_number?: Maybe<Scalars["Int"]>;
  house_other_animal_name?: Maybe<Scalars["String"]>;
  house_other_animal_number?: Maybe<Scalars["Int"]>;
  house_rabbit?: Maybe<Scalars["Int"]>;
  id?: Maybe<Scalars["uuid"]>;
  insecticide?: Maybe<Scalars["String"]>;
  origin_from_complaint?: Maybe<Scalars["String"]>;
  reported_at?: Maybe<Scalars["timestamptz"]>;
  residual_treatment_kind?: Maybe<Scalars["String"]>;
  roof_other_animal_1_name?: Maybe<Scalars["String"]>;
  roof_other_animal_1_number?: Maybe<Scalars["Int"]>;
  roof_other_animal_2_name?: Maybe<Scalars["String"]>;
  roof_other_animal_2_number?: Maybe<Scalars["Int"]>;
  roof_other_animal_3_name?: Maybe<Scalars["String"]>;
  roof_other_animal_3_number?: Maybe<Scalars["Int"]>;
  roof_other_animal_4_name?: Maybe<Scalars["String"]>;
  roof_other_animal_4_number?: Maybe<Scalars["Int"]>;
  roof_other_animal_5_name?: Maybe<Scalars["String"]>;
  roof_other_animal_5_number?: Maybe<Scalars["Int"]>;
  roof_other_animal_name?: Maybe<Scalars["String"]>;
  roof_other_animal_number?: Maybe<Scalars["Int"]>;
  roof_top_bird?: Maybe<Scalars["Int"]>;
  roof_top_cat?: Maybe<Scalars["Int"]>;
  roof_top_cuy?: Maybe<Scalars["Int"]>;
  roof_top_dog?: Maybe<Scalars["Int"]>;
  roof_top_rabbit?: Maybe<Scalars["Int"]>;
  spent_insecticides?: Maybe<Scalars["Int"]>;
  sprayer_id?: Maybe<Scalars["uuid"]>;
  sprayer_name?: Maybe<Scalars["String"]>;
  unicode_from_radio?: Maybe<Scalars["String"]>;
  updated_at?: Maybe<Scalars["timestamptz"]>;
  workspace_id?: Maybe<Scalars["uuid"]>;
  workspace_metadata_id?: Maybe<Scalars["uuid"]>;
  yard_bird?: Maybe<Scalars["Int"]>;
  yard_cat?: Maybe<Scalars["Int"]>;
  yard_cuy?: Maybe<Scalars["Int"]>;
  yard_dog?: Maybe<Scalars["Int"]>;
  yard_other_animal_1_name?: Maybe<Scalars["String"]>;
  yard_other_animal_1_number?: Maybe<Scalars["Int"]>;
  yard_other_animal_2_name?: Maybe<Scalars["String"]>;
  yard_other_animal_2_number?: Maybe<Scalars["Int"]>;
  yard_other_animal_3_name?: Maybe<Scalars["String"]>;
  yard_other_animal_3_number?: Maybe<Scalars["Int"]>;
  yard_other_animal_4_name?: Maybe<Scalars["String"]>;
  yard_other_animal_4_number?: Maybe<Scalars["Int"]>;
  yard_other_animal_5_name?: Maybe<Scalars["String"]>;
  yard_other_animal_5_number?: Maybe<Scalars["Int"]>;
  yard_other_animal_name?: Maybe<Scalars["String"]>;
  yard_other_animal_number?: Maybe<Scalars["Int"]>;
  yard_rabbit?: Maybe<Scalars["Int"]>;
};

/** order by max() on columns of table "sprayed" */
export type Sprayed_Max_Order_By = {
  address_in_crack?: InputMaybe<Order_By>;
  address_in_env?: InputMaybe<Order_By>;
  address_in_env_close?: InputMaybe<Order_By>;
  address_in_material?: InputMaybe<Order_By>;
  address_out_crack?: InputMaybe<Order_By>;
  address_out_env?: InputMaybe<Order_By>;
  address_out_env_close?: InputMaybe<Order_By>;
  address_out_material?: InputMaybe<Order_By>;
  address_out_triatomines?: InputMaybe<Order_By>;
  address_triatomines?: InputMaybe<Order_By>;
  app_state?: InputMaybe<Order_By>;
  clean_area?: InputMaybe<Order_By>;
  collaborator_id?: InputMaybe<Order_By>;
  complaint_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  experiment_id?: InputMaybe<Order_By>;
  family_chief?: InputMaybe<Order_By>;
  family_size?: InputMaybe<Order_By>;
  firebase_id?: InputMaybe<Order_By>;
  geogroup_id?: InputMaybe<Order_By>;
  geopoint_id?: InputMaybe<Order_By>;
  geopoint_unicode?: InputMaybe<Order_By>;
  house_bird?: InputMaybe<Order_By>;
  house_cat?: InputMaybe<Order_By>;
  house_cuy?: InputMaybe<Order_By>;
  house_dog?: InputMaybe<Order_By>;
  house_other_animal_1_name?: InputMaybe<Order_By>;
  house_other_animal_1_number?: InputMaybe<Order_By>;
  house_other_animal_2_name?: InputMaybe<Order_By>;
  house_other_animal_2_number?: InputMaybe<Order_By>;
  house_other_animal_3_name?: InputMaybe<Order_By>;
  house_other_animal_3_number?: InputMaybe<Order_By>;
  house_other_animal_4_name?: InputMaybe<Order_By>;
  house_other_animal_4_number?: InputMaybe<Order_By>;
  house_other_animal_5_name?: InputMaybe<Order_By>;
  house_other_animal_5_number?: InputMaybe<Order_By>;
  house_other_animal_name?: InputMaybe<Order_By>;
  house_other_animal_number?: InputMaybe<Order_By>;
  house_rabbit?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  insecticide?: InputMaybe<Order_By>;
  origin_from_complaint?: InputMaybe<Order_By>;
  reported_at?: InputMaybe<Order_By>;
  residual_treatment_kind?: InputMaybe<Order_By>;
  roof_other_animal_1_name?: InputMaybe<Order_By>;
  roof_other_animal_1_number?: InputMaybe<Order_By>;
  roof_other_animal_2_name?: InputMaybe<Order_By>;
  roof_other_animal_2_number?: InputMaybe<Order_By>;
  roof_other_animal_3_name?: InputMaybe<Order_By>;
  roof_other_animal_3_number?: InputMaybe<Order_By>;
  roof_other_animal_4_name?: InputMaybe<Order_By>;
  roof_other_animal_4_number?: InputMaybe<Order_By>;
  roof_other_animal_5_name?: InputMaybe<Order_By>;
  roof_other_animal_5_number?: InputMaybe<Order_By>;
  roof_other_animal_name?: InputMaybe<Order_By>;
  roof_other_animal_number?: InputMaybe<Order_By>;
  roof_top_bird?: InputMaybe<Order_By>;
  roof_top_cat?: InputMaybe<Order_By>;
  roof_top_cuy?: InputMaybe<Order_By>;
  roof_top_dog?: InputMaybe<Order_By>;
  roof_top_rabbit?: InputMaybe<Order_By>;
  spent_insecticides?: InputMaybe<Order_By>;
  sprayer_id?: InputMaybe<Order_By>;
  sprayer_name?: InputMaybe<Order_By>;
  unicode_from_radio?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  workspace_id?: InputMaybe<Order_By>;
  workspace_metadata_id?: InputMaybe<Order_By>;
  yard_bird?: InputMaybe<Order_By>;
  yard_cat?: InputMaybe<Order_By>;
  yard_cuy?: InputMaybe<Order_By>;
  yard_dog?: InputMaybe<Order_By>;
  yard_other_animal_1_name?: InputMaybe<Order_By>;
  yard_other_animal_1_number?: InputMaybe<Order_By>;
  yard_other_animal_2_name?: InputMaybe<Order_By>;
  yard_other_animal_2_number?: InputMaybe<Order_By>;
  yard_other_animal_3_name?: InputMaybe<Order_By>;
  yard_other_animal_3_number?: InputMaybe<Order_By>;
  yard_other_animal_4_name?: InputMaybe<Order_By>;
  yard_other_animal_4_number?: InputMaybe<Order_By>;
  yard_other_animal_5_name?: InputMaybe<Order_By>;
  yard_other_animal_5_number?: InputMaybe<Order_By>;
  yard_other_animal_name?: InputMaybe<Order_By>;
  yard_other_animal_number?: InputMaybe<Order_By>;
  yard_rabbit?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Sprayed_Min_Fields = {
  __typename?: "sprayed_min_fields";
  address_in_crack?: Maybe<Scalars["Int"]>;
  address_in_env?: Maybe<Scalars["Int"]>;
  address_in_env_close?: Maybe<Scalars["Int"]>;
  address_in_material?: Maybe<Scalars["String"]>;
  address_out_crack?: Maybe<Scalars["Int"]>;
  address_out_env?: Maybe<Scalars["Int"]>;
  address_out_env_close?: Maybe<Scalars["Int"]>;
  address_out_material?: Maybe<Scalars["String"]>;
  address_out_triatomines?: Maybe<Scalars["Int"]>;
  address_triatomines?: Maybe<Scalars["Int"]>;
  app_state?: Maybe<Scalars["String"]>;
  clean_area?: Maybe<Scalars["Int"]>;
  collaborator_id?: Maybe<Scalars["uuid"]>;
  complaint_id?: Maybe<Scalars["uuid"]>;
  created_at?: Maybe<Scalars["timestamptz"]>;
  experiment_id?: Maybe<Scalars["uuid"]>;
  family_chief?: Maybe<Scalars["String"]>;
  family_size?: Maybe<Scalars["Int"]>;
  firebase_id?: Maybe<Scalars["String"]>;
  geogroup_id?: Maybe<Scalars["uuid"]>;
  geopoint_id?: Maybe<Scalars["uuid"]>;
  geopoint_unicode?: Maybe<Scalars["String"]>;
  house_bird?: Maybe<Scalars["Int"]>;
  house_cat?: Maybe<Scalars["Int"]>;
  house_cuy?: Maybe<Scalars["Int"]>;
  house_dog?: Maybe<Scalars["Int"]>;
  house_other_animal_1_name?: Maybe<Scalars["String"]>;
  house_other_animal_1_number?: Maybe<Scalars["Int"]>;
  house_other_animal_2_name?: Maybe<Scalars["String"]>;
  house_other_animal_2_number?: Maybe<Scalars["Int"]>;
  house_other_animal_3_name?: Maybe<Scalars["String"]>;
  house_other_animal_3_number?: Maybe<Scalars["Int"]>;
  house_other_animal_4_name?: Maybe<Scalars["String"]>;
  house_other_animal_4_number?: Maybe<Scalars["Int"]>;
  house_other_animal_5_name?: Maybe<Scalars["String"]>;
  house_other_animal_5_number?: Maybe<Scalars["Int"]>;
  house_other_animal_name?: Maybe<Scalars["String"]>;
  house_other_animal_number?: Maybe<Scalars["Int"]>;
  house_rabbit?: Maybe<Scalars["Int"]>;
  id?: Maybe<Scalars["uuid"]>;
  insecticide?: Maybe<Scalars["String"]>;
  origin_from_complaint?: Maybe<Scalars["String"]>;
  reported_at?: Maybe<Scalars["timestamptz"]>;
  residual_treatment_kind?: Maybe<Scalars["String"]>;
  roof_other_animal_1_name?: Maybe<Scalars["String"]>;
  roof_other_animal_1_number?: Maybe<Scalars["Int"]>;
  roof_other_animal_2_name?: Maybe<Scalars["String"]>;
  roof_other_animal_2_number?: Maybe<Scalars["Int"]>;
  roof_other_animal_3_name?: Maybe<Scalars["String"]>;
  roof_other_animal_3_number?: Maybe<Scalars["Int"]>;
  roof_other_animal_4_name?: Maybe<Scalars["String"]>;
  roof_other_animal_4_number?: Maybe<Scalars["Int"]>;
  roof_other_animal_5_name?: Maybe<Scalars["String"]>;
  roof_other_animal_5_number?: Maybe<Scalars["Int"]>;
  roof_other_animal_name?: Maybe<Scalars["String"]>;
  roof_other_animal_number?: Maybe<Scalars["Int"]>;
  roof_top_bird?: Maybe<Scalars["Int"]>;
  roof_top_cat?: Maybe<Scalars["Int"]>;
  roof_top_cuy?: Maybe<Scalars["Int"]>;
  roof_top_dog?: Maybe<Scalars["Int"]>;
  roof_top_rabbit?: Maybe<Scalars["Int"]>;
  spent_insecticides?: Maybe<Scalars["Int"]>;
  sprayer_id?: Maybe<Scalars["uuid"]>;
  sprayer_name?: Maybe<Scalars["String"]>;
  unicode_from_radio?: Maybe<Scalars["String"]>;
  updated_at?: Maybe<Scalars["timestamptz"]>;
  workspace_id?: Maybe<Scalars["uuid"]>;
  workspace_metadata_id?: Maybe<Scalars["uuid"]>;
  yard_bird?: Maybe<Scalars["Int"]>;
  yard_cat?: Maybe<Scalars["Int"]>;
  yard_cuy?: Maybe<Scalars["Int"]>;
  yard_dog?: Maybe<Scalars["Int"]>;
  yard_other_animal_1_name?: Maybe<Scalars["String"]>;
  yard_other_animal_1_number?: Maybe<Scalars["Int"]>;
  yard_other_animal_2_name?: Maybe<Scalars["String"]>;
  yard_other_animal_2_number?: Maybe<Scalars["Int"]>;
  yard_other_animal_3_name?: Maybe<Scalars["String"]>;
  yard_other_animal_3_number?: Maybe<Scalars["Int"]>;
  yard_other_animal_4_name?: Maybe<Scalars["String"]>;
  yard_other_animal_4_number?: Maybe<Scalars["Int"]>;
  yard_other_animal_5_name?: Maybe<Scalars["String"]>;
  yard_other_animal_5_number?: Maybe<Scalars["Int"]>;
  yard_other_animal_name?: Maybe<Scalars["String"]>;
  yard_other_animal_number?: Maybe<Scalars["Int"]>;
  yard_rabbit?: Maybe<Scalars["Int"]>;
};

/** order by min() on columns of table "sprayed" */
export type Sprayed_Min_Order_By = {
  address_in_crack?: InputMaybe<Order_By>;
  address_in_env?: InputMaybe<Order_By>;
  address_in_env_close?: InputMaybe<Order_By>;
  address_in_material?: InputMaybe<Order_By>;
  address_out_crack?: InputMaybe<Order_By>;
  address_out_env?: InputMaybe<Order_By>;
  address_out_env_close?: InputMaybe<Order_By>;
  address_out_material?: InputMaybe<Order_By>;
  address_out_triatomines?: InputMaybe<Order_By>;
  address_triatomines?: InputMaybe<Order_By>;
  app_state?: InputMaybe<Order_By>;
  clean_area?: InputMaybe<Order_By>;
  collaborator_id?: InputMaybe<Order_By>;
  complaint_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  experiment_id?: InputMaybe<Order_By>;
  family_chief?: InputMaybe<Order_By>;
  family_size?: InputMaybe<Order_By>;
  firebase_id?: InputMaybe<Order_By>;
  geogroup_id?: InputMaybe<Order_By>;
  geopoint_id?: InputMaybe<Order_By>;
  geopoint_unicode?: InputMaybe<Order_By>;
  house_bird?: InputMaybe<Order_By>;
  house_cat?: InputMaybe<Order_By>;
  house_cuy?: InputMaybe<Order_By>;
  house_dog?: InputMaybe<Order_By>;
  house_other_animal_1_name?: InputMaybe<Order_By>;
  house_other_animal_1_number?: InputMaybe<Order_By>;
  house_other_animal_2_name?: InputMaybe<Order_By>;
  house_other_animal_2_number?: InputMaybe<Order_By>;
  house_other_animal_3_name?: InputMaybe<Order_By>;
  house_other_animal_3_number?: InputMaybe<Order_By>;
  house_other_animal_4_name?: InputMaybe<Order_By>;
  house_other_animal_4_number?: InputMaybe<Order_By>;
  house_other_animal_5_name?: InputMaybe<Order_By>;
  house_other_animal_5_number?: InputMaybe<Order_By>;
  house_other_animal_name?: InputMaybe<Order_By>;
  house_other_animal_number?: InputMaybe<Order_By>;
  house_rabbit?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  insecticide?: InputMaybe<Order_By>;
  origin_from_complaint?: InputMaybe<Order_By>;
  reported_at?: InputMaybe<Order_By>;
  residual_treatment_kind?: InputMaybe<Order_By>;
  roof_other_animal_1_name?: InputMaybe<Order_By>;
  roof_other_animal_1_number?: InputMaybe<Order_By>;
  roof_other_animal_2_name?: InputMaybe<Order_By>;
  roof_other_animal_2_number?: InputMaybe<Order_By>;
  roof_other_animal_3_name?: InputMaybe<Order_By>;
  roof_other_animal_3_number?: InputMaybe<Order_By>;
  roof_other_animal_4_name?: InputMaybe<Order_By>;
  roof_other_animal_4_number?: InputMaybe<Order_By>;
  roof_other_animal_5_name?: InputMaybe<Order_By>;
  roof_other_animal_5_number?: InputMaybe<Order_By>;
  roof_other_animal_name?: InputMaybe<Order_By>;
  roof_other_animal_number?: InputMaybe<Order_By>;
  roof_top_bird?: InputMaybe<Order_By>;
  roof_top_cat?: InputMaybe<Order_By>;
  roof_top_cuy?: InputMaybe<Order_By>;
  roof_top_dog?: InputMaybe<Order_By>;
  roof_top_rabbit?: InputMaybe<Order_By>;
  spent_insecticides?: InputMaybe<Order_By>;
  sprayer_id?: InputMaybe<Order_By>;
  sprayer_name?: InputMaybe<Order_By>;
  unicode_from_radio?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  workspace_id?: InputMaybe<Order_By>;
  workspace_metadata_id?: InputMaybe<Order_By>;
  yard_bird?: InputMaybe<Order_By>;
  yard_cat?: InputMaybe<Order_By>;
  yard_cuy?: InputMaybe<Order_By>;
  yard_dog?: InputMaybe<Order_By>;
  yard_other_animal_1_name?: InputMaybe<Order_By>;
  yard_other_animal_1_number?: InputMaybe<Order_By>;
  yard_other_animal_2_name?: InputMaybe<Order_By>;
  yard_other_animal_2_number?: InputMaybe<Order_By>;
  yard_other_animal_3_name?: InputMaybe<Order_By>;
  yard_other_animal_3_number?: InputMaybe<Order_By>;
  yard_other_animal_4_name?: InputMaybe<Order_By>;
  yard_other_animal_4_number?: InputMaybe<Order_By>;
  yard_other_animal_5_name?: InputMaybe<Order_By>;
  yard_other_animal_5_number?: InputMaybe<Order_By>;
  yard_other_animal_name?: InputMaybe<Order_By>;
  yard_other_animal_number?: InputMaybe<Order_By>;
  yard_rabbit?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "sprayed" */
export type Sprayed_Mutation_Response = {
  __typename?: "sprayed_mutation_response";
  /** number of rows affected by the mutation */
  affected_rows: Scalars["Int"];
  /** data from the rows affected by the mutation */
  returning: Array<Sprayed>;
};

/** on_conflict condition type for table "sprayed" */
export type Sprayed_On_Conflict = {
  constraint: Sprayed_Constraint;
  update_columns?: Array<Sprayed_Update_Column>;
  where?: InputMaybe<Sprayed_Bool_Exp>;
};

/** Ordering options when selecting data from "sprayed". */
export type Sprayed_Order_By = {
  address_in_crack?: InputMaybe<Order_By>;
  address_in_env?: InputMaybe<Order_By>;
  address_in_env_close?: InputMaybe<Order_By>;
  address_in_material?: InputMaybe<Order_By>;
  address_out_crack?: InputMaybe<Order_By>;
  address_out_env?: InputMaybe<Order_By>;
  address_out_env_close?: InputMaybe<Order_By>;
  address_out_material?: InputMaybe<Order_By>;
  address_out_triatomines?: InputMaybe<Order_By>;
  address_triatomines?: InputMaybe<Order_By>;
  app_state?: InputMaybe<Order_By>;
  clean_area?: InputMaybe<Order_By>;
  collaborator_id?: InputMaybe<Order_By>;
  complaint_id?: InputMaybe<Order_By>;
  comprehensive_treatment_metadata?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  experiment_id?: InputMaybe<Order_By>;
  family_chief?: InputMaybe<Order_By>;
  family_size?: InputMaybe<Order_By>;
  firebase_id?: InputMaybe<Order_By>;
  from_radio?: InputMaybe<Order_By>;
  geogroup_id?: InputMaybe<Order_By>;
  geopoint_id?: InputMaybe<Order_By>;
  geopoint_unicode?: InputMaybe<Order_By>;
  house_bird?: InputMaybe<Order_By>;
  house_cat?: InputMaybe<Order_By>;
  house_cuy?: InputMaybe<Order_By>;
  house_dog?: InputMaybe<Order_By>;
  house_other_animal_1_name?: InputMaybe<Order_By>;
  house_other_animal_1_number?: InputMaybe<Order_By>;
  house_other_animal_2_name?: InputMaybe<Order_By>;
  house_other_animal_2_number?: InputMaybe<Order_By>;
  house_other_animal_3_name?: InputMaybe<Order_By>;
  house_other_animal_3_number?: InputMaybe<Order_By>;
  house_other_animal_4_name?: InputMaybe<Order_By>;
  house_other_animal_4_number?: InputMaybe<Order_By>;
  house_other_animal_5_name?: InputMaybe<Order_By>;
  house_other_animal_5_number?: InputMaybe<Order_By>;
  house_other_animal_name?: InputMaybe<Order_By>;
  house_other_animal_number?: InputMaybe<Order_By>;
  house_rabbit?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  insecticide?: InputMaybe<Order_By>;
  origin_from_complaint?: InputMaybe<Order_By>;
  reported_at?: InputMaybe<Order_By>;
  residual_treatment_kind?: InputMaybe<Order_By>;
  roof_other_animal_1_name?: InputMaybe<Order_By>;
  roof_other_animal_1_number?: InputMaybe<Order_By>;
  roof_other_animal_2_name?: InputMaybe<Order_By>;
  roof_other_animal_2_number?: InputMaybe<Order_By>;
  roof_other_animal_3_name?: InputMaybe<Order_By>;
  roof_other_animal_3_number?: InputMaybe<Order_By>;
  roof_other_animal_4_name?: InputMaybe<Order_By>;
  roof_other_animal_4_number?: InputMaybe<Order_By>;
  roof_other_animal_5_name?: InputMaybe<Order_By>;
  roof_other_animal_5_number?: InputMaybe<Order_By>;
  roof_other_animal_name?: InputMaybe<Order_By>;
  roof_other_animal_number?: InputMaybe<Order_By>;
  roof_top_bird?: InputMaybe<Order_By>;
  roof_top_cat?: InputMaybe<Order_By>;
  roof_top_cuy?: InputMaybe<Order_By>;
  roof_top_dog?: InputMaybe<Order_By>;
  roof_top_rabbit?: InputMaybe<Order_By>;
  spent_insecticides?: InputMaybe<Order_By>;
  sprayed_intra_event?: InputMaybe<Order_By>;
  sprayed_peri_event?: InputMaybe<Order_By>;
  sprayed_residual_treatment_kind?: InputMaybe<Sprayed_Residual_Treatment_Kind_Order_By>;
  sprayer_id?: InputMaybe<Order_By>;
  sprayer_name?: InputMaybe<Order_By>;
  unicode_from_radio?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  workspace?: InputMaybe<Workspace_Order_By>;
  workspace_id?: InputMaybe<Order_By>;
  workspace_metadata_id?: InputMaybe<Order_By>;
  workspace_metadatum?: InputMaybe<Workspace_Metadata_Order_By>;
  yard_bird?: InputMaybe<Order_By>;
  yard_cat?: InputMaybe<Order_By>;
  yard_cuy?: InputMaybe<Order_By>;
  yard_dog?: InputMaybe<Order_By>;
  yard_other_animal_1_name?: InputMaybe<Order_By>;
  yard_other_animal_1_number?: InputMaybe<Order_By>;
  yard_other_animal_2_name?: InputMaybe<Order_By>;
  yard_other_animal_2_number?: InputMaybe<Order_By>;
  yard_other_animal_3_name?: InputMaybe<Order_By>;
  yard_other_animal_3_number?: InputMaybe<Order_By>;
  yard_other_animal_4_name?: InputMaybe<Order_By>;
  yard_other_animal_4_number?: InputMaybe<Order_By>;
  yard_other_animal_5_name?: InputMaybe<Order_By>;
  yard_other_animal_5_number?: InputMaybe<Order_By>;
  yard_other_animal_name?: InputMaybe<Order_By>;
  yard_other_animal_number?: InputMaybe<Order_By>;
  yard_rabbit?: InputMaybe<Order_By>;
};

/** primary key columns input for table: sprayed */
export type Sprayed_Pk_Columns_Input = {
  id: Scalars["uuid"];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Sprayed_Prepend_Input = {
  comprehensive_treatment_metadata?: InputMaybe<Scalars["jsonb"]>;
  sprayed_intra_event?: InputMaybe<Scalars["jsonb"]>;
  sprayed_peri_event?: InputMaybe<Scalars["jsonb"]>;
};

/** columns and relationships of "sprayed_residual_treatment_kind" */
export type Sprayed_Residual_Treatment_Kind = {
  __typename?: "sprayed_residual_treatment_kind";
  comment?: Maybe<Scalars["String"]>;
  /** An array relationship */
  sprayeds: Array<Sprayed>;
  /** An aggregate relationship */
  sprayeds_aggregate: Sprayed_Aggregate;
  value: Scalars["String"];
};

/** columns and relationships of "sprayed_residual_treatment_kind" */
export type Sprayed_Residual_Treatment_KindSprayedsArgs = {
  distinct_on?: InputMaybe<Array<Sprayed_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Sprayed_Order_By>>;
  where?: InputMaybe<Sprayed_Bool_Exp>;
};

/** columns and relationships of "sprayed_residual_treatment_kind" */
export type Sprayed_Residual_Treatment_KindSprayeds_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Sprayed_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Sprayed_Order_By>>;
  where?: InputMaybe<Sprayed_Bool_Exp>;
};

/** aggregated selection of "sprayed_residual_treatment_kind" */
export type Sprayed_Residual_Treatment_Kind_Aggregate = {
  __typename?: "sprayed_residual_treatment_kind_aggregate";
  aggregate?: Maybe<Sprayed_Residual_Treatment_Kind_Aggregate_Fields>;
  nodes: Array<Sprayed_Residual_Treatment_Kind>;
};

/** aggregate fields of "sprayed_residual_treatment_kind" */
export type Sprayed_Residual_Treatment_Kind_Aggregate_Fields = {
  __typename?: "sprayed_residual_treatment_kind_aggregate_fields";
  count: Scalars["Int"];
  max?: Maybe<Sprayed_Residual_Treatment_Kind_Max_Fields>;
  min?: Maybe<Sprayed_Residual_Treatment_Kind_Min_Fields>;
};

/** aggregate fields of "sprayed_residual_treatment_kind" */
export type Sprayed_Residual_Treatment_Kind_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Sprayed_Residual_Treatment_Kind_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]>;
};

/** Boolean expression to filter rows from the table "sprayed_residual_treatment_kind". All fields are combined with a logical 'AND'. */
export type Sprayed_Residual_Treatment_Kind_Bool_Exp = {
  _and?: InputMaybe<Array<Sprayed_Residual_Treatment_Kind_Bool_Exp>>;
  _not?: InputMaybe<Sprayed_Residual_Treatment_Kind_Bool_Exp>;
  _or?: InputMaybe<Array<Sprayed_Residual_Treatment_Kind_Bool_Exp>>;
  comment?: InputMaybe<String_Comparison_Exp>;
  sprayeds?: InputMaybe<Sprayed_Bool_Exp>;
  sprayeds_aggregate?: InputMaybe<Sprayed_Aggregate_Bool_Exp>;
  value?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "sprayed_residual_treatment_kind" */
export enum Sprayed_Residual_Treatment_Kind_Constraint {
  /** unique or primary key constraint on columns "value" */
  SprayedResidualTreatmentKindPkey = "sprayed_residual_treatment_kind_pkey",
}

/** input type for inserting data into table "sprayed_residual_treatment_kind" */
export type Sprayed_Residual_Treatment_Kind_Insert_Input = {
  comment?: InputMaybe<Scalars["String"]>;
  sprayeds?: InputMaybe<Sprayed_Arr_Rel_Insert_Input>;
  value?: InputMaybe<Scalars["String"]>;
};

/** aggregate max on columns */
export type Sprayed_Residual_Treatment_Kind_Max_Fields = {
  __typename?: "sprayed_residual_treatment_kind_max_fields";
  comment?: Maybe<Scalars["String"]>;
  value?: Maybe<Scalars["String"]>;
};

/** aggregate min on columns */
export type Sprayed_Residual_Treatment_Kind_Min_Fields = {
  __typename?: "sprayed_residual_treatment_kind_min_fields";
  comment?: Maybe<Scalars["String"]>;
  value?: Maybe<Scalars["String"]>;
};

/** response of any mutation on the table "sprayed_residual_treatment_kind" */
export type Sprayed_Residual_Treatment_Kind_Mutation_Response = {
  __typename?: "sprayed_residual_treatment_kind_mutation_response";
  /** number of rows affected by the mutation */
  affected_rows: Scalars["Int"];
  /** data from the rows affected by the mutation */
  returning: Array<Sprayed_Residual_Treatment_Kind>;
};

/** input type for inserting object relation for remote table "sprayed_residual_treatment_kind" */
export type Sprayed_Residual_Treatment_Kind_Obj_Rel_Insert_Input = {
  data: Sprayed_Residual_Treatment_Kind_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Sprayed_Residual_Treatment_Kind_On_Conflict>;
};

/** on_conflict condition type for table "sprayed_residual_treatment_kind" */
export type Sprayed_Residual_Treatment_Kind_On_Conflict = {
  constraint: Sprayed_Residual_Treatment_Kind_Constraint;
  update_columns?: Array<Sprayed_Residual_Treatment_Kind_Update_Column>;
  where?: InputMaybe<Sprayed_Residual_Treatment_Kind_Bool_Exp>;
};

/** Ordering options when selecting data from "sprayed_residual_treatment_kind". */
export type Sprayed_Residual_Treatment_Kind_Order_By = {
  comment?: InputMaybe<Order_By>;
  sprayeds_aggregate?: InputMaybe<Sprayed_Aggregate_Order_By>;
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: sprayed_residual_treatment_kind */
export type Sprayed_Residual_Treatment_Kind_Pk_Columns_Input = {
  value: Scalars["String"];
};

/** select columns of table "sprayed_residual_treatment_kind" */
export enum Sprayed_Residual_Treatment_Kind_Select_Column {
  /** column name */
  Comment = "comment",
  /** column name */
  Value = "value",
}

/** input type for updating data in table "sprayed_residual_treatment_kind" */
export type Sprayed_Residual_Treatment_Kind_Set_Input = {
  comment?: InputMaybe<Scalars["String"]>;
  value?: InputMaybe<Scalars["String"]>;
};

/** Streaming cursor of the table "sprayed_residual_treatment_kind" */
export type Sprayed_Residual_Treatment_Kind_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Sprayed_Residual_Treatment_Kind_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Sprayed_Residual_Treatment_Kind_Stream_Cursor_Value_Input = {
  comment?: InputMaybe<Scalars["String"]>;
  value?: InputMaybe<Scalars["String"]>;
};

/** update columns of table "sprayed_residual_treatment_kind" */
export enum Sprayed_Residual_Treatment_Kind_Update_Column {
  /** column name */
  Comment = "comment",
  /** column name */
  Value = "value",
}

export type Sprayed_Residual_Treatment_Kind_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Sprayed_Residual_Treatment_Kind_Set_Input>;
  /** filter the rows which have to be updated */
  where: Sprayed_Residual_Treatment_Kind_Bool_Exp;
};

/** select columns of table "sprayed" */
export enum Sprayed_Select_Column {
  /** column name */
  AddressInCrack = "address_in_crack",
  /** column name */
  AddressInEnv = "address_in_env",
  /** column name */
  AddressInEnvClose = "address_in_env_close",
  /** column name */
  AddressInMaterial = "address_in_material",
  /** column name */
  AddressOutCrack = "address_out_crack",
  /** column name */
  AddressOutEnv = "address_out_env",
  /** column name */
  AddressOutEnvClose = "address_out_env_close",
  /** column name */
  AddressOutMaterial = "address_out_material",
  /** column name */
  AddressOutTriatomines = "address_out_triatomines",
  /** column name */
  AddressTriatomines = "address_triatomines",
  /** column name */
  AppState = "app_state",
  /** column name */
  CleanArea = "clean_area",
  /** column name */
  CollaboratorId = "collaborator_id",
  /** column name */
  ComplaintId = "complaint_id",
  /** column name */
  ComprehensiveTreatmentMetadata = "comprehensive_treatment_metadata",
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  ExperimentId = "experiment_id",
  /** column name */
  FamilyChief = "family_chief",
  /** column name */
  FamilySize = "family_size",
  /** column name */
  FirebaseId = "firebase_id",
  /** column name */
  FromRadio = "from_radio",
  /** column name */
  GeogroupId = "geogroup_id",
  /** column name */
  GeopointId = "geopoint_id",
  /** column name */
  GeopointUnicode = "geopoint_unicode",
  /** column name */
  HouseBird = "house_bird",
  /** column name */
  HouseCat = "house_cat",
  /** column name */
  HouseCuy = "house_cuy",
  /** column name */
  HouseDog = "house_dog",
  /** column name */
  HouseOtherAnimal_1Name = "house_other_animal_1_name",
  /** column name */
  HouseOtherAnimal_1Number = "house_other_animal_1_number",
  /** column name */
  HouseOtherAnimal_2Name = "house_other_animal_2_name",
  /** column name */
  HouseOtherAnimal_2Number = "house_other_animal_2_number",
  /** column name */
  HouseOtherAnimal_3Name = "house_other_animal_3_name",
  /** column name */
  HouseOtherAnimal_3Number = "house_other_animal_3_number",
  /** column name */
  HouseOtherAnimal_4Name = "house_other_animal_4_name",
  /** column name */
  HouseOtherAnimal_4Number = "house_other_animal_4_number",
  /** column name */
  HouseOtherAnimal_5Name = "house_other_animal_5_name",
  /** column name */
  HouseOtherAnimal_5Number = "house_other_animal_5_number",
  /** column name */
  HouseOtherAnimalName = "house_other_animal_name",
  /** column name */
  HouseOtherAnimalNumber = "house_other_animal_number",
  /** column name */
  HouseRabbit = "house_rabbit",
  /** column name */
  Id = "id",
  /** column name */
  Insecticide = "insecticide",
  /** column name */
  OriginFromComplaint = "origin_from_complaint",
  /** column name */
  ReportedAt = "reported_at",
  /** column name */
  ResidualTreatmentKind = "residual_treatment_kind",
  /** column name */
  RoofOtherAnimal_1Name = "roof_other_animal_1_name",
  /** column name */
  RoofOtherAnimal_1Number = "roof_other_animal_1_number",
  /** column name */
  RoofOtherAnimal_2Name = "roof_other_animal_2_name",
  /** column name */
  RoofOtherAnimal_2Number = "roof_other_animal_2_number",
  /** column name */
  RoofOtherAnimal_3Name = "roof_other_animal_3_name",
  /** column name */
  RoofOtherAnimal_3Number = "roof_other_animal_3_number",
  /** column name */
  RoofOtherAnimal_4Name = "roof_other_animal_4_name",
  /** column name */
  RoofOtherAnimal_4Number = "roof_other_animal_4_number",
  /** column name */
  RoofOtherAnimal_5Name = "roof_other_animal_5_name",
  /** column name */
  RoofOtherAnimal_5Number = "roof_other_animal_5_number",
  /** column name */
  RoofOtherAnimalName = "roof_other_animal_name",
  /** column name */
  RoofOtherAnimalNumber = "roof_other_animal_number",
  /** column name */
  RoofTopBird = "roof_top_bird",
  /** column name */
  RoofTopCat = "roof_top_cat",
  /** column name */
  RoofTopCuy = "roof_top_cuy",
  /** column name */
  RoofTopDog = "roof_top_dog",
  /** column name */
  RoofTopRabbit = "roof_top_rabbit",
  /** column name */
  SpentInsecticides = "spent_insecticides",
  /** column name */
  SprayedIntraEvent = "sprayed_intra_event",
  /** column name */
  SprayedPeriEvent = "sprayed_peri_event",
  /** column name */
  SprayerId = "sprayer_id",
  /** column name */
  SprayerName = "sprayer_name",
  /** column name */
  UnicodeFromRadio = "unicode_from_radio",
  /** column name */
  UpdatedAt = "updated_at",
  /** column name */
  WorkspaceId = "workspace_id",
  /** column name */
  WorkspaceMetadataId = "workspace_metadata_id",
  /** column name */
  YardBird = "yard_bird",
  /** column name */
  YardCat = "yard_cat",
  /** column name */
  YardCuy = "yard_cuy",
  /** column name */
  YardDog = "yard_dog",
  /** column name */
  YardOtherAnimal_1Name = "yard_other_animal_1_name",
  /** column name */
  YardOtherAnimal_1Number = "yard_other_animal_1_number",
  /** column name */
  YardOtherAnimal_2Name = "yard_other_animal_2_name",
  /** column name */
  YardOtherAnimal_2Number = "yard_other_animal_2_number",
  /** column name */
  YardOtherAnimal_3Name = "yard_other_animal_3_name",
  /** column name */
  YardOtherAnimal_3Number = "yard_other_animal_3_number",
  /** column name */
  YardOtherAnimal_4Name = "yard_other_animal_4_name",
  /** column name */
  YardOtherAnimal_4Number = "yard_other_animal_4_number",
  /** column name */
  YardOtherAnimal_5Name = "yard_other_animal_5_name",
  /** column name */
  YardOtherAnimal_5Number = "yard_other_animal_5_number",
  /** column name */
  YardOtherAnimalName = "yard_other_animal_name",
  /** column name */
  YardOtherAnimalNumber = "yard_other_animal_number",
  /** column name */
  YardRabbit = "yard_rabbit",
}

/** select "sprayed_aggregate_bool_exp_bool_and_arguments_columns" columns of table "sprayed" */
export enum Sprayed_Select_Column_Sprayed_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  FromRadio = "from_radio",
}

/** select "sprayed_aggregate_bool_exp_bool_or_arguments_columns" columns of table "sprayed" */
export enum Sprayed_Select_Column_Sprayed_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  FromRadio = "from_radio",
}

/** input type for updating data in table "sprayed" */
export type Sprayed_Set_Input = {
  address_in_crack?: InputMaybe<Scalars["Int"]>;
  address_in_env?: InputMaybe<Scalars["Int"]>;
  address_in_env_close?: InputMaybe<Scalars["Int"]>;
  address_in_material?: InputMaybe<Scalars["String"]>;
  address_out_crack?: InputMaybe<Scalars["Int"]>;
  address_out_env?: InputMaybe<Scalars["Int"]>;
  address_out_env_close?: InputMaybe<Scalars["Int"]>;
  address_out_material?: InputMaybe<Scalars["String"]>;
  address_out_triatomines?: InputMaybe<Scalars["Int"]>;
  address_triatomines?: InputMaybe<Scalars["Int"]>;
  app_state?: InputMaybe<Scalars["String"]>;
  clean_area?: InputMaybe<Scalars["Int"]>;
  collaborator_id?: InputMaybe<Scalars["uuid"]>;
  complaint_id?: InputMaybe<Scalars["uuid"]>;
  comprehensive_treatment_metadata?: InputMaybe<Scalars["jsonb"]>;
  created_at?: InputMaybe<Scalars["timestamptz"]>;
  experiment_id?: InputMaybe<Scalars["uuid"]>;
  family_chief?: InputMaybe<Scalars["String"]>;
  family_size?: InputMaybe<Scalars["Int"]>;
  firebase_id?: InputMaybe<Scalars["String"]>;
  from_radio?: InputMaybe<Scalars["Boolean"]>;
  geogroup_id?: InputMaybe<Scalars["uuid"]>;
  geopoint_id?: InputMaybe<Scalars["uuid"]>;
  geopoint_unicode?: InputMaybe<Scalars["String"]>;
  house_bird?: InputMaybe<Scalars["Int"]>;
  house_cat?: InputMaybe<Scalars["Int"]>;
  house_cuy?: InputMaybe<Scalars["Int"]>;
  house_dog?: InputMaybe<Scalars["Int"]>;
  house_other_animal_1_name?: InputMaybe<Scalars["String"]>;
  house_other_animal_1_number?: InputMaybe<Scalars["Int"]>;
  house_other_animal_2_name?: InputMaybe<Scalars["String"]>;
  house_other_animal_2_number?: InputMaybe<Scalars["Int"]>;
  house_other_animal_3_name?: InputMaybe<Scalars["String"]>;
  house_other_animal_3_number?: InputMaybe<Scalars["Int"]>;
  house_other_animal_4_name?: InputMaybe<Scalars["String"]>;
  house_other_animal_4_number?: InputMaybe<Scalars["Int"]>;
  house_other_animal_5_name?: InputMaybe<Scalars["String"]>;
  house_other_animal_5_number?: InputMaybe<Scalars["Int"]>;
  house_other_animal_name?: InputMaybe<Scalars["String"]>;
  house_other_animal_number?: InputMaybe<Scalars["Int"]>;
  house_rabbit?: InputMaybe<Scalars["Int"]>;
  id?: InputMaybe<Scalars["uuid"]>;
  insecticide?: InputMaybe<Scalars["String"]>;
  origin_from_complaint?: InputMaybe<Scalars["String"]>;
  reported_at?: InputMaybe<Scalars["timestamptz"]>;
  residual_treatment_kind?: InputMaybe<Scalars["String"]>;
  roof_other_animal_1_name?: InputMaybe<Scalars["String"]>;
  roof_other_animal_1_number?: InputMaybe<Scalars["Int"]>;
  roof_other_animal_2_name?: InputMaybe<Scalars["String"]>;
  roof_other_animal_2_number?: InputMaybe<Scalars["Int"]>;
  roof_other_animal_3_name?: InputMaybe<Scalars["String"]>;
  roof_other_animal_3_number?: InputMaybe<Scalars["Int"]>;
  roof_other_animal_4_name?: InputMaybe<Scalars["String"]>;
  roof_other_animal_4_number?: InputMaybe<Scalars["Int"]>;
  roof_other_animal_5_name?: InputMaybe<Scalars["String"]>;
  roof_other_animal_5_number?: InputMaybe<Scalars["Int"]>;
  roof_other_animal_name?: InputMaybe<Scalars["String"]>;
  roof_other_animal_number?: InputMaybe<Scalars["Int"]>;
  roof_top_bird?: InputMaybe<Scalars["Int"]>;
  roof_top_cat?: InputMaybe<Scalars["Int"]>;
  roof_top_cuy?: InputMaybe<Scalars["Int"]>;
  roof_top_dog?: InputMaybe<Scalars["Int"]>;
  roof_top_rabbit?: InputMaybe<Scalars["Int"]>;
  spent_insecticides?: InputMaybe<Scalars["Int"]>;
  sprayed_intra_event?: InputMaybe<Scalars["jsonb"]>;
  sprayed_peri_event?: InputMaybe<Scalars["jsonb"]>;
  sprayer_id?: InputMaybe<Scalars["uuid"]>;
  sprayer_name?: InputMaybe<Scalars["String"]>;
  unicode_from_radio?: InputMaybe<Scalars["String"]>;
  updated_at?: InputMaybe<Scalars["timestamptz"]>;
  workspace_id?: InputMaybe<Scalars["uuid"]>;
  workspace_metadata_id?: InputMaybe<Scalars["uuid"]>;
  yard_bird?: InputMaybe<Scalars["Int"]>;
  yard_cat?: InputMaybe<Scalars["Int"]>;
  yard_cuy?: InputMaybe<Scalars["Int"]>;
  yard_dog?: InputMaybe<Scalars["Int"]>;
  yard_other_animal_1_name?: InputMaybe<Scalars["String"]>;
  yard_other_animal_1_number?: InputMaybe<Scalars["Int"]>;
  yard_other_animal_2_name?: InputMaybe<Scalars["String"]>;
  yard_other_animal_2_number?: InputMaybe<Scalars["Int"]>;
  yard_other_animal_3_name?: InputMaybe<Scalars["String"]>;
  yard_other_animal_3_number?: InputMaybe<Scalars["Int"]>;
  yard_other_animal_4_name?: InputMaybe<Scalars["String"]>;
  yard_other_animal_4_number?: InputMaybe<Scalars["Int"]>;
  yard_other_animal_5_name?: InputMaybe<Scalars["String"]>;
  yard_other_animal_5_number?: InputMaybe<Scalars["Int"]>;
  yard_other_animal_name?: InputMaybe<Scalars["String"]>;
  yard_other_animal_number?: InputMaybe<Scalars["Int"]>;
  yard_rabbit?: InputMaybe<Scalars["Int"]>;
};

/** aggregate stddev on columns */
export type Sprayed_Stddev_Fields = {
  __typename?: "sprayed_stddev_fields";
  address_in_crack?: Maybe<Scalars["Float"]>;
  address_in_env?: Maybe<Scalars["Float"]>;
  address_in_env_close?: Maybe<Scalars["Float"]>;
  address_out_crack?: Maybe<Scalars["Float"]>;
  address_out_env?: Maybe<Scalars["Float"]>;
  address_out_env_close?: Maybe<Scalars["Float"]>;
  address_out_triatomines?: Maybe<Scalars["Float"]>;
  address_triatomines?: Maybe<Scalars["Float"]>;
  clean_area?: Maybe<Scalars["Float"]>;
  family_size?: Maybe<Scalars["Float"]>;
  house_bird?: Maybe<Scalars["Float"]>;
  house_cat?: Maybe<Scalars["Float"]>;
  house_cuy?: Maybe<Scalars["Float"]>;
  house_dog?: Maybe<Scalars["Float"]>;
  house_other_animal_1_number?: Maybe<Scalars["Float"]>;
  house_other_animal_2_number?: Maybe<Scalars["Float"]>;
  house_other_animal_3_number?: Maybe<Scalars["Float"]>;
  house_other_animal_4_number?: Maybe<Scalars["Float"]>;
  house_other_animal_5_number?: Maybe<Scalars["Float"]>;
  house_other_animal_number?: Maybe<Scalars["Float"]>;
  house_rabbit?: Maybe<Scalars["Float"]>;
  roof_other_animal_1_number?: Maybe<Scalars["Float"]>;
  roof_other_animal_2_number?: Maybe<Scalars["Float"]>;
  roof_other_animal_3_number?: Maybe<Scalars["Float"]>;
  roof_other_animal_4_number?: Maybe<Scalars["Float"]>;
  roof_other_animal_5_number?: Maybe<Scalars["Float"]>;
  roof_other_animal_number?: Maybe<Scalars["Float"]>;
  roof_top_bird?: Maybe<Scalars["Float"]>;
  roof_top_cat?: Maybe<Scalars["Float"]>;
  roof_top_cuy?: Maybe<Scalars["Float"]>;
  roof_top_dog?: Maybe<Scalars["Float"]>;
  roof_top_rabbit?: Maybe<Scalars["Float"]>;
  spent_insecticides?: Maybe<Scalars["Float"]>;
  yard_bird?: Maybe<Scalars["Float"]>;
  yard_cat?: Maybe<Scalars["Float"]>;
  yard_cuy?: Maybe<Scalars["Float"]>;
  yard_dog?: Maybe<Scalars["Float"]>;
  yard_other_animal_1_number?: Maybe<Scalars["Float"]>;
  yard_other_animal_2_number?: Maybe<Scalars["Float"]>;
  yard_other_animal_3_number?: Maybe<Scalars["Float"]>;
  yard_other_animal_4_number?: Maybe<Scalars["Float"]>;
  yard_other_animal_5_number?: Maybe<Scalars["Float"]>;
  yard_other_animal_number?: Maybe<Scalars["Float"]>;
  yard_rabbit?: Maybe<Scalars["Float"]>;
};

/** order by stddev() on columns of table "sprayed" */
export type Sprayed_Stddev_Order_By = {
  address_in_crack?: InputMaybe<Order_By>;
  address_in_env?: InputMaybe<Order_By>;
  address_in_env_close?: InputMaybe<Order_By>;
  address_out_crack?: InputMaybe<Order_By>;
  address_out_env?: InputMaybe<Order_By>;
  address_out_env_close?: InputMaybe<Order_By>;
  address_out_triatomines?: InputMaybe<Order_By>;
  address_triatomines?: InputMaybe<Order_By>;
  clean_area?: InputMaybe<Order_By>;
  family_size?: InputMaybe<Order_By>;
  house_bird?: InputMaybe<Order_By>;
  house_cat?: InputMaybe<Order_By>;
  house_cuy?: InputMaybe<Order_By>;
  house_dog?: InputMaybe<Order_By>;
  house_other_animal_1_number?: InputMaybe<Order_By>;
  house_other_animal_2_number?: InputMaybe<Order_By>;
  house_other_animal_3_number?: InputMaybe<Order_By>;
  house_other_animal_4_number?: InputMaybe<Order_By>;
  house_other_animal_5_number?: InputMaybe<Order_By>;
  house_other_animal_number?: InputMaybe<Order_By>;
  house_rabbit?: InputMaybe<Order_By>;
  roof_other_animal_1_number?: InputMaybe<Order_By>;
  roof_other_animal_2_number?: InputMaybe<Order_By>;
  roof_other_animal_3_number?: InputMaybe<Order_By>;
  roof_other_animal_4_number?: InputMaybe<Order_By>;
  roof_other_animal_5_number?: InputMaybe<Order_By>;
  roof_other_animal_number?: InputMaybe<Order_By>;
  roof_top_bird?: InputMaybe<Order_By>;
  roof_top_cat?: InputMaybe<Order_By>;
  roof_top_cuy?: InputMaybe<Order_By>;
  roof_top_dog?: InputMaybe<Order_By>;
  roof_top_rabbit?: InputMaybe<Order_By>;
  spent_insecticides?: InputMaybe<Order_By>;
  yard_bird?: InputMaybe<Order_By>;
  yard_cat?: InputMaybe<Order_By>;
  yard_cuy?: InputMaybe<Order_By>;
  yard_dog?: InputMaybe<Order_By>;
  yard_other_animal_1_number?: InputMaybe<Order_By>;
  yard_other_animal_2_number?: InputMaybe<Order_By>;
  yard_other_animal_3_number?: InputMaybe<Order_By>;
  yard_other_animal_4_number?: InputMaybe<Order_By>;
  yard_other_animal_5_number?: InputMaybe<Order_By>;
  yard_other_animal_number?: InputMaybe<Order_By>;
  yard_rabbit?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Sprayed_Stddev_Pop_Fields = {
  __typename?: "sprayed_stddev_pop_fields";
  address_in_crack?: Maybe<Scalars["Float"]>;
  address_in_env?: Maybe<Scalars["Float"]>;
  address_in_env_close?: Maybe<Scalars["Float"]>;
  address_out_crack?: Maybe<Scalars["Float"]>;
  address_out_env?: Maybe<Scalars["Float"]>;
  address_out_env_close?: Maybe<Scalars["Float"]>;
  address_out_triatomines?: Maybe<Scalars["Float"]>;
  address_triatomines?: Maybe<Scalars["Float"]>;
  clean_area?: Maybe<Scalars["Float"]>;
  family_size?: Maybe<Scalars["Float"]>;
  house_bird?: Maybe<Scalars["Float"]>;
  house_cat?: Maybe<Scalars["Float"]>;
  house_cuy?: Maybe<Scalars["Float"]>;
  house_dog?: Maybe<Scalars["Float"]>;
  house_other_animal_1_number?: Maybe<Scalars["Float"]>;
  house_other_animal_2_number?: Maybe<Scalars["Float"]>;
  house_other_animal_3_number?: Maybe<Scalars["Float"]>;
  house_other_animal_4_number?: Maybe<Scalars["Float"]>;
  house_other_animal_5_number?: Maybe<Scalars["Float"]>;
  house_other_animal_number?: Maybe<Scalars["Float"]>;
  house_rabbit?: Maybe<Scalars["Float"]>;
  roof_other_animal_1_number?: Maybe<Scalars["Float"]>;
  roof_other_animal_2_number?: Maybe<Scalars["Float"]>;
  roof_other_animal_3_number?: Maybe<Scalars["Float"]>;
  roof_other_animal_4_number?: Maybe<Scalars["Float"]>;
  roof_other_animal_5_number?: Maybe<Scalars["Float"]>;
  roof_other_animal_number?: Maybe<Scalars["Float"]>;
  roof_top_bird?: Maybe<Scalars["Float"]>;
  roof_top_cat?: Maybe<Scalars["Float"]>;
  roof_top_cuy?: Maybe<Scalars["Float"]>;
  roof_top_dog?: Maybe<Scalars["Float"]>;
  roof_top_rabbit?: Maybe<Scalars["Float"]>;
  spent_insecticides?: Maybe<Scalars["Float"]>;
  yard_bird?: Maybe<Scalars["Float"]>;
  yard_cat?: Maybe<Scalars["Float"]>;
  yard_cuy?: Maybe<Scalars["Float"]>;
  yard_dog?: Maybe<Scalars["Float"]>;
  yard_other_animal_1_number?: Maybe<Scalars["Float"]>;
  yard_other_animal_2_number?: Maybe<Scalars["Float"]>;
  yard_other_animal_3_number?: Maybe<Scalars["Float"]>;
  yard_other_animal_4_number?: Maybe<Scalars["Float"]>;
  yard_other_animal_5_number?: Maybe<Scalars["Float"]>;
  yard_other_animal_number?: Maybe<Scalars["Float"]>;
  yard_rabbit?: Maybe<Scalars["Float"]>;
};

/** order by stddev_pop() on columns of table "sprayed" */
export type Sprayed_Stddev_Pop_Order_By = {
  address_in_crack?: InputMaybe<Order_By>;
  address_in_env?: InputMaybe<Order_By>;
  address_in_env_close?: InputMaybe<Order_By>;
  address_out_crack?: InputMaybe<Order_By>;
  address_out_env?: InputMaybe<Order_By>;
  address_out_env_close?: InputMaybe<Order_By>;
  address_out_triatomines?: InputMaybe<Order_By>;
  address_triatomines?: InputMaybe<Order_By>;
  clean_area?: InputMaybe<Order_By>;
  family_size?: InputMaybe<Order_By>;
  house_bird?: InputMaybe<Order_By>;
  house_cat?: InputMaybe<Order_By>;
  house_cuy?: InputMaybe<Order_By>;
  house_dog?: InputMaybe<Order_By>;
  house_other_animal_1_number?: InputMaybe<Order_By>;
  house_other_animal_2_number?: InputMaybe<Order_By>;
  house_other_animal_3_number?: InputMaybe<Order_By>;
  house_other_animal_4_number?: InputMaybe<Order_By>;
  house_other_animal_5_number?: InputMaybe<Order_By>;
  house_other_animal_number?: InputMaybe<Order_By>;
  house_rabbit?: InputMaybe<Order_By>;
  roof_other_animal_1_number?: InputMaybe<Order_By>;
  roof_other_animal_2_number?: InputMaybe<Order_By>;
  roof_other_animal_3_number?: InputMaybe<Order_By>;
  roof_other_animal_4_number?: InputMaybe<Order_By>;
  roof_other_animal_5_number?: InputMaybe<Order_By>;
  roof_other_animal_number?: InputMaybe<Order_By>;
  roof_top_bird?: InputMaybe<Order_By>;
  roof_top_cat?: InputMaybe<Order_By>;
  roof_top_cuy?: InputMaybe<Order_By>;
  roof_top_dog?: InputMaybe<Order_By>;
  roof_top_rabbit?: InputMaybe<Order_By>;
  spent_insecticides?: InputMaybe<Order_By>;
  yard_bird?: InputMaybe<Order_By>;
  yard_cat?: InputMaybe<Order_By>;
  yard_cuy?: InputMaybe<Order_By>;
  yard_dog?: InputMaybe<Order_By>;
  yard_other_animal_1_number?: InputMaybe<Order_By>;
  yard_other_animal_2_number?: InputMaybe<Order_By>;
  yard_other_animal_3_number?: InputMaybe<Order_By>;
  yard_other_animal_4_number?: InputMaybe<Order_By>;
  yard_other_animal_5_number?: InputMaybe<Order_By>;
  yard_other_animal_number?: InputMaybe<Order_By>;
  yard_rabbit?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Sprayed_Stddev_Samp_Fields = {
  __typename?: "sprayed_stddev_samp_fields";
  address_in_crack?: Maybe<Scalars["Float"]>;
  address_in_env?: Maybe<Scalars["Float"]>;
  address_in_env_close?: Maybe<Scalars["Float"]>;
  address_out_crack?: Maybe<Scalars["Float"]>;
  address_out_env?: Maybe<Scalars["Float"]>;
  address_out_env_close?: Maybe<Scalars["Float"]>;
  address_out_triatomines?: Maybe<Scalars["Float"]>;
  address_triatomines?: Maybe<Scalars["Float"]>;
  clean_area?: Maybe<Scalars["Float"]>;
  family_size?: Maybe<Scalars["Float"]>;
  house_bird?: Maybe<Scalars["Float"]>;
  house_cat?: Maybe<Scalars["Float"]>;
  house_cuy?: Maybe<Scalars["Float"]>;
  house_dog?: Maybe<Scalars["Float"]>;
  house_other_animal_1_number?: Maybe<Scalars["Float"]>;
  house_other_animal_2_number?: Maybe<Scalars["Float"]>;
  house_other_animal_3_number?: Maybe<Scalars["Float"]>;
  house_other_animal_4_number?: Maybe<Scalars["Float"]>;
  house_other_animal_5_number?: Maybe<Scalars["Float"]>;
  house_other_animal_number?: Maybe<Scalars["Float"]>;
  house_rabbit?: Maybe<Scalars["Float"]>;
  roof_other_animal_1_number?: Maybe<Scalars["Float"]>;
  roof_other_animal_2_number?: Maybe<Scalars["Float"]>;
  roof_other_animal_3_number?: Maybe<Scalars["Float"]>;
  roof_other_animal_4_number?: Maybe<Scalars["Float"]>;
  roof_other_animal_5_number?: Maybe<Scalars["Float"]>;
  roof_other_animal_number?: Maybe<Scalars["Float"]>;
  roof_top_bird?: Maybe<Scalars["Float"]>;
  roof_top_cat?: Maybe<Scalars["Float"]>;
  roof_top_cuy?: Maybe<Scalars["Float"]>;
  roof_top_dog?: Maybe<Scalars["Float"]>;
  roof_top_rabbit?: Maybe<Scalars["Float"]>;
  spent_insecticides?: Maybe<Scalars["Float"]>;
  yard_bird?: Maybe<Scalars["Float"]>;
  yard_cat?: Maybe<Scalars["Float"]>;
  yard_cuy?: Maybe<Scalars["Float"]>;
  yard_dog?: Maybe<Scalars["Float"]>;
  yard_other_animal_1_number?: Maybe<Scalars["Float"]>;
  yard_other_animal_2_number?: Maybe<Scalars["Float"]>;
  yard_other_animal_3_number?: Maybe<Scalars["Float"]>;
  yard_other_animal_4_number?: Maybe<Scalars["Float"]>;
  yard_other_animal_5_number?: Maybe<Scalars["Float"]>;
  yard_other_animal_number?: Maybe<Scalars["Float"]>;
  yard_rabbit?: Maybe<Scalars["Float"]>;
};

/** order by stddev_samp() on columns of table "sprayed" */
export type Sprayed_Stddev_Samp_Order_By = {
  address_in_crack?: InputMaybe<Order_By>;
  address_in_env?: InputMaybe<Order_By>;
  address_in_env_close?: InputMaybe<Order_By>;
  address_out_crack?: InputMaybe<Order_By>;
  address_out_env?: InputMaybe<Order_By>;
  address_out_env_close?: InputMaybe<Order_By>;
  address_out_triatomines?: InputMaybe<Order_By>;
  address_triatomines?: InputMaybe<Order_By>;
  clean_area?: InputMaybe<Order_By>;
  family_size?: InputMaybe<Order_By>;
  house_bird?: InputMaybe<Order_By>;
  house_cat?: InputMaybe<Order_By>;
  house_cuy?: InputMaybe<Order_By>;
  house_dog?: InputMaybe<Order_By>;
  house_other_animal_1_number?: InputMaybe<Order_By>;
  house_other_animal_2_number?: InputMaybe<Order_By>;
  house_other_animal_3_number?: InputMaybe<Order_By>;
  house_other_animal_4_number?: InputMaybe<Order_By>;
  house_other_animal_5_number?: InputMaybe<Order_By>;
  house_other_animal_number?: InputMaybe<Order_By>;
  house_rabbit?: InputMaybe<Order_By>;
  roof_other_animal_1_number?: InputMaybe<Order_By>;
  roof_other_animal_2_number?: InputMaybe<Order_By>;
  roof_other_animal_3_number?: InputMaybe<Order_By>;
  roof_other_animal_4_number?: InputMaybe<Order_By>;
  roof_other_animal_5_number?: InputMaybe<Order_By>;
  roof_other_animal_number?: InputMaybe<Order_By>;
  roof_top_bird?: InputMaybe<Order_By>;
  roof_top_cat?: InputMaybe<Order_By>;
  roof_top_cuy?: InputMaybe<Order_By>;
  roof_top_dog?: InputMaybe<Order_By>;
  roof_top_rabbit?: InputMaybe<Order_By>;
  spent_insecticides?: InputMaybe<Order_By>;
  yard_bird?: InputMaybe<Order_By>;
  yard_cat?: InputMaybe<Order_By>;
  yard_cuy?: InputMaybe<Order_By>;
  yard_dog?: InputMaybe<Order_By>;
  yard_other_animal_1_number?: InputMaybe<Order_By>;
  yard_other_animal_2_number?: InputMaybe<Order_By>;
  yard_other_animal_3_number?: InputMaybe<Order_By>;
  yard_other_animal_4_number?: InputMaybe<Order_By>;
  yard_other_animal_5_number?: InputMaybe<Order_By>;
  yard_other_animal_number?: InputMaybe<Order_By>;
  yard_rabbit?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "sprayed" */
export type Sprayed_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Sprayed_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Sprayed_Stream_Cursor_Value_Input = {
  address_in_crack?: InputMaybe<Scalars["Int"]>;
  address_in_env?: InputMaybe<Scalars["Int"]>;
  address_in_env_close?: InputMaybe<Scalars["Int"]>;
  address_in_material?: InputMaybe<Scalars["String"]>;
  address_out_crack?: InputMaybe<Scalars["Int"]>;
  address_out_env?: InputMaybe<Scalars["Int"]>;
  address_out_env_close?: InputMaybe<Scalars["Int"]>;
  address_out_material?: InputMaybe<Scalars["String"]>;
  address_out_triatomines?: InputMaybe<Scalars["Int"]>;
  address_triatomines?: InputMaybe<Scalars["Int"]>;
  app_state?: InputMaybe<Scalars["String"]>;
  clean_area?: InputMaybe<Scalars["Int"]>;
  collaborator_id?: InputMaybe<Scalars["uuid"]>;
  complaint_id?: InputMaybe<Scalars["uuid"]>;
  comprehensive_treatment_metadata?: InputMaybe<Scalars["jsonb"]>;
  created_at?: InputMaybe<Scalars["timestamptz"]>;
  experiment_id?: InputMaybe<Scalars["uuid"]>;
  family_chief?: InputMaybe<Scalars["String"]>;
  family_size?: InputMaybe<Scalars["Int"]>;
  firebase_id?: InputMaybe<Scalars["String"]>;
  from_radio?: InputMaybe<Scalars["Boolean"]>;
  geogroup_id?: InputMaybe<Scalars["uuid"]>;
  geopoint_id?: InputMaybe<Scalars["uuid"]>;
  geopoint_unicode?: InputMaybe<Scalars["String"]>;
  house_bird?: InputMaybe<Scalars["Int"]>;
  house_cat?: InputMaybe<Scalars["Int"]>;
  house_cuy?: InputMaybe<Scalars["Int"]>;
  house_dog?: InputMaybe<Scalars["Int"]>;
  house_other_animal_1_name?: InputMaybe<Scalars["String"]>;
  house_other_animal_1_number?: InputMaybe<Scalars["Int"]>;
  house_other_animal_2_name?: InputMaybe<Scalars["String"]>;
  house_other_animal_2_number?: InputMaybe<Scalars["Int"]>;
  house_other_animal_3_name?: InputMaybe<Scalars["String"]>;
  house_other_animal_3_number?: InputMaybe<Scalars["Int"]>;
  house_other_animal_4_name?: InputMaybe<Scalars["String"]>;
  house_other_animal_4_number?: InputMaybe<Scalars["Int"]>;
  house_other_animal_5_name?: InputMaybe<Scalars["String"]>;
  house_other_animal_5_number?: InputMaybe<Scalars["Int"]>;
  house_other_animal_name?: InputMaybe<Scalars["String"]>;
  house_other_animal_number?: InputMaybe<Scalars["Int"]>;
  house_rabbit?: InputMaybe<Scalars["Int"]>;
  id?: InputMaybe<Scalars["uuid"]>;
  insecticide?: InputMaybe<Scalars["String"]>;
  origin_from_complaint?: InputMaybe<Scalars["String"]>;
  reported_at?: InputMaybe<Scalars["timestamptz"]>;
  residual_treatment_kind?: InputMaybe<Scalars["String"]>;
  roof_other_animal_1_name?: InputMaybe<Scalars["String"]>;
  roof_other_animal_1_number?: InputMaybe<Scalars["Int"]>;
  roof_other_animal_2_name?: InputMaybe<Scalars["String"]>;
  roof_other_animal_2_number?: InputMaybe<Scalars["Int"]>;
  roof_other_animal_3_name?: InputMaybe<Scalars["String"]>;
  roof_other_animal_3_number?: InputMaybe<Scalars["Int"]>;
  roof_other_animal_4_name?: InputMaybe<Scalars["String"]>;
  roof_other_animal_4_number?: InputMaybe<Scalars["Int"]>;
  roof_other_animal_5_name?: InputMaybe<Scalars["String"]>;
  roof_other_animal_5_number?: InputMaybe<Scalars["Int"]>;
  roof_other_animal_name?: InputMaybe<Scalars["String"]>;
  roof_other_animal_number?: InputMaybe<Scalars["Int"]>;
  roof_top_bird?: InputMaybe<Scalars["Int"]>;
  roof_top_cat?: InputMaybe<Scalars["Int"]>;
  roof_top_cuy?: InputMaybe<Scalars["Int"]>;
  roof_top_dog?: InputMaybe<Scalars["Int"]>;
  roof_top_rabbit?: InputMaybe<Scalars["Int"]>;
  spent_insecticides?: InputMaybe<Scalars["Int"]>;
  sprayed_intra_event?: InputMaybe<Scalars["jsonb"]>;
  sprayed_peri_event?: InputMaybe<Scalars["jsonb"]>;
  sprayer_id?: InputMaybe<Scalars["uuid"]>;
  sprayer_name?: InputMaybe<Scalars["String"]>;
  unicode_from_radio?: InputMaybe<Scalars["String"]>;
  updated_at?: InputMaybe<Scalars["timestamptz"]>;
  workspace_id?: InputMaybe<Scalars["uuid"]>;
  workspace_metadata_id?: InputMaybe<Scalars["uuid"]>;
  yard_bird?: InputMaybe<Scalars["Int"]>;
  yard_cat?: InputMaybe<Scalars["Int"]>;
  yard_cuy?: InputMaybe<Scalars["Int"]>;
  yard_dog?: InputMaybe<Scalars["Int"]>;
  yard_other_animal_1_name?: InputMaybe<Scalars["String"]>;
  yard_other_animal_1_number?: InputMaybe<Scalars["Int"]>;
  yard_other_animal_2_name?: InputMaybe<Scalars["String"]>;
  yard_other_animal_2_number?: InputMaybe<Scalars["Int"]>;
  yard_other_animal_3_name?: InputMaybe<Scalars["String"]>;
  yard_other_animal_3_number?: InputMaybe<Scalars["Int"]>;
  yard_other_animal_4_name?: InputMaybe<Scalars["String"]>;
  yard_other_animal_4_number?: InputMaybe<Scalars["Int"]>;
  yard_other_animal_5_name?: InputMaybe<Scalars["String"]>;
  yard_other_animal_5_number?: InputMaybe<Scalars["Int"]>;
  yard_other_animal_name?: InputMaybe<Scalars["String"]>;
  yard_other_animal_number?: InputMaybe<Scalars["Int"]>;
  yard_rabbit?: InputMaybe<Scalars["Int"]>;
};

/** aggregate sum on columns */
export type Sprayed_Sum_Fields = {
  __typename?: "sprayed_sum_fields";
  address_in_crack?: Maybe<Scalars["Int"]>;
  address_in_env?: Maybe<Scalars["Int"]>;
  address_in_env_close?: Maybe<Scalars["Int"]>;
  address_out_crack?: Maybe<Scalars["Int"]>;
  address_out_env?: Maybe<Scalars["Int"]>;
  address_out_env_close?: Maybe<Scalars["Int"]>;
  address_out_triatomines?: Maybe<Scalars["Int"]>;
  address_triatomines?: Maybe<Scalars["Int"]>;
  clean_area?: Maybe<Scalars["Int"]>;
  family_size?: Maybe<Scalars["Int"]>;
  house_bird?: Maybe<Scalars["Int"]>;
  house_cat?: Maybe<Scalars["Int"]>;
  house_cuy?: Maybe<Scalars["Int"]>;
  house_dog?: Maybe<Scalars["Int"]>;
  house_other_animal_1_number?: Maybe<Scalars["Int"]>;
  house_other_animal_2_number?: Maybe<Scalars["Int"]>;
  house_other_animal_3_number?: Maybe<Scalars["Int"]>;
  house_other_animal_4_number?: Maybe<Scalars["Int"]>;
  house_other_animal_5_number?: Maybe<Scalars["Int"]>;
  house_other_animal_number?: Maybe<Scalars["Int"]>;
  house_rabbit?: Maybe<Scalars["Int"]>;
  roof_other_animal_1_number?: Maybe<Scalars["Int"]>;
  roof_other_animal_2_number?: Maybe<Scalars["Int"]>;
  roof_other_animal_3_number?: Maybe<Scalars["Int"]>;
  roof_other_animal_4_number?: Maybe<Scalars["Int"]>;
  roof_other_animal_5_number?: Maybe<Scalars["Int"]>;
  roof_other_animal_number?: Maybe<Scalars["Int"]>;
  roof_top_bird?: Maybe<Scalars["Int"]>;
  roof_top_cat?: Maybe<Scalars["Int"]>;
  roof_top_cuy?: Maybe<Scalars["Int"]>;
  roof_top_dog?: Maybe<Scalars["Int"]>;
  roof_top_rabbit?: Maybe<Scalars["Int"]>;
  spent_insecticides?: Maybe<Scalars["Int"]>;
  yard_bird?: Maybe<Scalars["Int"]>;
  yard_cat?: Maybe<Scalars["Int"]>;
  yard_cuy?: Maybe<Scalars["Int"]>;
  yard_dog?: Maybe<Scalars["Int"]>;
  yard_other_animal_1_number?: Maybe<Scalars["Int"]>;
  yard_other_animal_2_number?: Maybe<Scalars["Int"]>;
  yard_other_animal_3_number?: Maybe<Scalars["Int"]>;
  yard_other_animal_4_number?: Maybe<Scalars["Int"]>;
  yard_other_animal_5_number?: Maybe<Scalars["Int"]>;
  yard_other_animal_number?: Maybe<Scalars["Int"]>;
  yard_rabbit?: Maybe<Scalars["Int"]>;
};

/** order by sum() on columns of table "sprayed" */
export type Sprayed_Sum_Order_By = {
  address_in_crack?: InputMaybe<Order_By>;
  address_in_env?: InputMaybe<Order_By>;
  address_in_env_close?: InputMaybe<Order_By>;
  address_out_crack?: InputMaybe<Order_By>;
  address_out_env?: InputMaybe<Order_By>;
  address_out_env_close?: InputMaybe<Order_By>;
  address_out_triatomines?: InputMaybe<Order_By>;
  address_triatomines?: InputMaybe<Order_By>;
  clean_area?: InputMaybe<Order_By>;
  family_size?: InputMaybe<Order_By>;
  house_bird?: InputMaybe<Order_By>;
  house_cat?: InputMaybe<Order_By>;
  house_cuy?: InputMaybe<Order_By>;
  house_dog?: InputMaybe<Order_By>;
  house_other_animal_1_number?: InputMaybe<Order_By>;
  house_other_animal_2_number?: InputMaybe<Order_By>;
  house_other_animal_3_number?: InputMaybe<Order_By>;
  house_other_animal_4_number?: InputMaybe<Order_By>;
  house_other_animal_5_number?: InputMaybe<Order_By>;
  house_other_animal_number?: InputMaybe<Order_By>;
  house_rabbit?: InputMaybe<Order_By>;
  roof_other_animal_1_number?: InputMaybe<Order_By>;
  roof_other_animal_2_number?: InputMaybe<Order_By>;
  roof_other_animal_3_number?: InputMaybe<Order_By>;
  roof_other_animal_4_number?: InputMaybe<Order_By>;
  roof_other_animal_5_number?: InputMaybe<Order_By>;
  roof_other_animal_number?: InputMaybe<Order_By>;
  roof_top_bird?: InputMaybe<Order_By>;
  roof_top_cat?: InputMaybe<Order_By>;
  roof_top_cuy?: InputMaybe<Order_By>;
  roof_top_dog?: InputMaybe<Order_By>;
  roof_top_rabbit?: InputMaybe<Order_By>;
  spent_insecticides?: InputMaybe<Order_By>;
  yard_bird?: InputMaybe<Order_By>;
  yard_cat?: InputMaybe<Order_By>;
  yard_cuy?: InputMaybe<Order_By>;
  yard_dog?: InputMaybe<Order_By>;
  yard_other_animal_1_number?: InputMaybe<Order_By>;
  yard_other_animal_2_number?: InputMaybe<Order_By>;
  yard_other_animal_3_number?: InputMaybe<Order_By>;
  yard_other_animal_4_number?: InputMaybe<Order_By>;
  yard_other_animal_5_number?: InputMaybe<Order_By>;
  yard_other_animal_number?: InputMaybe<Order_By>;
  yard_rabbit?: InputMaybe<Order_By>;
};

/** update columns of table "sprayed" */
export enum Sprayed_Update_Column {
  /** column name */
  AddressInCrack = "address_in_crack",
  /** column name */
  AddressInEnv = "address_in_env",
  /** column name */
  AddressInEnvClose = "address_in_env_close",
  /** column name */
  AddressInMaterial = "address_in_material",
  /** column name */
  AddressOutCrack = "address_out_crack",
  /** column name */
  AddressOutEnv = "address_out_env",
  /** column name */
  AddressOutEnvClose = "address_out_env_close",
  /** column name */
  AddressOutMaterial = "address_out_material",
  /** column name */
  AddressOutTriatomines = "address_out_triatomines",
  /** column name */
  AddressTriatomines = "address_triatomines",
  /** column name */
  AppState = "app_state",
  /** column name */
  CleanArea = "clean_area",
  /** column name */
  CollaboratorId = "collaborator_id",
  /** column name */
  ComplaintId = "complaint_id",
  /** column name */
  ComprehensiveTreatmentMetadata = "comprehensive_treatment_metadata",
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  ExperimentId = "experiment_id",
  /** column name */
  FamilyChief = "family_chief",
  /** column name */
  FamilySize = "family_size",
  /** column name */
  FirebaseId = "firebase_id",
  /** column name */
  FromRadio = "from_radio",
  /** column name */
  GeogroupId = "geogroup_id",
  /** column name */
  GeopointId = "geopoint_id",
  /** column name */
  GeopointUnicode = "geopoint_unicode",
  /** column name */
  HouseBird = "house_bird",
  /** column name */
  HouseCat = "house_cat",
  /** column name */
  HouseCuy = "house_cuy",
  /** column name */
  HouseDog = "house_dog",
  /** column name */
  HouseOtherAnimal_1Name = "house_other_animal_1_name",
  /** column name */
  HouseOtherAnimal_1Number = "house_other_animal_1_number",
  /** column name */
  HouseOtherAnimal_2Name = "house_other_animal_2_name",
  /** column name */
  HouseOtherAnimal_2Number = "house_other_animal_2_number",
  /** column name */
  HouseOtherAnimal_3Name = "house_other_animal_3_name",
  /** column name */
  HouseOtherAnimal_3Number = "house_other_animal_3_number",
  /** column name */
  HouseOtherAnimal_4Name = "house_other_animal_4_name",
  /** column name */
  HouseOtherAnimal_4Number = "house_other_animal_4_number",
  /** column name */
  HouseOtherAnimal_5Name = "house_other_animal_5_name",
  /** column name */
  HouseOtherAnimal_5Number = "house_other_animal_5_number",
  /** column name */
  HouseOtherAnimalName = "house_other_animal_name",
  /** column name */
  HouseOtherAnimalNumber = "house_other_animal_number",
  /** column name */
  HouseRabbit = "house_rabbit",
  /** column name */
  Id = "id",
  /** column name */
  Insecticide = "insecticide",
  /** column name */
  OriginFromComplaint = "origin_from_complaint",
  /** column name */
  ReportedAt = "reported_at",
  /** column name */
  ResidualTreatmentKind = "residual_treatment_kind",
  /** column name */
  RoofOtherAnimal_1Name = "roof_other_animal_1_name",
  /** column name */
  RoofOtherAnimal_1Number = "roof_other_animal_1_number",
  /** column name */
  RoofOtherAnimal_2Name = "roof_other_animal_2_name",
  /** column name */
  RoofOtherAnimal_2Number = "roof_other_animal_2_number",
  /** column name */
  RoofOtherAnimal_3Name = "roof_other_animal_3_name",
  /** column name */
  RoofOtherAnimal_3Number = "roof_other_animal_3_number",
  /** column name */
  RoofOtherAnimal_4Name = "roof_other_animal_4_name",
  /** column name */
  RoofOtherAnimal_4Number = "roof_other_animal_4_number",
  /** column name */
  RoofOtherAnimal_5Name = "roof_other_animal_5_name",
  /** column name */
  RoofOtherAnimal_5Number = "roof_other_animal_5_number",
  /** column name */
  RoofOtherAnimalName = "roof_other_animal_name",
  /** column name */
  RoofOtherAnimalNumber = "roof_other_animal_number",
  /** column name */
  RoofTopBird = "roof_top_bird",
  /** column name */
  RoofTopCat = "roof_top_cat",
  /** column name */
  RoofTopCuy = "roof_top_cuy",
  /** column name */
  RoofTopDog = "roof_top_dog",
  /** column name */
  RoofTopRabbit = "roof_top_rabbit",
  /** column name */
  SpentInsecticides = "spent_insecticides",
  /** column name */
  SprayedIntraEvent = "sprayed_intra_event",
  /** column name */
  SprayedPeriEvent = "sprayed_peri_event",
  /** column name */
  SprayerId = "sprayer_id",
  /** column name */
  SprayerName = "sprayer_name",
  /** column name */
  UnicodeFromRadio = "unicode_from_radio",
  /** column name */
  UpdatedAt = "updated_at",
  /** column name */
  WorkspaceId = "workspace_id",
  /** column name */
  WorkspaceMetadataId = "workspace_metadata_id",
  /** column name */
  YardBird = "yard_bird",
  /** column name */
  YardCat = "yard_cat",
  /** column name */
  YardCuy = "yard_cuy",
  /** column name */
  YardDog = "yard_dog",
  /** column name */
  YardOtherAnimal_1Name = "yard_other_animal_1_name",
  /** column name */
  YardOtherAnimal_1Number = "yard_other_animal_1_number",
  /** column name */
  YardOtherAnimal_2Name = "yard_other_animal_2_name",
  /** column name */
  YardOtherAnimal_2Number = "yard_other_animal_2_number",
  /** column name */
  YardOtherAnimal_3Name = "yard_other_animal_3_name",
  /** column name */
  YardOtherAnimal_3Number = "yard_other_animal_3_number",
  /** column name */
  YardOtherAnimal_4Name = "yard_other_animal_4_name",
  /** column name */
  YardOtherAnimal_4Number = "yard_other_animal_4_number",
  /** column name */
  YardOtherAnimal_5Name = "yard_other_animal_5_name",
  /** column name */
  YardOtherAnimal_5Number = "yard_other_animal_5_number",
  /** column name */
  YardOtherAnimalName = "yard_other_animal_name",
  /** column name */
  YardOtherAnimalNumber = "yard_other_animal_number",
  /** column name */
  YardRabbit = "yard_rabbit",
}

export type Sprayed_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Sprayed_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Sprayed_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Sprayed_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Sprayed_Delete_Key_Input>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Sprayed_Inc_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Sprayed_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Sprayed_Set_Input>;
  /** filter the rows which have to be updated */
  where: Sprayed_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Sprayed_Var_Pop_Fields = {
  __typename?: "sprayed_var_pop_fields";
  address_in_crack?: Maybe<Scalars["Float"]>;
  address_in_env?: Maybe<Scalars["Float"]>;
  address_in_env_close?: Maybe<Scalars["Float"]>;
  address_out_crack?: Maybe<Scalars["Float"]>;
  address_out_env?: Maybe<Scalars["Float"]>;
  address_out_env_close?: Maybe<Scalars["Float"]>;
  address_out_triatomines?: Maybe<Scalars["Float"]>;
  address_triatomines?: Maybe<Scalars["Float"]>;
  clean_area?: Maybe<Scalars["Float"]>;
  family_size?: Maybe<Scalars["Float"]>;
  house_bird?: Maybe<Scalars["Float"]>;
  house_cat?: Maybe<Scalars["Float"]>;
  house_cuy?: Maybe<Scalars["Float"]>;
  house_dog?: Maybe<Scalars["Float"]>;
  house_other_animal_1_number?: Maybe<Scalars["Float"]>;
  house_other_animal_2_number?: Maybe<Scalars["Float"]>;
  house_other_animal_3_number?: Maybe<Scalars["Float"]>;
  house_other_animal_4_number?: Maybe<Scalars["Float"]>;
  house_other_animal_5_number?: Maybe<Scalars["Float"]>;
  house_other_animal_number?: Maybe<Scalars["Float"]>;
  house_rabbit?: Maybe<Scalars["Float"]>;
  roof_other_animal_1_number?: Maybe<Scalars["Float"]>;
  roof_other_animal_2_number?: Maybe<Scalars["Float"]>;
  roof_other_animal_3_number?: Maybe<Scalars["Float"]>;
  roof_other_animal_4_number?: Maybe<Scalars["Float"]>;
  roof_other_animal_5_number?: Maybe<Scalars["Float"]>;
  roof_other_animal_number?: Maybe<Scalars["Float"]>;
  roof_top_bird?: Maybe<Scalars["Float"]>;
  roof_top_cat?: Maybe<Scalars["Float"]>;
  roof_top_cuy?: Maybe<Scalars["Float"]>;
  roof_top_dog?: Maybe<Scalars["Float"]>;
  roof_top_rabbit?: Maybe<Scalars["Float"]>;
  spent_insecticides?: Maybe<Scalars["Float"]>;
  yard_bird?: Maybe<Scalars["Float"]>;
  yard_cat?: Maybe<Scalars["Float"]>;
  yard_cuy?: Maybe<Scalars["Float"]>;
  yard_dog?: Maybe<Scalars["Float"]>;
  yard_other_animal_1_number?: Maybe<Scalars["Float"]>;
  yard_other_animal_2_number?: Maybe<Scalars["Float"]>;
  yard_other_animal_3_number?: Maybe<Scalars["Float"]>;
  yard_other_animal_4_number?: Maybe<Scalars["Float"]>;
  yard_other_animal_5_number?: Maybe<Scalars["Float"]>;
  yard_other_animal_number?: Maybe<Scalars["Float"]>;
  yard_rabbit?: Maybe<Scalars["Float"]>;
};

/** order by var_pop() on columns of table "sprayed" */
export type Sprayed_Var_Pop_Order_By = {
  address_in_crack?: InputMaybe<Order_By>;
  address_in_env?: InputMaybe<Order_By>;
  address_in_env_close?: InputMaybe<Order_By>;
  address_out_crack?: InputMaybe<Order_By>;
  address_out_env?: InputMaybe<Order_By>;
  address_out_env_close?: InputMaybe<Order_By>;
  address_out_triatomines?: InputMaybe<Order_By>;
  address_triatomines?: InputMaybe<Order_By>;
  clean_area?: InputMaybe<Order_By>;
  family_size?: InputMaybe<Order_By>;
  house_bird?: InputMaybe<Order_By>;
  house_cat?: InputMaybe<Order_By>;
  house_cuy?: InputMaybe<Order_By>;
  house_dog?: InputMaybe<Order_By>;
  house_other_animal_1_number?: InputMaybe<Order_By>;
  house_other_animal_2_number?: InputMaybe<Order_By>;
  house_other_animal_3_number?: InputMaybe<Order_By>;
  house_other_animal_4_number?: InputMaybe<Order_By>;
  house_other_animal_5_number?: InputMaybe<Order_By>;
  house_other_animal_number?: InputMaybe<Order_By>;
  house_rabbit?: InputMaybe<Order_By>;
  roof_other_animal_1_number?: InputMaybe<Order_By>;
  roof_other_animal_2_number?: InputMaybe<Order_By>;
  roof_other_animal_3_number?: InputMaybe<Order_By>;
  roof_other_animal_4_number?: InputMaybe<Order_By>;
  roof_other_animal_5_number?: InputMaybe<Order_By>;
  roof_other_animal_number?: InputMaybe<Order_By>;
  roof_top_bird?: InputMaybe<Order_By>;
  roof_top_cat?: InputMaybe<Order_By>;
  roof_top_cuy?: InputMaybe<Order_By>;
  roof_top_dog?: InputMaybe<Order_By>;
  roof_top_rabbit?: InputMaybe<Order_By>;
  spent_insecticides?: InputMaybe<Order_By>;
  yard_bird?: InputMaybe<Order_By>;
  yard_cat?: InputMaybe<Order_By>;
  yard_cuy?: InputMaybe<Order_By>;
  yard_dog?: InputMaybe<Order_By>;
  yard_other_animal_1_number?: InputMaybe<Order_By>;
  yard_other_animal_2_number?: InputMaybe<Order_By>;
  yard_other_animal_3_number?: InputMaybe<Order_By>;
  yard_other_animal_4_number?: InputMaybe<Order_By>;
  yard_other_animal_5_number?: InputMaybe<Order_By>;
  yard_other_animal_number?: InputMaybe<Order_By>;
  yard_rabbit?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Sprayed_Var_Samp_Fields = {
  __typename?: "sprayed_var_samp_fields";
  address_in_crack?: Maybe<Scalars["Float"]>;
  address_in_env?: Maybe<Scalars["Float"]>;
  address_in_env_close?: Maybe<Scalars["Float"]>;
  address_out_crack?: Maybe<Scalars["Float"]>;
  address_out_env?: Maybe<Scalars["Float"]>;
  address_out_env_close?: Maybe<Scalars["Float"]>;
  address_out_triatomines?: Maybe<Scalars["Float"]>;
  address_triatomines?: Maybe<Scalars["Float"]>;
  clean_area?: Maybe<Scalars["Float"]>;
  family_size?: Maybe<Scalars["Float"]>;
  house_bird?: Maybe<Scalars["Float"]>;
  house_cat?: Maybe<Scalars["Float"]>;
  house_cuy?: Maybe<Scalars["Float"]>;
  house_dog?: Maybe<Scalars["Float"]>;
  house_other_animal_1_number?: Maybe<Scalars["Float"]>;
  house_other_animal_2_number?: Maybe<Scalars["Float"]>;
  house_other_animal_3_number?: Maybe<Scalars["Float"]>;
  house_other_animal_4_number?: Maybe<Scalars["Float"]>;
  house_other_animal_5_number?: Maybe<Scalars["Float"]>;
  house_other_animal_number?: Maybe<Scalars["Float"]>;
  house_rabbit?: Maybe<Scalars["Float"]>;
  roof_other_animal_1_number?: Maybe<Scalars["Float"]>;
  roof_other_animal_2_number?: Maybe<Scalars["Float"]>;
  roof_other_animal_3_number?: Maybe<Scalars["Float"]>;
  roof_other_animal_4_number?: Maybe<Scalars["Float"]>;
  roof_other_animal_5_number?: Maybe<Scalars["Float"]>;
  roof_other_animal_number?: Maybe<Scalars["Float"]>;
  roof_top_bird?: Maybe<Scalars["Float"]>;
  roof_top_cat?: Maybe<Scalars["Float"]>;
  roof_top_cuy?: Maybe<Scalars["Float"]>;
  roof_top_dog?: Maybe<Scalars["Float"]>;
  roof_top_rabbit?: Maybe<Scalars["Float"]>;
  spent_insecticides?: Maybe<Scalars["Float"]>;
  yard_bird?: Maybe<Scalars["Float"]>;
  yard_cat?: Maybe<Scalars["Float"]>;
  yard_cuy?: Maybe<Scalars["Float"]>;
  yard_dog?: Maybe<Scalars["Float"]>;
  yard_other_animal_1_number?: Maybe<Scalars["Float"]>;
  yard_other_animal_2_number?: Maybe<Scalars["Float"]>;
  yard_other_animal_3_number?: Maybe<Scalars["Float"]>;
  yard_other_animal_4_number?: Maybe<Scalars["Float"]>;
  yard_other_animal_5_number?: Maybe<Scalars["Float"]>;
  yard_other_animal_number?: Maybe<Scalars["Float"]>;
  yard_rabbit?: Maybe<Scalars["Float"]>;
};

/** order by var_samp() on columns of table "sprayed" */
export type Sprayed_Var_Samp_Order_By = {
  address_in_crack?: InputMaybe<Order_By>;
  address_in_env?: InputMaybe<Order_By>;
  address_in_env_close?: InputMaybe<Order_By>;
  address_out_crack?: InputMaybe<Order_By>;
  address_out_env?: InputMaybe<Order_By>;
  address_out_env_close?: InputMaybe<Order_By>;
  address_out_triatomines?: InputMaybe<Order_By>;
  address_triatomines?: InputMaybe<Order_By>;
  clean_area?: InputMaybe<Order_By>;
  family_size?: InputMaybe<Order_By>;
  house_bird?: InputMaybe<Order_By>;
  house_cat?: InputMaybe<Order_By>;
  house_cuy?: InputMaybe<Order_By>;
  house_dog?: InputMaybe<Order_By>;
  house_other_animal_1_number?: InputMaybe<Order_By>;
  house_other_animal_2_number?: InputMaybe<Order_By>;
  house_other_animal_3_number?: InputMaybe<Order_By>;
  house_other_animal_4_number?: InputMaybe<Order_By>;
  house_other_animal_5_number?: InputMaybe<Order_By>;
  house_other_animal_number?: InputMaybe<Order_By>;
  house_rabbit?: InputMaybe<Order_By>;
  roof_other_animal_1_number?: InputMaybe<Order_By>;
  roof_other_animal_2_number?: InputMaybe<Order_By>;
  roof_other_animal_3_number?: InputMaybe<Order_By>;
  roof_other_animal_4_number?: InputMaybe<Order_By>;
  roof_other_animal_5_number?: InputMaybe<Order_By>;
  roof_other_animal_number?: InputMaybe<Order_By>;
  roof_top_bird?: InputMaybe<Order_By>;
  roof_top_cat?: InputMaybe<Order_By>;
  roof_top_cuy?: InputMaybe<Order_By>;
  roof_top_dog?: InputMaybe<Order_By>;
  roof_top_rabbit?: InputMaybe<Order_By>;
  spent_insecticides?: InputMaybe<Order_By>;
  yard_bird?: InputMaybe<Order_By>;
  yard_cat?: InputMaybe<Order_By>;
  yard_cuy?: InputMaybe<Order_By>;
  yard_dog?: InputMaybe<Order_By>;
  yard_other_animal_1_number?: InputMaybe<Order_By>;
  yard_other_animal_2_number?: InputMaybe<Order_By>;
  yard_other_animal_3_number?: InputMaybe<Order_By>;
  yard_other_animal_4_number?: InputMaybe<Order_By>;
  yard_other_animal_5_number?: InputMaybe<Order_By>;
  yard_other_animal_number?: InputMaybe<Order_By>;
  yard_rabbit?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Sprayed_Variance_Fields = {
  __typename?: "sprayed_variance_fields";
  address_in_crack?: Maybe<Scalars["Float"]>;
  address_in_env?: Maybe<Scalars["Float"]>;
  address_in_env_close?: Maybe<Scalars["Float"]>;
  address_out_crack?: Maybe<Scalars["Float"]>;
  address_out_env?: Maybe<Scalars["Float"]>;
  address_out_env_close?: Maybe<Scalars["Float"]>;
  address_out_triatomines?: Maybe<Scalars["Float"]>;
  address_triatomines?: Maybe<Scalars["Float"]>;
  clean_area?: Maybe<Scalars["Float"]>;
  family_size?: Maybe<Scalars["Float"]>;
  house_bird?: Maybe<Scalars["Float"]>;
  house_cat?: Maybe<Scalars["Float"]>;
  house_cuy?: Maybe<Scalars["Float"]>;
  house_dog?: Maybe<Scalars["Float"]>;
  house_other_animal_1_number?: Maybe<Scalars["Float"]>;
  house_other_animal_2_number?: Maybe<Scalars["Float"]>;
  house_other_animal_3_number?: Maybe<Scalars["Float"]>;
  house_other_animal_4_number?: Maybe<Scalars["Float"]>;
  house_other_animal_5_number?: Maybe<Scalars["Float"]>;
  house_other_animal_number?: Maybe<Scalars["Float"]>;
  house_rabbit?: Maybe<Scalars["Float"]>;
  roof_other_animal_1_number?: Maybe<Scalars["Float"]>;
  roof_other_animal_2_number?: Maybe<Scalars["Float"]>;
  roof_other_animal_3_number?: Maybe<Scalars["Float"]>;
  roof_other_animal_4_number?: Maybe<Scalars["Float"]>;
  roof_other_animal_5_number?: Maybe<Scalars["Float"]>;
  roof_other_animal_number?: Maybe<Scalars["Float"]>;
  roof_top_bird?: Maybe<Scalars["Float"]>;
  roof_top_cat?: Maybe<Scalars["Float"]>;
  roof_top_cuy?: Maybe<Scalars["Float"]>;
  roof_top_dog?: Maybe<Scalars["Float"]>;
  roof_top_rabbit?: Maybe<Scalars["Float"]>;
  spent_insecticides?: Maybe<Scalars["Float"]>;
  yard_bird?: Maybe<Scalars["Float"]>;
  yard_cat?: Maybe<Scalars["Float"]>;
  yard_cuy?: Maybe<Scalars["Float"]>;
  yard_dog?: Maybe<Scalars["Float"]>;
  yard_other_animal_1_number?: Maybe<Scalars["Float"]>;
  yard_other_animal_2_number?: Maybe<Scalars["Float"]>;
  yard_other_animal_3_number?: Maybe<Scalars["Float"]>;
  yard_other_animal_4_number?: Maybe<Scalars["Float"]>;
  yard_other_animal_5_number?: Maybe<Scalars["Float"]>;
  yard_other_animal_number?: Maybe<Scalars["Float"]>;
  yard_rabbit?: Maybe<Scalars["Float"]>;
};

/** order by variance() on columns of table "sprayed" */
export type Sprayed_Variance_Order_By = {
  address_in_crack?: InputMaybe<Order_By>;
  address_in_env?: InputMaybe<Order_By>;
  address_in_env_close?: InputMaybe<Order_By>;
  address_out_crack?: InputMaybe<Order_By>;
  address_out_env?: InputMaybe<Order_By>;
  address_out_env_close?: InputMaybe<Order_By>;
  address_out_triatomines?: InputMaybe<Order_By>;
  address_triatomines?: InputMaybe<Order_By>;
  clean_area?: InputMaybe<Order_By>;
  family_size?: InputMaybe<Order_By>;
  house_bird?: InputMaybe<Order_By>;
  house_cat?: InputMaybe<Order_By>;
  house_cuy?: InputMaybe<Order_By>;
  house_dog?: InputMaybe<Order_By>;
  house_other_animal_1_number?: InputMaybe<Order_By>;
  house_other_animal_2_number?: InputMaybe<Order_By>;
  house_other_animal_3_number?: InputMaybe<Order_By>;
  house_other_animal_4_number?: InputMaybe<Order_By>;
  house_other_animal_5_number?: InputMaybe<Order_By>;
  house_other_animal_number?: InputMaybe<Order_By>;
  house_rabbit?: InputMaybe<Order_By>;
  roof_other_animal_1_number?: InputMaybe<Order_By>;
  roof_other_animal_2_number?: InputMaybe<Order_By>;
  roof_other_animal_3_number?: InputMaybe<Order_By>;
  roof_other_animal_4_number?: InputMaybe<Order_By>;
  roof_other_animal_5_number?: InputMaybe<Order_By>;
  roof_other_animal_number?: InputMaybe<Order_By>;
  roof_top_bird?: InputMaybe<Order_By>;
  roof_top_cat?: InputMaybe<Order_By>;
  roof_top_cuy?: InputMaybe<Order_By>;
  roof_top_dog?: InputMaybe<Order_By>;
  roof_top_rabbit?: InputMaybe<Order_By>;
  spent_insecticides?: InputMaybe<Order_By>;
  yard_bird?: InputMaybe<Order_By>;
  yard_cat?: InputMaybe<Order_By>;
  yard_cuy?: InputMaybe<Order_By>;
  yard_dog?: InputMaybe<Order_By>;
  yard_other_animal_1_number?: InputMaybe<Order_By>;
  yard_other_animal_2_number?: InputMaybe<Order_By>;
  yard_other_animal_3_number?: InputMaybe<Order_By>;
  yard_other_animal_4_number?: InputMaybe<Order_By>;
  yard_other_animal_5_number?: InputMaybe<Order_By>;
  yard_other_animal_number?: InputMaybe<Order_By>;
  yard_rabbit?: InputMaybe<Order_By>;
};

/** columns and relationships of "sprayer" */
export type Sprayer = {
  __typename?: "sprayer";
  auth_id?: Maybe<Scalars["uuid"]>;
  created_at: Scalars["timestamptz"];
  dni: Scalars["String"];
  email?: Maybe<Scalars["String"]>;
  id: Scalars["uuid"];
  name: Scalars["String"];
  phone?: Maybe<Scalars["String"]>;
  updated_at: Scalars["timestamptz"];
};

/** aggregated selection of "sprayer" */
export type Sprayer_Aggregate = {
  __typename?: "sprayer_aggregate";
  aggregate?: Maybe<Sprayer_Aggregate_Fields>;
  nodes: Array<Sprayer>;
};

/** aggregate fields of "sprayer" */
export type Sprayer_Aggregate_Fields = {
  __typename?: "sprayer_aggregate_fields";
  count: Scalars["Int"];
  max?: Maybe<Sprayer_Max_Fields>;
  min?: Maybe<Sprayer_Min_Fields>;
};

/** aggregate fields of "sprayer" */
export type Sprayer_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Sprayer_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]>;
};

/** Boolean expression to filter rows from the table "sprayer". All fields are combined with a logical 'AND'. */
export type Sprayer_Bool_Exp = {
  _and?: InputMaybe<Array<Sprayer_Bool_Exp>>;
  _not?: InputMaybe<Sprayer_Bool_Exp>;
  _or?: InputMaybe<Array<Sprayer_Bool_Exp>>;
  auth_id?: InputMaybe<Uuid_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  dni?: InputMaybe<String_Comparison_Exp>;
  email?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  phone?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "sprayer" */
export enum Sprayer_Constraint {
  /** unique or primary key constraint on columns "dni" */
  SprayerDniKey = "sprayer_dni_key",
  /** unique or primary key constraint on columns "id" */
  SprayerPkey = "sprayer_pkey",
}

/** input type for inserting data into table "sprayer" */
export type Sprayer_Insert_Input = {
  auth_id?: InputMaybe<Scalars["uuid"]>;
  created_at?: InputMaybe<Scalars["timestamptz"]>;
  dni?: InputMaybe<Scalars["String"]>;
  email?: InputMaybe<Scalars["String"]>;
  id?: InputMaybe<Scalars["uuid"]>;
  name?: InputMaybe<Scalars["String"]>;
  phone?: InputMaybe<Scalars["String"]>;
  updated_at?: InputMaybe<Scalars["timestamptz"]>;
};

/** aggregate max on columns */
export type Sprayer_Max_Fields = {
  __typename?: "sprayer_max_fields";
  auth_id?: Maybe<Scalars["uuid"]>;
  created_at?: Maybe<Scalars["timestamptz"]>;
  dni?: Maybe<Scalars["String"]>;
  email?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["uuid"]>;
  name?: Maybe<Scalars["String"]>;
  phone?: Maybe<Scalars["String"]>;
  updated_at?: Maybe<Scalars["timestamptz"]>;
};

/** aggregate min on columns */
export type Sprayer_Min_Fields = {
  __typename?: "sprayer_min_fields";
  auth_id?: Maybe<Scalars["uuid"]>;
  created_at?: Maybe<Scalars["timestamptz"]>;
  dni?: Maybe<Scalars["String"]>;
  email?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["uuid"]>;
  name?: Maybe<Scalars["String"]>;
  phone?: Maybe<Scalars["String"]>;
  updated_at?: Maybe<Scalars["timestamptz"]>;
};

/** response of any mutation on the table "sprayer" */
export type Sprayer_Mutation_Response = {
  __typename?: "sprayer_mutation_response";
  /** number of rows affected by the mutation */
  affected_rows: Scalars["Int"];
  /** data from the rows affected by the mutation */
  returning: Array<Sprayer>;
};

/** on_conflict condition type for table "sprayer" */
export type Sprayer_On_Conflict = {
  constraint: Sprayer_Constraint;
  update_columns?: Array<Sprayer_Update_Column>;
  where?: InputMaybe<Sprayer_Bool_Exp>;
};

/** Ordering options when selecting data from "sprayer". */
export type Sprayer_Order_By = {
  auth_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  dni?: InputMaybe<Order_By>;
  email?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  phone?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: sprayer */
export type Sprayer_Pk_Columns_Input = {
  id: Scalars["uuid"];
};

/** select columns of table "sprayer" */
export enum Sprayer_Select_Column {
  /** column name */
  AuthId = "auth_id",
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  Dni = "dni",
  /** column name */
  Email = "email",
  /** column name */
  Id = "id",
  /** column name */
  Name = "name",
  /** column name */
  Phone = "phone",
  /** column name */
  UpdatedAt = "updated_at",
}

/** input type for updating data in table "sprayer" */
export type Sprayer_Set_Input = {
  auth_id?: InputMaybe<Scalars["uuid"]>;
  created_at?: InputMaybe<Scalars["timestamptz"]>;
  dni?: InputMaybe<Scalars["String"]>;
  email?: InputMaybe<Scalars["String"]>;
  id?: InputMaybe<Scalars["uuid"]>;
  name?: InputMaybe<Scalars["String"]>;
  phone?: InputMaybe<Scalars["String"]>;
  updated_at?: InputMaybe<Scalars["timestamptz"]>;
};

/** Streaming cursor of the table "sprayer" */
export type Sprayer_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Sprayer_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Sprayer_Stream_Cursor_Value_Input = {
  auth_id?: InputMaybe<Scalars["uuid"]>;
  created_at?: InputMaybe<Scalars["timestamptz"]>;
  dni?: InputMaybe<Scalars["String"]>;
  email?: InputMaybe<Scalars["String"]>;
  id?: InputMaybe<Scalars["uuid"]>;
  name?: InputMaybe<Scalars["String"]>;
  phone?: InputMaybe<Scalars["String"]>;
  updated_at?: InputMaybe<Scalars["timestamptz"]>;
};

/** update columns of table "sprayer" */
export enum Sprayer_Update_Column {
  /** column name */
  AuthId = "auth_id",
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  Dni = "dni",
  /** column name */
  Email = "email",
  /** column name */
  Id = "id",
  /** column name */
  Name = "name",
  /** column name */
  Phone = "phone",
  /** column name */
  UpdatedAt = "updated_at",
}

export type Sprayer_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Sprayer_Set_Input>;
  /** filter the rows which have to be updated */
  where: Sprayer_Bool_Exp;
};

export type St_D_Within_Geography_Input = {
  distance: Scalars["Float"];
  from: Scalars["geography"];
  use_spheroid?: InputMaybe<Scalars["Boolean"]>;
};

export type St_D_Within_Input = {
  distance: Scalars["Float"];
  from: Scalars["geometry"];
};

export type Subscription_Root = {
  __typename?: "subscription_root";
  /** fetch data from the table: "advertisement" */
  advertisement: Array<Advertisement>;
  /** fetch aggregated fields from the table: "advertisement" */
  advertisement_aggregate: Advertisement_Aggregate;
  /** fetch data from the table: "advertisement" using primary key columns */
  advertisement_by_pk?: Maybe<Advertisement>;
  /** fetch data from the table in a streaming manner: "advertisement" */
  advertisement_stream: Array<Advertisement>;
  /** fetch data from the table: "collaborator" */
  collaborator: Array<Collaborator>;
  /** fetch aggregated fields from the table: "collaborator" */
  collaborator_aggregate: Collaborator_Aggregate;
  /** fetch data from the table: "collaborator" using primary key columns */
  collaborator_by_pk?: Maybe<Collaborator>;
  /** fetch data from the table in a streaming manner: "collaborator" */
  collaborator_stream: Array<Collaborator>;
  /** fetch data from the table: "complaint" */
  complaint: Array<Complaint>;
  /** fetch aggregated fields from the table: "complaint" */
  complaint_aggregate: Complaint_Aggregate;
  /** fetch data from the table: "complaint" using primary key columns */
  complaint_by_pk?: Maybe<Complaint>;
  /** fetch data from the table: "complaint_kind" */
  complaint_kind: Array<Complaint_Kind>;
  /** fetch aggregated fields from the table: "complaint_kind" */
  complaint_kind_aggregate: Complaint_Kind_Aggregate;
  /** fetch data from the table: "complaint_kind" using primary key columns */
  complaint_kind_by_pk?: Maybe<Complaint_Kind>;
  /** fetch data from the table in a streaming manner: "complaint_kind" */
  complaint_kind_stream: Array<Complaint_Kind>;
  /** fetch data from the table: "complaint_source" */
  complaint_source: Array<Complaint_Source>;
  /** fetch aggregated fields from the table: "complaint_source" */
  complaint_source_aggregate: Complaint_Source_Aggregate;
  /** fetch data from the table: "complaint_source" using primary key columns */
  complaint_source_by_pk?: Maybe<Complaint_Source>;
  /** fetch data from the table in a streaming manner: "complaint_source" */
  complaint_source_stream: Array<Complaint_Source>;
  /** fetch data from the table in a streaming manner: "complaint" */
  complaint_stream: Array<Complaint>;
  /** An array relationship */
  distances: Array<Distances>;
  /** An aggregate relationship */
  distances_aggregate: Distances_Aggregate;
  /** fetch data from the table: "distances" using primary key columns */
  distances_by_pk?: Maybe<Distances>;
  /** fetch data from the table in a streaming manner: "distances" */
  distances_stream: Array<Distances>;
  /** fetch data from the table: "estate_kind" */
  estate_kind: Array<Estate_Kind>;
  /** fetch aggregated fields from the table: "estate_kind" */
  estate_kind_aggregate: Estate_Kind_Aggregate;
  /** fetch data from the table: "estate_kind" using primary key columns */
  estate_kind_by_pk?: Maybe<Estate_Kind>;
  /** fetch data from the table in a streaming manner: "estate_kind" */
  estate_kind_stream: Array<Estate_Kind>;
  /** fetch data from the table: "experiment" */
  experiment: Array<Experiment>;
  /** fetch aggregated fields from the table: "experiment" */
  experiment_aggregate: Experiment_Aggregate;
  /** fetch data from the table: "experiment" using primary key columns */
  experiment_by_pk?: Maybe<Experiment>;
  /** fetch data from the table in a streaming manner: "experiment" */
  experiment_stream: Array<Experiment>;
  /** fetch data from the table: "geography_columns" */
  geography_columns: Array<Geography_Columns>;
  /** fetch aggregated fields from the table: "geography_columns" */
  geography_columns_aggregate: Geography_Columns_Aggregate;
  /** fetch data from the table in a streaming manner: "geography_columns" */
  geography_columns_stream: Array<Geography_Columns>;
  /** fetch data from the table: "geogroup" */
  geogroup: Array<Geogroup>;
  /** fetch aggregated fields from the table: "geogroup" */
  geogroup_aggregate: Geogroup_Aggregate;
  /** fetch data from the table: "geogroup" using primary key columns */
  geogroup_by_pk?: Maybe<Geogroup>;
  /** fetch data from the table in a streaming manner: "geogroup" */
  geogroup_stream: Array<Geogroup>;
  /** fetch data from the table: "geometry_columns" */
  geometry_columns: Array<Geometry_Columns>;
  /** fetch aggregated fields from the table: "geometry_columns" */
  geometry_columns_aggregate: Geometry_Columns_Aggregate;
  /** fetch data from the table in a streaming manner: "geometry_columns" */
  geometry_columns_stream: Array<Geometry_Columns>;
  /** fetch data from the table: "geopoint" */
  geopoint: Array<Geopoint>;
  /** fetch aggregated fields from the table: "geopoint" */
  geopoint_aggregate: Geopoint_Aggregate;
  /** fetch data from the table: "geopoint" using primary key columns */
  geopoint_by_pk?: Maybe<Geopoint>;
  /** fetch data from the table in a streaming manner: "geopoint" */
  geopoint_stream: Array<Geopoint>;
  /** fetch data from the table: "inspection" */
  inspection: Array<Inspection>;
  /** fetch aggregated fields from the table: "inspection" */
  inspection_aggregate: Inspection_Aggregate;
  /** fetch data from the table: "inspection" using primary key columns */
  inspection_by_pk?: Maybe<Inspection>;
  /** fetch data from the table in a streaming manner: "inspection" */
  inspection_stream: Array<Inspection>;
  /** fetch data from the table: "observation" */
  observation: Array<Observation>;
  /** fetch aggregated fields from the table: "observation" */
  observation_aggregate: Observation_Aggregate;
  /** fetch data from the table: "observation" using primary key columns */
  observation_by_pk?: Maybe<Observation>;
  /** fetch data from the table in a streaming manner: "observation" */
  observation_stream: Array<Observation>;
  /** fetch data from the table: "place" */
  place: Array<Place>;
  /** fetch aggregated fields from the table: "place" */
  place_aggregate: Place_Aggregate;
  /** fetch data from the table: "place" using primary key columns */
  place_by_pk?: Maybe<Place>;
  /** fetch data from the table in a streaming manner: "place" */
  place_stream: Array<Place>;
  /** fetch data from the table: "spatial_ref_sys" */
  spatial_ref_sys: Array<Spatial_Ref_Sys>;
  /** fetch aggregated fields from the table: "spatial_ref_sys" */
  spatial_ref_sys_aggregate: Spatial_Ref_Sys_Aggregate;
  /** fetch data from the table: "spatial_ref_sys" using primary key columns */
  spatial_ref_sys_by_pk?: Maybe<Spatial_Ref_Sys>;
  /** fetch data from the table in a streaming manner: "spatial_ref_sys" */
  spatial_ref_sys_stream: Array<Spatial_Ref_Sys>;
  /** fetch data from the table: "sprayed" */
  sprayed: Array<Sprayed>;
  /** fetch aggregated fields from the table: "sprayed" */
  sprayed_aggregate: Sprayed_Aggregate;
  /** fetch data from the table: "sprayed" using primary key columns */
  sprayed_by_pk?: Maybe<Sprayed>;
  /** fetch data from the table: "sprayed_residual_treatment_kind" */
  sprayed_residual_treatment_kind: Array<Sprayed_Residual_Treatment_Kind>;
  /** fetch aggregated fields from the table: "sprayed_residual_treatment_kind" */
  sprayed_residual_treatment_kind_aggregate: Sprayed_Residual_Treatment_Kind_Aggregate;
  /** fetch data from the table: "sprayed_residual_treatment_kind" using primary key columns */
  sprayed_residual_treatment_kind_by_pk?: Maybe<Sprayed_Residual_Treatment_Kind>;
  /** fetch data from the table in a streaming manner: "sprayed_residual_treatment_kind" */
  sprayed_residual_treatment_kind_stream: Array<Sprayed_Residual_Treatment_Kind>;
  /** fetch data from the table in a streaming manner: "sprayed" */
  sprayed_stream: Array<Sprayed>;
  /** fetch data from the table: "sprayer" */
  sprayer: Array<Sprayer>;
  /** fetch aggregated fields from the table: "sprayer" */
  sprayer_aggregate: Sprayer_Aggregate;
  /** fetch data from the table: "sprayer" using primary key columns */
  sprayer_by_pk?: Maybe<Sprayer>;
  /** fetch data from the table in a streaming manner: "sprayer" */
  sprayer_stream: Array<Sprayer>;
  /** An array relationship */
  work_sessions: Array<Work_Sessions>;
  /** An aggregate relationship */
  work_sessions_aggregate: Work_Sessions_Aggregate;
  /** fetch data from the table: "work_sessions" using primary key columns */
  work_sessions_by_pk?: Maybe<Work_Sessions>;
  /** fetch data from the table in a streaming manner: "work_sessions" */
  work_sessions_stream: Array<Work_Sessions>;
  /** fetch data from the table: "workspace" */
  workspace: Array<Workspace>;
  /** fetch aggregated fields from the table: "workspace" */
  workspace_aggregate: Workspace_Aggregate;
  /** fetch data from the table: "workspace" using primary key columns */
  workspace_by_pk?: Maybe<Workspace>;
  /** fetch data from the table: "workspace_kind" */
  workspace_kind: Array<Workspace_Kind>;
  /** fetch aggregated fields from the table: "workspace_kind" */
  workspace_kind_aggregate: Workspace_Kind_Aggregate;
  /** fetch data from the table: "workspace_kind" using primary key columns */
  workspace_kind_by_pk?: Maybe<Workspace_Kind>;
  /** fetch data from the table in a streaming manner: "workspace_kind" */
  workspace_kind_stream: Array<Workspace_Kind>;
  /** An array relationship */
  workspace_metadata: Array<Workspace_Metadata>;
  /** An aggregate relationship */
  workspace_metadata_aggregate: Workspace_Metadata_Aggregate;
  /** fetch data from the table: "workspace_metadata" using primary key columns */
  workspace_metadata_by_pk?: Maybe<Workspace_Metadata>;
  /** fetch data from the table in a streaming manner: "workspace_metadata" */
  workspace_metadata_stream: Array<Workspace_Metadata>;
  /** fetch data from the table: "workspace_owners" */
  workspace_owners: Array<Workspace_Owners>;
  /** fetch aggregated fields from the table: "workspace_owners" */
  workspace_owners_aggregate: Workspace_Owners_Aggregate;
  /** fetch data from the table: "workspace_owners" using primary key columns */
  workspace_owners_by_pk?: Maybe<Workspace_Owners>;
  /** fetch data from the table in a streaming manner: "workspace_owners" */
  workspace_owners_stream: Array<Workspace_Owners>;
  /** fetch data from the table in a streaming manner: "workspace" */
  workspace_stream: Array<Workspace>;
};

export type Subscription_RootAdvertisementArgs = {
  distinct_on?: InputMaybe<Array<Advertisement_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Advertisement_Order_By>>;
  where?: InputMaybe<Advertisement_Bool_Exp>;
};

export type Subscription_RootAdvertisement_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Advertisement_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Advertisement_Order_By>>;
  where?: InputMaybe<Advertisement_Bool_Exp>;
};

export type Subscription_RootAdvertisement_By_PkArgs = {
  id: Scalars["uuid"];
};

export type Subscription_RootAdvertisement_StreamArgs = {
  batch_size: Scalars["Int"];
  cursor: Array<InputMaybe<Advertisement_Stream_Cursor_Input>>;
  where?: InputMaybe<Advertisement_Bool_Exp>;
};

export type Subscription_RootCollaboratorArgs = {
  distinct_on?: InputMaybe<Array<Collaborator_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Collaborator_Order_By>>;
  where?: InputMaybe<Collaborator_Bool_Exp>;
};

export type Subscription_RootCollaborator_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Collaborator_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Collaborator_Order_By>>;
  where?: InputMaybe<Collaborator_Bool_Exp>;
};

export type Subscription_RootCollaborator_By_PkArgs = {
  id: Scalars["uuid"];
};

export type Subscription_RootCollaborator_StreamArgs = {
  batch_size: Scalars["Int"];
  cursor: Array<InputMaybe<Collaborator_Stream_Cursor_Input>>;
  where?: InputMaybe<Collaborator_Bool_Exp>;
};

export type Subscription_RootComplaintArgs = {
  distinct_on?: InputMaybe<Array<Complaint_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Complaint_Order_By>>;
  where?: InputMaybe<Complaint_Bool_Exp>;
};

export type Subscription_RootComplaint_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Complaint_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Complaint_Order_By>>;
  where?: InputMaybe<Complaint_Bool_Exp>;
};

export type Subscription_RootComplaint_By_PkArgs = {
  id: Scalars["uuid"];
};

export type Subscription_RootComplaint_KindArgs = {
  distinct_on?: InputMaybe<Array<Complaint_Kind_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Complaint_Kind_Order_By>>;
  where?: InputMaybe<Complaint_Kind_Bool_Exp>;
};

export type Subscription_RootComplaint_Kind_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Complaint_Kind_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Complaint_Kind_Order_By>>;
  where?: InputMaybe<Complaint_Kind_Bool_Exp>;
};

export type Subscription_RootComplaint_Kind_By_PkArgs = {
  value: Scalars["String"];
};

export type Subscription_RootComplaint_Kind_StreamArgs = {
  batch_size: Scalars["Int"];
  cursor: Array<InputMaybe<Complaint_Kind_Stream_Cursor_Input>>;
  where?: InputMaybe<Complaint_Kind_Bool_Exp>;
};

export type Subscription_RootComplaint_SourceArgs = {
  distinct_on?: InputMaybe<Array<Complaint_Source_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Complaint_Source_Order_By>>;
  where?: InputMaybe<Complaint_Source_Bool_Exp>;
};

export type Subscription_RootComplaint_Source_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Complaint_Source_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Complaint_Source_Order_By>>;
  where?: InputMaybe<Complaint_Source_Bool_Exp>;
};

export type Subscription_RootComplaint_Source_By_PkArgs = {
  value: Scalars["String"];
};

export type Subscription_RootComplaint_Source_StreamArgs = {
  batch_size: Scalars["Int"];
  cursor: Array<InputMaybe<Complaint_Source_Stream_Cursor_Input>>;
  where?: InputMaybe<Complaint_Source_Bool_Exp>;
};

export type Subscription_RootComplaint_StreamArgs = {
  batch_size: Scalars["Int"];
  cursor: Array<InputMaybe<Complaint_Stream_Cursor_Input>>;
  where?: InputMaybe<Complaint_Bool_Exp>;
};

export type Subscription_RootDistancesArgs = {
  distinct_on?: InputMaybe<Array<Distances_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Distances_Order_By>>;
  where?: InputMaybe<Distances_Bool_Exp>;
};

export type Subscription_RootDistances_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Distances_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Distances_Order_By>>;
  where?: InputMaybe<Distances_Bool_Exp>;
};

export type Subscription_RootDistances_By_PkArgs = {
  id: Scalars["uuid"];
};

export type Subscription_RootDistances_StreamArgs = {
  batch_size: Scalars["Int"];
  cursor: Array<InputMaybe<Distances_Stream_Cursor_Input>>;
  where?: InputMaybe<Distances_Bool_Exp>;
};

export type Subscription_RootEstate_KindArgs = {
  distinct_on?: InputMaybe<Array<Estate_Kind_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Estate_Kind_Order_By>>;
  where?: InputMaybe<Estate_Kind_Bool_Exp>;
};

export type Subscription_RootEstate_Kind_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Estate_Kind_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Estate_Kind_Order_By>>;
  where?: InputMaybe<Estate_Kind_Bool_Exp>;
};

export type Subscription_RootEstate_Kind_By_PkArgs = {
  value: Scalars["String"];
};

export type Subscription_RootEstate_Kind_StreamArgs = {
  batch_size: Scalars["Int"];
  cursor: Array<InputMaybe<Estate_Kind_Stream_Cursor_Input>>;
  where?: InputMaybe<Estate_Kind_Bool_Exp>;
};

export type Subscription_RootExperimentArgs = {
  distinct_on?: InputMaybe<Array<Experiment_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Experiment_Order_By>>;
  where?: InputMaybe<Experiment_Bool_Exp>;
};

export type Subscription_RootExperiment_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Experiment_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Experiment_Order_By>>;
  where?: InputMaybe<Experiment_Bool_Exp>;
};

export type Subscription_RootExperiment_By_PkArgs = {
  id: Scalars["uuid"];
};

export type Subscription_RootExperiment_StreamArgs = {
  batch_size: Scalars["Int"];
  cursor: Array<InputMaybe<Experiment_Stream_Cursor_Input>>;
  where?: InputMaybe<Experiment_Bool_Exp>;
};

export type Subscription_RootGeography_ColumnsArgs = {
  distinct_on?: InputMaybe<Array<Geography_Columns_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Geography_Columns_Order_By>>;
  where?: InputMaybe<Geography_Columns_Bool_Exp>;
};

export type Subscription_RootGeography_Columns_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Geography_Columns_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Geography_Columns_Order_By>>;
  where?: InputMaybe<Geography_Columns_Bool_Exp>;
};

export type Subscription_RootGeography_Columns_StreamArgs = {
  batch_size: Scalars["Int"];
  cursor: Array<InputMaybe<Geography_Columns_Stream_Cursor_Input>>;
  where?: InputMaybe<Geography_Columns_Bool_Exp>;
};

export type Subscription_RootGeogroupArgs = {
  distinct_on?: InputMaybe<Array<Geogroup_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Geogroup_Order_By>>;
  where?: InputMaybe<Geogroup_Bool_Exp>;
};

export type Subscription_RootGeogroup_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Geogroup_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Geogroup_Order_By>>;
  where?: InputMaybe<Geogroup_Bool_Exp>;
};

export type Subscription_RootGeogroup_By_PkArgs = {
  id: Scalars["uuid"];
};

export type Subscription_RootGeogroup_StreamArgs = {
  batch_size: Scalars["Int"];
  cursor: Array<InputMaybe<Geogroup_Stream_Cursor_Input>>;
  where?: InputMaybe<Geogroup_Bool_Exp>;
};

export type Subscription_RootGeometry_ColumnsArgs = {
  distinct_on?: InputMaybe<Array<Geometry_Columns_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Geometry_Columns_Order_By>>;
  where?: InputMaybe<Geometry_Columns_Bool_Exp>;
};

export type Subscription_RootGeometry_Columns_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Geometry_Columns_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Geometry_Columns_Order_By>>;
  where?: InputMaybe<Geometry_Columns_Bool_Exp>;
};

export type Subscription_RootGeometry_Columns_StreamArgs = {
  batch_size: Scalars["Int"];
  cursor: Array<InputMaybe<Geometry_Columns_Stream_Cursor_Input>>;
  where?: InputMaybe<Geometry_Columns_Bool_Exp>;
};

export type Subscription_RootGeopointArgs = {
  distinct_on?: InputMaybe<Array<Geopoint_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Geopoint_Order_By>>;
  where?: InputMaybe<Geopoint_Bool_Exp>;
};

export type Subscription_RootGeopoint_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Geopoint_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Geopoint_Order_By>>;
  where?: InputMaybe<Geopoint_Bool_Exp>;
};

export type Subscription_RootGeopoint_By_PkArgs = {
  id: Scalars["uuid"];
};

export type Subscription_RootGeopoint_StreamArgs = {
  batch_size: Scalars["Int"];
  cursor: Array<InputMaybe<Geopoint_Stream_Cursor_Input>>;
  where?: InputMaybe<Geopoint_Bool_Exp>;
};

export type Subscription_RootInspectionArgs = {
  distinct_on?: InputMaybe<Array<Inspection_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Inspection_Order_By>>;
  where?: InputMaybe<Inspection_Bool_Exp>;
};

export type Subscription_RootInspection_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Inspection_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Inspection_Order_By>>;
  where?: InputMaybe<Inspection_Bool_Exp>;
};

export type Subscription_RootInspection_By_PkArgs = {
  id: Scalars["uuid"];
};

export type Subscription_RootInspection_StreamArgs = {
  batch_size: Scalars["Int"];
  cursor: Array<InputMaybe<Inspection_Stream_Cursor_Input>>;
  where?: InputMaybe<Inspection_Bool_Exp>;
};

export type Subscription_RootObservationArgs = {
  distinct_on?: InputMaybe<Array<Observation_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Observation_Order_By>>;
  where?: InputMaybe<Observation_Bool_Exp>;
};

export type Subscription_RootObservation_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Observation_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Observation_Order_By>>;
  where?: InputMaybe<Observation_Bool_Exp>;
};

export type Subscription_RootObservation_By_PkArgs = {
  id: Scalars["uuid"];
};

export type Subscription_RootObservation_StreamArgs = {
  batch_size: Scalars["Int"];
  cursor: Array<InputMaybe<Observation_Stream_Cursor_Input>>;
  where?: InputMaybe<Observation_Bool_Exp>;
};

export type Subscription_RootPlaceArgs = {
  distinct_on?: InputMaybe<Array<Place_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Place_Order_By>>;
  where?: InputMaybe<Place_Bool_Exp>;
};

export type Subscription_RootPlace_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Place_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Place_Order_By>>;
  where?: InputMaybe<Place_Bool_Exp>;
};

export type Subscription_RootPlace_By_PkArgs = {
  id: Scalars["uuid"];
};

export type Subscription_RootPlace_StreamArgs = {
  batch_size: Scalars["Int"];
  cursor: Array<InputMaybe<Place_Stream_Cursor_Input>>;
  where?: InputMaybe<Place_Bool_Exp>;
};

export type Subscription_RootSpatial_Ref_SysArgs = {
  distinct_on?: InputMaybe<Array<Spatial_Ref_Sys_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Spatial_Ref_Sys_Order_By>>;
  where?: InputMaybe<Spatial_Ref_Sys_Bool_Exp>;
};

export type Subscription_RootSpatial_Ref_Sys_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Spatial_Ref_Sys_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Spatial_Ref_Sys_Order_By>>;
  where?: InputMaybe<Spatial_Ref_Sys_Bool_Exp>;
};

export type Subscription_RootSpatial_Ref_Sys_By_PkArgs = {
  srid: Scalars["Int"];
};

export type Subscription_RootSpatial_Ref_Sys_StreamArgs = {
  batch_size: Scalars["Int"];
  cursor: Array<InputMaybe<Spatial_Ref_Sys_Stream_Cursor_Input>>;
  where?: InputMaybe<Spatial_Ref_Sys_Bool_Exp>;
};

export type Subscription_RootSprayedArgs = {
  distinct_on?: InputMaybe<Array<Sprayed_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Sprayed_Order_By>>;
  where?: InputMaybe<Sprayed_Bool_Exp>;
};

export type Subscription_RootSprayed_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Sprayed_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Sprayed_Order_By>>;
  where?: InputMaybe<Sprayed_Bool_Exp>;
};

export type Subscription_RootSprayed_By_PkArgs = {
  id: Scalars["uuid"];
};

export type Subscription_RootSprayed_Residual_Treatment_KindArgs = {
  distinct_on?: InputMaybe<Array<Sprayed_Residual_Treatment_Kind_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Sprayed_Residual_Treatment_Kind_Order_By>>;
  where?: InputMaybe<Sprayed_Residual_Treatment_Kind_Bool_Exp>;
};

export type Subscription_RootSprayed_Residual_Treatment_Kind_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Sprayed_Residual_Treatment_Kind_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Sprayed_Residual_Treatment_Kind_Order_By>>;
  where?: InputMaybe<Sprayed_Residual_Treatment_Kind_Bool_Exp>;
};

export type Subscription_RootSprayed_Residual_Treatment_Kind_By_PkArgs = {
  value: Scalars["String"];
};

export type Subscription_RootSprayed_Residual_Treatment_Kind_StreamArgs = {
  batch_size: Scalars["Int"];
  cursor: Array<InputMaybe<Sprayed_Residual_Treatment_Kind_Stream_Cursor_Input>>;
  where?: InputMaybe<Sprayed_Residual_Treatment_Kind_Bool_Exp>;
};

export type Subscription_RootSprayed_StreamArgs = {
  batch_size: Scalars["Int"];
  cursor: Array<InputMaybe<Sprayed_Stream_Cursor_Input>>;
  where?: InputMaybe<Sprayed_Bool_Exp>;
};

export type Subscription_RootSprayerArgs = {
  distinct_on?: InputMaybe<Array<Sprayer_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Sprayer_Order_By>>;
  where?: InputMaybe<Sprayer_Bool_Exp>;
};

export type Subscription_RootSprayer_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Sprayer_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Sprayer_Order_By>>;
  where?: InputMaybe<Sprayer_Bool_Exp>;
};

export type Subscription_RootSprayer_By_PkArgs = {
  id: Scalars["uuid"];
};

export type Subscription_RootSprayer_StreamArgs = {
  batch_size: Scalars["Int"];
  cursor: Array<InputMaybe<Sprayer_Stream_Cursor_Input>>;
  where?: InputMaybe<Sprayer_Bool_Exp>;
};

export type Subscription_RootWork_SessionsArgs = {
  distinct_on?: InputMaybe<Array<Work_Sessions_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Work_Sessions_Order_By>>;
  where?: InputMaybe<Work_Sessions_Bool_Exp>;
};

export type Subscription_RootWork_Sessions_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Work_Sessions_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Work_Sessions_Order_By>>;
  where?: InputMaybe<Work_Sessions_Bool_Exp>;
};

export type Subscription_RootWork_Sessions_By_PkArgs = {
  id: Scalars["uuid"];
};

export type Subscription_RootWork_Sessions_StreamArgs = {
  batch_size: Scalars["Int"];
  cursor: Array<InputMaybe<Work_Sessions_Stream_Cursor_Input>>;
  where?: InputMaybe<Work_Sessions_Bool_Exp>;
};

export type Subscription_RootWorkspaceArgs = {
  distinct_on?: InputMaybe<Array<Workspace_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Workspace_Order_By>>;
  where?: InputMaybe<Workspace_Bool_Exp>;
};

export type Subscription_RootWorkspace_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Workspace_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Workspace_Order_By>>;
  where?: InputMaybe<Workspace_Bool_Exp>;
};

export type Subscription_RootWorkspace_By_PkArgs = {
  id: Scalars["uuid"];
};

export type Subscription_RootWorkspace_KindArgs = {
  distinct_on?: InputMaybe<Array<Workspace_Kind_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Workspace_Kind_Order_By>>;
  where?: InputMaybe<Workspace_Kind_Bool_Exp>;
};

export type Subscription_RootWorkspace_Kind_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Workspace_Kind_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Workspace_Kind_Order_By>>;
  where?: InputMaybe<Workspace_Kind_Bool_Exp>;
};

export type Subscription_RootWorkspace_Kind_By_PkArgs = {
  value: Scalars["String"];
};

export type Subscription_RootWorkspace_Kind_StreamArgs = {
  batch_size: Scalars["Int"];
  cursor: Array<InputMaybe<Workspace_Kind_Stream_Cursor_Input>>;
  where?: InputMaybe<Workspace_Kind_Bool_Exp>;
};

export type Subscription_RootWorkspace_MetadataArgs = {
  distinct_on?: InputMaybe<Array<Workspace_Metadata_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Workspace_Metadata_Order_By>>;
  where?: InputMaybe<Workspace_Metadata_Bool_Exp>;
};

export type Subscription_RootWorkspace_Metadata_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Workspace_Metadata_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Workspace_Metadata_Order_By>>;
  where?: InputMaybe<Workspace_Metadata_Bool_Exp>;
};

export type Subscription_RootWorkspace_Metadata_By_PkArgs = {
  id: Scalars["uuid"];
};

export type Subscription_RootWorkspace_Metadata_StreamArgs = {
  batch_size: Scalars["Int"];
  cursor: Array<InputMaybe<Workspace_Metadata_Stream_Cursor_Input>>;
  where?: InputMaybe<Workspace_Metadata_Bool_Exp>;
};

export type Subscription_RootWorkspace_OwnersArgs = {
  distinct_on?: InputMaybe<Array<Workspace_Owners_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Workspace_Owners_Order_By>>;
  where?: InputMaybe<Workspace_Owners_Bool_Exp>;
};

export type Subscription_RootWorkspace_Owners_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Workspace_Owners_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Workspace_Owners_Order_By>>;
  where?: InputMaybe<Workspace_Owners_Bool_Exp>;
};

export type Subscription_RootWorkspace_Owners_By_PkArgs = {
  owner_id: Scalars["uuid"];
  workspace_id: Scalars["uuid"];
};

export type Subscription_RootWorkspace_Owners_StreamArgs = {
  batch_size: Scalars["Int"];
  cursor: Array<InputMaybe<Workspace_Owners_Stream_Cursor_Input>>;
  where?: InputMaybe<Workspace_Owners_Bool_Exp>;
};

export type Subscription_RootWorkspace_StreamArgs = {
  batch_size: Scalars["Int"];
  cursor: Array<InputMaybe<Workspace_Stream_Cursor_Input>>;
  where?: InputMaybe<Workspace_Bool_Exp>;
};

/** Boolean expression to compare columns of type "time". All fields are combined with logical 'AND'. */
export type Time_Comparison_Exp = {
  _eq?: InputMaybe<Scalars["time"]>;
  _gt?: InputMaybe<Scalars["time"]>;
  _gte?: InputMaybe<Scalars["time"]>;
  _in?: InputMaybe<Array<Scalars["time"]>>;
  _is_null?: InputMaybe<Scalars["Boolean"]>;
  _lt?: InputMaybe<Scalars["time"]>;
  _lte?: InputMaybe<Scalars["time"]>;
  _neq?: InputMaybe<Scalars["time"]>;
  _nin?: InputMaybe<Array<Scalars["time"]>>;
};

/** Boolean expression to compare columns of type "timestamptz". All fields are combined with logical 'AND'. */
export type Timestamptz_Comparison_Exp = {
  _eq?: InputMaybe<Scalars["timestamptz"]>;
  _gt?: InputMaybe<Scalars["timestamptz"]>;
  _gte?: InputMaybe<Scalars["timestamptz"]>;
  _in?: InputMaybe<Array<Scalars["timestamptz"]>>;
  _is_null?: InputMaybe<Scalars["Boolean"]>;
  _lt?: InputMaybe<Scalars["timestamptz"]>;
  _lte?: InputMaybe<Scalars["timestamptz"]>;
  _neq?: InputMaybe<Scalars["timestamptz"]>;
  _nin?: InputMaybe<Array<Scalars["timestamptz"]>>;
};

/** Boolean expression to compare columns of type "uuid". All fields are combined with logical 'AND'. */
export type Uuid_Comparison_Exp = {
  _eq?: InputMaybe<Scalars["uuid"]>;
  _gt?: InputMaybe<Scalars["uuid"]>;
  _gte?: InputMaybe<Scalars["uuid"]>;
  _in?: InputMaybe<Array<Scalars["uuid"]>>;
  _is_null?: InputMaybe<Scalars["Boolean"]>;
  _lt?: InputMaybe<Scalars["uuid"]>;
  _lte?: InputMaybe<Scalars["uuid"]>;
  _neq?: InputMaybe<Scalars["uuid"]>;
  _nin?: InputMaybe<Array<Scalars["uuid"]>>;
};

/** columns and relationships of "work_sessions" */
export type Work_Sessions = {
  __typename?: "work_sessions";
  branch?: Maybe<Scalars["String"]>;
  /** An object relationship */
  collaborator?: Maybe<Collaborator>;
  collaborator_id?: Maybe<Scalars["uuid"]>;
  comments?: Maybe<Scalars["String"]>;
  created_at: Scalars["timestamptz"];
  /** An object relationship */
  geogroup?: Maybe<Geogroup>;
  geogroup_id?: Maybe<Scalars["uuid"]>;
  id: Scalars["uuid"];
  issued_at: Scalars["date"];
  kind: Scalars["String"];
  updated_at: Scalars["timestamptz"];
  user?: Maybe<Scalars["String"]>;
  work_percent: Scalars["float8"];
};

/** aggregated selection of "work_sessions" */
export type Work_Sessions_Aggregate = {
  __typename?: "work_sessions_aggregate";
  aggregate?: Maybe<Work_Sessions_Aggregate_Fields>;
  nodes: Array<Work_Sessions>;
};

export type Work_Sessions_Aggregate_Bool_Exp = {
  avg?: InputMaybe<Work_Sessions_Aggregate_Bool_Exp_Avg>;
  corr?: InputMaybe<Work_Sessions_Aggregate_Bool_Exp_Corr>;
  count?: InputMaybe<Work_Sessions_Aggregate_Bool_Exp_Count>;
  covar_samp?: InputMaybe<Work_Sessions_Aggregate_Bool_Exp_Covar_Samp>;
  max?: InputMaybe<Work_Sessions_Aggregate_Bool_Exp_Max>;
  min?: InputMaybe<Work_Sessions_Aggregate_Bool_Exp_Min>;
  stddev_samp?: InputMaybe<Work_Sessions_Aggregate_Bool_Exp_Stddev_Samp>;
  sum?: InputMaybe<Work_Sessions_Aggregate_Bool_Exp_Sum>;
  var_samp?: InputMaybe<Work_Sessions_Aggregate_Bool_Exp_Var_Samp>;
};

export type Work_Sessions_Aggregate_Bool_Exp_Avg = {
  arguments: Work_Sessions_Select_Column_Work_Sessions_Aggregate_Bool_Exp_Avg_Arguments_Columns;
  distinct?: InputMaybe<Scalars["Boolean"]>;
  filter?: InputMaybe<Work_Sessions_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Work_Sessions_Aggregate_Bool_Exp_Corr = {
  arguments: Work_Sessions_Aggregate_Bool_Exp_Corr_Arguments;
  distinct?: InputMaybe<Scalars["Boolean"]>;
  filter?: InputMaybe<Work_Sessions_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Work_Sessions_Aggregate_Bool_Exp_Corr_Arguments = {
  X: Work_Sessions_Select_Column_Work_Sessions_Aggregate_Bool_Exp_Corr_Arguments_Columns;
  Y: Work_Sessions_Select_Column_Work_Sessions_Aggregate_Bool_Exp_Corr_Arguments_Columns;
};

export type Work_Sessions_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Work_Sessions_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]>;
  filter?: InputMaybe<Work_Sessions_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

export type Work_Sessions_Aggregate_Bool_Exp_Covar_Samp = {
  arguments: Work_Sessions_Aggregate_Bool_Exp_Covar_Samp_Arguments;
  distinct?: InputMaybe<Scalars["Boolean"]>;
  filter?: InputMaybe<Work_Sessions_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Work_Sessions_Aggregate_Bool_Exp_Covar_Samp_Arguments = {
  X: Work_Sessions_Select_Column_Work_Sessions_Aggregate_Bool_Exp_Covar_Samp_Arguments_Columns;
  Y: Work_Sessions_Select_Column_Work_Sessions_Aggregate_Bool_Exp_Covar_Samp_Arguments_Columns;
};

export type Work_Sessions_Aggregate_Bool_Exp_Max = {
  arguments: Work_Sessions_Select_Column_Work_Sessions_Aggregate_Bool_Exp_Max_Arguments_Columns;
  distinct?: InputMaybe<Scalars["Boolean"]>;
  filter?: InputMaybe<Work_Sessions_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Work_Sessions_Aggregate_Bool_Exp_Min = {
  arguments: Work_Sessions_Select_Column_Work_Sessions_Aggregate_Bool_Exp_Min_Arguments_Columns;
  distinct?: InputMaybe<Scalars["Boolean"]>;
  filter?: InputMaybe<Work_Sessions_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Work_Sessions_Aggregate_Bool_Exp_Stddev_Samp = {
  arguments: Work_Sessions_Select_Column_Work_Sessions_Aggregate_Bool_Exp_Stddev_Samp_Arguments_Columns;
  distinct?: InputMaybe<Scalars["Boolean"]>;
  filter?: InputMaybe<Work_Sessions_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Work_Sessions_Aggregate_Bool_Exp_Sum = {
  arguments: Work_Sessions_Select_Column_Work_Sessions_Aggregate_Bool_Exp_Sum_Arguments_Columns;
  distinct?: InputMaybe<Scalars["Boolean"]>;
  filter?: InputMaybe<Work_Sessions_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Work_Sessions_Aggregate_Bool_Exp_Var_Samp = {
  arguments: Work_Sessions_Select_Column_Work_Sessions_Aggregate_Bool_Exp_Var_Samp_Arguments_Columns;
  distinct?: InputMaybe<Scalars["Boolean"]>;
  filter?: InputMaybe<Work_Sessions_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

/** aggregate fields of "work_sessions" */
export type Work_Sessions_Aggregate_Fields = {
  __typename?: "work_sessions_aggregate_fields";
  avg?: Maybe<Work_Sessions_Avg_Fields>;
  count: Scalars["Int"];
  max?: Maybe<Work_Sessions_Max_Fields>;
  min?: Maybe<Work_Sessions_Min_Fields>;
  stddev?: Maybe<Work_Sessions_Stddev_Fields>;
  stddev_pop?: Maybe<Work_Sessions_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Work_Sessions_Stddev_Samp_Fields>;
  sum?: Maybe<Work_Sessions_Sum_Fields>;
  var_pop?: Maybe<Work_Sessions_Var_Pop_Fields>;
  var_samp?: Maybe<Work_Sessions_Var_Samp_Fields>;
  variance?: Maybe<Work_Sessions_Variance_Fields>;
};

/** aggregate fields of "work_sessions" */
export type Work_Sessions_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Work_Sessions_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "work_sessions" */
export type Work_Sessions_Aggregate_Order_By = {
  avg?: InputMaybe<Work_Sessions_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Work_Sessions_Max_Order_By>;
  min?: InputMaybe<Work_Sessions_Min_Order_By>;
  stddev?: InputMaybe<Work_Sessions_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Work_Sessions_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Work_Sessions_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Work_Sessions_Sum_Order_By>;
  var_pop?: InputMaybe<Work_Sessions_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Work_Sessions_Var_Samp_Order_By>;
  variance?: InputMaybe<Work_Sessions_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "work_sessions" */
export type Work_Sessions_Arr_Rel_Insert_Input = {
  data: Array<Work_Sessions_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Work_Sessions_On_Conflict>;
};

/** aggregate avg on columns */
export type Work_Sessions_Avg_Fields = {
  __typename?: "work_sessions_avg_fields";
  work_percent?: Maybe<Scalars["Float"]>;
};

/** order by avg() on columns of table "work_sessions" */
export type Work_Sessions_Avg_Order_By = {
  work_percent?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "work_sessions". All fields are combined with a logical 'AND'. */
export type Work_Sessions_Bool_Exp = {
  _and?: InputMaybe<Array<Work_Sessions_Bool_Exp>>;
  _not?: InputMaybe<Work_Sessions_Bool_Exp>;
  _or?: InputMaybe<Array<Work_Sessions_Bool_Exp>>;
  branch?: InputMaybe<String_Comparison_Exp>;
  collaborator?: InputMaybe<Collaborator_Bool_Exp>;
  collaborator_id?: InputMaybe<Uuid_Comparison_Exp>;
  comments?: InputMaybe<String_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  geogroup?: InputMaybe<Geogroup_Bool_Exp>;
  geogroup_id?: InputMaybe<Uuid_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  issued_at?: InputMaybe<Date_Comparison_Exp>;
  kind?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  user?: InputMaybe<String_Comparison_Exp>;
  work_percent?: InputMaybe<Float8_Comparison_Exp>;
};

/** unique or primary key constraints on table "work_sessions" */
export enum Work_Sessions_Constraint {
  /** unique or primary key constraint on columns "id" */
  WorkSessionsPkey = "work_sessions_pkey",
}

/** input type for incrementing numeric columns in table "work_sessions" */
export type Work_Sessions_Inc_Input = {
  work_percent?: InputMaybe<Scalars["float8"]>;
};

/** input type for inserting data into table "work_sessions" */
export type Work_Sessions_Insert_Input = {
  branch?: InputMaybe<Scalars["String"]>;
  collaborator?: InputMaybe<Collaborator_Obj_Rel_Insert_Input>;
  collaborator_id?: InputMaybe<Scalars["uuid"]>;
  comments?: InputMaybe<Scalars["String"]>;
  created_at?: InputMaybe<Scalars["timestamptz"]>;
  geogroup?: InputMaybe<Geogroup_Obj_Rel_Insert_Input>;
  geogroup_id?: InputMaybe<Scalars["uuid"]>;
  id?: InputMaybe<Scalars["uuid"]>;
  issued_at?: InputMaybe<Scalars["date"]>;
  kind?: InputMaybe<Scalars["String"]>;
  updated_at?: InputMaybe<Scalars["timestamptz"]>;
  user?: InputMaybe<Scalars["String"]>;
  work_percent?: InputMaybe<Scalars["float8"]>;
};

/** aggregate max on columns */
export type Work_Sessions_Max_Fields = {
  __typename?: "work_sessions_max_fields";
  branch?: Maybe<Scalars["String"]>;
  collaborator_id?: Maybe<Scalars["uuid"]>;
  comments?: Maybe<Scalars["String"]>;
  created_at?: Maybe<Scalars["timestamptz"]>;
  geogroup_id?: Maybe<Scalars["uuid"]>;
  id?: Maybe<Scalars["uuid"]>;
  issued_at?: Maybe<Scalars["date"]>;
  kind?: Maybe<Scalars["String"]>;
  updated_at?: Maybe<Scalars["timestamptz"]>;
  user?: Maybe<Scalars["String"]>;
  work_percent?: Maybe<Scalars["float8"]>;
};

/** order by max() on columns of table "work_sessions" */
export type Work_Sessions_Max_Order_By = {
  branch?: InputMaybe<Order_By>;
  collaborator_id?: InputMaybe<Order_By>;
  comments?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  geogroup_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  issued_at?: InputMaybe<Order_By>;
  kind?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user?: InputMaybe<Order_By>;
  work_percent?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Work_Sessions_Min_Fields = {
  __typename?: "work_sessions_min_fields";
  branch?: Maybe<Scalars["String"]>;
  collaborator_id?: Maybe<Scalars["uuid"]>;
  comments?: Maybe<Scalars["String"]>;
  created_at?: Maybe<Scalars["timestamptz"]>;
  geogroup_id?: Maybe<Scalars["uuid"]>;
  id?: Maybe<Scalars["uuid"]>;
  issued_at?: Maybe<Scalars["date"]>;
  kind?: Maybe<Scalars["String"]>;
  updated_at?: Maybe<Scalars["timestamptz"]>;
  user?: Maybe<Scalars["String"]>;
  work_percent?: Maybe<Scalars["float8"]>;
};

/** order by min() on columns of table "work_sessions" */
export type Work_Sessions_Min_Order_By = {
  branch?: InputMaybe<Order_By>;
  collaborator_id?: InputMaybe<Order_By>;
  comments?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  geogroup_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  issued_at?: InputMaybe<Order_By>;
  kind?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user?: InputMaybe<Order_By>;
  work_percent?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "work_sessions" */
export type Work_Sessions_Mutation_Response = {
  __typename?: "work_sessions_mutation_response";
  /** number of rows affected by the mutation */
  affected_rows: Scalars["Int"];
  /** data from the rows affected by the mutation */
  returning: Array<Work_Sessions>;
};

/** on_conflict condition type for table "work_sessions" */
export type Work_Sessions_On_Conflict = {
  constraint: Work_Sessions_Constraint;
  update_columns?: Array<Work_Sessions_Update_Column>;
  where?: InputMaybe<Work_Sessions_Bool_Exp>;
};

/** Ordering options when selecting data from "work_sessions". */
export type Work_Sessions_Order_By = {
  branch?: InputMaybe<Order_By>;
  collaborator?: InputMaybe<Collaborator_Order_By>;
  collaborator_id?: InputMaybe<Order_By>;
  comments?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  geogroup?: InputMaybe<Geogroup_Order_By>;
  geogroup_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  issued_at?: InputMaybe<Order_By>;
  kind?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user?: InputMaybe<Order_By>;
  work_percent?: InputMaybe<Order_By>;
};

/** primary key columns input for table: work_sessions */
export type Work_Sessions_Pk_Columns_Input = {
  id: Scalars["uuid"];
};

/** select columns of table "work_sessions" */
export enum Work_Sessions_Select_Column {
  /** column name */
  Branch = "branch",
  /** column name */
  CollaboratorId = "collaborator_id",
  /** column name */
  Comments = "comments",
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  GeogroupId = "geogroup_id",
  /** column name */
  Id = "id",
  /** column name */
  IssuedAt = "issued_at",
  /** column name */
  Kind = "kind",
  /** column name */
  UpdatedAt = "updated_at",
  /** column name */
  User = "user",
  /** column name */
  WorkPercent = "work_percent",
}

/** select "work_sessions_aggregate_bool_exp_avg_arguments_columns" columns of table "work_sessions" */
export enum Work_Sessions_Select_Column_Work_Sessions_Aggregate_Bool_Exp_Avg_Arguments_Columns {
  /** column name */
  WorkPercent = "work_percent",
}

/** select "work_sessions_aggregate_bool_exp_corr_arguments_columns" columns of table "work_sessions" */
export enum Work_Sessions_Select_Column_Work_Sessions_Aggregate_Bool_Exp_Corr_Arguments_Columns {
  /** column name */
  WorkPercent = "work_percent",
}

/** select "work_sessions_aggregate_bool_exp_covar_samp_arguments_columns" columns of table "work_sessions" */
export enum Work_Sessions_Select_Column_Work_Sessions_Aggregate_Bool_Exp_Covar_Samp_Arguments_Columns {
  /** column name */
  WorkPercent = "work_percent",
}

/** select "work_sessions_aggregate_bool_exp_max_arguments_columns" columns of table "work_sessions" */
export enum Work_Sessions_Select_Column_Work_Sessions_Aggregate_Bool_Exp_Max_Arguments_Columns {
  /** column name */
  WorkPercent = "work_percent",
}

/** select "work_sessions_aggregate_bool_exp_min_arguments_columns" columns of table "work_sessions" */
export enum Work_Sessions_Select_Column_Work_Sessions_Aggregate_Bool_Exp_Min_Arguments_Columns {
  /** column name */
  WorkPercent = "work_percent",
}

/** select "work_sessions_aggregate_bool_exp_stddev_samp_arguments_columns" columns of table "work_sessions" */
export enum Work_Sessions_Select_Column_Work_Sessions_Aggregate_Bool_Exp_Stddev_Samp_Arguments_Columns {
  /** column name */
  WorkPercent = "work_percent",
}

/** select "work_sessions_aggregate_bool_exp_sum_arguments_columns" columns of table "work_sessions" */
export enum Work_Sessions_Select_Column_Work_Sessions_Aggregate_Bool_Exp_Sum_Arguments_Columns {
  /** column name */
  WorkPercent = "work_percent",
}

/** select "work_sessions_aggregate_bool_exp_var_samp_arguments_columns" columns of table "work_sessions" */
export enum Work_Sessions_Select_Column_Work_Sessions_Aggregate_Bool_Exp_Var_Samp_Arguments_Columns {
  /** column name */
  WorkPercent = "work_percent",
}

/** input type for updating data in table "work_sessions" */
export type Work_Sessions_Set_Input = {
  branch?: InputMaybe<Scalars["String"]>;
  collaborator_id?: InputMaybe<Scalars["uuid"]>;
  comments?: InputMaybe<Scalars["String"]>;
  created_at?: InputMaybe<Scalars["timestamptz"]>;
  geogroup_id?: InputMaybe<Scalars["uuid"]>;
  id?: InputMaybe<Scalars["uuid"]>;
  issued_at?: InputMaybe<Scalars["date"]>;
  kind?: InputMaybe<Scalars["String"]>;
  updated_at?: InputMaybe<Scalars["timestamptz"]>;
  user?: InputMaybe<Scalars["String"]>;
  work_percent?: InputMaybe<Scalars["float8"]>;
};

/** aggregate stddev on columns */
export type Work_Sessions_Stddev_Fields = {
  __typename?: "work_sessions_stddev_fields";
  work_percent?: Maybe<Scalars["Float"]>;
};

/** order by stddev() on columns of table "work_sessions" */
export type Work_Sessions_Stddev_Order_By = {
  work_percent?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Work_Sessions_Stddev_Pop_Fields = {
  __typename?: "work_sessions_stddev_pop_fields";
  work_percent?: Maybe<Scalars["Float"]>;
};

/** order by stddev_pop() on columns of table "work_sessions" */
export type Work_Sessions_Stddev_Pop_Order_By = {
  work_percent?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Work_Sessions_Stddev_Samp_Fields = {
  __typename?: "work_sessions_stddev_samp_fields";
  work_percent?: Maybe<Scalars["Float"]>;
};

/** order by stddev_samp() on columns of table "work_sessions" */
export type Work_Sessions_Stddev_Samp_Order_By = {
  work_percent?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "work_sessions" */
export type Work_Sessions_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Work_Sessions_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Work_Sessions_Stream_Cursor_Value_Input = {
  branch?: InputMaybe<Scalars["String"]>;
  collaborator_id?: InputMaybe<Scalars["uuid"]>;
  comments?: InputMaybe<Scalars["String"]>;
  created_at?: InputMaybe<Scalars["timestamptz"]>;
  geogroup_id?: InputMaybe<Scalars["uuid"]>;
  id?: InputMaybe<Scalars["uuid"]>;
  issued_at?: InputMaybe<Scalars["date"]>;
  kind?: InputMaybe<Scalars["String"]>;
  updated_at?: InputMaybe<Scalars["timestamptz"]>;
  user?: InputMaybe<Scalars["String"]>;
  work_percent?: InputMaybe<Scalars["float8"]>;
};

/** aggregate sum on columns */
export type Work_Sessions_Sum_Fields = {
  __typename?: "work_sessions_sum_fields";
  work_percent?: Maybe<Scalars["float8"]>;
};

/** order by sum() on columns of table "work_sessions" */
export type Work_Sessions_Sum_Order_By = {
  work_percent?: InputMaybe<Order_By>;
};

/** update columns of table "work_sessions" */
export enum Work_Sessions_Update_Column {
  /** column name */
  Branch = "branch",
  /** column name */
  CollaboratorId = "collaborator_id",
  /** column name */
  Comments = "comments",
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  GeogroupId = "geogroup_id",
  /** column name */
  Id = "id",
  /** column name */
  IssuedAt = "issued_at",
  /** column name */
  Kind = "kind",
  /** column name */
  UpdatedAt = "updated_at",
  /** column name */
  User = "user",
  /** column name */
  WorkPercent = "work_percent",
}

export type Work_Sessions_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Work_Sessions_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Work_Sessions_Set_Input>;
  /** filter the rows which have to be updated */
  where: Work_Sessions_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Work_Sessions_Var_Pop_Fields = {
  __typename?: "work_sessions_var_pop_fields";
  work_percent?: Maybe<Scalars["Float"]>;
};

/** order by var_pop() on columns of table "work_sessions" */
export type Work_Sessions_Var_Pop_Order_By = {
  work_percent?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Work_Sessions_Var_Samp_Fields = {
  __typename?: "work_sessions_var_samp_fields";
  work_percent?: Maybe<Scalars["Float"]>;
};

/** order by var_samp() on columns of table "work_sessions" */
export type Work_Sessions_Var_Samp_Order_By = {
  work_percent?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Work_Sessions_Variance_Fields = {
  __typename?: "work_sessions_variance_fields";
  work_percent?: Maybe<Scalars["Float"]>;
};

/** order by variance() on columns of table "work_sessions" */
export type Work_Sessions_Variance_Order_By = {
  work_percent?: InputMaybe<Order_By>;
};

/** columns and relationships of "workspace" */
export type Workspace = {
  __typename?: "workspace";
  /** An array relationship */
  advertisements: Array<Advertisement>;
  /** An aggregate relationship */
  advertisements_aggregate: Advertisement_Aggregate;
  /** An object relationship */
  collaborator?: Maybe<Collaborator>;
  /** An array relationship */
  complaints: Array<Complaint>;
  /** An aggregate relationship */
  complaints_aggregate: Complaint_Aggregate;
  created_at: Scalars["timestamptz"];
  /** An object relationship */
  geogroup: Geogroup;
  group_id: Scalars["uuid"];
  id: Scalars["uuid"];
  /** An array relationship */
  inspections: Array<Inspection>;
  /** An aggregate relationship */
  inspections_aggregate: Inspection_Aggregate;
  kind?: Maybe<Scalars["String"]>;
  /** An array relationship */
  observations: Array<Observation>;
  /** An aggregate relationship */
  observations_aggregate: Observation_Aggregate;
  owner_id?: Maybe<Scalars["uuid"]>;
  /** An array relationship */
  sprayeds: Array<Sprayed>;
  /** An aggregate relationship */
  sprayeds_aggregate: Sprayed_Aggregate;
  updated_at: Scalars["timestamptz"];
  /** An object relationship */
  workspace_kind?: Maybe<Workspace_Kind>;
};

/** columns and relationships of "workspace" */
export type WorkspaceAdvertisementsArgs = {
  distinct_on?: InputMaybe<Array<Advertisement_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Advertisement_Order_By>>;
  where?: InputMaybe<Advertisement_Bool_Exp>;
};

/** columns and relationships of "workspace" */
export type WorkspaceAdvertisements_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Advertisement_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Advertisement_Order_By>>;
  where?: InputMaybe<Advertisement_Bool_Exp>;
};

/** columns and relationships of "workspace" */
export type WorkspaceComplaintsArgs = {
  distinct_on?: InputMaybe<Array<Complaint_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Complaint_Order_By>>;
  where?: InputMaybe<Complaint_Bool_Exp>;
};

/** columns and relationships of "workspace" */
export type WorkspaceComplaints_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Complaint_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Complaint_Order_By>>;
  where?: InputMaybe<Complaint_Bool_Exp>;
};

/** columns and relationships of "workspace" */
export type WorkspaceInspectionsArgs = {
  distinct_on?: InputMaybe<Array<Inspection_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Inspection_Order_By>>;
  where?: InputMaybe<Inspection_Bool_Exp>;
};

/** columns and relationships of "workspace" */
export type WorkspaceInspections_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Inspection_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Inspection_Order_By>>;
  where?: InputMaybe<Inspection_Bool_Exp>;
};

/** columns and relationships of "workspace" */
export type WorkspaceObservationsArgs = {
  distinct_on?: InputMaybe<Array<Observation_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Observation_Order_By>>;
  where?: InputMaybe<Observation_Bool_Exp>;
};

/** columns and relationships of "workspace" */
export type WorkspaceObservations_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Observation_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Observation_Order_By>>;
  where?: InputMaybe<Observation_Bool_Exp>;
};

/** columns and relationships of "workspace" */
export type WorkspaceSprayedsArgs = {
  distinct_on?: InputMaybe<Array<Sprayed_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Sprayed_Order_By>>;
  where?: InputMaybe<Sprayed_Bool_Exp>;
};

/** columns and relationships of "workspace" */
export type WorkspaceSprayeds_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Sprayed_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Sprayed_Order_By>>;
  where?: InputMaybe<Sprayed_Bool_Exp>;
};

/** aggregated selection of "workspace" */
export type Workspace_Aggregate = {
  __typename?: "workspace_aggregate";
  aggregate?: Maybe<Workspace_Aggregate_Fields>;
  nodes: Array<Workspace>;
};

export type Workspace_Aggregate_Bool_Exp = {
  count?: InputMaybe<Workspace_Aggregate_Bool_Exp_Count>;
};

export type Workspace_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Workspace_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]>;
  filter?: InputMaybe<Workspace_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "workspace" */
export type Workspace_Aggregate_Fields = {
  __typename?: "workspace_aggregate_fields";
  count: Scalars["Int"];
  max?: Maybe<Workspace_Max_Fields>;
  min?: Maybe<Workspace_Min_Fields>;
};

/** aggregate fields of "workspace" */
export type Workspace_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Workspace_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "workspace" */
export type Workspace_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Workspace_Max_Order_By>;
  min?: InputMaybe<Workspace_Min_Order_By>;
};

/** input type for inserting array relation for remote table "workspace" */
export type Workspace_Arr_Rel_Insert_Input = {
  data: Array<Workspace_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Workspace_On_Conflict>;
};

/** Boolean expression to filter rows from the table "workspace". All fields are combined with a logical 'AND'. */
export type Workspace_Bool_Exp = {
  _and?: InputMaybe<Array<Workspace_Bool_Exp>>;
  _not?: InputMaybe<Workspace_Bool_Exp>;
  _or?: InputMaybe<Array<Workspace_Bool_Exp>>;
  advertisements?: InputMaybe<Advertisement_Bool_Exp>;
  advertisements_aggregate?: InputMaybe<Advertisement_Aggregate_Bool_Exp>;
  collaborator?: InputMaybe<Collaborator_Bool_Exp>;
  complaints?: InputMaybe<Complaint_Bool_Exp>;
  complaints_aggregate?: InputMaybe<Complaint_Aggregate_Bool_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  geogroup?: InputMaybe<Geogroup_Bool_Exp>;
  group_id?: InputMaybe<Uuid_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  inspections?: InputMaybe<Inspection_Bool_Exp>;
  inspections_aggregate?: InputMaybe<Inspection_Aggregate_Bool_Exp>;
  kind?: InputMaybe<String_Comparison_Exp>;
  observations?: InputMaybe<Observation_Bool_Exp>;
  observations_aggregate?: InputMaybe<Observation_Aggregate_Bool_Exp>;
  owner_id?: InputMaybe<Uuid_Comparison_Exp>;
  sprayeds?: InputMaybe<Sprayed_Bool_Exp>;
  sprayeds_aggregate?: InputMaybe<Sprayed_Aggregate_Bool_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  workspace_kind?: InputMaybe<Workspace_Kind_Bool_Exp>;
};

/** unique or primary key constraints on table "workspace" */
export enum Workspace_Constraint {
  /** unique or primary key constraint on columns "id" */
  WorkspacePkey = "workspace_pkey",
}

/** input type for inserting data into table "workspace" */
export type Workspace_Insert_Input = {
  advertisements?: InputMaybe<Advertisement_Arr_Rel_Insert_Input>;
  collaborator?: InputMaybe<Collaborator_Obj_Rel_Insert_Input>;
  complaints?: InputMaybe<Complaint_Arr_Rel_Insert_Input>;
  created_at?: InputMaybe<Scalars["timestamptz"]>;
  geogroup?: InputMaybe<Geogroup_Obj_Rel_Insert_Input>;
  group_id?: InputMaybe<Scalars["uuid"]>;
  id?: InputMaybe<Scalars["uuid"]>;
  inspections?: InputMaybe<Inspection_Arr_Rel_Insert_Input>;
  kind?: InputMaybe<Scalars["String"]>;
  observations?: InputMaybe<Observation_Arr_Rel_Insert_Input>;
  owner_id?: InputMaybe<Scalars["uuid"]>;
  sprayeds?: InputMaybe<Sprayed_Arr_Rel_Insert_Input>;
  updated_at?: InputMaybe<Scalars["timestamptz"]>;
  workspace_kind?: InputMaybe<Workspace_Kind_Obj_Rel_Insert_Input>;
};

/** columns and relationships of "workspace_kind" */
export type Workspace_Kind = {
  __typename?: "workspace_kind";
  comment?: Maybe<Scalars["String"]>;
  value: Scalars["String"];
  /** An array relationship */
  workspaces: Array<Workspace>;
  /** An aggregate relationship */
  workspaces_aggregate: Workspace_Aggregate;
};

/** columns and relationships of "workspace_kind" */
export type Workspace_KindWorkspacesArgs = {
  distinct_on?: InputMaybe<Array<Workspace_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Workspace_Order_By>>;
  where?: InputMaybe<Workspace_Bool_Exp>;
};

/** columns and relationships of "workspace_kind" */
export type Workspace_KindWorkspaces_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Workspace_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Workspace_Order_By>>;
  where?: InputMaybe<Workspace_Bool_Exp>;
};

/** aggregated selection of "workspace_kind" */
export type Workspace_Kind_Aggregate = {
  __typename?: "workspace_kind_aggregate";
  aggregate?: Maybe<Workspace_Kind_Aggregate_Fields>;
  nodes: Array<Workspace_Kind>;
};

/** aggregate fields of "workspace_kind" */
export type Workspace_Kind_Aggregate_Fields = {
  __typename?: "workspace_kind_aggregate_fields";
  count: Scalars["Int"];
  max?: Maybe<Workspace_Kind_Max_Fields>;
  min?: Maybe<Workspace_Kind_Min_Fields>;
};

/** aggregate fields of "workspace_kind" */
export type Workspace_Kind_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Workspace_Kind_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]>;
};

/** Boolean expression to filter rows from the table "workspace_kind". All fields are combined with a logical 'AND'. */
export type Workspace_Kind_Bool_Exp = {
  _and?: InputMaybe<Array<Workspace_Kind_Bool_Exp>>;
  _not?: InputMaybe<Workspace_Kind_Bool_Exp>;
  _or?: InputMaybe<Array<Workspace_Kind_Bool_Exp>>;
  comment?: InputMaybe<String_Comparison_Exp>;
  value?: InputMaybe<String_Comparison_Exp>;
  workspaces?: InputMaybe<Workspace_Bool_Exp>;
  workspaces_aggregate?: InputMaybe<Workspace_Aggregate_Bool_Exp>;
};

/** unique or primary key constraints on table "workspace_kind" */
export enum Workspace_Kind_Constraint {
  /** unique or primary key constraint on columns "value" */
  WorkspaceKindPkey = "workspace_kind_pkey",
}

/** input type for inserting data into table "workspace_kind" */
export type Workspace_Kind_Insert_Input = {
  comment?: InputMaybe<Scalars["String"]>;
  value?: InputMaybe<Scalars["String"]>;
  workspaces?: InputMaybe<Workspace_Arr_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Workspace_Kind_Max_Fields = {
  __typename?: "workspace_kind_max_fields";
  comment?: Maybe<Scalars["String"]>;
  value?: Maybe<Scalars["String"]>;
};

/** aggregate min on columns */
export type Workspace_Kind_Min_Fields = {
  __typename?: "workspace_kind_min_fields";
  comment?: Maybe<Scalars["String"]>;
  value?: Maybe<Scalars["String"]>;
};

/** response of any mutation on the table "workspace_kind" */
export type Workspace_Kind_Mutation_Response = {
  __typename?: "workspace_kind_mutation_response";
  /** number of rows affected by the mutation */
  affected_rows: Scalars["Int"];
  /** data from the rows affected by the mutation */
  returning: Array<Workspace_Kind>;
};

/** input type for inserting object relation for remote table "workspace_kind" */
export type Workspace_Kind_Obj_Rel_Insert_Input = {
  data: Workspace_Kind_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Workspace_Kind_On_Conflict>;
};

/** on_conflict condition type for table "workspace_kind" */
export type Workspace_Kind_On_Conflict = {
  constraint: Workspace_Kind_Constraint;
  update_columns?: Array<Workspace_Kind_Update_Column>;
  where?: InputMaybe<Workspace_Kind_Bool_Exp>;
};

/** Ordering options when selecting data from "workspace_kind". */
export type Workspace_Kind_Order_By = {
  comment?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
  workspaces_aggregate?: InputMaybe<Workspace_Aggregate_Order_By>;
};

/** primary key columns input for table: workspace_kind */
export type Workspace_Kind_Pk_Columns_Input = {
  value: Scalars["String"];
};

/** select columns of table "workspace_kind" */
export enum Workspace_Kind_Select_Column {
  /** column name */
  Comment = "comment",
  /** column name */
  Value = "value",
}

/** input type for updating data in table "workspace_kind" */
export type Workspace_Kind_Set_Input = {
  comment?: InputMaybe<Scalars["String"]>;
  value?: InputMaybe<Scalars["String"]>;
};

/** Streaming cursor of the table "workspace_kind" */
export type Workspace_Kind_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Workspace_Kind_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Workspace_Kind_Stream_Cursor_Value_Input = {
  comment?: InputMaybe<Scalars["String"]>;
  value?: InputMaybe<Scalars["String"]>;
};

/** update columns of table "workspace_kind" */
export enum Workspace_Kind_Update_Column {
  /** column name */
  Comment = "comment",
  /** column name */
  Value = "value",
}

export type Workspace_Kind_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Workspace_Kind_Set_Input>;
  /** filter the rows which have to be updated */
  where: Workspace_Kind_Bool_Exp;
};

/** aggregate max on columns */
export type Workspace_Max_Fields = {
  __typename?: "workspace_max_fields";
  created_at?: Maybe<Scalars["timestamptz"]>;
  group_id?: Maybe<Scalars["uuid"]>;
  id?: Maybe<Scalars["uuid"]>;
  kind?: Maybe<Scalars["String"]>;
  owner_id?: Maybe<Scalars["uuid"]>;
  updated_at?: Maybe<Scalars["timestamptz"]>;
};

/** order by max() on columns of table "workspace" */
export type Workspace_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  group_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  kind?: InputMaybe<Order_By>;
  owner_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** columns and relationships of "workspace_metadata" */
export type Workspace_Metadata = {
  __typename?: "workspace_metadata";
  app_state: Scalars["String"];
  /** An array relationship */
  complaints: Array<Complaint>;
  /** An aggregate relationship */
  complaints_aggregate: Complaint_Aggregate;
  created_at?: Maybe<Scalars["timestamptz"]>;
  data: Scalars["jsonb"];
  /** An object relationship */
  experiment?: Maybe<Experiment>;
  experiment_id?: Maybe<Scalars["uuid"]>;
  firebase_id?: Maybe<Scalars["String"]>;
  /** An object relationship */
  geogroup: Geogroup;
  geogroup_id: Scalars["uuid"];
  /** An object relationship */
  geopoint: Geopoint;
  geopoint_id: Scalars["uuid"];
  id: Scalars["uuid"];
  /** An array relationship */
  inspections: Array<Inspection>;
  /** An aggregate relationship */
  inspections_aggregate: Inspection_Aggregate;
  kind: Scalars["String"];
  parent_id?: Maybe<Scalars["uuid"]>;
  /** An array relationship */
  sprayeds: Array<Sprayed>;
  /** An aggregate relationship */
  sprayeds_aggregate: Sprayed_Aggregate;
  updated_at?: Maybe<Scalars["timestamptz"]>;
  workspace_id?: Maybe<Scalars["uuid"]>;
  /** An array relationship */
  workspace_metadata: Array<Workspace_Metadata>;
  /** An aggregate relationship */
  workspace_metadata_aggregate: Workspace_Metadata_Aggregate;
  /** An object relationship */
  workspace_metadatum?: Maybe<Workspace_Metadata>;
};

/** columns and relationships of "workspace_metadata" */
export type Workspace_MetadataComplaintsArgs = {
  distinct_on?: InputMaybe<Array<Complaint_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Complaint_Order_By>>;
  where?: InputMaybe<Complaint_Bool_Exp>;
};

/** columns and relationships of "workspace_metadata" */
export type Workspace_MetadataComplaints_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Complaint_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Complaint_Order_By>>;
  where?: InputMaybe<Complaint_Bool_Exp>;
};

/** columns and relationships of "workspace_metadata" */
export type Workspace_MetadataDataArgs = {
  path?: InputMaybe<Scalars["String"]>;
};

/** columns and relationships of "workspace_metadata" */
export type Workspace_MetadataInspectionsArgs = {
  distinct_on?: InputMaybe<Array<Inspection_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Inspection_Order_By>>;
  where?: InputMaybe<Inspection_Bool_Exp>;
};

/** columns and relationships of "workspace_metadata" */
export type Workspace_MetadataInspections_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Inspection_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Inspection_Order_By>>;
  where?: InputMaybe<Inspection_Bool_Exp>;
};

/** columns and relationships of "workspace_metadata" */
export type Workspace_MetadataSprayedsArgs = {
  distinct_on?: InputMaybe<Array<Sprayed_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Sprayed_Order_By>>;
  where?: InputMaybe<Sprayed_Bool_Exp>;
};

/** columns and relationships of "workspace_metadata" */
export type Workspace_MetadataSprayeds_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Sprayed_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Sprayed_Order_By>>;
  where?: InputMaybe<Sprayed_Bool_Exp>;
};

/** columns and relationships of "workspace_metadata" */
export type Workspace_MetadataWorkspace_MetadataArgs = {
  distinct_on?: InputMaybe<Array<Workspace_Metadata_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Workspace_Metadata_Order_By>>;
  where?: InputMaybe<Workspace_Metadata_Bool_Exp>;
};

/** columns and relationships of "workspace_metadata" */
export type Workspace_MetadataWorkspace_Metadata_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Workspace_Metadata_Select_Column>>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  order_by?: InputMaybe<Array<Workspace_Metadata_Order_By>>;
  where?: InputMaybe<Workspace_Metadata_Bool_Exp>;
};

/** aggregated selection of "workspace_metadata" */
export type Workspace_Metadata_Aggregate = {
  __typename?: "workspace_metadata_aggregate";
  aggregate?: Maybe<Workspace_Metadata_Aggregate_Fields>;
  nodes: Array<Workspace_Metadata>;
};

export type Workspace_Metadata_Aggregate_Bool_Exp = {
  count?: InputMaybe<Workspace_Metadata_Aggregate_Bool_Exp_Count>;
};

export type Workspace_Metadata_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Workspace_Metadata_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]>;
  filter?: InputMaybe<Workspace_Metadata_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "workspace_metadata" */
export type Workspace_Metadata_Aggregate_Fields = {
  __typename?: "workspace_metadata_aggregate_fields";
  count: Scalars["Int"];
  max?: Maybe<Workspace_Metadata_Max_Fields>;
  min?: Maybe<Workspace_Metadata_Min_Fields>;
};

/** aggregate fields of "workspace_metadata" */
export type Workspace_Metadata_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Workspace_Metadata_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "workspace_metadata" */
export type Workspace_Metadata_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Workspace_Metadata_Max_Order_By>;
  min?: InputMaybe<Workspace_Metadata_Min_Order_By>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Workspace_Metadata_Append_Input = {
  data?: InputMaybe<Scalars["jsonb"]>;
};

/** input type for inserting array relation for remote table "workspace_metadata" */
export type Workspace_Metadata_Arr_Rel_Insert_Input = {
  data: Array<Workspace_Metadata_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Workspace_Metadata_On_Conflict>;
};

/** Boolean expression to filter rows from the table "workspace_metadata". All fields are combined with a logical 'AND'. */
export type Workspace_Metadata_Bool_Exp = {
  _and?: InputMaybe<Array<Workspace_Metadata_Bool_Exp>>;
  _not?: InputMaybe<Workspace_Metadata_Bool_Exp>;
  _or?: InputMaybe<Array<Workspace_Metadata_Bool_Exp>>;
  app_state?: InputMaybe<String_Comparison_Exp>;
  complaints?: InputMaybe<Complaint_Bool_Exp>;
  complaints_aggregate?: InputMaybe<Complaint_Aggregate_Bool_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  data?: InputMaybe<Jsonb_Comparison_Exp>;
  experiment?: InputMaybe<Experiment_Bool_Exp>;
  experiment_id?: InputMaybe<Uuid_Comparison_Exp>;
  firebase_id?: InputMaybe<String_Comparison_Exp>;
  geogroup?: InputMaybe<Geogroup_Bool_Exp>;
  geogroup_id?: InputMaybe<Uuid_Comparison_Exp>;
  geopoint?: InputMaybe<Geopoint_Bool_Exp>;
  geopoint_id?: InputMaybe<Uuid_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  inspections?: InputMaybe<Inspection_Bool_Exp>;
  inspections_aggregate?: InputMaybe<Inspection_Aggregate_Bool_Exp>;
  kind?: InputMaybe<String_Comparison_Exp>;
  parent_id?: InputMaybe<Uuid_Comparison_Exp>;
  sprayeds?: InputMaybe<Sprayed_Bool_Exp>;
  sprayeds_aggregate?: InputMaybe<Sprayed_Aggregate_Bool_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  workspace_id?: InputMaybe<Uuid_Comparison_Exp>;
  workspace_metadata?: InputMaybe<Workspace_Metadata_Bool_Exp>;
  workspace_metadata_aggregate?: InputMaybe<Workspace_Metadata_Aggregate_Bool_Exp>;
  workspace_metadatum?: InputMaybe<Workspace_Metadata_Bool_Exp>;
};

/** unique or primary key constraints on table "workspace_metadata" */
export enum Workspace_Metadata_Constraint {
  /** unique or primary key constraint on columns "id" */
  WorkspaceMetadataPkey = "workspace_metadata_pkey",
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Workspace_Metadata_Delete_At_Path_Input = {
  data?: InputMaybe<Array<Scalars["String"]>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Workspace_Metadata_Delete_Elem_Input = {
  data?: InputMaybe<Scalars["Int"]>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Workspace_Metadata_Delete_Key_Input = {
  data?: InputMaybe<Scalars["String"]>;
};

/** input type for inserting data into table "workspace_metadata" */
export type Workspace_Metadata_Insert_Input = {
  app_state?: InputMaybe<Scalars["String"]>;
  complaints?: InputMaybe<Complaint_Arr_Rel_Insert_Input>;
  created_at?: InputMaybe<Scalars["timestamptz"]>;
  data?: InputMaybe<Scalars["jsonb"]>;
  experiment?: InputMaybe<Experiment_Obj_Rel_Insert_Input>;
  experiment_id?: InputMaybe<Scalars["uuid"]>;
  firebase_id?: InputMaybe<Scalars["String"]>;
  geogroup?: InputMaybe<Geogroup_Obj_Rel_Insert_Input>;
  geogroup_id?: InputMaybe<Scalars["uuid"]>;
  geopoint?: InputMaybe<Geopoint_Obj_Rel_Insert_Input>;
  geopoint_id?: InputMaybe<Scalars["uuid"]>;
  id?: InputMaybe<Scalars["uuid"]>;
  inspections?: InputMaybe<Inspection_Arr_Rel_Insert_Input>;
  kind?: InputMaybe<Scalars["String"]>;
  parent_id?: InputMaybe<Scalars["uuid"]>;
  sprayeds?: InputMaybe<Sprayed_Arr_Rel_Insert_Input>;
  updated_at?: InputMaybe<Scalars["timestamptz"]>;
  workspace_id?: InputMaybe<Scalars["uuid"]>;
  workspace_metadata?: InputMaybe<Workspace_Metadata_Arr_Rel_Insert_Input>;
  workspace_metadatum?: InputMaybe<Workspace_Metadata_Obj_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Workspace_Metadata_Max_Fields = {
  __typename?: "workspace_metadata_max_fields";
  app_state?: Maybe<Scalars["String"]>;
  created_at?: Maybe<Scalars["timestamptz"]>;
  experiment_id?: Maybe<Scalars["uuid"]>;
  firebase_id?: Maybe<Scalars["String"]>;
  geogroup_id?: Maybe<Scalars["uuid"]>;
  geopoint_id?: Maybe<Scalars["uuid"]>;
  id?: Maybe<Scalars["uuid"]>;
  kind?: Maybe<Scalars["String"]>;
  parent_id?: Maybe<Scalars["uuid"]>;
  updated_at?: Maybe<Scalars["timestamptz"]>;
  workspace_id?: Maybe<Scalars["uuid"]>;
};

/** order by max() on columns of table "workspace_metadata" */
export type Workspace_Metadata_Max_Order_By = {
  app_state?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  experiment_id?: InputMaybe<Order_By>;
  firebase_id?: InputMaybe<Order_By>;
  geogroup_id?: InputMaybe<Order_By>;
  geopoint_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  kind?: InputMaybe<Order_By>;
  parent_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  workspace_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Workspace_Metadata_Min_Fields = {
  __typename?: "workspace_metadata_min_fields";
  app_state?: Maybe<Scalars["String"]>;
  created_at?: Maybe<Scalars["timestamptz"]>;
  experiment_id?: Maybe<Scalars["uuid"]>;
  firebase_id?: Maybe<Scalars["String"]>;
  geogroup_id?: Maybe<Scalars["uuid"]>;
  geopoint_id?: Maybe<Scalars["uuid"]>;
  id?: Maybe<Scalars["uuid"]>;
  kind?: Maybe<Scalars["String"]>;
  parent_id?: Maybe<Scalars["uuid"]>;
  updated_at?: Maybe<Scalars["timestamptz"]>;
  workspace_id?: Maybe<Scalars["uuid"]>;
};

/** order by min() on columns of table "workspace_metadata" */
export type Workspace_Metadata_Min_Order_By = {
  app_state?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  experiment_id?: InputMaybe<Order_By>;
  firebase_id?: InputMaybe<Order_By>;
  geogroup_id?: InputMaybe<Order_By>;
  geopoint_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  kind?: InputMaybe<Order_By>;
  parent_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  workspace_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "workspace_metadata" */
export type Workspace_Metadata_Mutation_Response = {
  __typename?: "workspace_metadata_mutation_response";
  /** number of rows affected by the mutation */
  affected_rows: Scalars["Int"];
  /** data from the rows affected by the mutation */
  returning: Array<Workspace_Metadata>;
};

/** input type for inserting object relation for remote table "workspace_metadata" */
export type Workspace_Metadata_Obj_Rel_Insert_Input = {
  data: Workspace_Metadata_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Workspace_Metadata_On_Conflict>;
};

/** on_conflict condition type for table "workspace_metadata" */
export type Workspace_Metadata_On_Conflict = {
  constraint: Workspace_Metadata_Constraint;
  update_columns?: Array<Workspace_Metadata_Update_Column>;
  where?: InputMaybe<Workspace_Metadata_Bool_Exp>;
};

/** Ordering options when selecting data from "workspace_metadata". */
export type Workspace_Metadata_Order_By = {
  app_state?: InputMaybe<Order_By>;
  complaints_aggregate?: InputMaybe<Complaint_Aggregate_Order_By>;
  created_at?: InputMaybe<Order_By>;
  data?: InputMaybe<Order_By>;
  experiment?: InputMaybe<Experiment_Order_By>;
  experiment_id?: InputMaybe<Order_By>;
  firebase_id?: InputMaybe<Order_By>;
  geogroup?: InputMaybe<Geogroup_Order_By>;
  geogroup_id?: InputMaybe<Order_By>;
  geopoint?: InputMaybe<Geopoint_Order_By>;
  geopoint_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  inspections_aggregate?: InputMaybe<Inspection_Aggregate_Order_By>;
  kind?: InputMaybe<Order_By>;
  parent_id?: InputMaybe<Order_By>;
  sprayeds_aggregate?: InputMaybe<Sprayed_Aggregate_Order_By>;
  updated_at?: InputMaybe<Order_By>;
  workspace_id?: InputMaybe<Order_By>;
  workspace_metadata_aggregate?: InputMaybe<Workspace_Metadata_Aggregate_Order_By>;
  workspace_metadatum?: InputMaybe<Workspace_Metadata_Order_By>;
};

/** primary key columns input for table: workspace_metadata */
export type Workspace_Metadata_Pk_Columns_Input = {
  id: Scalars["uuid"];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Workspace_Metadata_Prepend_Input = {
  data?: InputMaybe<Scalars["jsonb"]>;
};

/** select columns of table "workspace_metadata" */
export enum Workspace_Metadata_Select_Column {
  /** column name */
  AppState = "app_state",
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  Data = "data",
  /** column name */
  ExperimentId = "experiment_id",
  /** column name */
  FirebaseId = "firebase_id",
  /** column name */
  GeogroupId = "geogroup_id",
  /** column name */
  GeopointId = "geopoint_id",
  /** column name */
  Id = "id",
  /** column name */
  Kind = "kind",
  /** column name */
  ParentId = "parent_id",
  /** column name */
  UpdatedAt = "updated_at",
  /** column name */
  WorkspaceId = "workspace_id",
}

/** input type for updating data in table "workspace_metadata" */
export type Workspace_Metadata_Set_Input = {
  app_state?: InputMaybe<Scalars["String"]>;
  created_at?: InputMaybe<Scalars["timestamptz"]>;
  data?: InputMaybe<Scalars["jsonb"]>;
  experiment_id?: InputMaybe<Scalars["uuid"]>;
  firebase_id?: InputMaybe<Scalars["String"]>;
  geogroup_id?: InputMaybe<Scalars["uuid"]>;
  geopoint_id?: InputMaybe<Scalars["uuid"]>;
  id?: InputMaybe<Scalars["uuid"]>;
  kind?: InputMaybe<Scalars["String"]>;
  parent_id?: InputMaybe<Scalars["uuid"]>;
  updated_at?: InputMaybe<Scalars["timestamptz"]>;
  workspace_id?: InputMaybe<Scalars["uuid"]>;
};

/** Streaming cursor of the table "workspace_metadata" */
export type Workspace_Metadata_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Workspace_Metadata_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Workspace_Metadata_Stream_Cursor_Value_Input = {
  app_state?: InputMaybe<Scalars["String"]>;
  created_at?: InputMaybe<Scalars["timestamptz"]>;
  data?: InputMaybe<Scalars["jsonb"]>;
  experiment_id?: InputMaybe<Scalars["uuid"]>;
  firebase_id?: InputMaybe<Scalars["String"]>;
  geogroup_id?: InputMaybe<Scalars["uuid"]>;
  geopoint_id?: InputMaybe<Scalars["uuid"]>;
  id?: InputMaybe<Scalars["uuid"]>;
  kind?: InputMaybe<Scalars["String"]>;
  parent_id?: InputMaybe<Scalars["uuid"]>;
  updated_at?: InputMaybe<Scalars["timestamptz"]>;
  workspace_id?: InputMaybe<Scalars["uuid"]>;
};

/** update columns of table "workspace_metadata" */
export enum Workspace_Metadata_Update_Column {
  /** column name */
  AppState = "app_state",
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  Data = "data",
  /** column name */
  ExperimentId = "experiment_id",
  /** column name */
  FirebaseId = "firebase_id",
  /** column name */
  GeogroupId = "geogroup_id",
  /** column name */
  GeopointId = "geopoint_id",
  /** column name */
  Id = "id",
  /** column name */
  Kind = "kind",
  /** column name */
  ParentId = "parent_id",
  /** column name */
  UpdatedAt = "updated_at",
  /** column name */
  WorkspaceId = "workspace_id",
}

export type Workspace_Metadata_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Workspace_Metadata_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Workspace_Metadata_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Workspace_Metadata_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Workspace_Metadata_Delete_Key_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Workspace_Metadata_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Workspace_Metadata_Set_Input>;
  /** filter the rows which have to be updated */
  where: Workspace_Metadata_Bool_Exp;
};

/** aggregate min on columns */
export type Workspace_Min_Fields = {
  __typename?: "workspace_min_fields";
  created_at?: Maybe<Scalars["timestamptz"]>;
  group_id?: Maybe<Scalars["uuid"]>;
  id?: Maybe<Scalars["uuid"]>;
  kind?: Maybe<Scalars["String"]>;
  owner_id?: Maybe<Scalars["uuid"]>;
  updated_at?: Maybe<Scalars["timestamptz"]>;
};

/** order by min() on columns of table "workspace" */
export type Workspace_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  group_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  kind?: InputMaybe<Order_By>;
  owner_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "workspace" */
export type Workspace_Mutation_Response = {
  __typename?: "workspace_mutation_response";
  /** number of rows affected by the mutation */
  affected_rows: Scalars["Int"];
  /** data from the rows affected by the mutation */
  returning: Array<Workspace>;
};

/** input type for inserting object relation for remote table "workspace" */
export type Workspace_Obj_Rel_Insert_Input = {
  data: Workspace_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Workspace_On_Conflict>;
};

/** on_conflict condition type for table "workspace" */
export type Workspace_On_Conflict = {
  constraint: Workspace_Constraint;
  update_columns?: Array<Workspace_Update_Column>;
  where?: InputMaybe<Workspace_Bool_Exp>;
};

/** Ordering options when selecting data from "workspace". */
export type Workspace_Order_By = {
  advertisements_aggregate?: InputMaybe<Advertisement_Aggregate_Order_By>;
  collaborator?: InputMaybe<Collaborator_Order_By>;
  complaints_aggregate?: InputMaybe<Complaint_Aggregate_Order_By>;
  created_at?: InputMaybe<Order_By>;
  geogroup?: InputMaybe<Geogroup_Order_By>;
  group_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  inspections_aggregate?: InputMaybe<Inspection_Aggregate_Order_By>;
  kind?: InputMaybe<Order_By>;
  observations_aggregate?: InputMaybe<Observation_Aggregate_Order_By>;
  owner_id?: InputMaybe<Order_By>;
  sprayeds_aggregate?: InputMaybe<Sprayed_Aggregate_Order_By>;
  updated_at?: InputMaybe<Order_By>;
  workspace_kind?: InputMaybe<Workspace_Kind_Order_By>;
};

/** columns and relationships of "workspace_owners" */
export type Workspace_Owners = {
  __typename?: "workspace_owners";
  owner_id: Scalars["uuid"];
  workspace_id: Scalars["uuid"];
};

/** aggregated selection of "workspace_owners" */
export type Workspace_Owners_Aggregate = {
  __typename?: "workspace_owners_aggregate";
  aggregate?: Maybe<Workspace_Owners_Aggregate_Fields>;
  nodes: Array<Workspace_Owners>;
};

/** aggregate fields of "workspace_owners" */
export type Workspace_Owners_Aggregate_Fields = {
  __typename?: "workspace_owners_aggregate_fields";
  count: Scalars["Int"];
  max?: Maybe<Workspace_Owners_Max_Fields>;
  min?: Maybe<Workspace_Owners_Min_Fields>;
};

/** aggregate fields of "workspace_owners" */
export type Workspace_Owners_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Workspace_Owners_Select_Column>>;
  distinct?: InputMaybe<Scalars["Boolean"]>;
};

/** Boolean expression to filter rows from the table "workspace_owners". All fields are combined with a logical 'AND'. */
export type Workspace_Owners_Bool_Exp = {
  _and?: InputMaybe<Array<Workspace_Owners_Bool_Exp>>;
  _not?: InputMaybe<Workspace_Owners_Bool_Exp>;
  _or?: InputMaybe<Array<Workspace_Owners_Bool_Exp>>;
  owner_id?: InputMaybe<Uuid_Comparison_Exp>;
  workspace_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "workspace_owners" */
export enum Workspace_Owners_Constraint {
  /** unique or primary key constraint on columns "owner_id", "workspace_id" */
  WorkspaceOwnersPkey = "workspace_owners_pkey",
}

/** input type for inserting data into table "workspace_owners" */
export type Workspace_Owners_Insert_Input = {
  owner_id?: InputMaybe<Scalars["uuid"]>;
  workspace_id?: InputMaybe<Scalars["uuid"]>;
};

/** aggregate max on columns */
export type Workspace_Owners_Max_Fields = {
  __typename?: "workspace_owners_max_fields";
  owner_id?: Maybe<Scalars["uuid"]>;
  workspace_id?: Maybe<Scalars["uuid"]>;
};

/** aggregate min on columns */
export type Workspace_Owners_Min_Fields = {
  __typename?: "workspace_owners_min_fields";
  owner_id?: Maybe<Scalars["uuid"]>;
  workspace_id?: Maybe<Scalars["uuid"]>;
};

/** response of any mutation on the table "workspace_owners" */
export type Workspace_Owners_Mutation_Response = {
  __typename?: "workspace_owners_mutation_response";
  /** number of rows affected by the mutation */
  affected_rows: Scalars["Int"];
  /** data from the rows affected by the mutation */
  returning: Array<Workspace_Owners>;
};

/** on_conflict condition type for table "workspace_owners" */
export type Workspace_Owners_On_Conflict = {
  constraint: Workspace_Owners_Constraint;
  update_columns?: Array<Workspace_Owners_Update_Column>;
  where?: InputMaybe<Workspace_Owners_Bool_Exp>;
};

/** Ordering options when selecting data from "workspace_owners". */
export type Workspace_Owners_Order_By = {
  owner_id?: InputMaybe<Order_By>;
  workspace_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: workspace_owners */
export type Workspace_Owners_Pk_Columns_Input = {
  owner_id: Scalars["uuid"];
  workspace_id: Scalars["uuid"];
};

/** select columns of table "workspace_owners" */
export enum Workspace_Owners_Select_Column {
  /** column name */
  OwnerId = "owner_id",
  /** column name */
  WorkspaceId = "workspace_id",
}

/** input type for updating data in table "workspace_owners" */
export type Workspace_Owners_Set_Input = {
  owner_id?: InputMaybe<Scalars["uuid"]>;
  workspace_id?: InputMaybe<Scalars["uuid"]>;
};

/** Streaming cursor of the table "workspace_owners" */
export type Workspace_Owners_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Workspace_Owners_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Workspace_Owners_Stream_Cursor_Value_Input = {
  owner_id?: InputMaybe<Scalars["uuid"]>;
  workspace_id?: InputMaybe<Scalars["uuid"]>;
};

/** update columns of table "workspace_owners" */
export enum Workspace_Owners_Update_Column {
  /** column name */
  OwnerId = "owner_id",
  /** column name */
  WorkspaceId = "workspace_id",
}

export type Workspace_Owners_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Workspace_Owners_Set_Input>;
  /** filter the rows which have to be updated */
  where: Workspace_Owners_Bool_Exp;
};

/** primary key columns input for table: workspace */
export type Workspace_Pk_Columns_Input = {
  id: Scalars["uuid"];
};

/** select columns of table "workspace" */
export enum Workspace_Select_Column {
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  GroupId = "group_id",
  /** column name */
  Id = "id",
  /** column name */
  Kind = "kind",
  /** column name */
  OwnerId = "owner_id",
  /** column name */
  UpdatedAt = "updated_at",
}

/** input type for updating data in table "workspace" */
export type Workspace_Set_Input = {
  created_at?: InputMaybe<Scalars["timestamptz"]>;
  group_id?: InputMaybe<Scalars["uuid"]>;
  id?: InputMaybe<Scalars["uuid"]>;
  kind?: InputMaybe<Scalars["String"]>;
  owner_id?: InputMaybe<Scalars["uuid"]>;
  updated_at?: InputMaybe<Scalars["timestamptz"]>;
};

/** Streaming cursor of the table "workspace" */
export type Workspace_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Workspace_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Workspace_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars["timestamptz"]>;
  group_id?: InputMaybe<Scalars["uuid"]>;
  id?: InputMaybe<Scalars["uuid"]>;
  kind?: InputMaybe<Scalars["String"]>;
  owner_id?: InputMaybe<Scalars["uuid"]>;
  updated_at?: InputMaybe<Scalars["timestamptz"]>;
};

/** update columns of table "workspace" */
export enum Workspace_Update_Column {
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  GroupId = "group_id",
  /** column name */
  Id = "id",
  /** column name */
  Kind = "kind",
  /** column name */
  OwnerId = "owner_id",
  /** column name */
  UpdatedAt = "updated_at",
}

export type Workspace_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Workspace_Set_Input>;
  /** filter the rows which have to be updated */
  where: Workspace_Bool_Exp;
};

export type CollaboratorQueryVariables = Exact<{
  auth_id?: InputMaybe<Scalars["uuid"]>;
}>;

export type CollaboratorQuery = {
  __typename?: "query_root";
  collaborator: Array<{ __typename?: "collaborator"; id: any; email: string }>;
};

export type ExperimentsByGeogroupQueryVariables = Exact<{
  geogroups: Array<Scalars["uuid"]> | Scalars["uuid"];
}>;

export type ExperimentsByGeogroupQuery = {
  __typename?: "query_root";
  experiment: Array<{ __typename?: "experiment"; id: any; created_at: any; kind?: string | null }>;
};

export type GroupsQueryVariables = Exact<{ [key: string]: never }>;

export type GroupsQuery = {
  __typename?: "query_root";
  geogroup: Array<{ __typename?: "geogroup"; id: any; name: string; geometry?: any | null }>;
};

export type TableWorkspaceMetadataQueryVariables = Exact<{
  input?: InputMaybe<Workspace_Metadata_Bool_Exp>;
}>;

export type TableWorkspaceMetadataQuery = {
  __typename?: "query_root";
  workspace_metadata: Array<{
    __typename?: "workspace_metadata";
    id: any;
    kind: string;
    data: any;
    created_at?: any | null;
    geogroup: { __typename?: "geogroup"; id: any; name: string };
    geopoint: { __typename?: "geopoint"; id: any; unicode?: string | null; lat: any; lng: any };
    experiment?: { __typename?: "experiment"; id: any; created_at: any } | null;
  }>;
};

export type MetadataKindsQueryVariables = Exact<{ [key: string]: never }>;

export type MetadataKindsQuery = {
  __typename?: "query_root";
  workspace_metadata_aggregate: {
    __typename?: "workspace_metadata_aggregate";
    nodes: Array<{ __typename?: "workspace_metadata"; kind: string }>;
  };
};

export type WorkspacesQueryVariables = Exact<{ [key: string]: never }>;

export type WorkspacesQuery = {
  __typename?: "query_root";
  workspace: Array<{ __typename?: "workspace"; id: any }>;
};

export type WorkspacebyGeoGroupandExperimentsQueryVariables = Exact<{ [key: string]: never }>;

export type WorkspacebyGeoGroupandExperimentsQuery = {
  __typename?: "query_root";
  workspace: Array<{ __typename?: "workspace"; id: any; kind?: string | null }>;
};

export const CollaboratorDocument = gql`
  query Collaborator($auth_id: uuid) {
    collaborator(where: { id: { _eq: $auth_id } }) {
      id
      email
    }
  }
`;

export function useCollaboratorQuery(
  options?: Omit<Urql.UseQueryArgs<CollaboratorQueryVariables>, "query">
) {
  return Urql.useQuery<CollaboratorQuery>({ query: CollaboratorDocument, ...options });
}
export const ExperimentsByGeogroupDocument = gql`
  query ExperimentsByGeogroup($geogroups: [uuid!]!) {
    experiment(where: { geogroup_id: { _in: $geogroups } }, order_by: { created_at: asc }) {
      id
      created_at
      kind
    }
  }
`;

export function useExperimentsByGeogroupQuery(
  options: Omit<Urql.UseQueryArgs<ExperimentsByGeogroupQueryVariables>, "query">
) {
  return Urql.useQuery<ExperimentsByGeogroupQuery>({
    query: ExperimentsByGeogroupDocument,
    ...options,
  });
}
export const GroupsDocument = gql`
  query Groups {
    geogroup(order_by: { name: asc }) {
      id
      name
      geometry
    }
  }
`;

export function useGroupsQuery(options?: Omit<Urql.UseQueryArgs<GroupsQueryVariables>, "query">) {
  return Urql.useQuery<GroupsQuery>({ query: GroupsDocument, ...options });
}
export const TableWorkspaceMetadataDocument = gql`
  query TableWorkspaceMetadata($input: workspace_metadata_bool_exp) {
    workspace_metadata(where: $input) {
      id
      kind
      data
      created_at
      geogroup {
        id
        name
      }
      geopoint {
        id
        unicode
        lat
        lng
      }
      experiment {
        id
        created_at
      }
    }
  }
`;

export function useTableWorkspaceMetadataQuery(
  options?: Omit<Urql.UseQueryArgs<TableWorkspaceMetadataQueryVariables>, "query">
) {
  return Urql.useQuery<TableWorkspaceMetadataQuery>({
    query: TableWorkspaceMetadataDocument,
    ...options,
  });
}
export const MetadataKindsDocument = gql`
  query MetadataKinds {
    workspace_metadata_aggregate(distinct_on: kind) {
      nodes {
        kind
      }
    }
  }
`;

export function useMetadataKindsQuery(
  options?: Omit<Urql.UseQueryArgs<MetadataKindsQueryVariables>, "query">
) {
  return Urql.useQuery<MetadataKindsQuery>({ query: MetadataKindsDocument, ...options });
}
export const WorkspacesDocument = gql`
  query Workspaces {
    workspace {
      id
    }
  }
`;

export function useWorkspacesQuery(
  options?: Omit<Urql.UseQueryArgs<WorkspacesQueryVariables>, "query">
) {
  return Urql.useQuery<WorkspacesQuery>({ query: WorkspacesDocument, ...options });
}
export const WorkspacebyGeoGroupandExperimentsDocument = gql`
  query WorkspacebyGeoGroupandExperiments {
    workspace {
      id
      kind
    }
  }
`;

export function useWorkspacebyGeoGroupandExperimentsQuery(
  options?: Omit<Urql.UseQueryArgs<WorkspacebyGeoGroupandExperimentsQueryVariables>, "query">
) {
  return Urql.useQuery<WorkspacebyGeoGroupandExperimentsQuery>({
    query: WorkspacebyGeoGroupandExperimentsDocument,
    ...options,
  });
}
