const MAPBOX_ACCESS_TOKEN =
  "pk.eyJ1IjoiYnJlZ3kiLCJhIjoiY2txd2lucmk1MDBxazJvbzcyeDZyMXBubyJ9.VdiuEyhmzBgJORxU-AUqMw";

const REACT_APP_ENDPOINT = "https://nvi-cajamarca-api.innovalab.minsky.cc/v1/graphql";

const CHAGAS_ENDPOINT = "https://nvi-cajamarca-data-pipeline.innovalab.minsky.cc/api/reports";

const REACT_CHAGAS_INSPECTIONS_ENDPOINT = `${CHAGAS_ENDPOINT}/inspections`;

const REACT_CHAGAS_SPRAYED_ENDPOINT = `${CHAGAS_ENDPOINT}/sprayed`;

const REACT_CHAGAS_ADVERTISEMENT_ENDPOINT = `${CHAGAS_ENDPOINT}/advertisement`;

const REACT_CHAGAS_COMPLAINT_ENDPOINT = `${CHAGAS_ENDPOINT}/complaint`;

export {
  MAPBOX_ACCESS_TOKEN,
  REACT_APP_ENDPOINT,
  REACT_CHAGAS_INSPECTIONS_ENDPOINT,
  REACT_CHAGAS_SPRAYED_ENDPOINT,
  REACT_CHAGAS_ADVERTISEMENT_ENDPOINT,
  REACT_CHAGAS_COMPLAINT_ENDPOINT,
};
