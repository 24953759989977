import {
  Box,
  Button,
  Checkbox,
  createStyles,
  MultiSelect,
  Select,
  Stack,
  Title,
} from "@mantine/core";
import { useEffect, useState } from "react";
import { ChevronDown, FileDownload } from "tabler-icons-react";

import { useGroupsQuery } from "#root/generated/graphql";
import { getDataToExport, makeCsv } from "#root/lib/makeCsv";

const useStyles = createStyles(theme => ({
  select: {
    maxWidth: 250,
  },
  selectInput: {
    maxHeight: 60,
    paddingRight: 15,
    overflowY: "scroll",
    overflowX: "auto",
  },
  selectRightSection: {
    display: "none",
  },
  container: {
    height: "100%",
    [theme.fn.largerThan("xs")]: {
      margin: "auto",
    },
  },
  form: {
    width: 460,
    backgroundColor: theme.colors.gray[1],
    [theme.fn.smallerThan("xs")]: {
      width: "100%",
    },
  },
}));

const SimpleView = () => {
  const { classes } = useStyles();
  const [groups, setGroups] = useState([]);
  const [selectedGroups, setSelectedGroups] = useState([]);
  const [isGroupSelectDisabled, setIsGroupSelectDisabled] = useState(false);
  const [resource, setResource] = useState<string>("inspection");
  const [isFetching, setIsFetching] = useState<boolean>(false);
  const [{ data: resultGroups, fetching: isFetchingGroups }] = useGroupsQuery({
    requestPolicy: "network-only",
  });

  useEffect(() => {
    const getGroups = () => {
      const dataGroups = resultGroups
        ? resultGroups.geogroup.map(item => {
            return { value: item.id, label: item.name };
          })
        : [];
      setGroups(dataGroups);
    };
    getGroups();
  }, [resultGroups]);

  const handleSelectAllGroups = e => {
    const dataGroups = groups.map(item => item.value);
    if (e.target.checked) {
      setSelectedGroups(dataGroups);
      setIsGroupSelectDisabled(true);
    } else {
      setSelectedGroups([]);
      setIsGroupSelectDisabled(false);
    }
  };

  const handleDowloadCsv = async () => {
    setIsFetching(true);
    const response = await getDataToExport({
      resource: resource,
      groups: selectedGroups,
      advanced: false,
      setIsFetching: setIsFetching,
    });

    if (!response) {
      return null;
    }

    return makeCsv(response, `${resource}.csv`);
  };

  return (
    <Box className={classes.container}>
      <Stack sx={{ marginTop: 50 }}>
        <Title order={2} align="center">
          Descargar reporte
        </Title>
        <Stack spacing={20} p={20} className={classes.form}>
          <MultiSelect
            searchable
            required
            label="Grupos"
            placeholder="Seleccionar grupo"
            data={groups}
            onChange={setSelectedGroups}
            disabled={isGroupSelectDisabled || isFetchingGroups}
            classNames={{
              input: classes.selectInput,
              rightSection: classes.selectRightSection,
            }}
          />
          <Checkbox
            label="Seleccionar todos"
            onChange={e => {
              handleSelectAllGroups(e);
            }}
          />

          <Select
            label="Reporte"
            placeholder="Tipo de reporte"
            value={resource}
            onChange={setResource}
            data={[
              { value: "inspection", label: "Inspecciones" },
              { value: "sprayed", label: "Rociados" },
              { value: "advertisement", label: "Publicidades" },
              { value: "complaint", label: "Denuncias" },
            ]}
            rightSection={<ChevronDown size={14} />}
            styles={{
              input: {
                "&::placeholder": {
                  fontFamily: "sans-serif",
                },
              },
              rightSection: { pointerEvents: "none" },
            }}
          />
          <Stack justify={"center"}>
            <Button
              size="sm"
              loading={isFetching}
              disabled={selectedGroups.length >= 1 ? false : true}
              onClick={() => handleDowloadCsv()}
              leftIcon={<FileDownload size={16} />}
            >
              Descargar
            </Button>
          </Stack>
        </Stack>
      </Stack>
    </Box>
  );
};

export default SimpleView;
