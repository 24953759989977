import { Button, Checkbox, createStyles, Group, MultiSelect, Select, Stack } from "@mantine/core";
import { showNotification } from "@mantine/notifications";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { ChevronDown, Database, FileDownload } from "tabler-icons-react";
import { addDataToMap } from "kepler.gl/actions";

import {
  useExperimentsByGeogroupQuery,
  useGroupsQuery,
  useMetadataKindsQuery,
  useTableWorkspaceMetadataQuery,
} from "#root/generated/graphql";
import useFormate from "#root/lib/formate";
import { getDataToExport, makeCsv } from "#root/lib/makeCsv";
import datasetConfig from "#root/lib/datasetConfig";

type AdvancedViewProps = {
  section: string;
  toggleOpened?: (opened: boolean) => void;
};

const useStyles = createStyles(theme => ({
  desktopComponent: {
    [theme.fn.smallerThan("lg")]: {
      display: "none",
    },
  },
  filterContainer: {
    flexDirection: "column",
  },
  select: {
    maxWidth: 250,
  },
  selectInput: {
    maxHeight: 60,
    paddingRight: 15,
    overflowY: "scroll",
    overflowX: "auto",
  },
  selectRightSection: {
    display: "none",
  },
}));

const AdvancedView = ({ section, toggleOpened }: AdvancedViewProps) => {
  const { classes } = useStyles();
  const { formatDate, formateDatasetTitle, kindName } = useFormate();
  const [groups, setGroups] = useState([]);
  const [selectedGroups, setSelectedGroups] = useState([]);
  const [selectedGeometries, setSelectedGeometries] = useState([]);
  const [experiments, setExperiments] = useState([]);
  const [selectedExperiments, setSelectedExperiments] = useState<string[] | undefined>(undefined);
  const [kinds, setKinds] = useState([]);
  const [selectedKind, setSelectedKind] = useState("infection_model");
  const [isDisabled, setIsDisabled] = useState(false);
  const [isGroupSelectDisabled, setIsGroupSelectDisabled] = useState(false);
  const [isFetchingReport, setIsFetchingReport] = useState<boolean>(false);
  const [{ data: resultGroups, fetching: isFetchingGroups }] = useGroupsQuery({
    requestPolicy: "network-only",
  });
  const [resultExperiment] = useExperimentsByGeogroupQuery({
    variables: { geogroups: selectedGroups },
  });
  const [{ data: resultKinds, fetching: isfetchingKinds }] = useMetadataKindsQuery();
  const [{ data: resultMetadata, fetching: isLoading }, fetchMetadataPoints] =
    useTableWorkspaceMetadataQuery({
      pause: true,
      variables: {
        input: {
          //Retirar registros de prueba para rociados
          /* workspace:
            selectedKind === "sprayed"
              ? {
                  owner: {
                    email: { _neq: "amaru@minsky.cc" },
                  },
                }
              : undefined, */
          kind: { _eq: selectedKind },
          geopoint: {
            geometry: {
              _st_within: {
                type: "MultiPolygon",
                coordinates: selectedGeometries,
              },
            },
          },
          /* geogroup_id: { _in: selectedGroups }, */
          experiment_id: selectedExperiments?.length ? { _in: selectedExperiments } : undefined,
        },
      },
    });
  const dispatch = useDispatch();

  useEffect(() => {
    const getGroups = () => {
      const dataGroups = resultGroups
        ? resultGroups.geogroup.map(item => {
            return { value: item.id, label: item.name };
          })
        : [];
      setGroups(dataGroups);
    };
    getGroups();
  }, [resultGroups]);

  useEffect(() => {
    const getExperiments = () => {
      const { data } = resultExperiment;

      const dataExperiments = data
        ? data.experiment.map(item => {
            return {
              value: item.id,
              label: formatDate(item.created_at),
            };
          })
        : [];
      setExperiments(dataExperiments);
    };
    getExperiments();
  }, [resultExperiment]);

  useEffect(() => {
    const getKind = () => {
      const dataKinds = resultKinds
        ? resultKinds.workspace_metadata_aggregate.nodes.map(item => {
            return { value: item.kind, label: kindName(item.kind) };
          })
        : [];
      dataKinds.push(
        { value: "advertisement", label: "publicidades" },
        { value: "complaint", label: "denuncias" }
      );
      setKinds(dataKinds);
    };
    getKind();
  }, [resultKinds]);

  useEffect(() => {
    const dataMetadata = resultMetadata?.workspace_metadata.map(item => {
      const commonData = {
        created_at: item.created_at,
        experiment: item.experiment.id.substring(item.experiment.id.length - 4),
        lat: item.geopoint.lat,
        lng: item.geopoint.lng,
        unicode: item.geopoint.unicode,
        geogroup: item.geogroup.name,
        type: item.data.type,
      };
      let dataByKind = {};
      switch (selectedKind) {
        case "infection_model":
          dataByKind = {
            model: item.data.data.model,
            probability: item.data.data.probability,
            uncertainty: item.data.data.uncertainty,
            accuracy: 1 - item.data.data.uncertainty,
            time: item.data.data.time,
          };
          break;
        case "bluepoint_mod":
        case "vectorpoint_mod":
          dataByKind = {
            group: item.data.data.group,
            order: item.data.data.order,
            risk: item.data.data.risk,
            revision: item.data.data.revision,
          };
          break;
        case "inspection":
          dataByKind = {
            data_created_at: item.data.data.created_at,
            status: item.data.data.estado_inspeccion,
            result: item.data.data.result,
            /* peri_event: item.data.data.peri_event,
            intra_event: item.data.data.intra_event, */
          };
          break;
        case "sprayed":
          dataByKind = {
            status: item.data.data.status,
            result: item.data.data.result,
          };
          break;
      }
      return { ...commonData, ...dataByKind };
    });

    if (dataMetadata && !dataMetadata.length) {
      //Mostrar notificacion si no hay datos
      toggleOpened && toggleOpened(false);
      showNotification({
        title: "No hay datos!",
        message: "Seleccione otros grupos y experimentos.",
      });
    }

    if (dataMetadata?.length) {
      const dataset = datasetConfig({ selectedKind, dataMetadata, formateDatasetTitle });
      //Agregar datos al mapa
      dispatch(addDataToMap(dataset));
      toggleOpened && toggleOpened(false);
    }
  }, [resultMetadata]);

  useEffect(() => {
    //Obtener geometrias de grupos seleccionados
    const data = selectedGroups.length
      ? resultGroups.geogroup.reduce((acc, curr) => {
          if (selectedGroups.includes(curr.id)) {
            return [...acc, curr.geometry["coordinates"]];
          }

          return [...acc];
        }, [])
      : [];

    setSelectedGeometries(data);
  }, [selectedGroups]);

  const getMetadata = () => {
    fetchMetadataPoints();
  };

  const handleGroupsChange = e => {
    setSelectedGroups(e);
    setSelectedExperiments(undefined);
  };

  const handleExperimentChange = e => {
    setSelectedExperiments(e);
  };

  const handleKindChange = e => {
    setSelectedKind(e);
  };

  const handleLoad = () => {
    getMetadata();
  };

  const handleSelectAllGroups = e => {
    const dataGroups = groups.map(item => item.value);
    if (e.target.checked) {
      setSelectedGroups(dataGroups);
      setIsGroupSelectDisabled(true);
    } else {
      setSelectedGroups([]);
      setSelectedGeometries([]);
      setIsGroupSelectDisabled(false);
    }
  };

  const handleSelectAllExperiments = e => {
    const dataExperiments = experiments.map(item => item.value);
    if (e.target.checked) {
      setSelectedExperiments(dataExperiments);
      setIsDisabled(true);
    } else {
      setSelectedExperiments(undefined);
      setIsDisabled(false);
    }
  };

  const handleDowloadCsv = async () => {
    setIsFetchingReport(true);
    const response = await getDataToExport({
      resource: selectedKind,
      groups: selectedGroups,
      advanced: true,
      setIsFetching: setIsFetchingReport,
    });

    if (!response) {
      return null;
    }

    return makeCsv(response, `${selectedKind}.csv`);
  };

  const downloadBtnState = ["inspection", "sprayed", "advertisement", "complaint"].includes(
    selectedKind
  );

  return (
    <Group
      align="stretch"
      className={section === "header" ? classes.desktopComponent : classes.filterContainer}
    >
      <Stack>
        <MultiSelect
          data={groups}
          label="Grupos"
          placeholder="Seleccionar grupo"
          required
          onChange={e => handleGroupsChange(e)}
          className={section === "header" ? classes.select : ""}
          classNames={{
            input: classes.selectInput,
            rightSection: classes.selectRightSection,
          }}
          searchable
          disabled={isGroupSelectDisabled || isFetchingGroups}
        />
        <Checkbox
          label="Seleccionar todos"
          onChange={e => {
            handleSelectAllGroups(e);
          }}
        />
      </Stack>
      <Stack>
        <MultiSelect
          data={experiments}
          label="Experimentos"
          placeholder="Seleccionar experimento"
          onChange={e => handleExperimentChange(e)}
          className={section === "header" ? classes.select : ""}
          classNames={{
            input: classes.selectInput,
            rightSection: classes.selectRightSection,
          }}
          searchable
          disabled={isDisabled}
        />
        <Checkbox
          label="Seleccionar todos"
          onChange={e => {
            handleSelectAllExperiments(e);
          }}
        />
      </Stack>
      <Select
        disabled={isfetchingKinds}
        data={kinds}
        defaultValue={"infection_model"}
        label="Tipo"
        placeholder="Seleccionar tipo"
        required
        onChange={e => handleKindChange(e)}
        rightSection={<ChevronDown size={14} />}
        styles={{ rightSection: { pointerEvents: "none" } }}
      />
      <Stack justify={"center"}>
        <Button
          size="sm"
          onClick={() => handleLoad()}
          loading={isLoading}
          disabled={selectedGroups.length >= 1 && selectedKind.length >= 1 ? false : true}
          leftIcon={<Database size={16} />}
        >
          Agregar datos
        </Button>
        <Button
          size="sm"
          loading={isFetchingReport}
          disabled={!downloadBtnState}
          onClick={() => handleDowloadCsv()}
          leftIcon={<FileDownload size={16} />}
        >
          Descargar
        </Button>
      </Stack>
    </Group>
  );
};

export default AdvancedView;
