import dayjs from "dayjs";
import localizedFormat from "dayjs/plugin/localizedFormat";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import "dayjs/locale/es";

const useFormate = () => {
  const formatDate = date => {
    dayjs.extend(localizedFormat);
    dayjs.extend(utc);
    dayjs.extend(timezone);
    dayjs.locale("es");

    return dayjs(date).tz("GMT").format("lll");
  };

  const kindName = (kind: string) => {
    switch (kind) {
      case "bluepoint_mod":
        return "resultados_puntosazules";
      case "infection_model":
        return "resultados_modelo";
      case "inspection":
        return "inspecciones";
      case "sprayed":
        return "rociados";
      case "vectorpoint_mod":
        return "riesgo_inicial_final";
      case "vectorpoint_radio":
        return "radios";
    }
  };

  const formateDatasetTitle = (selectedKind: string) => {
    const word = kindName(selectedKind).split("_");
    const kindTitle = word
      .map(item => {
        return item[0].toUpperCase() + item.substring(1);
      })
      .join(" ");

    return kindTitle;
  };

  return { formatDate, formateDatasetTitle, kindName };
};

export default useFormate;
