import { showNotification } from "@mantine/notifications";
import { useState, createContext, ReactNode } from "react";
import { useNavigate } from "react-router-dom";
import { X } from "tabler-icons-react";
import { signInWithEmailAndPassword, signOut } from "firebase/auth";

import { auth } from "#root/lib/firebase/config";

type AuthProviderProps = {
  children: ReactNode;
};

type LoginWithFirebaseProps = {
  email: string;
  password: string;
  setIsLoading: (isLoading: boolean) => void;
};

export const AuthContext = createContext(null);

const AuthProvider = ({ children }: AuthProviderProps) => {
  const navigate = useNavigate();
  const [email, setEmail] = useState<string>("");

  const loginFirebase = ({ email, password, setIsLoading }: LoginWithFirebaseProps) => {
    signInWithEmailAndPassword(auth, email, password)
      .then(userCredential => {
        const user = userCredential.user;
        //Get id token
        user.getIdToken().then(token => {
          localStorage.setItem("token", token);
          navigate("/", { replace: true });
          setIsLoading(false);
        });
      })
      .catch(error => {
        setIsLoading(false);
        showNotification({
          title: "Authentication error!",
          message: error.message,
          icon: <X />,
        });
      });
  };

  const logoutFirebase = async () => {
    try {
      await signOut(auth);
      localStorage.removeItem("token");
      navigate("/login", { replace: true });
    } catch (error) {
      console.error({ error: error });
    }
  };

  return (
    <AuthContext.Provider
      value={{
        email,
        setEmail,
        loginFirebase,
        logoutFirebase,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthProvider;
