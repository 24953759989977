import KeplerGl from "kepler.gl";
import { connect } from "react-redux";
import AutoSizer from "react-virtualized/dist/commonjs/AutoSizer";
import styled from "styled-components";
import HeaderNvi from "../../Header/Header";
import { MAPBOX_ACCESS_TOKEN } from "#root/config";
import { useContext, useEffect, useState } from "react";
import jwtDecode from "jwt-decode";
import { AuthContext } from "#root/context/AuthenticationContext";
import SimpleView from "#root/components/Header/Views/SimpleView";
import { useCollaboratorQuery } from "#root/generated/graphql";

const StyledWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100vw;
  height: 100vh;
`;

const KeplerWrapper = styled.div`
  height: 100%;
`;

const Observatory = () => {
  const { logoutFirebase, email, setEmail } = useContext(AuthContext);
  const [view, setView] = useState<string>("simple");

  const userId = localStorage.getItem("userId");

  const [{ data: userData }] = useCollaboratorQuery({
    variables: { auth_id: userId },
    requestPolicy: "network-only",
  });

  useEffect(() => {
    const userEmail =
      userData && userData?.collaborator.length ? userData?.collaborator[0].email : email;
    setEmail(userEmail);
  }, [userData]);

  //Decode Token
  const token = localStorage.getItem("token");
  const decode = jwtDecode(token);
  const exp = decode["exp"];
  const expiredDate = new Date(exp * 1000);

  useEffect(() => {
    //Check expired token
    if (new Date() > expiredDate) {
      logoutFirebase();
    }
  }, []);

  return (
    <StyledWrapper>
      <HeaderNvi view={view} setView={setView} />
      {view === "advance" ? (
        <KeplerWrapper>
          <AutoSizer>
            {({ height, width }) => (
              <KeplerGl
                mapboxApiAccessToken={MAPBOX_ACCESS_TOKEN}
                id="map1"
                height={height}
                width={width}
              />
            )}
          </AutoSizer>
        </KeplerWrapper>
      ) : (
        <SimpleView />
      )}
    </StyledWrapper>
  );
};

const mapStateToProps = (state: any) => state;
const dispatchToProps = (dispatch: any) => ({ dispatch });

export default connect(mapStateToProps, dispatchToProps)(Observatory);
