import { Tuple } from "@mantine/core";

export const nviBrand: Tuple<string, 10> = [
  "#E1E7F9",
  "#C1D0F6",
  "#A2B9F5",
  "#83A2F3",
  "#6F94F4",
  "#5481F3",
  "#3D70F4",
  "#3865D9",
  "#1B4FD6",
  "#0F40BF",
];
